/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface LoginDto { 
    email: string;
    password: string;
    persistent?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of LoginDto.
 */
export namespace LoginDto {
    /**
     * All properties of LoginDto.
     */
    export enum Properties {
        email = 'email',
        password = 'password',
        persistent = 'persistent'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of LoginDto.
    */
    export const ModelValidators: {[K in keyof LoginDto]: [string, ValidatorFn][]} = {
        email: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        password: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
        persistent: [
        ],
    };

    /**
    * The FormControlFactory for LoginDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<LoginDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for LoginDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: LoginDto = {
            email: null,
            password: null,
            persistent: null,
          }
        ) {
            super(model, LoginDto.ModelValidators);
        }
    }

}


