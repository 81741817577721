<div class="user-chat-host" [ngClass]="{'maximized': windowMaximized}"
     *ngIf="hasSessions() && userChatService.showChatWindow">
  <div class="titleRow">
    <span>Private chat messages</span>
    <i (click)="minimize($event)" *ngIf="windowMaximized" class="closeButton far fa-window-restore fa-pull-right"
       style="cursor: pointer;"
       i18n-title="userChat.minimizeChatWindow" title="Minimize"></i>
    <i (click)="maximize($event)" *ngIf="!windowMaximized"
       class="closeButton far fa-window-maximize fa-pull-right"
       style="cursor: pointer;"
       i18n-title="userChat.maximizeChatWindow" title="Maximize"></i>
    <i class="closeButton far fa-window-minimize fa-pull-right" style="cursor: pointer;" (click)="close($event)"
       i18n-title="userChat.hideChatWindow" title="Hide"></i>
  </div>
  <div class="chatSessions">
      <div class="session-header" [ngClass]="{'maximized': windowMaximized}">
        <div class="chatNames">
          <ul>
            <ng-container *ngFor="let selfId of userChatService.sessions | keyvalue">
              <li *ngFor="let session of selfId.value | keyvalue"
                  [ngClass]="{'active': session.value == selectedSession }"
                  (click)="chooseChat($event, session.value)">{{ session.value.partnerName }}
                <span class="messageCount" *ngIf="session.value.unreadMessages > 0">({{session.value.unreadMessages}}
                  )</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div style="flex: 1 1 auto;">
        <app-user-chat-session [session]="selectedSession" (unreadChanged)="recountUnread()"
                               (hideSession)="hideSession($event)" (closeSession)="closeSession($event)"
                               *ngIf="selectedSession"></app-user-chat-session>
      </div>
  </div>
</div>

