/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MongoChatMessage } from './mongoChatMessage';


export interface MongoChatItemDto { 
    selfId?: string;
    partnerId?: string;
    partnerName?: string;
    isClosed?: boolean;
    isBlocked?: boolean;
    timestamp?: Date;
    totalMessages?: number;
    lastRead?: number;
    chatMessages?: Array<MongoChatMessage>;
}

/**
 * Namespace for property- and property-value-enumerations of MongoChatItemDto.
 */
export namespace MongoChatItemDto {
    /**
     * All properties of MongoChatItemDto.
     */
    export enum Properties {
        selfId = 'selfId',
        partnerId = 'partnerId',
        partnerName = 'partnerName',
        isClosed = 'isClosed',
        isBlocked = 'isBlocked',
        timestamp = 'timestamp',
        totalMessages = 'totalMessages',
        lastRead = 'lastRead',
        chatMessages = 'chatMessages'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MongoChatItemDto.
    */
    export const ModelValidators: {[K in keyof MongoChatItemDto]: [string, ValidatorFn][]} = {
        selfId: [
        ],
        partnerId: [
        ],
        partnerName: [
        ],
        isClosed: [
        ],
        isBlocked: [
        ],
        timestamp: [
        ],
        totalMessages: [
        ],
        lastRead: [
        ],
        chatMessages: [
        ],
    };

    /**
    * The FormControlFactory for MongoChatItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MongoChatItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MongoChatItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MongoChatItemDto = {
            selfId: null,
            partnerId: null,
            partnerName: null,
            isClosed: null,
            isBlocked: null,
            timestamp: null,
            totalMessages: null,
            lastRead: null,
            chatMessages: null,
          }
        ) {
            super(model, MongoChatItemDto.ModelValidators);
        }
    }

}


