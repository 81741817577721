<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/">
    <img class="img-fluid" src="/assets/logo-wide.png" alt="ezFair by Nethotline UG (haftungsbeschränkt)"
         style="max-height: 1.5em;"/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          (click)="navCollapsed = !navCollapsed"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show': !navCollapsed}">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngIf="hasRole(['Manager'])">
        <a class="nav-link" [routerLink]="['/', 'admin']">
          Platform
        </a>
      </li>
      <li class="nav-item" *ngIf="hasRole(['Organizer', 'Manager'])">
        <a class="nav-link" [routerLink]="['/', 'organizer']">
          Event
        </a>
      </li>
      <li class="nav-item" *ngIf="hasRole(['Exhibitor', 'Organizer', 'Manager'])">
        <a class="nav-link" [routerLink]="['/', 'exhibitor']">
          Exhibitor
        </a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', 'user', 'profile']" i18n-title title="Edit profile">
          {{ givenName }}
        </a>
      </li>
      <li class="nav-item" style="margin-right: 2em;">
        <app-user-chat-notification></app-user-chat-notification>
      </li>
<!--      <li class="nav-item" style="margin-right: 1em;">-->
<!--        <a href="#" class="nav-link" (click)="signOut($event)"><i-->
<!--          class="far fa fa-power-off fa-2x text-danger"> </i></a>-->
<!--      </li>-->
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarUser" role="button">
          <i class="far fa fa-user" style="font-size: 1.5em"> </i>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUser" class="dropdown-menu">
          <a ngbDropdownItem href="#" (click)="setPassword($event)">Change/set password</a>
          <a ngbDropdownItem href="#" (click)="editProfile($event)">Profile</a>
          <a href="#" class="nav-link" (click)="signOut($event)">
            <i class="far fa fa-power-off text-danger"> </i> Logout
          </a>
        </div>
      </li>
    </ul>
    <!-- END Left navbar-->
  </div>
</nav>
<!-- END Top Navbar-->
