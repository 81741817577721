/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { AccessMode } from './accessMode';


export interface VHost { 
    domain?: string;
    expoName?: string;
    expoShortKey?: string;
    exhibitorName?: string;
    exhibitorShortKey?: string;
    showHall?: boolean;
    defaultLanguage?: string;
    accessMode?: AccessMode;
    privacy?: string;
    homepage?: string;
    logo?: string;
    landingPagePortrait?: string;
    landingPageLandscape?: string;
    registrationBackgroundImagePortrait?: string;
    registrationBackgroundImage?: string;
    descriptionEntry?: string;
    hasAccess?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of VHost.
 */
export namespace VHost {
    /**
     * All properties of VHost.
     */
    export enum Properties {
        domain = 'domain',
        expoName = 'expoName',
        expoShortKey = 'expoShortKey',
        exhibitorName = 'exhibitorName',
        exhibitorShortKey = 'exhibitorShortKey',
        showHall = 'showHall',
        defaultLanguage = 'defaultLanguage',
        accessMode = 'accessMode',
        privacy = 'privacy',
        homepage = 'homepage',
        logo = 'logo',
        landingPagePortrait = 'landingPagePortrait',
        landingPageLandscape = 'landingPageLandscape',
        registrationBackgroundImagePortrait = 'registrationBackgroundImagePortrait',
        registrationBackgroundImage = 'registrationBackgroundImage',
        descriptionEntry = 'descriptionEntry',
        hasAccess = 'hasAccess'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of VHost.
    */
    export const ModelValidators: {[K in keyof VHost]: [string, ValidatorFn][]} = {
        domain: [
        ],
        expoName: [
        ],
        expoShortKey: [
        ],
        exhibitorName: [
        ],
        exhibitorShortKey: [
        ],
        showHall: [
        ],
        defaultLanguage: [
        ],
        accessMode: [
        ],
        privacy: [
        ],
        homepage: [
        ],
        logo: [
        ],
        landingPagePortrait: [
        ],
        landingPageLandscape: [
        ],
        registrationBackgroundImagePortrait: [
        ],
        registrationBackgroundImage: [
        ],
        descriptionEntry: [
        ],
        hasAccess: [
        ],
    };

    /**
    * The FormControlFactory for VHost.
    */
    export class FormControlFactory extends BaseFormControlFactory<VHost> {

        /**
         * Constructor.
         *
         * @param model An existing model for VHost.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: VHost = {
            domain: null,
            expoName: null,
            expoShortKey: null,
            exhibitorName: null,
            exhibitorShortKey: null,
            showHall: null,
            defaultLanguage: null,
            accessMode: null,
            privacy: null,
            homepage: null,
            logo: null,
            landingPagePortrait: null,
            landingPageLandscape: null,
            registrationBackgroundImagePortrait: null,
            registrationBackgroundImage: null,
            descriptionEntry: null,
            hasAccess: null,
          }
        ) {
            super(model, VHost.ModelValidators);
        }
    }

}


