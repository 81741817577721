/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CalendarEntrySessionDataDto { 
    id: number;
    name?: string;
    password?: string;
    stream?: string;
    streamAdditional?: string;
    managerEmailUpdate?: string;
    managerName?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarEntrySessionDataDto.
 */
export namespace CalendarEntrySessionDataDto {
    /**
     * All properties of CalendarEntrySessionDataDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        password = 'password',
        stream = 'stream',
        streamAdditional = 'streamAdditional',
        managerEmailUpdate = 'managerEmailUpdate',
        managerName = 'managerName'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarEntrySessionDataDto.
    */
    export const ModelValidators: {[K in keyof CalendarEntrySessionDataDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
        ],
        name: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        password: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        stream: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        managerEmailUpdate: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        managerName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
    };

    /**
    * The FormControlFactory for CalendarEntrySessionDataDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarEntrySessionDataDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarEntrySessionDataDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarEntrySessionDataDto = {
            id: null,
            name: null,
            password: null,
            stream: null,
            streamAdditional: null,
            managerEmailUpdate: null,
            managerName: null,
          }
        ) {
            super(model, CalendarEntrySessionDataDto.ModelValidators);
        }
    }

}


