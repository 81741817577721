/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BoothSize } from './boothSize';


export interface BoothTypeDto { 
    id?: number;
    size?: BoothSize;
    name?: string;
    description?: string;
    previewImage?: string;
    colors?: number;
    hasPosterA?: number;
    hasPosterB?: number;
    hasInfoScreen?: number;
    enabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of BoothTypeDto.
 */
export namespace BoothTypeDto {
    /**
     * All properties of BoothTypeDto.
     */
    export enum Properties {
        id = 'id',
        size = 'size',
        name = 'name',
        description = 'description',
        previewImage = 'previewImage',
        colors = 'colors',
        hasPosterA = 'hasPosterA',
        hasPosterB = 'hasPosterB',
        hasInfoScreen = 'hasInfoScreen',
        enabled = 'enabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothTypeDto.
    */
    export const ModelValidators: {[K in keyof BoothTypeDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        size: [
        ],
        name: [
        ],
        description: [
        ],
        previewImage: [
        ],
        colors: [
        ],
        hasPosterA: [
        ],
        hasPosterB: [
        ],
        hasInfoScreen: [
        ],
        enabled: [
        ],
    };

    /**
    * The FormControlFactory for BoothTypeDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothTypeDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothTypeDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothTypeDto = {
            id: null,
            size: null,
            name: null,
            description: null,
            previewImage: null,
            colors: null,
            hasPosterA: null,
            hasPosterB: null,
            hasInfoScreen: null,
            enabled: null,
          }
        ) {
            super(model, BoothTypeDto.ModelValidators);
        }
    }

}


