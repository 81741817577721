<!--    <button class="mb-lg btn btn-secondary" type="button" (click)="changePassword($event)">Change password</button>-->
<div class="card">
  <div class="card-header">
<!--    <a routerLink="" class="btn btn-success" style="float: right;" target="_blank">-->
<!--      <span>Change password</span>-->
<!--      <i class="fa fa-user-circle ml-2"></i>-->
<!--    </a>-->
    <h3>Profile</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="valForm" class="form-validate mb-lg" role="form">
      <div class="row" *ngIf="profile">
        <div class="col-sm-12 col-md-8">
          <div class="form-group has-feedback">
            <label for="name" i18n="profile.displayName">Display name</label>
            <input class="form-control" id="name" type="text" name="name"
                   i18n-placeholder="profile.displayNamePlaceholder" placeholder="Your displayed name"
                   autocomplete="off"
                   formControlName="name" required=""/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="name"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="company" i18n="profile.company">Company</label>
            <input class="form-control" id="company" type="text" name="company"
                   i18n-placeholder="profile.companyPlaceholder" placeholder="Company name"
                   autocomplete="off"
                   formControlName="company"/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="company"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="company" i18n="profile.role">Role</label>
            <input class="form-control" id="role" type="text" name="role"
                   i18n="profile.rolePlaceholder" placeholder="Your role"
                   autocomplete="off"
                   formControlName="role"/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="role"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="address" i18n="profile.address">Address</label>
            <input class="form-control" id="address" type="text" name="address"
                   i18n-placeholder="profile.addressPlaceholder" placeholder="Your address"
                   autocomplete="off"
                   formControlName="address"/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="address"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="phoneNumber" i18n="profile.phoneNumber">Phone number</label>
            <input class="form-control" id="phoneNumber" type="tel" name="phoneNumber"
                   i18n-placeholder="profile.phoneNumberPlaceholder" placeholder="Your phone number"
                   autocomplete="off"
                   formControlName="phoneNumber"/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="phoneNumber"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="email" [translate]="'profile.email'"></label>
            <input class="form-control" id="email" type="email" name="email"
                   i18n-placeholder="profile.emailPlaceholder" placeholder="Your email address"
                   autocomplete="off"
                   formControlName="email"/>
            <app-control-validation-results [formGroup]="valForm" validatedControl="email"></app-control-validation-results>
          </div>
          <div class="card">
            <div class="card-body">
              <label style="width: 100%;">Social networks
                <button class="btn btn-xs btn-primary float-right" (click)="addProfile($event)">Add</button>
              </label>
              <div class="row social" *ngFor="let item of profile.socialProfiles | keyvalue">
                <div class="col-sm-4" [translate]="'profile.' + item.key"></div>
                <div class="col-sm-4">
                  {{ item.value }}
                </div>
                <div class="col-sm-4">
                  <div class="float-right">
                    <i class="btn btn-xs btn-success fa fa-edit" (click)="editSocial($event, item)"> </i> &nbsp;
                    <i class="btn btn-xs btn-danger fa fa-trash" (click)="removeSocial($event, item)"> </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div>
            <app-upload #wideLogo [label]="'Profile image'" [imageUrl]="profile.image" [imageId]="profile.image"
                        [dynamic]="true"
                        (imageUploaded)="onImageAdded($event)" [width]="512" [height]="512"></app-upload>
          </div>
        </div>
        <div class="clearfix">
          <span class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</span>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="container-fluid row">
      <div class="col-md-2">
        <a class="btn btn-block btn-danger" [routerLink]="['/']">Cancel</a>
      </div>
      <div class="col-md-6">
        &nbsp;
      </div>
      <div class="col-md-4">
        <a class="btn btn-block btn-success" href="#" (click)="submitForm($event)">Save</a>
      </div>
    </div>
  </div>
</div>
