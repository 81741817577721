/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StageMode } from './stageMode';


export interface MStageListItem { 
    id?: string;
    shortKey?: string;
    name?: string;
    label?: string;
    logoWide?: string;
    logoSquare?: string;
    description?: string;
    sequence?: number;
    hideOnLanding?: boolean;
    hidden?: boolean;
    deleted?: boolean;
    exhibitorId?: string;
    exhibitorName?: string;
    stageMode?: StageMode;
}

/**
 * Namespace for property- and property-value-enumerations of MStageListItem.
 */
export namespace MStageListItem {
    /**
     * All properties of MStageListItem.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        label = 'label',
        logoWide = 'logoWide',
        logoSquare = 'logoSquare',
        description = 'description',
        sequence = 'sequence',
        hideOnLanding = 'hideOnLanding',
        hidden = 'hidden',
        deleted = 'deleted',
        exhibitorId = 'exhibitorId',
        exhibitorName = 'exhibitorName',
        stageMode = 'stageMode'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MStageListItem.
    */
    export const ModelValidators: {[K in keyof MStageListItem]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(10)],
        ],
        name: [
        ],
        label: [
        ],
        logoWide: [
        ],
        logoSquare: [
        ],
        description: [
        ],
        sequence: [
        ],
        hideOnLanding: [
        ],
        hidden: [
        ],
        deleted: [
        ],
        exhibitorId: [
        ],
        exhibitorName: [
        ],
        stageMode: [
        ],
    };

    /**
    * The FormControlFactory for MStageListItem.
    */
    export class FormControlFactory extends BaseFormControlFactory<MStageListItem> {

        /**
         * Constructor.
         *
         * @param model An existing model for MStageListItem.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MStageListItem = {
            id: null,
            shortKey: null,
            name: null,
            label: null,
            logoWide: null,
            logoSquare: null,
            description: null,
            sequence: null,
            hideOnLanding: null,
            hidden: null,
            deleted: null,
            exhibitorId: null,
            exhibitorName: null,
            stageMode: null,
          }
        ) {
            super(model, MStageListItem.ModelValidators);
        }
    }

}


