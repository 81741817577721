/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface VisitorListDto { 
    timestamp?: Date;
    exhibitorName?: string;
    name?: string;
    emailAddress?: string;
    city?: string;
    country?: string;
    label?: string;
}

/**
 * Namespace for property- and property-value-enumerations of VisitorListDto.
 */
export namespace VisitorListDto {
    /**
     * All properties of VisitorListDto.
     */
    export enum Properties {
        timestamp = 'timestamp',
        exhibitorName = 'exhibitorName',
        name = 'name',
        emailAddress = 'emailAddress',
        city = 'city',
        country = 'country',
        label = 'label'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of VisitorListDto.
    */
    export const ModelValidators: {[K in keyof VisitorListDto]: [string, ValidatorFn][]} = {
        timestamp: [
        ],
        exhibitorName: [
        ],
        name: [
        ],
        emailAddress: [
        ],
        city: [
        ],
        country: [
        ],
        label: [
        ],
    };

    /**
    * The FormControlFactory for VisitorListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<VisitorListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for VisitorListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: VisitorListDto = {
            timestamp: null,
            exhibitorName: null,
            name: null,
            emailAddress: null,
            city: null,
            country: null,
            label: null,
          }
        ) {
            super(model, VisitorListDto.ModelValidators);
        }
    }

}


