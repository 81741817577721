/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MExhibitorGroupReferenceDto { 
    id?: string;
    name?: string;
    description?: string;
    logoSquare?: string;
    logoWide?: string;
    sequence?: number;
    active?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorGroupReferenceDto.
 */
export namespace MExhibitorGroupReferenceDto {
    /**
     * All properties of MExhibitorGroupReferenceDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        sequence = 'sequence',
        active = 'active'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorGroupReferenceDto.
    */
    export const ModelValidators: {[K in keyof MExhibitorGroupReferenceDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        sequence: [
        ],
        active: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorGroupReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorGroupReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorGroupReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorGroupReferenceDto = {
            id: null,
            name: null,
            description: null,
            logoSquare: null,
            logoWide: null,
            sequence: null,
            active: null,
          }
        ) {
            super(model, MExhibitorGroupReferenceDto.ModelValidators);
        }
    }

}


