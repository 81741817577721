/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { FeaturedMode } from './featuredMode';
import { CalendarEntryType } from './calendarEntryType';


export interface MCalendarEntryBoothDto { 
    id?: string;
    exhibitorShortKey?: string;
    label?: string;
    entryType?: CalendarEntryType;
    description?: string;
    start?: Date;
    duration?: number;
    streamType?: StreamType;
    stream?: string;
    streamAdditional?: string;
    streamAdditional2?: string;
    featured?: FeaturedMode;
}

/**
 * Namespace for property- and property-value-enumerations of MCalendarEntryBoothDto.
 */
export namespace MCalendarEntryBoothDto {
    /**
     * All properties of MCalendarEntryBoothDto.
     */
    export enum Properties {
        id = 'id',
        exhibitorShortKey = 'exhibitorShortKey',
        label = 'label',
        entryType = 'entryType',
        description = 'description',
        start = 'start',
        duration = 'duration',
        streamType = 'streamType',
        stream = 'stream',
        streamAdditional = 'streamAdditional',
        streamAdditional2 = 'streamAdditional2',
        featured = 'featured'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MCalendarEntryBoothDto.
    */
    export const ModelValidators: {[K in keyof MCalendarEntryBoothDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        exhibitorShortKey: [
        ],
        label: [
        ],
        entryType: [
        ],
        description: [
        ],
        start: [
        ],
        duration: [
        ],
        streamType: [
        ],
        stream: [
        ],
        streamAdditional: [
        ],
        streamAdditional2: [
        ],
        featured: [
        ],
    };

    /**
    * The FormControlFactory for MCalendarEntryBoothDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MCalendarEntryBoothDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MCalendarEntryBoothDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MCalendarEntryBoothDto = {
            id: null,
            exhibitorShortKey: null,
            label: null,
            entryType: null,
            description: null,
            start: null,
            duration: null,
            streamType: null,
            stream: null,
            streamAdditional: null,
            streamAdditional2: null,
            featured: null,
          }
        ) {
            super(model, MCalendarEntryBoothDto.ModelValidators);
        }
    }

}


