/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExhibitorState } from './exhibitorState';
import { MExpoEditDto } from './mExpoEditDto';
import { MExhibitorEditDto } from './mExhibitorEditDto';


export interface MExhibitorResult { 
    expo?: MExpoEditDto;
    exhibitor?: MExhibitorEditDto;
    state?: ExhibitorState;
    sizes?: string;
    exhibitorCount?: number;
    expoCount?: number;
    isManager?: boolean;
    isChatUser?: boolean;
    displayMax?: number;
    linkMax?: number;
    infoSlideMax?: number;
    textChatEnabled?: boolean;
    liveStreamEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    boothPresentationEnabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorResult.
 */
export namespace MExhibitorResult {
    /**
     * All properties of MExhibitorResult.
     */
    export enum Properties {
        expo = 'expo',
        exhibitor = 'exhibitor',
        state = 'state',
        sizes = 'sizes',
        exhibitorCount = 'exhibitorCount',
        expoCount = 'expoCount',
        isManager = 'isManager',
        isChatUser = 'isChatUser',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        infoSlideMax = 'infoSlideMax',
        textChatEnabled = 'textChatEnabled',
        liveStreamEnabled = 'liveStreamEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        boothPresentationEnabled = 'boothPresentationEnabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorResult.
    */
    export const ModelValidators: {[K in keyof MExhibitorResult]: [string, ValidatorFn][]} = {
        expo: [
        ],
        exhibitor: [
        ],
        state: [
        ],
        sizes: [
        ],
        exhibitorCount: [
        ],
        expoCount: [
        ],
        isManager: [
        ],
        isChatUser: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        infoSlideMax: [
        ],
        textChatEnabled: [
        ],
        liveStreamEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        boothPresentationEnabled: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorResult = {
            expo: null,
            exhibitor: null,
            state: null,
            sizes: null,
            exhibitorCount: null,
            expoCount: null,
            isManager: null,
            isChatUser: null,
            displayMax: null,
            linkMax: null,
            infoSlideMax: null,
            textChatEnabled: null,
            liveStreamEnabled: null,
            tagsExhibitorEditable: null,
            boothPresentationEnabled: null,
          }
        ) {
            super(model, MExhibitorResult.ModelValidators);
        }
    }

}


