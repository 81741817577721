/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type StreamType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 10 | 11 | 12 | 20 | 21 | 22 | 23 | 100;

export const StreamType = {
    NUMBER_0: 0 as StreamType,
    NUMBER_1: 1 as StreamType,
    NUMBER_2: 2 as StreamType,
    NUMBER_3: 3 as StreamType,
    NUMBER_4: 4 as StreamType,
    NUMBER_5: 5 as StreamType,
    NUMBER_6: 6 as StreamType,
    NUMBER_7: 7 as StreamType,
    NUMBER_10: 10 as StreamType,
    NUMBER_11: 11 as StreamType,
    NUMBER_12: 12 as StreamType,
    NUMBER_20: 20 as StreamType,
    NUMBER_21: 21 as StreamType,
    NUMBER_22: 22 as StreamType,
    NUMBER_23: 23 as StreamType,
    NUMBER_100: 100 as StreamType
};

