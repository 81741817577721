import {MongoChatItemDto, MongoChatMessage} from '../../../ezfair-api';
import {EventEmitter} from '@angular/core';

export class MongoChatItemExtended implements MongoChatItemDto {
  newMessage: EventEmitter<MongoChatMessage> = new EventEmitter<MongoChatMessage>();
  newCommand: EventEmitter<{ session: MongoChatItemExtended, command: MongoChatMessage }> = new EventEmitter<{ session: MongoChatItemExtended, command: MongoChatMessage }>();

  selfId: string;
  partnerId: string;
  partnerName: string;
  isClosed: boolean;
  isBlocked: boolean;
  totalMessages: number;
  chatMessages: Array<MongoChatMessage> = new Array<MongoChatMessage>();
  timestamp: Date;
  unreadMessages = 0;
  open = true;

  constructor(item: MongoChatItemDto) {
    this.selfId = item.selfId;
    this.partnerId = item.partnerId;
    this.partnerName = item.partnerName;
    this.isBlocked = item.isBlocked;
    this.isClosed = item.isClosed;
    this.totalMessages = item.totalMessages;

    this.timestamp = item.timestamp;
    if (!this.timestamp) {
      this.timestamp = new Date(0);
    } else if (typeof this.timestamp.getMonth !== 'function') {
      this.timestamp = new Date(this.timestamp);
    }

    if (item.chatMessages) {
      item.chatMessages.forEach(msg => {
        this.addMessage(msg);
      });
    }
  }

  addMessage(msg: MongoChatMessage) {
    if (typeof msg.timestamp.getMonth !== 'function') {
      msg.timestamp = new Date(msg.timestamp);
    }
    if (msg.message.match(/\*_.*_\*/)) {
      this.newCommand.emit({session: this, command: msg});
    } else {
      this.chatMessages.push(msg);
      if (msg.timestamp > this.timestamp && msg.sender !== this.selfId) {
        this.unreadMessages++;
      }
      this.newMessage.emit(msg);
    }
  }
}
