import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {SharedModule} from '../../shared/shared.module';
import {LoginComponent} from './login/login.component';
import {LockComponent} from './lock/lock.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {Error404Component} from './error404/error404.component';
import {Error500Component} from './error500/error500.component';
import { AccountHeaderComponent } from './account-header/account-header.component';
import { AccountFooterComponent } from './account-footer/account-footer.component';
import { AccountLayoutComponent } from './account-layout/account-layout.component';
import { CallbackComponent } from './callback/callback.component';
import { PwdLessComponent } from './pwd-less/pwd-less.component';
import { ProfileComponent } from './profile/profile.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { WorldLayoutComponent } from './world-layout/world-layout.component';
import { TokenComponent } from './token/token.component';

/* Use this routes definition in case you want to make them lazy-loaded */

/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@NgModule({
  imports: [
    SharedModule,
    // RouterModule.forChild(routes)
  ],
  declarations: [
    LoginComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component,
    AccountHeaderComponent,
    AccountFooterComponent,
    AccountLayoutComponent,
    CallbackComponent,
    PwdLessComponent,
    ProfileComponent,
    SetPasswordComponent,
    WorldLayoutComponent,
    TokenComponent
  ],
  exports: [
    RouterModule,
    LoginComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component
  ]
})
export class PagesModule {
}
