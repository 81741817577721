<div *ngIf="session" class="chatOuter" [ngClass]="{'chat-hidden': !session.open}">
  <i class="fas fa-2x fa-video video-icon" title="Offer video call"
     [ngClass]="{'available' : !userChatService.videoActive, 'busy' : userChatService.videoActive}"
     (click)="offerVideoCall($event)"></i>
  <div is="chatNameFrame" class="chatNameFrame">
    <div class="session-buttons">
      <i class="closeButton far fa-window-close red" style="cursor: pointer;" (click)="close($event)"
         i18n-title title="userChat.closeSession|Chat beenden"></i>
    </div>
    <!--    <i class="fas messageIcon" [ngClass]="{'fa-chevron-down': session.open, 'fa-chevron-up': !session.open}"-->
    <!--       i18n-title title="userChat.hideSession|Verbergen"></i>-->
    <i class="closeButton fas fa-chevron-left" style="cursor: pointer; margin-right: 1em;" (click)="hide($event)"
       i18n-title title="userChat.hideSession|Chat schließen"></i>
    <span class="messageText" [innerText]="session.partnerName"></span>
    <span class="messageCount" *ngIf="session.unreadMessages > 0" style="margin-left: 0.5em;">({{session.unreadMessages}})</span>
  </div>
  <div #chatContentFrame class="chatInnerFrame" [ngClass]="{'chat-hidden': !session.open}">

  </div>
  <div class="chatFooter" [ngClass]="{'chat-hidden': !session.open}">
    <div class="messageWrapper">
      <input type="text" (keydown.enter)="callSendMessage()" [(ngModel)]="message" class="messageText"
             i18n-placeholder="userChat.messagePlaceholder" placeholder="Your message"/>
    </div>
    <i class="sendButton fa fa-paper-plane" (click)="sendButtonClick($event)"
       i18n-title="userChat.sendMessage" title="Send"></i>
  </div>
</div>
