import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserChatService} from './services/user-chat.service';
import {SignalsService} from './services/signals.service';
import {UserChatComponent} from './components/user-chat/user-chat.component';
import {UserChatSessionComponent} from './components/user-chat-session/user-chat-session.component';
import {VideoCallComponent} from './components/video-call/video-call.component';
import {FormsModule} from '@angular/forms';
import { UserChatNotificationComponent } from './components/user-chat-notification/user-chat-notification.component';



@NgModule({
  declarations: [
    UserChatComponent,
    UserChatSessionComponent,
    VideoCallComponent,
    UserChatNotificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    UserChatComponent,
    UserChatNotificationComponent
  ],
  providers: [
    UserChatService,
    SignalsService
  ]
})
export class SignalsChatModule { }
