import { Injectable } from '@angular/core';
import {AuthService} from './security/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppCompatibilityService {

  constructor(private signInService: AuthService) { }

  getDisplayName() {
    return this.signInService.getUserName();
  }
}
