/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface PasswordSetDto { 
    password: string;
}

/**
 * Namespace for property- and property-value-enumerations of PasswordSetDto.
 */
export namespace PasswordSetDto {
    /**
     * All properties of PasswordSetDto.
     */
    export enum Properties {
        password = 'password'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of PasswordSetDto.
    */
    export const ModelValidators: {[K in keyof PasswordSetDto]: [string, ValidatorFn][]} = {
        password: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
    };

    /**
    * The FormControlFactory for PasswordSetDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<PasswordSetDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for PasswordSetDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: PasswordSetDto = {
            password: null,
          }
        ) {
            super(model, PasswordSetDto.ModelValidators);
        }
    }

}


