/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { AdminChatChannelMessageDto } from '../model/adminChatChannelMessageDto';
import { ChatDownloadDto } from '../model/chatDownloadDto';
import { JoinExpoDto } from '../model/joinExpoDto';
import { MongoChatItemDto } from '../model/mongoChatItemDto';
import { RequestResult } from '../model/requestResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for adminChatGetChatlog.
 */
export namespace AdminChatGetChatlog {
    /**
     * Parameter map for adminChatGetChatlog.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for adminChatGetChatlog.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatGetChatlog
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatGetChatlog.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatJoinChat.
 */
export namespace AdminChatJoinChat {
    /**
     * Parameter map for adminChatJoinChat.
     */
    export interface PartialParamMap {
      JoinExpoDto: JoinExpoDto;
    }

    /**
     * Enumeration of all parameters for adminChatJoinChat.
     */
    export enum Parameters {
      JoinExpoDto = 'JoinExpoDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatJoinChat
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatJoinChat.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for adminChatMarkAsRead.
 */
export namespace AdminChatMarkAsRead {
    /**
     * Parameter map for adminChatMarkAsRead.
     */
    export interface PartialParamMap {
      selfId: string;
      partnerId: string;
      last: Date;
    }

    /**
     * Enumeration of all parameters for adminChatMarkAsRead.
     */
    export enum Parameters {
      selfId = 'selfId',
      partnerId = 'partnerId',
      last = 'last'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatMarkAsRead
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatMarkAsRead.PartialParamMap]?: [string, ValidatorFn][]} = {
      selfId: [
              ['required', Validators.required],
      ],
      partnerId: [
              ['required', Validators.required],
      ],
      last: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatPrivateChatBlock.
 */
export namespace AdminChatPrivateChatBlock {
    /**
     * Parameter map for adminChatPrivateChatBlock.
     */
    export interface PartialParamMap {
      selfId: string;
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatBlock.
     */
    export enum Parameters {
      selfId = 'selfId',
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatBlock
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatBlock.PartialParamMap]?: [string, ValidatorFn][]} = {
      selfId: [
              ['required', Validators.required],
      ],
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatPrivateChatClose.
 */
export namespace AdminChatPrivateChatClose {
    /**
     * Parameter map for adminChatPrivateChatClose.
     */
    export interface PartialParamMap {
      selfId: string;
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatClose.
     */
    export enum Parameters {
      selfId = 'selfId',
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatClose
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatClose.PartialParamMap]?: [string, ValidatorFn][]} = {
      selfId: [
              ['required', Validators.required],
      ],
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatPrivateChatGet.
 */
export namespace AdminChatPrivateChatGet {
    /**
     * Parameter map for adminChatPrivateChatGet.
     */
    export interface PartialParamMap {
      selfId: string;
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatGet.
     */
    export enum Parameters {
      selfId = 'selfId',
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      selfId: [
              ['required', Validators.required],
      ],
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatPrivateChatSendMessage.
 */
export namespace AdminChatPrivateChatSendMessage {
    /**
     * Parameter map for adminChatPrivateChatSendMessage.
     */
    export interface PartialParamMap {
      AdminChatChannelMessageDto: AdminChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatSendMessage.
     */
    export enum Parameters {
      AdminChatChannelMessageDto = 'AdminChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatSendMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatSendMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for adminChatPrivateChatUnblock.
 */
export namespace AdminChatPrivateChatUnblock {
    /**
     * Parameter map for adminChatPrivateChatUnblock.
     */
    export interface PartialParamMap {
      selfId: string;
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatUnblock.
     */
    export enum Parameters {
      selfId = 'selfId',
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatUnblock
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatUnblock.PartialParamMap]?: [string, ValidatorFn][]} = {
      selfId: [
              ['required', Validators.required],
      ],
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for adminChatPrivateChatVideoAccept.
 */
export namespace AdminChatPrivateChatVideoAccept {
    /**
     * Parameter map for adminChatPrivateChatVideoAccept.
     */
    export interface PartialParamMap {
      AdminChatChannelMessageDto: AdminChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatVideoAccept.
     */
    export enum Parameters {
      AdminChatChannelMessageDto = 'AdminChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatVideoAccept
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatVideoAccept.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for adminChatPrivateChatVideoDeny.
 */
export namespace AdminChatPrivateChatVideoDeny {
    /**
     * Parameter map for adminChatPrivateChatVideoDeny.
     */
    export interface PartialParamMap {
      AdminChatChannelMessageDto: AdminChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatVideoDeny.
     */
    export enum Parameters {
      AdminChatChannelMessageDto = 'AdminChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatVideoDeny
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatVideoDeny.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for adminChatPrivateChatVideoHangup.
 */
export namespace AdminChatPrivateChatVideoHangup {
    /**
     * Parameter map for adminChatPrivateChatVideoHangup.
     */
    export interface PartialParamMap {
      AdminChatChannelMessageDto: AdminChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatVideoHangup.
     */
    export enum Parameters {
      AdminChatChannelMessageDto = 'AdminChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatVideoHangup
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatVideoHangup.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for adminChatPrivateChatVideoOffer.
 */
export namespace AdminChatPrivateChatVideoOffer {
    /**
     * Parameter map for adminChatPrivateChatVideoOffer.
     */
    export interface PartialParamMap {
      AdminChatChannelMessageDto: AdminChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for adminChatPrivateChatVideoOffer.
     */
    export enum Parameters {
      AdminChatChannelMessageDto = 'AdminChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of adminChatPrivateChatVideoOffer
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AdminChatPrivateChatVideoOffer.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class AdminChatService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatGetChatlogByMap(
    map: AdminChatGetChatlog.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ChatDownloadDto>>;
  public adminChatGetChatlogByMap(
    map: AdminChatGetChatlog.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ChatDownloadDto>>>;
  public adminChatGetChatlogByMap(
    map: AdminChatGetChatlog.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ChatDownloadDto>>>;
  public adminChatGetChatlogByMap(
    map: AdminChatGetChatlog.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatGetChatlog(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatGetChatlog(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ChatDownloadDto>>;
    public adminChatGetChatlog(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ChatDownloadDto>>>;
    public adminChatGetChatlog(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ChatDownloadDto>>>;
    public adminChatGetChatlog(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling adminChatGetChatlog.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling adminChatGetChatlog.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ChatDownloadDto>>(`${this.configuration.basePath}/api/AdminChat/chatlog/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_GetChatlog']) {
                this.cancelMap['AdminChat_GetChatlog'].next(null);
            }
            this.cancelMap['AdminChat_GetChatlog'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_GetChatlog']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_GetChatlog']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_GetChatlog', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_GetChatlog']) {
            this.cancelMap['AdminChat_GetChatlog'].complete();
            delete this.cancelMap['AdminChat_GetChatlog'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_GetChatlog',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_GetChatlog')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatJoinChatByMap(
    map: AdminChatJoinChat.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MongoChatItemDto>>;
  public adminChatJoinChatByMap(
    map: AdminChatJoinChat.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MongoChatItemDto>>>;
  public adminChatJoinChatByMap(
    map: AdminChatJoinChat.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MongoChatItemDto>>>;
  public adminChatJoinChatByMap(
    map: AdminChatJoinChat.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatJoinChat(
      map.JoinExpoDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param JoinExpoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatJoinChat(JoinExpoDto: JoinExpoDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MongoChatItemDto>>;
    public adminChatJoinChat(JoinExpoDto: JoinExpoDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MongoChatItemDto>>>;
    public adminChatJoinChat(JoinExpoDto: JoinExpoDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MongoChatItemDto>>>;
    public adminChatJoinChat(JoinExpoDto: JoinExpoDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (JoinExpoDto === null || JoinExpoDto === undefined) {
            throw new Error('Required parameter JoinExpoDto was null or undefined when calling adminChatJoinChat.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<MongoChatItemDto>>(`${this.configuration.basePath}/api/AdminChat/chat/join`,
            JoinExpoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_JoinChat']) {
                this.cancelMap['AdminChat_JoinChat'].next(null);
            }
            this.cancelMap['AdminChat_JoinChat'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_JoinChat']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_JoinChat']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_JoinChat', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_JoinChat']) {
            this.cancelMap['AdminChat_JoinChat'].complete();
            delete this.cancelMap['AdminChat_JoinChat'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_JoinChat',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_JoinChat')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatMarkAsReadByMap(
    map: AdminChatMarkAsRead.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public adminChatMarkAsReadByMap(
    map: AdminChatMarkAsRead.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public adminChatMarkAsReadByMap(
    map: AdminChatMarkAsRead.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public adminChatMarkAsReadByMap(
    map: AdminChatMarkAsRead.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatMarkAsRead(
      map.selfId,
      map.partnerId,
      map.last,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param selfId 
     * @param partnerId 
     * @param last 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatMarkAsRead(selfId: string, partnerId: string, last: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public adminChatMarkAsRead(selfId: string, partnerId: string, last: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public adminChatMarkAsRead(selfId: string, partnerId: string, last: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public adminChatMarkAsRead(selfId: string, partnerId: string, last: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (selfId === null || selfId === undefined) {
            throw new Error('Required parameter selfId was null or undefined when calling adminChatMarkAsRead.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling adminChatMarkAsRead.');
        }
        if (last === null || last === undefined) {
            throw new Error('Required parameter last was null or undefined when calling adminChatMarkAsRead.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/AdminChat/chat/ts/${encodeURIComponent(String(selfId))}/${encodeURIComponent(String(partnerId))}/${encodeURIComponent(String(last.toISOString()))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_MarkAsRead']) {
                this.cancelMap['AdminChat_MarkAsRead'].next(null);
            }
            this.cancelMap['AdminChat_MarkAsRead'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_MarkAsRead']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_MarkAsRead']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_MarkAsRead', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_MarkAsRead']) {
            this.cancelMap['AdminChat_MarkAsRead'].complete();
            delete this.cancelMap['AdminChat_MarkAsRead'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_MarkAsRead',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_MarkAsRead')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatBlockByMap(
    map: AdminChatPrivateChatBlock.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public adminChatPrivateChatBlockByMap(
    map: AdminChatPrivateChatBlock.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public adminChatPrivateChatBlockByMap(
    map: AdminChatPrivateChatBlock.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public adminChatPrivateChatBlockByMap(
    map: AdminChatPrivateChatBlock.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatBlock(
      map.selfId,
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param selfId 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatBlock(selfId: string, partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public adminChatPrivateChatBlock(selfId: string, partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public adminChatPrivateChatBlock(selfId: string, partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public adminChatPrivateChatBlock(selfId: string, partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (selfId === null || selfId === undefined) {
            throw new Error('Required parameter selfId was null or undefined when calling adminChatPrivateChatBlock.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling adminChatPrivateChatBlock.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/AdminChat/chat/block/${encodeURIComponent(String(selfId))}/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatBlock']) {
                this.cancelMap['AdminChat_PrivateChatBlock'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatBlock'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatBlock']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatBlock']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatBlock', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatBlock']) {
            this.cancelMap['AdminChat_PrivateChatBlock'].complete();
            delete this.cancelMap['AdminChat_PrivateChatBlock'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatBlock',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatBlock')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatCloseByMap(
    map: AdminChatPrivateChatClose.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public adminChatPrivateChatCloseByMap(
    map: AdminChatPrivateChatClose.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public adminChatPrivateChatCloseByMap(
    map: AdminChatPrivateChatClose.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public adminChatPrivateChatCloseByMap(
    map: AdminChatPrivateChatClose.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatClose(
      map.selfId,
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param selfId 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatClose(selfId: string, partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public adminChatPrivateChatClose(selfId: string, partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public adminChatPrivateChatClose(selfId: string, partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public adminChatPrivateChatClose(selfId: string, partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (selfId === null || selfId === undefined) {
            throw new Error('Required parameter selfId was null or undefined when calling adminChatPrivateChatClose.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling adminChatPrivateChatClose.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/AdminChat/chat/close/${encodeURIComponent(String(selfId))}/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatClose']) {
                this.cancelMap['AdminChat_PrivateChatClose'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatClose'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatClose']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatClose']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatClose', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatClose']) {
            this.cancelMap['AdminChat_PrivateChatClose'].complete();
            delete this.cancelMap['AdminChat_PrivateChatClose'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatClose',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatClose')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatGetByMap(
    map: AdminChatPrivateChatGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
  public adminChatPrivateChatGetByMap(
    map: AdminChatPrivateChatGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
  public adminChatPrivateChatGetByMap(
    map: AdminChatPrivateChatGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
  public adminChatPrivateChatGetByMap(
    map: AdminChatPrivateChatGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatGet(
      map.selfId,
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param selfId 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatGet(selfId: string, partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
    public adminChatPrivateChatGet(selfId: string, partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
    public adminChatPrivateChatGet(selfId: string, partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
    public adminChatPrivateChatGet(selfId: string, partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (selfId === null || selfId === undefined) {
            throw new Error('Required parameter selfId was null or undefined when calling adminChatPrivateChatGet.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling adminChatPrivateChatGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<MongoChatItemDto>(`${this.configuration.basePath}/api/AdminChat/chat/get/${encodeURIComponent(String(selfId))}/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatGet']) {
                this.cancelMap['AdminChat_PrivateChatGet'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatGet'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatGet', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatGet']) {
            this.cancelMap['AdminChat_PrivateChatGet'].complete();
            delete this.cancelMap['AdminChat_PrivateChatGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatGet',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatSendMessageByMap(
    map: AdminChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
  public adminChatPrivateChatSendMessageByMap(
    map: AdminChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
  public adminChatPrivateChatSendMessageByMap(
    map: AdminChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
  public adminChatPrivateChatSendMessageByMap(
    map: AdminChatPrivateChatSendMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatSendMessage(
      map.AdminChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param AdminChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatSendMessage(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
    public adminChatPrivateChatSendMessage(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
    public adminChatPrivateChatSendMessage(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
    public adminChatPrivateChatSendMessage(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (AdminChatChannelMessageDto === null || AdminChatChannelMessageDto === undefined) {
            throw new Error('Required parameter AdminChatChannelMessageDto was null or undefined when calling adminChatPrivateChatSendMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MongoChatItemDto>(`${this.configuration.basePath}/api/AdminChat/chat/send`,
            AdminChatChannelMessageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatSendMessage']) {
                this.cancelMap['AdminChat_PrivateChatSendMessage'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatSendMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatSendMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatSendMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatSendMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatSendMessage']) {
            this.cancelMap['AdminChat_PrivateChatSendMessage'].complete();
            delete this.cancelMap['AdminChat_PrivateChatSendMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatSendMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatSendMessage')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatUnblockByMap(
    map: AdminChatPrivateChatUnblock.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public adminChatPrivateChatUnblockByMap(
    map: AdminChatPrivateChatUnblock.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public adminChatPrivateChatUnblockByMap(
    map: AdminChatPrivateChatUnblock.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public adminChatPrivateChatUnblockByMap(
    map: AdminChatPrivateChatUnblock.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatUnblock(
      map.selfId,
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param selfId 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatUnblock(selfId: string, partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public adminChatPrivateChatUnblock(selfId: string, partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public adminChatPrivateChatUnblock(selfId: string, partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public adminChatPrivateChatUnblock(selfId: string, partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (selfId === null || selfId === undefined) {
            throw new Error('Required parameter selfId was null or undefined when calling adminChatPrivateChatUnblock.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling adminChatPrivateChatUnblock.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/AdminChat/chat/unblock/${encodeURIComponent(String(selfId))}/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatUnblock']) {
                this.cancelMap['AdminChat_PrivateChatUnblock'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatUnblock'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatUnblock']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatUnblock']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatUnblock', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatUnblock']) {
            this.cancelMap['AdminChat_PrivateChatUnblock'].complete();
            delete this.cancelMap['AdminChat_PrivateChatUnblock'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatUnblock',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatUnblock')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatVideoAcceptByMap(
    map: AdminChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public adminChatPrivateChatVideoAcceptByMap(
    map: AdminChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public adminChatPrivateChatVideoAcceptByMap(
    map: AdminChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public adminChatPrivateChatVideoAcceptByMap(
    map: AdminChatPrivateChatVideoAccept.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatVideoAccept(
      map.AdminChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param AdminChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatVideoAccept(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public adminChatPrivateChatVideoAccept(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public adminChatPrivateChatVideoAccept(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public adminChatPrivateChatVideoAccept(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (AdminChatChannelMessageDto === null || AdminChatChannelMessageDto === undefined) {
            throw new Error('Required parameter AdminChatChannelMessageDto was null or undefined when calling adminChatPrivateChatVideoAccept.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/AdminChat/chat/video/accept`,
            AdminChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatVideoAccept']) {
                this.cancelMap['AdminChat_PrivateChatVideoAccept'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatVideoAccept'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatVideoAccept']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatVideoAccept']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatVideoAccept', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatVideoAccept']) {
            this.cancelMap['AdminChat_PrivateChatVideoAccept'].complete();
            delete this.cancelMap['AdminChat_PrivateChatVideoAccept'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatVideoAccept',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatVideoAccept')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatVideoDenyByMap(
    map: AdminChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public adminChatPrivateChatVideoDenyByMap(
    map: AdminChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public adminChatPrivateChatVideoDenyByMap(
    map: AdminChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public adminChatPrivateChatVideoDenyByMap(
    map: AdminChatPrivateChatVideoDeny.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatVideoDeny(
      map.AdminChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param AdminChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatVideoDeny(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public adminChatPrivateChatVideoDeny(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public adminChatPrivateChatVideoDeny(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public adminChatPrivateChatVideoDeny(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (AdminChatChannelMessageDto === null || AdminChatChannelMessageDto === undefined) {
            throw new Error('Required parameter AdminChatChannelMessageDto was null or undefined when calling adminChatPrivateChatVideoDeny.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/AdminChat/chat/video/deny`,
            AdminChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatVideoDeny']) {
                this.cancelMap['AdminChat_PrivateChatVideoDeny'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatVideoDeny'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatVideoDeny']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatVideoDeny']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatVideoDeny', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatVideoDeny']) {
            this.cancelMap['AdminChat_PrivateChatVideoDeny'].complete();
            delete this.cancelMap['AdminChat_PrivateChatVideoDeny'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatVideoDeny',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatVideoDeny')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatVideoHangupByMap(
    map: AdminChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public adminChatPrivateChatVideoHangupByMap(
    map: AdminChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public adminChatPrivateChatVideoHangupByMap(
    map: AdminChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public adminChatPrivateChatVideoHangupByMap(
    map: AdminChatPrivateChatVideoHangup.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatVideoHangup(
      map.AdminChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param AdminChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatVideoHangup(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public adminChatPrivateChatVideoHangup(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public adminChatPrivateChatVideoHangup(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public adminChatPrivateChatVideoHangup(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (AdminChatChannelMessageDto === null || AdminChatChannelMessageDto === undefined) {
            throw new Error('Required parameter AdminChatChannelMessageDto was null or undefined when calling adminChatPrivateChatVideoHangup.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/AdminChat/chat/video/hangup`,
            AdminChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatVideoHangup']) {
                this.cancelMap['AdminChat_PrivateChatVideoHangup'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatVideoHangup'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatVideoHangup']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatVideoHangup']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatVideoHangup', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatVideoHangup']) {
            this.cancelMap['AdminChat_PrivateChatVideoHangup'].complete();
            delete this.cancelMap['AdminChat_PrivateChatVideoHangup'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatVideoHangup',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatVideoHangup')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public adminChatPrivateChatVideoOfferByMap(
    map: AdminChatPrivateChatVideoOffer.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public adminChatPrivateChatVideoOfferByMap(
    map: AdminChatPrivateChatVideoOffer.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public adminChatPrivateChatVideoOfferByMap(
    map: AdminChatPrivateChatVideoOffer.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public adminChatPrivateChatVideoOfferByMap(
    map: AdminChatPrivateChatVideoOffer.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.adminChatPrivateChatVideoOffer(
      map.AdminChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param AdminChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public adminChatPrivateChatVideoOffer(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public adminChatPrivateChatVideoOffer(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public adminChatPrivateChatVideoOffer(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public adminChatPrivateChatVideoOffer(AdminChatChannelMessageDto: AdminChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (AdminChatChannelMessageDto === null || AdminChatChannelMessageDto === undefined) {
            throw new Error('Required parameter AdminChatChannelMessageDto was null or undefined when calling adminChatPrivateChatVideoOffer.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/AdminChat/chat/video/offer`,
            AdminChatChannelMessageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['AdminChat_PrivateChatVideoOffer']) {
                this.cancelMap['AdminChat_PrivateChatVideoOffer'].next(null);
            }
            this.cancelMap['AdminChat_PrivateChatVideoOffer'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['AdminChat_PrivateChatVideoOffer']) {
                handle = handle.pipe(takeUntil(this.cancelMap['AdminChat_PrivateChatVideoOffer']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('AdminChat_PrivateChatVideoOffer', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['AdminChat_PrivateChatVideoOffer']) {
            this.cancelMap['AdminChat_PrivateChatVideoOffer'].complete();
            delete this.cancelMap['AdminChat_PrivateChatVideoOffer'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'AdminChat_PrivateChatVideoOffer',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'AdminChat_PrivateChatVideoOffer')));
        }
        return handle;
    }

  }

export namespace AdminChatService {
  export enum Operations {
    AdminChat_GetChatlog = 'AdminChat_GetChatlog',
    AdminChat_JoinChat = 'AdminChat_JoinChat',
    AdminChat_MarkAsRead = 'AdminChat_MarkAsRead',
    AdminChat_PrivateChatBlock = 'AdminChat_PrivateChatBlock',
    AdminChat_PrivateChatClose = 'AdminChat_PrivateChatClose',
    AdminChat_PrivateChatGet = 'AdminChat_PrivateChatGet',
    AdminChat_PrivateChatSendMessage = 'AdminChat_PrivateChatSendMessage',
    AdminChat_PrivateChatUnblock = 'AdminChat_PrivateChatUnblock',
    AdminChat_PrivateChatVideoAccept = 'AdminChat_PrivateChatVideoAccept',
    AdminChat_PrivateChatVideoDeny = 'AdminChat_PrivateChatVideoDeny',
    AdminChat_PrivateChatVideoHangup = 'AdminChat_PrivateChatVideoHangup',
    AdminChat_PrivateChatVideoOffer = 'AdminChat_PrivateChatVideoOffer'
  }
}
