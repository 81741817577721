/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface Coordinates { 
    x?: number;
    y?: number;
}

/**
 * Namespace for property- and property-value-enumerations of Coordinates.
 */
export namespace Coordinates {
    /**
     * All properties of Coordinates.
     */
    export enum Properties {
        x = 'x',
        y = 'y'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Coordinates.
    */
    export const ModelValidators: {[K in keyof Coordinates]: [string, ValidatorFn][]} = {
        x: [
        ],
        y: [
        ],
    };

    /**
    * The FormControlFactory for Coordinates.
    */
    export class FormControlFactory extends BaseFormControlFactory<Coordinates> {

        /**
         * Constructor.
         *
         * @param model An existing model for Coordinates.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Coordinates = {
            x: null,
            y: null,
          }
        ) {
            super(model, Coordinates.ModelValidators);
        }
    }

}


