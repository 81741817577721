import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MenuService} from './menu/menu.service';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {VersionCheckService} from './version-check.service';
import {SoundService} from './sound-service.service';

@NgModule({
  providers: [
    VersionCheckService,
    MenuService,

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true
    // },
    SoundService
  ],
  declarations: [],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
