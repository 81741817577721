/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarAvailability } from './calendarAvailability';
import { BookingState } from './bookingState';
import { Exhibitor } from './exhibitor';


export interface CalendarBookedSlot { 
    rowId?: number;
    availabilityId?: number;
    availability?: CalendarAvailability;
    exhibitorId?: string;
    exhibitor?: Exhibitor;
    start?: Date;
    partnerId?: string;
    isAnon?: boolean;
    email?: string;
    name?: string;
    comment?: string;
    meta?: string;
    bookingState?: BookingState;
    hash?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlot.
 */
export namespace CalendarBookedSlot {
    /**
     * All properties of CalendarBookedSlot.
     */
    export enum Properties {
        rowId = 'rowId',
        availabilityId = 'availabilityId',
        availability = 'availability',
        exhibitorId = 'exhibitorId',
        exhibitor = 'exhibitor',
        start = 'start',
        partnerId = 'partnerId',
        isAnon = 'isAnon',
        email = 'email',
        name = 'name',
        comment = 'comment',
        meta = 'meta',
        bookingState = 'bookingState',
        hash = 'hash'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlot.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlot]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        availabilityId: [
        ],
        availability: [
        ],
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        exhibitor: [
        ],
        start: [
        ],
        partnerId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        isAnon: [
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        name: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        meta: [
        ],
        bookingState: [
        ],
        hash: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlot.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlot> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlot.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlot = {
            rowId: null,
            availabilityId: null,
            availability: null,
            exhibitorId: null,
            exhibitor: null,
            start: null,
            partnerId: null,
            isAnon: null,
            email: null,
            name: null,
            comment: null,
            meta: null,
            bookingState: null,
            hash: null,
          }
        ) {
            super(model, CalendarBookedSlot.ModelValidators);
        }
    }

}


