/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { PresentationScreenMode } from './presentationScreenMode';
import { StreamType } from './streamType';
import { Category } from './category';
import { Expo } from './expo';
import { Exhibitor } from './exhibitor';
import { LecternVisibility } from './lecternVisibility';
import { CalendarEntryBooth } from './calendarEntryBooth';
import { InfoSlide } from './infoSlide';
import { Link } from './link';
import { Display } from './display';
import { ExhibitorGroup } from './exhibitorGroup';
import { ExhibitorInfoVisibility } from './exhibitorInfoVisibility';


export interface ExpoExhibitor { 
    expoId?: string;
    expo?: Expo;
    exhibitorId?: string;
    exhibitor?: Exhibitor;
    place?: string;
    boothType?: string;
    boothIcon?: string;
    style?: string;
    rotation?: number;
    panels?: string;
    displays?: Array<Display>;
    links?: Array<Link>;
    infoSlides?: Array<InfoSlide>;
    sequence?: number;
    categoryId?: number;
    category?: Category;
    boothConfiguration?: string;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    chatEnabled?: boolean;
    exhibitorGroupId?: number;
    exhibitorGroup?: ExhibitorGroup;
    exhibitorInfoVisibility?: ExhibitorInfoVisibility;
    hideFurniture?: boolean;
    hideWalls?: boolean;
    hideLogo?: boolean;
    lecternVisibility?: LecternVisibility;
    liveStreamActive?: boolean;
    liveStreamType?: StreamType;
    liveStreamId?: string;
    calendarEntries?: Array<CalendarEntryBooth>;
    current?: number;
    presentationScreenMode?: PresentationScreenMode;
    deleted?: boolean;
    hidden?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoExhibitor.
 */
export namespace ExpoExhibitor {
    /**
     * All properties of ExpoExhibitor.
     */
    export enum Properties {
        expoId = 'expoId',
        expo = 'expo',
        exhibitorId = 'exhibitorId',
        exhibitor = 'exhibitor',
        place = 'place',
        boothType = 'boothType',
        boothIcon = 'boothIcon',
        style = 'style',
        rotation = 'rotation',
        panels = 'panels',
        displays = 'displays',
        links = 'links',
        infoSlides = 'infoSlides',
        sequence = 'sequence',
        categoryId = 'categoryId',
        category = 'category',
        boothConfiguration = 'boothConfiguration',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        chatEnabled = 'chatEnabled',
        exhibitorGroupId = 'exhibitorGroupId',
        exhibitorGroup = 'exhibitorGroup',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        hideFurniture = 'hideFurniture',
        hideWalls = 'hideWalls',
        hideLogo = 'hideLogo',
        lecternVisibility = 'lecternVisibility',
        liveStreamActive = 'liveStreamActive',
        liveStreamType = 'liveStreamType',
        liveStreamId = 'liveStreamId',
        calendarEntries = 'calendarEntries',
        current = 'current',
        presentationScreenMode = 'presentationScreenMode',
        deleted = 'deleted',
        hidden = 'hidden'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoExhibitor.
    */
    export const ModelValidators: {[K in keyof ExpoExhibitor]: [string, ValidatorFn][]} = {
        expoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        expo: [
        ],
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        exhibitor: [
        ],
        place: [
        ],
        boothType: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        boothIcon: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        style: [
        ],
        rotation: [
        ],
        panels: [
        ],
        displays: [
        ],
        links: [
        ],
        infoSlides: [
        ],
        sequence: [
        ],
        categoryId: [
        ],
        category: [
        ],
        boothConfiguration: [
        ],
        backgroundImage: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        posterA: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        posterB: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        chatEnabled: [
        ],
        exhibitorGroupId: [
        ],
        exhibitorGroup: [
        ],
        exhibitorInfoVisibility: [
        ],
        hideFurniture: [
        ],
        hideWalls: [
        ],
        hideLogo: [
        ],
        lecternVisibility: [
        ],
        liveStreamActive: [
        ],
        liveStreamType: [
        ],
        liveStreamId: [
        ],
        calendarEntries: [
        ],
        current: [
        ],
        presentationScreenMode: [
        ],
        deleted: [
        ],
        hidden: [
        ],
    };

    /**
    * The FormControlFactory for ExpoExhibitor.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoExhibitor> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoExhibitor.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoExhibitor = {
            expoId: null,
            expo: null,
            exhibitorId: null,
            exhibitor: null,
            place: null,
            boothType: null,
            boothIcon: null,
            style: null,
            rotation: null,
            panels: null,
            displays: null,
            links: null,
            infoSlides: null,
            sequence: null,
            categoryId: null,
            category: null,
            boothConfiguration: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            chatEnabled: null,
            exhibitorGroupId: null,
            exhibitorGroup: null,
            exhibitorInfoVisibility: null,
            hideFurniture: null,
            hideWalls: null,
            hideLogo: null,
            lecternVisibility: null,
            liveStreamActive: null,
            liveStreamType: null,
            liveStreamId: null,
            calendarEntries: null,
            current: null,
            presentationScreenMode: null,
            deleted: null,
            hidden: null,
          }
        ) {
            super(model, ExpoExhibitor.ModelValidators);
        }
    }

}


