/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ActiveUpdateDto } from '../model/activeUpdateDto';
import { MExpoResult } from '../model/mExpoResult';
import { MStageEditDto } from '../model/mStageEditDto';
import { MStageEditResult } from '../model/mStageEditResult';
import { MStageListItem } from '../model/mStageListItem';
import { SortUpdateDto } from '../model/sortUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mStageStageActive.
 */
export namespace MStageStageActive {
    /**
     * Parameter map for mStageStageActive.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mStageStageActive.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageActive
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageActive.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageDelete.
 */
export namespace MStageStageDelete {
    /**
     * Parameter map for mStageStageDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mStageStageDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageGet.
 */
export namespace MStageStageGet {
    /**
     * Parameter map for mStageStageGet.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
    }

    /**
     * Enumeration of all parameters for mStageStageGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageGetAll.
 */
export namespace MStageStageGetAll {
    /**
     * Parameter map for mStageStageGetAll.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mStageStageGetAll.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageGetAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageGetAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageHideOnLanding.
 */
export namespace MStageStageHideOnLanding {
    /**
     * Parameter map for mStageStageHideOnLanding.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mStageStageHideOnLanding.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageHideOnLanding
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageHideOnLanding.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageSort.
 */
export namespace MStageStageSort {
    /**
     * Parameter map for mStageStageSort.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mStageStageSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageUndelete.
 */
export namespace MStageStageUndelete {
    /**
     * Parameter map for mStageStageUndelete.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mStageStageUndelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageUndelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageUndelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mStageStageUpdate.
 */
export namespace MStageStageUpdate {
    /**
     * Parameter map for mStageStageUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      MStageEditDto: MStageEditDto;
    }

    /**
     * Enumeration of all parameters for mStageStageUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      MStageEditDto = 'MStageEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mStageStageUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MStageStageUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MStageService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageActiveByMap(
    map: MStageStageActive.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mStageStageActiveByMap(
    map: MStageStageActive.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mStageStageActiveByMap(
    map: MStageStageActive.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mStageStageActiveByMap(
    map: MStageStageActive.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageActive(
      map.expoId,
      map.stageId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageActive(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mStageStageActive(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mStageStageActive(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mStageStageActive(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageActive.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageActive.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mStageStageActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/active`,
            ActiveUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageActive']) {
                this.cancelMap['MStage_StageActive'].next(null);
            }
            this.cancelMap['MStage_StageActive'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageActive']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageActive']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageActive', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageActive']) {
            this.cancelMap['MStage_StageActive'].complete();
            delete this.cancelMap['MStage_StageActive'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageActive',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageActive')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageDeleteByMap(
    map: MStageStageDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mStageStageDeleteByMap(
    map: MStageStageDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mStageStageDeleteByMap(
    map: MStageStageDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mStageStageDeleteByMap(
    map: MStageStageDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageDelete(
      map.expoId,
      map.stageId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageDelete(expoId: string, stageId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mStageStageDelete(expoId: string, stageId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mStageStageDelete(expoId: string, stageId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mStageStageDelete(expoId: string, stageId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageDelete.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mStageStageDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MExpoResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageDelete']) {
                this.cancelMap['MStage_StageDelete'].next(null);
            }
            this.cancelMap['MStage_StageDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageDelete']) {
            this.cancelMap['MStage_StageDelete'].complete();
            delete this.cancelMap['MStage_StageDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageGetByMap(
    map: MStageStageGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MStageEditResult>;
  public mStageStageGetByMap(
    map: MStageStageGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MStageEditResult>>;
  public mStageStageGetByMap(
    map: MStageStageGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MStageEditResult>>;
  public mStageStageGetByMap(
    map: MStageStageGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageGet(
      map.expoId,
      map.stageId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageGet(expoId: string, stageId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MStageEditResult>;
    public mStageStageGet(expoId: string, stageId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MStageEditResult>>;
    public mStageStageGet(expoId: string, stageId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MStageEditResult>>;
    public mStageStageGet(expoId: string, stageId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageGet.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MStageEditResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageGet']) {
                this.cancelMap['MStage_StageGet'].next(null);
            }
            this.cancelMap['MStage_StageGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageGet']) {
            this.cancelMap['MStage_StageGet'].complete();
            delete this.cancelMap['MStage_StageGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageGetAllByMap(
    map: MStageStageGetAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MStageListItem>>;
  public mStageStageGetAllByMap(
    map: MStageStageGetAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MStageListItem>>>;
  public mStageStageGetAllByMap(
    map: MStageStageGetAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MStageListItem>>>;
  public mStageStageGetAllByMap(
    map: MStageStageGetAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageGetAll(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageGetAll(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MStageListItem>>;
    public mStageStageGetAll(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MStageListItem>>>;
    public mStageStageGetAll(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MStageListItem>>>;
    public mStageStageGetAll(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageGetAll.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MStageListItem>>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageGetAll']) {
                this.cancelMap['MStage_StageGetAll'].next(null);
            }
            this.cancelMap['MStage_StageGetAll'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageGetAll']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageGetAll']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageGetAll', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageGetAll']) {
            this.cancelMap['MStage_StageGetAll'].complete();
            delete this.cancelMap['MStage_StageGetAll'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageGetAll',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageGetAll')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageHideOnLandingByMap(
    map: MStageStageHideOnLanding.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mStageStageHideOnLandingByMap(
    map: MStageStageHideOnLanding.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mStageStageHideOnLandingByMap(
    map: MStageStageHideOnLanding.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mStageStageHideOnLandingByMap(
    map: MStageStageHideOnLanding.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageHideOnLanding(
      map.expoId,
      map.stageId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageHideOnLanding(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mStageStageHideOnLanding(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mStageStageHideOnLanding(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mStageStageHideOnLanding(expoId: string, stageId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageHideOnLanding.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageHideOnLanding.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mStageStageHideOnLanding.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.delete<MExpoResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageHideOnLanding']) {
                this.cancelMap['MStage_StageHideOnLanding'].next(null);
            }
            this.cancelMap['MStage_StageHideOnLanding'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageHideOnLanding']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageHideOnLanding']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageHideOnLanding', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageHideOnLanding']) {
            this.cancelMap['MStage_StageHideOnLanding'].complete();
            delete this.cancelMap['MStage_StageHideOnLanding'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageHideOnLanding',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageHideOnLanding')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageSortByMap(
    map: MStageStageSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mStageStageSortByMap(
    map: MStageStageSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mStageStageSortByMap(
    map: MStageStageSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mStageStageSortByMap(
    map: MStageStageSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageSort(
      map.expoId,
      map.stageId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageSort(expoId: string, stageId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mStageStageSort(expoId: string, stageId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mStageStageSort(expoId: string, stageId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mStageStageSort(expoId: string, stageId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageSort.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mStageStageSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/sort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageSort']) {
                this.cancelMap['MStage_StageSort'].next(null);
            }
            this.cancelMap['MStage_StageSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageSort']) {
            this.cancelMap['MStage_StageSort'].complete();
            delete this.cancelMap['MStage_StageSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageUndeleteByMap(
    map: MStageStageUndelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mStageStageUndeleteByMap(
    map: MStageStageUndelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mStageStageUndeleteByMap(
    map: MStageStageUndelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mStageStageUndeleteByMap(
    map: MStageStageUndelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageUndelete(
      map.expoId,
      map.stageId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageUndelete(expoId: string, stageId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mStageStageUndelete(expoId: string, stageId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mStageStageUndelete(expoId: string, stageId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mStageStageUndelete(expoId: string, stageId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageUndelete.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mStageStageUndelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mStageStageUndelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(rowVersion))}/recover`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageUndelete']) {
                this.cancelMap['MStage_StageUndelete'].next(null);
            }
            this.cancelMap['MStage_StageUndelete'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageUndelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageUndelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageUndelete', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageUndelete']) {
            this.cancelMap['MStage_StageUndelete'].complete();
            delete this.cancelMap['MStage_StageUndelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageUndelete',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageUndelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mStageStageUpdateByMap(
    map: MStageStageUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MStageEditResult>;
  public mStageStageUpdateByMap(
    map: MStageStageUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MStageEditResult>>;
  public mStageStageUpdateByMap(
    map: MStageStageUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MStageEditResult>>;
  public mStageStageUpdateByMap(
    map: MStageStageUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mStageStageUpdate(
      map.expoId,
      map.MStageEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param MStageEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mStageStageUpdate(expoId: string, MStageEditDto: MStageEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MStageEditResult>;
    public mStageStageUpdate(expoId: string, MStageEditDto: MStageEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MStageEditResult>>;
    public mStageStageUpdate(expoId: string, MStageEditDto: MStageEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MStageEditResult>>;
    public mStageStageUpdate(expoId: string, MStageEditDto: MStageEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mStageStageUpdate.');
        }
        if (MStageEditDto === null || MStageEditDto === undefined) {
            throw new Error('Required parameter MStageEditDto was null or undefined when calling mStageStageUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MStageEditResult>(`${this.configuration.basePath}/api/MStage/${encodeURIComponent(String(expoId))}/stage`,
            MStageEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MStage_StageUpdate']) {
                this.cancelMap['MStage_StageUpdate'].next(null);
            }
            this.cancelMap['MStage_StageUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MStage_StageUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MStage_StageUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MStage_StageUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MStage_StageUpdate']) {
            this.cancelMap['MStage_StageUpdate'].complete();
            delete this.cancelMap['MStage_StageUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MStage_StageUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MStage_StageUpdate')));
        }
        return handle;
    }

  }

export namespace MStageService {
  export enum Operations {
    MStage_StageActive = 'MStage_StageActive',
    MStage_StageDelete = 'MStage_StageDelete',
    MStage_StageGet = 'MStage_StageGet',
    MStage_StageGetAll = 'MStage_StageGetAll',
    MStage_StageHideOnLanding = 'MStage_StageHideOnLanding',
    MStage_StageSort = 'MStage_StageSort',
    MStage_StageUndelete = 'MStage_StageUndelete',
    MStage_StageUpdate = 'MStage_StageUpdate'
  }
}
