/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type StageMode = 0 | 1 | 10 | 11 | 40 | 41 | 50 | 51 | 100;

export const StageMode = {
    NUMBER_0: 0 as StageMode,
    NUMBER_1: 1 as StageMode,
    NUMBER_10: 10 as StageMode,
    NUMBER_11: 11 as StageMode,
    NUMBER_40: 40 as StageMode,
    NUMBER_41: 41 as StageMode,
    NUMBER_50: 50 as StageMode,
    NUMBER_51: 51 as StageMode,
    NUMBER_100: 100 as StageMode
};

