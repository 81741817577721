import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { AccountManageService } from './api/accountManage.service';
import { ActionService } from './api/action.service';
import { AdminChatService } from './api/adminChat.service';
import { BoothAvailabilityService } from './api/boothAvailability.service';
import { ChatService } from './api/chat.service';
import { ContactService } from './api/contact.service';
import { ContentService } from './api/content.service';
import { ExpoService } from './api/expo.service';
import { MCalendarService } from './api/mCalendar.service';
import { MConfigOptionsService } from './api/mConfigOptions.service';
import { MExhibitorService } from './api/mExhibitor.service';
import { MExpoService } from './api/mExpo.service';
import { MStageService } from './api/mStage.service';
import { MWebService } from './api/mWeb.service';
import { OauthClientService } from './api/oauthClient.service';
import { ReportService } from './api/report.service';
import { TalkBookingService } from './api/talkBooking.service';
import { TicketService } from './api/ticket.service';
import { WebService } from './api/web.service';
import { WebHooksService } from './api/webHooks.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    AccountManageService,
    ActionService,
    AdminChatService,
    BoothAvailabilityService,
    ChatService,
    ContactService,
    ContentService,
    ExpoService,
    MCalendarService,
    MConfigOptionsService,
    MExhibitorService,
    MExpoService,
    MStageService,
    MWebService,
    OauthClientService,
    ReportService,
    TalkBookingService,
    TicketService,
    WebService,
    WebHooksService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: (...args: any[]) => Configuration, deps?: any[]): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory, deps } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
