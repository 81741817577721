/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface PwdLessLoginDto { 
    email: string;
}

/**
 * Namespace for property- and property-value-enumerations of PwdLessLoginDto.
 */
export namespace PwdLessLoginDto {
    /**
     * All properties of PwdLessLoginDto.
     */
    export enum Properties {
        email = 'email'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of PwdLessLoginDto.
    */
    export const ModelValidators: {[K in keyof PwdLessLoginDto]: [string, ValidatorFn][]} = {
        email: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
    };

    /**
    * The FormControlFactory for PwdLessLoginDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<PwdLessLoginDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for PwdLessLoginDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: PwdLessLoginDto = {
            email: null,
          }
        ) {
            super(model, PwdLessLoginDto.ModelValidators);
        }
    }

}


