/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';


export interface ExhibitorState { 
    shortKey?: string;
    current?: string;
    isOnline?: boolean;
    liveStreamActive?: boolean;
    liveStreamType?: StreamType;
    liveStreamId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorState.
 */
export namespace ExhibitorState {
    /**
     * All properties of ExhibitorState.
     */
    export enum Properties {
        shortKey = 'shortKey',
        current = 'current',
        isOnline = 'isOnline',
        liveStreamActive = 'liveStreamActive',
        liveStreamType = 'liveStreamType',
        liveStreamId = 'liveStreamId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorState.
    */
    export const ModelValidators: {[K in keyof ExhibitorState]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        current: [
        ],
        isOnline: [
        ],
        liveStreamActive: [
        ],
        liveStreamType: [
        ],
        liveStreamId: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorState.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorState> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorState.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorState = {
            shortKey: null,
            current: null,
            isOnline: null,
            liveStreamActive: null,
            liveStreamType: null,
            liveStreamId: null,
          }
        ) {
            super(model, ExhibitorState.ModelValidators);
        }
    }

}


