import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-profile-edit-social',
  templateUrl: './profile-edit-social.component.html',
  styleUrls: ['./profile-edit-social.component.scss']
})
export class ProfileEditSocialComponent implements OnInit {
  @Input() network: string;
  @Input() value: string;

  @Output() networkUpdated: EventEmitter<{ oldNetwork: string, network: string, value: string }> = new EventEmitter<{ oldNetwork: string, network: string, value: string }>();

  modalRef: NgbModalRef;
  valForm: FormGroup;

  oldNetwork: string;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.valForm = this.fb.group({
      'network': [null, Validators.required],
      'value': [null, Validators.required]
    });

    this.valForm.reset({network: this.network, value: this.value});
  }

  cancelEditor($event) {
    $event.preventDefault();
    this.modalRef.close();
  }

  submitForm($event: MouseEvent) {
    $event.preventDefault();
    this.networkUpdated.emit({oldNetwork: this.network, network: this.valForm.value['network'], value: this.valForm.value['value']});
    this.modalRef.close();
  }
}
