/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { AlbedoColor } from './albedoColor';


export interface Material { 
    name?: string;
    albedoColor?: AlbedoColor;
}

/**
 * Namespace for property- and property-value-enumerations of Material.
 */
export namespace Material {
    /**
     * All properties of Material.
     */
    export enum Properties {
        name = 'name',
        albedoColor = 'albedoColor'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Material.
    */
    export const ModelValidators: {[K in keyof Material]: [string, ValidatorFn][]} = {
        name: [
        ],
        albedoColor: [
        ],
    };

    /**
    * The FormControlFactory for Material.
    */
    export class FormControlFactory extends BaseFormControlFactory<Material> {

        /**
         * Constructor.
         *
         * @param model An existing model for Material.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Material = {
            name: null,
            albedoColor: null,
          }
        ) {
            super(model, Material.ModelValidators);
        }
    }

}


