/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type BoothSize = 0 | 1 | 2 | 3 | 10 | 11;

export const BoothSize = {
    NUMBER_0: 0 as BoothSize,
    NUMBER_1: 1 as BoothSize,
    NUMBER_2: 2 as BoothSize,
    NUMBER_3: 3 as BoothSize,
    NUMBER_10: 10 as BoothSize,
    NUMBER_11: 11 as BoothSize
};

