/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ActiveUpdateDto { 
    itemId?: string;
    inActive?: boolean;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ActiveUpdateDto.
 */
export namespace ActiveUpdateDto {
    /**
     * All properties of ActiveUpdateDto.
     */
    export enum Properties {
        itemId = 'itemId',
        inActive = 'inActive',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ActiveUpdateDto.
    */
    export const ModelValidators: {[K in keyof ActiveUpdateDto]: [string, ValidatorFn][]} = {
        itemId: [
        ],
        inActive: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ActiveUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ActiveUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ActiveUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ActiveUpdateDto = {
            itemId: null,
            inActive: null,
            rowVersion: null,
          }
        ) {
            super(model, ActiveUpdateDto.ModelValidators);
        }
    }

}


