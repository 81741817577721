/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { AvailableBookingResult } from '../model/availableBookingResult';
import { BoockingCheckDto } from '../model/boockingCheckDto';
import { BookSlotDto } from '../model/bookSlotDto';
import { BookingResultState } from '../model/bookingResultState';
import { MessageExhibitorDto } from '../model/messageExhibitorDto';
import { ProblemDetails } from '../model/problemDetails';
import { SessionPassword } from '../model/sessionPassword';
import { SurveyResult } from '../model/surveyResult';
import { TrackDownloadDto } from '../model/trackDownloadDto';
import { TrackingType } from '../model/trackingType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for webBookSlot.
 */
export namespace WebBookSlot {
    /**
     * Parameter map for webBookSlot.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      BookSlotDto: BookSlotDto;
    }

    /**
     * Enumeration of all parameters for webBookSlot.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      BookSlotDto = 'BookSlotDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webBookSlot
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebBookSlot.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webGetAvailableSlots.
 */
export namespace WebGetAvailableSlots {
    /**
     * Parameter map for webGetAvailableSlots.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      BoockingCheckDto: BoockingCheckDto;
    }

    /**
     * Enumeration of all parameters for webGetAvailableSlots.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      BoockingCheckDto = 'BoockingCheckDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetAvailableSlots
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetAvailableSlots.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webOpenSession.
 */
export namespace WebOpenSession {
    /**
     * Parameter map for webOpenSession.
     */
    export interface PartialParamMap {
      roomId: number;
      SessionPassword: SessionPassword;
    }

    /**
     * Enumeration of all parameters for webOpenSession.
     */
    export enum Parameters {
      roomId = 'roomId',
      SessionPassword = 'SessionPassword'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webOpenSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebOpenSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      roomId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webPostSurveyResult.
 */
export namespace WebPostSurveyResult {
    /**
     * Parameter map for webPostSurveyResult.
     */
    export interface PartialParamMap {
      SurveyResult: SurveyResult;
    }

    /**
     * Enumeration of all parameters for webPostSurveyResult.
     */
    export enum Parameters {
      SurveyResult = 'SurveyResult'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPostSurveyResult
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPostSurveyResult.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webSendOfflineMessage.
 */
export namespace WebSendOfflineMessage {
    /**
     * Parameter map for webSendOfflineMessage.
     */
    export interface PartialParamMap {
      MessageExhibitorDto: MessageExhibitorDto;
    }

    /**
     * Enumeration of all parameters for webSendOfflineMessage.
     */
    export enum Parameters {
      MessageExhibitorDto = 'MessageExhibitorDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webSendOfflineMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebSendOfflineMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webTrackContact.
 */
export namespace WebTrackContact {
    /**
     * Parameter map for webTrackContact.
     */
    export interface PartialParamMap {
      TrackDownloadDto: TrackDownloadDto;
    }

    /**
     * Enumeration of all parameters for webTrackContact.
     */
    export enum Parameters {
      TrackDownloadDto = 'TrackDownloadDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackContact.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webTrackDownload.
 */
export namespace WebTrackDownload {
    /**
     * Parameter map for webTrackDownload.
     */
    export interface PartialParamMap {
      ExpoShortKey?: string;
      ExhibitorId?: string;
      DisplayId?: string;
      Url?: string;
      TrackingType?: TrackingType;
    }

    /**
     * Enumeration of all parameters for webTrackDownload.
     */
    export enum Parameters {
      ExpoShortKey = 'ExpoShortKey',
      ExhibitorId = 'ExhibitorId',
      DisplayId = 'DisplayId',
      Url = 'Url',
      TrackingType = 'TrackingType'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackDownload
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackDownload.PartialParamMap]?: [string, ValidatorFn][]} = {
      ExpoShortKey: [
      ],
      ExhibitorId: [
              ['minlength', Validators.minLength(36)],
              ['maxlength', Validators.maxLength(36)],
      ],
      DisplayId: [
      ],
      Url: [
      ],
      TrackingType: [
      ],
    };
}

/**
 * Namespace for webTrackDownloadPost.
 */
export namespace WebTrackDownloadPost {
    /**
     * Parameter map for webTrackDownloadPost.
     */
    export interface PartialParamMap {
      TrackDownloadDto: TrackDownloadDto;
    }

    /**
     * Enumeration of all parameters for webTrackDownloadPost.
     */
    export enum Parameters {
      TrackDownloadDto = 'TrackDownloadDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackDownloadPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackDownloadPost.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class WebService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<BookingResultState>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webBookSlot(
      map.expoId,
      map.exhibitorId,
      map.BookSlotDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param BookSlotDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<BookingResultState>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webBookSlot.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webBookSlot.');
        }
        if (BookSlotDto === null || BookSlotDto === undefined) {
            throw new Error('Required parameter BookSlotDto was null or undefined when calling webBookSlot.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<BookingResultState>(`${this.configuration.basePath}/api/web/book/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            BookSlotDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_BookSlot']) {
                this.cancelMap['Web_BookSlot'].next(null);
            }
            this.cancelMap['Web_BookSlot'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_BookSlot']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_BookSlot']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_BookSlot', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_BookSlot']) {
            this.cancelMap['Web_BookSlot'].complete();
            delete this.cancelMap['Web_BookSlot'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_BookSlot',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_BookSlot')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<AvailableBookingResult>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<AvailableBookingResult>>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<AvailableBookingResult>>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetAvailableSlots(
      map.expoId,
      map.exhibitorId,
      map.BoockingCheckDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param BoockingCheckDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<AvailableBookingResult>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<AvailableBookingResult>>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<AvailableBookingResult>>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webGetAvailableSlots.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webGetAvailableSlots.');
        }
        if (BoockingCheckDto === null || BoockingCheckDto === undefined) {
            throw new Error('Required parameter BoockingCheckDto was null or undefined when calling webGetAvailableSlots.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<AvailableBookingResult>(`${this.configuration.basePath}/api/web/free/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            BoockingCheckDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetAvailableSlots']) {
                this.cancelMap['Web_GetAvailableSlots'].next(null);
            }
            this.cancelMap['Web_GetAvailableSlots'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetAvailableSlots']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetAvailableSlots']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetAvailableSlots', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetAvailableSlots']) {
            this.cancelMap['Web_GetAvailableSlots'].complete();
            delete this.cancelMap['Web_GetAvailableSlots'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetAvailableSlots',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetAvailableSlots')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webOpenSession(
      map.roomId,
      map.SessionPassword,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param roomId 
     * @param SessionPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling webOpenSession.');
        }
        if (SessionPassword === null || SessionPassword === undefined) {
            throw new Error('Required parameter SessionPassword was null or undefined when calling webOpenSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/web/conf/${encodeURIComponent(String(roomId))}`,
            SessionPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_OpenSession']) {
                this.cancelMap['Web_OpenSession'].next(null);
            }
            this.cancelMap['Web_OpenSession'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_OpenSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_OpenSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_OpenSession', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_OpenSession']) {
            this.cancelMap['Web_OpenSession'].complete();
            delete this.cancelMap['Web_OpenSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_OpenSession',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_OpenSession')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPostSurveyResult(
      map.SurveyResult,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param SurveyResult 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SurveyResult === null || SurveyResult === undefined) {
            throw new Error('Required parameter SurveyResult was null or undefined when calling webPostSurveyResult.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/web/survey`,
            SurveyResult,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PostSurveyResult']) {
                this.cancelMap['Web_PostSurveyResult'].next(null);
            }
            this.cancelMap['Web_PostSurveyResult'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PostSurveyResult']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PostSurveyResult']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PostSurveyResult', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PostSurveyResult']) {
            this.cancelMap['Web_PostSurveyResult'].complete();
            delete this.cancelMap['Web_PostSurveyResult'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PostSurveyResult',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PostSurveyResult')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<any>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webSendOfflineMessage(
      map.MessageExhibitorDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param MessageExhibitorDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<any>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (MessageExhibitorDto === null || MessageExhibitorDto === undefined) {
            throw new Error('Required parameter MessageExhibitorDto was null or undefined when calling webSendOfflineMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<any>(`${this.configuration.basePath}/api/web/message`,
            MessageExhibitorDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_SendOfflineMessage']) {
                this.cancelMap['Web_SendOfflineMessage'].next(null);
            }
            this.cancelMap['Web_SendOfflineMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_SendOfflineMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_SendOfflineMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_SendOfflineMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_SendOfflineMessage']) {
            this.cancelMap['Web_SendOfflineMessage'].complete();
            delete this.cancelMap['Web_SendOfflineMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_SendOfflineMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_SendOfflineMessage')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackContact(
      map.TrackDownloadDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param TrackDownloadDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TrackDownloadDto === null || TrackDownloadDto === undefined) {
            throw new Error('Required parameter TrackDownloadDto was null or undefined when calling webTrackContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/web/contact`,
            TrackDownloadDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackContact']) {
                this.cancelMap['Web_TrackContact'].next(null);
            }
            this.cancelMap['Web_TrackContact'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackContact', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackContact']) {
            this.cancelMap['Web_TrackContact'].complete();
            delete this.cancelMap['Web_TrackContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackContact',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackContact')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<any>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackDownload(
      map.ExpoShortKey,
      map.ExhibitorId,
      map.DisplayId,
      map.Url,
      map.TrackingType,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExpoShortKey 
     * @param ExhibitorId 
     * @param DisplayId 
     * @param Url 
     * @param TrackingType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackDownload(ExpoShortKey?: string, ExhibitorId?: string, DisplayId?: string, Url?: string, TrackingType?: TrackingType, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<any>;
    public webTrackDownload(ExpoShortKey?: string, ExhibitorId?: string, DisplayId?: string, Url?: string, TrackingType?: TrackingType, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
    public webTrackDownload(ExpoShortKey?: string, ExhibitorId?: string, DisplayId?: string, Url?: string, TrackingType?: TrackingType, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
    public webTrackDownload(ExpoShortKey?: string, ExhibitorId?: string, DisplayId?: string, Url?: string, TrackingType?: TrackingType, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (ExpoShortKey !== undefined && ExpoShortKey !== null) {
            queryParameters = queryParameters.set('ExpoShortKey', <any>ExpoShortKey);
        }
        if (ExhibitorId !== undefined && ExhibitorId !== null) {
            queryParameters = queryParameters.set('ExhibitorId', <any>ExhibitorId);
        }
        if (DisplayId !== undefined && DisplayId !== null) {
            queryParameters = queryParameters.set('DisplayId', <any>DisplayId);
        }
        if (Url !== undefined && Url !== null) {
            queryParameters = queryParameters.set('Url', <any>Url);
        }
        if (TrackingType !== undefined && TrackingType !== null) {
            queryParameters = queryParameters.set('TrackingType', <any>TrackingType);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<any>(`${this.configuration.basePath}/api/web/download`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackDownload']) {
                this.cancelMap['Web_TrackDownload'].next(null);
            }
            this.cancelMap['Web_TrackDownload'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackDownload']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackDownload']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackDownload', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackDownload']) {
            this.cancelMap['Web_TrackDownload'].complete();
            delete this.cancelMap['Web_TrackDownload'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackDownload',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackDownload')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackDownloadPost(
      map.TrackDownloadDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param TrackDownloadDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TrackDownloadDto === null || TrackDownloadDto === undefined) {
            throw new Error('Required parameter TrackDownloadDto was null or undefined when calling webTrackDownloadPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/web/download`,
            TrackDownloadDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackDownloadPost']) {
                this.cancelMap['Web_TrackDownloadPost'].next(null);
            }
            this.cancelMap['Web_TrackDownloadPost'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackDownloadPost']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackDownloadPost']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackDownloadPost', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackDownloadPost']) {
            this.cancelMap['Web_TrackDownloadPost'].complete();
            delete this.cancelMap['Web_TrackDownloadPost'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackDownloadPost',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackDownloadPost')));
        }
        return handle;
    }

  }

export namespace WebService {
  export enum Operations {
    Web_BookSlot = 'Web_BookSlot',
    Web_GetAvailableSlots = 'Web_GetAvailableSlots',
    Web_OpenSession = 'Web_OpenSession',
    Web_PostSurveyResult = 'Web_PostSurveyResult',
    Web_SendOfflineMessage = 'Web_SendOfflineMessage',
    Web_TrackContact = 'Web_TrackContact',
    Web_TrackDownload = 'Web_TrackDownload',
    Web_TrackDownloadPost = 'Web_TrackDownloadPost'
  }
}
