import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthInterceptor} from './interceptors/auth-interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {RoleGuard} from './guards/role.guard';
import {AuthInitService} from './initializers/auth-init.service';
import {WebService} from '../../ezfair-api';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    AuthService,
    AuthInterceptor,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AuthInitService) => () => {
        return ds.init();
      },
      deps: [AuthInitService, AuthService, WebService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ]
})
export class SecurityModule {
  static forRoot(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule
    };
  }
}
