/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';
import { MSequenceEditDto } from './mSequenceEditDto';
import { StageConfigurationDto } from './stageConfigurationDto';
import { StageMode } from './stageMode';
import { MCalendarEntryStage } from './mCalendarEntryStage';


export interface MStageEditDto { 
    id: string;
    shortKey: string;
    expoId?: string;
    name: string;
    description?: string;
    logoSquare?: string;
    logoWide?: string;
    sequence?: number;
    stagePass?: string;
    background?: string;
    backgroundRaw?: string;
    stageMode?: StageMode;
    exhibitorId?: string;
    calendarEntries?: Array<MCalendarEntryStage>;
    exhibitors?: Array<MSequenceEditDto>;
    vodType?: InventoryItemType;
    vodSource?: string;
    hideOnLanding?: boolean;
    uploadTs?: Date;
    stageConfiguration?: StageConfigurationDto;
    logoSquareRaw?: string;
    logoWideRaw?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MStageEditDto.
 */
export namespace MStageEditDto {
    /**
     * All properties of MStageEditDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        expoId = 'expoId',
        name = 'name',
        description = 'description',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        sequence = 'sequence',
        stagePass = 'stagePass',
        background = 'background',
        backgroundRaw = 'backgroundRaw',
        stageMode = 'stageMode',
        exhibitorId = 'exhibitorId',
        calendarEntries = 'calendarEntries',
        exhibitors = 'exhibitors',
        vodType = 'vodType',
        vodSource = 'vodSource',
        hideOnLanding = 'hideOnLanding',
        uploadTs = 'uploadTs',
        stageConfiguration = 'stageConfiguration',
        logoSquareRaw = 'logoSquareRaw',
        logoWideRaw = 'logoWideRaw'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MStageEditDto.
    */
    export const ModelValidators: {[K in keyof MStageEditDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortKey: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        expoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        sequence: [
        ],
        stagePass: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        background: [
        ],
        backgroundRaw: [
        ],
        stageMode: [
        ],
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        calendarEntries: [
        ],
        exhibitors: [
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        hideOnLanding: [
        ],
        uploadTs: [
        ],
        stageConfiguration: [
        ],
        logoSquareRaw: [
        ],
        logoWideRaw: [
        ],
    };

    /**
    * The FormControlFactory for MStageEditDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MStageEditDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MStageEditDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MStageEditDto = {
            id: null,
            shortKey: null,
            expoId: null,
            name: null,
            description: null,
            logoSquare: null,
            logoWide: null,
            sequence: null,
            stagePass: null,
            background: null,
            backgroundRaw: null,
            stageMode: null,
            exhibitorId: null,
            calendarEntries: null,
            exhibitors: null,
            vodType: null,
            vodSource: null,
            hideOnLanding: null,
            uploadTs: null,
            stageConfiguration: null,
            logoSquareRaw: null,
            logoWideRaw: null,
          }
        ) {
            super(model, MStageEditDto.ModelValidators);
        }
    }

}


