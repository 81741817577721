import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {JitsiMediaTrack, JitsiService} from '../jitsi.service';

@Component({
  selector: 'app-jitsi-track',
  templateUrl: './jitsi-track.component.html',
  styleUrls: ['./jitsi-track.component.scss']
})
export class JitsiTrackComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tracks: Array<JitsiMediaTrack> = [];
  @Input() trackId: string = 'local'
  @Input() muted: boolean = false;
  @Input() participantId: string = '';
  @Input() hideIcons: boolean = false;

  private lastTrackLength: number = -1;
  hasVideo: boolean = false;
  hasAudio: boolean = false;

  constructor(
    public jitsiService: JitsiService
  ) {
  }

  ngOnInit(): void {
    this.jitsiService.trackMuteChangedEvent.subscribe(
      (trackMuteChangedEvent) => {
        console.log('mute changed caught')
        if (trackMuteChangedEvent.id === this.participantId && trackMuteChangedEvent.type === 'video') {
          setTimeout(() => {
            this.hasVideo = !trackMuteChangedEvent.muted;
          }, 250);
        }
        if (trackMuteChangedEvent.id === this.participantId && trackMuteChangedEvent.type === 'audio') {
          setTimeout(() => {
            this.hasAudio = !trackMuteChangedEvent.muted;
          }, 250);
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.trackId === 'local') {
    //   if (changes && changes['muted']) {
    //     this.tracks.forEach((track) => {
    //       if (track.getType() === 'audio') {
    //         track.muted = this.muted;
    //       }
    //     });
    //   }
    // }
    // this.refreshTracks();
  }

  ngAfterViewChecked() {
    if (this.tracks.length !== this.lastTrackLength) {
      setTimeout(() => {
        this.refreshTracks();
      }, 250);
      // this.refreshTracks();
    }
  }

  ngOnDestroy() {
    this.onUnload(this.tracks)
  }

  onUnload(tracks: any[]) {
    console.log('track unload called')
    tracks.forEach((track: any, index: number) => {
      let id: string = '';
      if (track.getType() === 'video') {
        id = `video_${this.trackId}_${index}`;
      } else {
        id = `audio_${this.trackId}_${index}`;
      }

      let item = document.getElementById(id);
      if (item) {
        try {
          track.detach(item);
        } catch (e) {

        } finally {
          setTimeout(() => {
            try {
              if (item) {
                item.remove();
              }
            } catch (e) {

            }
          }, 500);
        }
      }
    });
  }

  refreshTracks() {
    this.lastTrackLength = this.tracks.length;
    this.tracks.forEach((track: any, index: number) => {
      let id: string = '';
      if (track.getType() === 'video') {
        id = `video_${this.trackId}_${index}`;
        if (this.participantId !== 'local') {
          this.hasVideo = !track.muted;
        }
      } else {
        id = `audio_${this.trackId}_${index}`;
        if (this.participantId !== 'local') {
          this.hasAudio = !track.muted;
        }
      }

      let item = document.getElementById(id);
      if (item) {
        try {
          track.attach(item);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
}
