import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JitsiHostComponent } from './jitsi-host/jitsi-host.component';
import { JitsiControllerComponent } from './jitsi-controller/jitsi-controller.component';
import { JitsiTrackComponent } from './jitsi-track/jitsi-track.component';
import { JitsiChatComponent } from './jitsi-chat/jitsi-chat.component';
import {FormsModule} from '@angular/forms';
import { JitsiConfigureComponent } from './jitsi-configure/jitsi-configure.component';
import { JitsiConfigurationDialogComponent } from './jitsi-configuration-dialog/jitsi-configuration-dialog.component';
import { JitsiControllerSmallComponent } from './jitsi-controller-small/jitsi-controller-small.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    declarations: [
        JitsiHostComponent,
        JitsiControllerComponent,
        JitsiTrackComponent,
        JitsiChatComponent,
        JitsiConfigureComponent,
        JitsiConfigurationDialogComponent,
        JitsiControllerSmallComponent
    ],
    exports: [
        JitsiHostComponent,
        JitsiControllerComponent,
        JitsiChatComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ]
})
export class MeetModule { }
