/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MessageExhibitorDto { 
    expoId: string;
    exhibitorId: string;
    name: string;
    eMail: string;
    message: string;
    exhibitor?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MessageExhibitorDto.
 */
export namespace MessageExhibitorDto {
    /**
     * All properties of MessageExhibitorDto.
     */
    export enum Properties {
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        name = 'name',
        eMail = 'eMail',
        message = 'message',
        exhibitor = 'exhibitor'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MessageExhibitorDto.
    */
    export const ModelValidators: {[K in keyof MessageExhibitorDto]: [string, ValidatorFn][]} = {
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        exhibitorId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(4)],
                ['maxlength', Validators.maxLength(50)],
        ],
        eMail: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(4)],
                ['maxlength', Validators.maxLength(100)],
        ],
        message: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(4)],
                ['maxlength', Validators.maxLength(1000)],
        ],
        exhibitor: [
        ],
    };

    /**
    * The FormControlFactory for MessageExhibitorDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MessageExhibitorDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MessageExhibitorDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MessageExhibitorDto = {
            expoId: null,
            exhibitorId: null,
            name: null,
            eMail: null,
            message: null,
            exhibitor: null,
          }
        ) {
            super(model, MessageExhibitorDto.ModelValidators);
        }
    }

}


