import {Component, OnInit} from '@angular/core';
import {VersionCheckService} from '../../core/version-check.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;

  constructor(public versionCheckService: VersionCheckService) {
    this.currentYear = (new Date()).getFullYear();
  }

  ngOnInit() {

  }

  getBuildDate(): string {
    return environment.timeStamp;
    // return this.versionCheckService.buildDate().toLocaleString();
  }
}
