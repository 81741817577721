/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface IntegrationDto { 
    id?: string;
    expiry?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of IntegrationDto.
 */
export namespace IntegrationDto {
    /**
     * All properties of IntegrationDto.
     */
    export enum Properties {
        id = 'id',
        expiry = 'expiry'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of IntegrationDto.
    */
    export const ModelValidators: {[K in keyof IntegrationDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        expiry: [
        ],
    };

    /**
    * The FormControlFactory for IntegrationDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<IntegrationDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for IntegrationDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: IntegrationDto = {
            id: null,
            expiry: null,
          }
        ) {
            super(model, IntegrationDto.ModelValidators);
        }
    }

}


