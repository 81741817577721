import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  MUserProfileEditDto
} from '../../../../ezfair-api';
import {ToasterService} from '../../../../core/toaster.service';
import {KeyValue} from '@angular/common';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProfileEditSocialComponent} from './profile-edit-social/profile-edit-social.component';
import {ConfirmationDialogService} from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import {TypedFormGroup} from '../../../../ezfair-api/formgroup';
import {AuthService} from '../../../../modules/security/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: MUserProfileEditDto;
  valForm: TypedFormGroup<MUserProfileEditDto>;
  errorMessage: string;
  private modalRef: NgbModalRef;

  constructor(
    private auth: AuthService
    , private fb: FormBuilder
    , private toasterService: ToasterService
    , private modalService: NgbModal
    , private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    const factory = new MUserProfileEditDto.FormControlFactory(this.profile);
    this.valForm = new TypedFormGroup<MUserProfileEditDto>({
      name: factory.createFormControl<string>('name'),
      phoneNumber: factory.createFormControl<string>('phoneNumber'),
      address: factory.createFormControl<string>('address'),
      company: factory.createFormControl<string>('company'),
      role: factory.createFormControl<string>('role'),
      email: factory.createFormControl<string>('email')
    });

    this.auth.getProfile()
      .then(
        (profile: MUserProfileEditDto) => {
          this.profile = profile;
          this.valForm.reset(profile);
        },
        (error) => {
          this.errorMessage = error.message;
        }
      );
  }

  changePassword($event) {
    $event.preventDefault();
    // show change password dialog
  }

  onImageAdded(data: string) {
    this.profile.imageRaw = data;
  }

  submitForm($ev) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      const saveData = {...this.profile, ...this.valForm.value};
      Object.assign(this.profile, this.valForm.value);
      this.auth.updateProfile(saveData)
        .then
        (
          (profile: MUserProfileEditDto) => {
            this.profile = profile;
            this.valForm.reset(profile);
            this.toasterService.saved();
          },
          (error) => {
            this.toasterService.error('An error occurred trying to save your profile change: ' + error.message);
            this.errorMessage = error.message;
          }
        );
    }
  }

  editSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(ProfileEditSocialComponent);
    const modalComponent = this.modalRef.componentInstance as ProfileEditSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = item.key;
    modalComponent.value = item.value;
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      debugger;
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.profile.socialProfiles[data.oldNetwork];
      }
      this.profile.socialProfiles[data.network] = data.value;
    });
  }

  addProfile($event: MouseEvent) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(ProfileEditSocialComponent);
    const modalComponent = this.modalRef.componentInstance as ProfileEditSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = '';
    modalComponent.value = '';
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      debugger;
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.profile.socialProfiles[data.oldNetwork];
      }
      this.profile.socialProfiles[data.network] = data.value;
    });
  }

  removeSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    this.confirmationDialogService.confirm('Delete social', 'Really delete social network info?')
      .then(value => {
        if (value) {
          delete this.profile.socialProfiles[item.key];
        }
      }, reason => {
      });
  }
}
