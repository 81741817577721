/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BoothOption } from './boothOption';
import { FurnitureOption } from './furnitureOption';


export interface FurnitureOptions { 
    code?: number;
    booths?: Array<BoothOption>;
    halls?: Array<string>;
    furniture?: Array<FurnitureOption>;
    materials?: Array<string>;
}

/**
 * Namespace for property- and property-value-enumerations of FurnitureOptions.
 */
export namespace FurnitureOptions {
    /**
     * All properties of FurnitureOptions.
     */
    export enum Properties {
        code = 'code',
        booths = 'booths',
        halls = 'halls',
        furniture = 'furniture',
        materials = 'materials'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of FurnitureOptions.
    */
    export const ModelValidators: {[K in keyof FurnitureOptions]: [string, ValidatorFn][]} = {
        code: [
        ],
        booths: [
        ],
        halls: [
        ],
        furniture: [
        ],
        materials: [
        ],
    };

    /**
    * The FormControlFactory for FurnitureOptions.
    */
    export class FormControlFactory extends BaseFormControlFactory<FurnitureOptions> {

        /**
         * Constructor.
         *
         * @param model An existing model for FurnitureOptions.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: FurnitureOptions = {
            code: null,
            booths: null,
            halls: null,
            furniture: null,
            materials: null,
          }
        ) {
            super(model, FurnitureOptions.ModelValidators);
        }
    }

}


