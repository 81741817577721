/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ExpoListDto } from '../model/expoListDto';
import { ExpoUpdateDto } from '../model/expoUpdateDto';
import { UserSearchResult } from '../model/userSearchResult';
import { VisitorListDto } from '../model/visitorListDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for expoGetExpo.
 */
export namespace ExpoGetExpo {
    /**
     * Parameter map for expoGetExpo.
     */
    export interface PartialParamMap {
      id: string;
    }

    /**
     * Enumeration of all parameters for expoGetExpo.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for expoGetExpoList.
 */
export namespace ExpoGetExpoList {
    /**
     * Parameter map for expoGetExpoList.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for expoGetExpoList.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetExpoList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetExpoList.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for expoGetExpos.
 */
export namespace ExpoGetExpos {
    /**
     * Parameter map for expoGetExpos.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for expoGetExpos.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetExpos
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetExpos.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for expoGetExposForOwner.
 */
export namespace ExpoGetExposForOwner {
    /**
     * Parameter map for expoGetExposForOwner.
     */
    export interface PartialParamMap {
      ownerId: string;
    }

    /**
     * Enumeration of all parameters for expoGetExposForOwner.
     */
    export enum Parameters {
      ownerId = 'ownerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetExposForOwner
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetExposForOwner.PartialParamMap]?: [string, ValidatorFn][]} = {
      ownerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for expoGetMyExpos.
 */
export namespace ExpoGetMyExpos {
    /**
     * Parameter map for expoGetMyExpos.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for expoGetMyExpos.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetMyExpos
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetMyExpos.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for expoGetUsers.
 */
export namespace ExpoGetUsers {
    /**
     * Parameter map for expoGetUsers.
     */
    export interface PartialParamMap {
      pattern: string;
    }

    /**
     * Enumeration of all parameters for expoGetUsers.
     */
    export enum Parameters {
      pattern = 'pattern'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetUsers
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetUsers.PartialParamMap]?: [string, ValidatorFn][]} = {
      pattern: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for expoGetVisitors.
 */
export namespace ExpoGetVisitors {
    /**
     * Parameter map for expoGetVisitors.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for expoGetVisitors.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoGetVisitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoGetVisitors.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for expoPostExpo.
 */
export namespace ExpoPostExpo {
    /**
     * Parameter map for expoPostExpo.
     */
    export interface PartialParamMap {
      ExpoUpdateDto: ExpoUpdateDto;
    }

    /**
     * Enumeration of all parameters for expoPostExpo.
     */
    export enum Parameters {
      ExpoUpdateDto = 'ExpoUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of expoPostExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExpoPostExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class ExpoService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetExpoByMap(
    map: ExpoGetExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
  public expoGetExpoByMap(
    map: ExpoGetExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
  public expoGetExpoByMap(
    map: ExpoGetExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
  public expoGetExpoByMap(
    map: ExpoGetExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetExpo(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetExpo(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
    public expoGetExpo(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
    public expoGetExpo(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
    public expoGetExpo(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling expoGetExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoUpdateDto>(`${this.configuration.basePath}/api/Expo/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetExpo']) {
                this.cancelMap['Expo_GetExpo'].next(null);
            }
            this.cancelMap['Expo_GetExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetExpo']) {
            this.cancelMap['Expo_GetExpo'].complete();
            delete this.cancelMap['Expo_GetExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetExpoListByMap(
    map: ExpoGetExpoList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
  public expoGetExpoListByMap(
    map: ExpoGetExpoList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
  public expoGetExpoListByMap(
    map: ExpoGetExpoList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
  public expoGetExpoListByMap(
    map: ExpoGetExpoList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetExpoList(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetExpoList(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
    public expoGetExpoList(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
    public expoGetExpoList(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
    public expoGetExpoList(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoListDto>>(`${this.configuration.basePath}/api/Expo/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetExpoList']) {
                this.cancelMap['Expo_GetExpoList'].next(null);
            }
            this.cancelMap['Expo_GetExpoList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetExpoList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetExpoList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetExpoList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetExpoList']) {
            this.cancelMap['Expo_GetExpoList'].complete();
            delete this.cancelMap['Expo_GetExpoList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetExpoList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetExpoList')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetExposByMap(
    map: ExpoGetExpos.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
  public expoGetExposByMap(
    map: ExpoGetExpos.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
  public expoGetExposByMap(
    map: ExpoGetExpos.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
  public expoGetExposByMap(
    map: ExpoGetExpos.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetExpos(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetExpos(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
    public expoGetExpos(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
    public expoGetExpos(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
    public expoGetExpos(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoUpdateDto>>(`${this.configuration.basePath}/api/Expo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetExpos']) {
                this.cancelMap['Expo_GetExpos'].next(null);
            }
            this.cancelMap['Expo_GetExpos'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetExpos']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetExpos']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetExpos', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetExpos']) {
            this.cancelMap['Expo_GetExpos'].complete();
            delete this.cancelMap['Expo_GetExpos'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetExpos',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetExpos')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetExposForOwnerByMap(
    map: ExpoGetExposForOwner.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
  public expoGetExposForOwnerByMap(
    map: ExpoGetExposForOwner.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
  public expoGetExposForOwnerByMap(
    map: ExpoGetExposForOwner.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
  public expoGetExposForOwnerByMap(
    map: ExpoGetExposForOwner.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetExposForOwner(
      map.ownerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ownerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetExposForOwner(ownerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
    public expoGetExposForOwner(ownerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
    public expoGetExposForOwner(ownerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
    public expoGetExposForOwner(ownerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ownerId === null || ownerId === undefined) {
            throw new Error('Required parameter ownerId was null or undefined when calling expoGetExposForOwner.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoUpdateDto>>(`${this.configuration.basePath}/api/Expo/owner/${encodeURIComponent(String(ownerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetExposForOwner']) {
                this.cancelMap['Expo_GetExposForOwner'].next(null);
            }
            this.cancelMap['Expo_GetExposForOwner'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetExposForOwner']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetExposForOwner']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetExposForOwner', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetExposForOwner']) {
            this.cancelMap['Expo_GetExposForOwner'].complete();
            delete this.cancelMap['Expo_GetExposForOwner'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetExposForOwner',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetExposForOwner')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetMyExposByMap(
    map: ExpoGetMyExpos.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
  public expoGetMyExposByMap(
    map: ExpoGetMyExpos.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
  public expoGetMyExposByMap(
    map: ExpoGetMyExpos.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
  public expoGetMyExposByMap(
    map: ExpoGetMyExpos.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetMyExpos(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetMyExpos(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
    public expoGetMyExpos(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
    public expoGetMyExpos(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
    public expoGetMyExpos(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoUpdateDto>>(`${this.configuration.basePath}/api/Expo/my`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetMyExpos']) {
                this.cancelMap['Expo_GetMyExpos'].next(null);
            }
            this.cancelMap['Expo_GetMyExpos'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetMyExpos']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetMyExpos']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetMyExpos', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetMyExpos']) {
            this.cancelMap['Expo_GetMyExpos'].complete();
            delete this.cancelMap['Expo_GetMyExpos'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetMyExpos',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetMyExpos')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetUsersByMap(
    map: ExpoGetUsers.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<UserSearchResult>>;
  public expoGetUsersByMap(
    map: ExpoGetUsers.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<UserSearchResult>>>;
  public expoGetUsersByMap(
    map: ExpoGetUsers.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<UserSearchResult>>>;
  public expoGetUsersByMap(
    map: ExpoGetUsers.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetUsers(
      map.pattern,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param pattern 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetUsers(pattern: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<UserSearchResult>>;
    public expoGetUsers(pattern: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<UserSearchResult>>>;
    public expoGetUsers(pattern: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<UserSearchResult>>>;
    public expoGetUsers(pattern: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (pattern === null || pattern === undefined) {
            throw new Error('Required parameter pattern was null or undefined when calling expoGetUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<UserSearchResult>>(`${this.configuration.basePath}/api/Expo/user/${encodeURIComponent(String(pattern))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetUsers']) {
                this.cancelMap['Expo_GetUsers'].next(null);
            }
            this.cancelMap['Expo_GetUsers'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetUsers']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetUsers']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetUsers', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetUsers']) {
            this.cancelMap['Expo_GetUsers'].complete();
            delete this.cancelMap['Expo_GetUsers'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetUsers',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetUsers')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoGetVisitorsByMap(
    map: ExpoGetVisitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
  public expoGetVisitorsByMap(
    map: ExpoGetVisitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
  public expoGetVisitorsByMap(
    map: ExpoGetVisitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
  public expoGetVisitorsByMap(
    map: ExpoGetVisitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoGetVisitors(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoGetVisitors(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
    public expoGetVisitors(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
    public expoGetVisitors(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
    public expoGetVisitors(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling expoGetVisitors.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<VisitorListDto>>(`${this.configuration.basePath}/api/Expo/visitors/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_GetVisitors']) {
                this.cancelMap['Expo_GetVisitors'].next(null);
            }
            this.cancelMap['Expo_GetVisitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_GetVisitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_GetVisitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_GetVisitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_GetVisitors']) {
            this.cancelMap['Expo_GetVisitors'].complete();
            delete this.cancelMap['Expo_GetVisitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_GetVisitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_GetVisitors')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public expoPostExpoByMap(
    map: ExpoPostExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
  public expoPostExpoByMap(
    map: ExpoPostExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
  public expoPostExpoByMap(
    map: ExpoPostExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
  public expoPostExpoByMap(
    map: ExpoPostExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.expoPostExpo(
      map.ExpoUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExpoUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public expoPostExpo(ExpoUpdateDto: ExpoUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
    public expoPostExpo(ExpoUpdateDto: ExpoUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
    public expoPostExpo(ExpoUpdateDto: ExpoUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
    public expoPostExpo(ExpoUpdateDto: ExpoUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExpoUpdateDto === null || ExpoUpdateDto === undefined) {
            throw new Error('Required parameter ExpoUpdateDto was null or undefined when calling expoPostExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ExpoUpdateDto>(`${this.configuration.basePath}/api/Expo`,
            ExpoUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Expo_PostExpo']) {
                this.cancelMap['Expo_PostExpo'].next(null);
            }
            this.cancelMap['Expo_PostExpo'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Expo_PostExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Expo_PostExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Expo_PostExpo', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Expo_PostExpo']) {
            this.cancelMap['Expo_PostExpo'].complete();
            delete this.cancelMap['Expo_PostExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Expo_PostExpo',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Expo_PostExpo')));
        }
        return handle;
    }

  }

export namespace ExpoService {
  export enum Operations {
    Expo_GetExpo = 'Expo_GetExpo',
    Expo_GetExpoList = 'Expo_GetExpoList',
    Expo_GetExpos = 'Expo_GetExpos',
    Expo_GetExposForOwner = 'Expo_GetExposForOwner',
    Expo_GetMyExpos = 'Expo_GetMyExpos',
    Expo_GetUsers = 'Expo_GetUsers',
    Expo_GetVisitors = 'Expo_GetVisitors',
    Expo_PostExpo = 'Expo_PostExpo'
  }
}
