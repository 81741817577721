/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type OverviewMode = 0 | 1 | 2 | 3 | 4 | 5 | 11 | 12 | 13 | 20 | 21 | 22;

export const OverviewMode = {
    NUMBER_0: 0 as OverviewMode,
    NUMBER_1: 1 as OverviewMode,
    NUMBER_2: 2 as OverviewMode,
    NUMBER_3: 3 as OverviewMode,
    NUMBER_4: 4 as OverviewMode,
    NUMBER_5: 5 as OverviewMode,
    NUMBER_11: 11 as OverviewMode,
    NUMBER_12: 12 as OverviewMode,
    NUMBER_13: 13 as OverviewMode,
    NUMBER_20: 20 as OverviewMode,
    NUMBER_21: 21 as OverviewMode,
    NUMBER_22: 22 as OverviewMode
};

