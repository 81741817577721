import {Component, HostBinding, OnInit, Sanitizer, SecurityContext} from '@angular/core';
import {VersionCheckService} from './core/version-check.service';
import {AuthService} from './modules/security/services/auth.service';
import {MarkdownService} from 'ngx-markdown';
import {MExpoService, MUserProfileEditDto} from './ezfair-api';

declare var $: any;
declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  freshChatInit = false;

  // @HostBinding('class.layout-fixed') get isFixed() {
  //   return this.settings.layout.isFixed;
  // }
  //
  // @HostBinding('class.aside-collapsed') get isCollapsed() {
  //   return this.settings.layout.isCollapsed;
  // }
  //
  // @HostBinding('class.layout-boxed') get isBoxed() {
  //   return this.settings.layout.isBoxed;
  // }
  //
  // @HostBinding('class.layout-fs') get useFullLayout() {
  //   return this.settings.layout.useFullLayout;
  // }
  //
  // @HostBinding('class.hidden-footer') get hiddenFooter() {
  //   return this.settings.layout.hiddenFooter;
  // }
  //
  // @HostBinding('class.layout-h') get horizontal() {
  //   return this.settings.layout.horizontal;
  // }
  //
  // @HostBinding('class.aside-float') get isFloat() {
  //   return this.settings.layout.isFloat;
  // }
  //
  // @HostBinding('class.offsidebar-open') get offsidebarOpen() {
  //   return this.settings.layout.offsidebarOpen;
  // }
  //
  // @HostBinding('class.aside-toggled') get asideToggled() {
  //   return this.settings.layout.asideToggled;
  // }
  //
  // @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
  //   return this.settings.layout.isCollapsedText;
  // }

  constructor(
    private auth: AuthService
    , private mExpoService: MExpoService
  )  {
  }

  ngOnInit() {

    // this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    //
    // this.authService.onTokenRefresh
    //   .asObservable()
    //   .subscribe(value => {
    //     this.checkFreshChatInit();
    //   });
    //
    // setTimeout(() => {
    //   this.tryCheckFreshChat();
    // }, 5000);
  }

  // checkFreshChatInit() {
  //   if (window.fcWidget && this.authService.isLoggedIn()) {
  //     const tmp = this.authService.getTokenData();
  //     if (tmp) {
  //       const nameId = tmp['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
  //       window.fcWidget.setExternalId(nameId);
  //       this.freshChatInit = true;
  //     }
  //   }
  // }
  //
  // tryCheckFreshChat() {
  //   if (!this.freshChatInit) {
  //     this.checkFreshChatInit();
  //     if (!this.freshChatInit) {
  //       setTimeout(() => {
  //         this.tryCheckFreshChat();
  //       }, 10000);
  //     }
  //   }
  // }
}
