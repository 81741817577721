import {Component, OnInit, HostBinding} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {Router} from '@angular/router';
import {AuthService} from '../../../modules/security/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(fb: FormBuilder, private router: Router, private auth: AuthService) {
    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required]
    });
  }

  valForm: FormGroup;
  errorMessage: string;

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.auth.login(this.valForm.controls['email'].value, this.valForm.controls['password'].value)
        .subscribe(res => {
            this.errorMessage = '';
            this.router.navigate(['home']);
          },
          error => {
            this.errorMessage = 'Invalid username or password';
          }
        );
    }
  }

  ngOnInit() {

  }
}
