/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarAvailability } from './calendarAvailability';
import { BookingState } from './bookingState';
import { CalendarBookedSlotMeta } from './calendarBookedSlotMeta';


export interface CalendarBookedSlotAdminDto { 
    rowId?: number;
    availabilityId?: number;
    availability?: CalendarAvailability;
    start?: Date;
    partnerName?: string;
    partnerEmail?: string;
    comment?: string;
    bookingState?: BookingState;
    userId?: string;
    meta?: CalendarBookedSlotMeta;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlotAdminDto.
 */
export namespace CalendarBookedSlotAdminDto {
    /**
     * All properties of CalendarBookedSlotAdminDto.
     */
    export enum Properties {
        rowId = 'rowId',
        availabilityId = 'availabilityId',
        availability = 'availability',
        start = 'start',
        partnerName = 'partnerName',
        partnerEmail = 'partnerEmail',
        comment = 'comment',
        bookingState = 'bookingState',
        userId = 'userId',
        meta = 'meta'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlotAdminDto.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlotAdminDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        availabilityId: [
        ],
        availability: [
        ],
        start: [
        ],
        partnerName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        partnerEmail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        bookingState: [
        ],
        userId: [
        ],
        meta: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlotAdminDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlotAdminDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlotAdminDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlotAdminDto = {
            rowId: null,
            availabilityId: null,
            availability: null,
            start: null,
            partnerName: null,
            partnerEmail: null,
            comment: null,
            bookingState: null,
            userId: null,
            meta: null,
          }
        ) {
            super(model, CalendarBookedSlotAdminDto.ModelValidators);
        }
    }

}


