/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface JitsiRoomSettings { 
    room?: string;
    token?: string;
}

/**
 * Namespace for property- and property-value-enumerations of JitsiRoomSettings.
 */
export namespace JitsiRoomSettings {
    /**
     * All properties of JitsiRoomSettings.
     */
    export enum Properties {
        room = 'room',
        token = 'token'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of JitsiRoomSettings.
    */
    export const ModelValidators: {[K in keyof JitsiRoomSettings]: [string, ValidatorFn][]} = {
        room: [
        ],
        token: [
        ],
    };

    /**
    * The FormControlFactory for JitsiRoomSettings.
    */
    export class FormControlFactory extends BaseFormControlFactory<JitsiRoomSettings> {

        /**
         * Constructor.
         *
         * @param model An existing model for JitsiRoomSettings.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: JitsiRoomSettings = {
            room: null,
            token: null,
          }
        ) {
            super(model, JitsiRoomSettings.ModelValidators);
        }
    }

}


