/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ActionButton } from './actionButton';
import { TouchArea } from './touchArea';
import { Material } from './material';
import { Furniture } from './furniture';


export interface BoothConfigurationDto { 
    boothTypeId?: number;
    colors?: Array<string>;
    boothButtonMode?: number;
    actionButtonMode?: number;
    actionButtons?: Array<ActionButton>;
    groupChatLabel?: string;
    privateChatGreeting?: string;
    bannerImage?: string;
    bannerSource?: string;
    bannerLink?: string;
    bannerType?: number;
    bannerSize?: number;
    bannerDelay?: number;
    boothImage?: string;
    boothImageTransparent?: string;
    boothName?: string;
    boothAreas?: { [key: string]: TouchArea; };
    furniture?: { [key: string]: Furniture; };
    materials?: { [key: string]: Material; };
}

/**
 * Namespace for property- and property-value-enumerations of BoothConfigurationDto.
 */
export namespace BoothConfigurationDto {
    /**
     * All properties of BoothConfigurationDto.
     */
    export enum Properties {
        boothTypeId = 'boothTypeId',
        colors = 'colors',
        boothButtonMode = 'boothButtonMode',
        actionButtonMode = 'actionButtonMode',
        actionButtons = 'actionButtons',
        groupChatLabel = 'groupChatLabel',
        privateChatGreeting = 'privateChatGreeting',
        bannerImage = 'bannerImage',
        bannerSource = 'bannerSource',
        bannerLink = 'bannerLink',
        bannerType = 'bannerType',
        bannerSize = 'bannerSize',
        bannerDelay = 'bannerDelay',
        boothImage = 'boothImage',
        boothImageTransparent = 'boothImageTransparent',
        boothName = 'boothName',
        boothAreas = 'boothAreas',
        furniture = 'furniture',
        materials = 'materials'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothConfigurationDto.
    */
    export const ModelValidators: {[K in keyof BoothConfigurationDto]: [string, ValidatorFn][]} = {
        boothTypeId: [
        ],
        colors: [
        ],
        boothButtonMode: [
        ],
        actionButtonMode: [
        ],
        actionButtons: [
        ],
        groupChatLabel: [
        ],
        privateChatGreeting: [
        ],
        bannerImage: [
        ],
        bannerSource: [
        ],
        bannerLink: [
        ],
        bannerType: [
        ],
        bannerSize: [
        ],
        bannerDelay: [
                ['min', Validators.min(0)],
                ['max', Validators.max(30)],
        ],
        boothImage: [
        ],
        boothImageTransparent: [
        ],
        boothName: [
        ],
        boothAreas: [
        ],
        furniture: [
        ],
        materials: [
        ],
    };

    /**
    * The FormControlFactory for BoothConfigurationDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothConfigurationDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothConfigurationDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothConfigurationDto = {
            boothTypeId: null,
            colors: null,
            boothButtonMode: null,
            actionButtonMode: null,
            actionButtons: null,
            groupChatLabel: null,
            privateChatGreeting: null,
            bannerImage: null,
            bannerSource: null,
            bannerLink: null,
            bannerType: null,
            bannerSize: null,
            bannerDelay: null,
            boothImage: null,
            boothImageTransparent: null,
            boothName: null,
            boothAreas: null,
            furniture: null,
            materials: null,
          }
        ) {
            super(model, BoothConfigurationDto.ModelValidators);
        }
    }

}


