/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarEntrySessionViewDto } from './calendarEntrySessionViewDto';
import { MCalendarEntryStageDto } from './mCalendarEntryStageDto';
import { MCalendarEntryBoothDto } from './mCalendarEntryBoothDto';
import { MExpoState } from './mExpoState';
import { MExpoDto } from './mExpoDto';
import { MStageDtoSequence } from './mStageDtoSequence';
import { MExhibitorDtoSequence } from './mExhibitorDtoSequence';


export interface MExpoResultDto { 
    expo?: MExpoDto;
    exhibitors?: Array<MExhibitorDtoSequence>;
    stages?: Array<MStageDtoSequence>;
    stageCalendar?: Array<MCalendarEntryStageDto>;
    exhibitorCalendar?: Array<MCalendarEntryBoothDto>;
    state?: MExpoState;
    session?: Array<CalendarEntrySessionViewDto>;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoResultDto.
 */
export namespace MExpoResultDto {
    /**
     * All properties of MExpoResultDto.
     */
    export enum Properties {
        expo = 'expo',
        exhibitors = 'exhibitors',
        stages = 'stages',
        stageCalendar = 'stageCalendar',
        exhibitorCalendar = 'exhibitorCalendar',
        state = 'state',
        session = 'session'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoResultDto.
    */
    export const ModelValidators: {[K in keyof MExpoResultDto]: [string, ValidatorFn][]} = {
        expo: [
        ],
        exhibitors: [
        ],
        stages: [
        ],
        stageCalendar: [
        ],
        exhibitorCalendar: [
        ],
        state: [
        ],
        session: [
        ],
    };

    /**
    * The FormControlFactory for MExpoResultDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoResultDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoResultDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoResultDto = {
            expo: null,
            exhibitors: null,
            stages: null,
            stageCalendar: null,
            exhibitorCalendar: null,
            state: null,
            session: null,
          }
        ) {
            super(model, MExpoResultDto.ModelValidators);
        }
    }

}


