/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface FurnitureOption { 
    name?: string;
    role?: string;
}

/**
 * Namespace for property- and property-value-enumerations of FurnitureOption.
 */
export namespace FurnitureOption {
    /**
     * All properties of FurnitureOption.
     */
    export enum Properties {
        name = 'name',
        role = 'role'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of FurnitureOption.
    */
    export const ModelValidators: {[K in keyof FurnitureOption]: [string, ValidatorFn][]} = {
        name: [
        ],
        role: [
        ],
    };

    /**
    * The FormControlFactory for FurnitureOption.
    */
    export class FormControlFactory extends BaseFormControlFactory<FurnitureOption> {

        /**
         * Constructor.
         *
         * @param model An existing model for FurnitureOption.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: FurnitureOption = {
            name: null,
            role: null,
          }
        ) {
            super(model, FurnitureOption.ModelValidators);
        }
    }

}


