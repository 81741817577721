/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ActionDefinition { 
    action?: string;
    expo?: string;
    exhibitor?: string;
    stage?: string;
    sessionId?: string;
    item?: string;
    value?: string;
    detail?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ActionDefinition.
 */
export namespace ActionDefinition {
    /**
     * All properties of ActionDefinition.
     */
    export enum Properties {
        action = 'action',
        expo = 'expo',
        exhibitor = 'exhibitor',
        stage = 'stage',
        sessionId = 'sessionId',
        item = 'item',
        value = 'value',
        detail = 'detail'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ActionDefinition.
    */
    export const ModelValidators: {[K in keyof ActionDefinition]: [string, ValidatorFn][]} = {
        action: [
        ],
        expo: [
        ],
        exhibitor: [
        ],
        stage: [
        ],
        sessionId: [
        ],
        item: [
        ],
        value: [
        ],
        detail: [
        ],
    };

    /**
    * The FormControlFactory for ActionDefinition.
    */
    export class FormControlFactory extends BaseFormControlFactory<ActionDefinition> {

        /**
         * Constructor.
         *
         * @param model An existing model for ActionDefinition.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ActionDefinition = {
            action: null,
            expo: null,
            exhibitor: null,
            stage: null,
            sessionId: null,
            item: null,
            value: null,
            detail: null,
          }
        ) {
            super(model, ActionDefinition.ModelValidators);
        }
    }

}


