import * as moment from 'moment';
import {EventEmitter, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
  AccountService,
  LoginDto, MExpoService, MUserProfileEditDto,
  PwdLessCallbackDto, PwdLessLoginDto, TokenRefreshDto, TokenResultDto
} from '../../../ezfair-api';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {promise} from 'protractor';

@Injectable()
export class AuthService {
  onTokenRefresh = new Subject<TokenResultDto>();
  onTokenUpdate = new Subject<TokenResultDto>();
  onLogout = new Subject<void>();
  onLogin = new Subject<void>();
  roles: Array<string> = [];
  token: string;
  profile: MUserProfileEditDto;
  private jwtHelper: JwtHelperService;

  constructor(private accountApi: AccountService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  login(email: string, password: string): Observable<TokenResultDto> {
    const loginData: LoginDto = {email: email, password: password};
    return this.accountApi
      .accountLogin(loginData)
      .pipe(
        tap(x => {
            this.setSession(x);
            this.onLogin.next();
            this.getProfile();
          }
        )
      );
  }

  private setSession(authResult: TokenResultDto) {
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('refresh_token', authResult.refreshToken);

    this.token = authResult.token;
    const tokenPayload = this.getTokenData();
    this.roles = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    this.onTokenUpdate.next(authResult);

    this.startRefreshTokenTimer();
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    this.roles = [];
    this.token = null;
    this.profile = null;
    this.onLogout.next();
    this.stopRefreshTokenTimer();
    window.location.href = '/';
  }

  public isLoggedIn(): boolean {
    if (localStorage.getItem('id_token')) {
      const token = localStorage.getItem('id_token');
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  public hasRole(role: string): boolean {
    return this.roles?.includes(role) ?? false;
  }

  public hasRoleAny(roles: Array<string>): boolean {
    return roles?.some(x => this.hasRole(x)) ?? false;
  }

  public getToken(): string {
    const token = localStorage.getItem('id_token');
    return token;
  }

  public getTokenData() {
    const token = localStorage.getItem('id_token');
    const decodedToken = this.jwtHelper.decodeToken(token);
    return decodedToken;
  }

  public validateToken(token: string): Promise<TokenResultDto> {
    return new Promise((resolve, reject) => {
      const refreshDto: TokenRefreshDto = {
        token: localStorage.getItem("id_token"),
        refreshToken: localStorage.getItem("refresh_token")
      };
      this.accountApi
        .accountRefresh(refreshDto)
        .subscribe((x) => {
          this.setSession(x);
          resolve(x);
        }, error => {
          reject(error);
        });
    });
  }

  public startPwdLess(email: string): Promise<boolean> {
    return new Promise((resolve) => {
      const loginData: PwdLessLoginDto = {email: email};
      this.accountApi
        .accountEmailLogin(loginData)
        .subscribe(x => {
          resolve(true);
        }, (err) => {
          resolve(false);
        });
    });
  }

  public validatePwdLess(email: string, token: string): Promise<TokenResultDto> {
    return new Promise((resolve, reject) => {
      const loginData: PwdLessCallbackDto = {email: email, token: token};
      return this.accountApi
        .accountEmailCallback(loginData)
        .subscribe(x => {
            this.setSession(x);
            resolve(x);
          }, error => {
            reject(error);
          }
        );
    });
  }

  public validateTotpPwdLess(email: string, token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const loginData: PwdLessCallbackDto = {email: email, token: token};
      this.accountApi
        .accountEmailCallback(loginData)
        .subscribe(x => {
          this.setSession(x);
          resolve(true);
        }, error => {
          reject(error);
        });
    });
  }

  refreshToken(): Promise<TokenResultDto> {
    return new Promise((resolve, reject) => {
      const refreshDto: TokenRefreshDto = {
        token: localStorage.getItem("id_token"),
        refreshToken: localStorage.getItem("refresh_token")
      };
      if (refreshDto.token && refreshDto.refreshToken) {
        this.accountApi
          .accountRefresh(refreshDto)
          .subscribe((x) => {
              this.setSession(x);
              this.onTokenRefresh.next(x);
              resolve(x);
            }, (err) => {
              reject(err);
            }
          );
      } else {
        reject('No token to refresh');
      }
    });
  }

  getUserId(): string {
    const decodedToken = this.getTokenData();
    const id = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    return id;
  }

  getProfile(): Promise<MUserProfileEditDto> {
    return new Promise((resolve, reject) => {
      this.accountApi.accountProfileGetOwn()
        .subscribe(x => {
          this.profile = x;
          resolve(x);
        })
      ;
    });
  }

  updateProfile(dto: MUserProfileEditDto): Promise<MUserProfileEditDto> {
    return new Promise((resolve) => {
      this.accountApi.accountProfileUpdate(dto)
        .subscribe(x => {
          this.setSession(x);
          resolve(dto);
        });
    });
  }

  getUserName() {
    if (this.profile && this.profile.name) {
      return this.profile.name;
    }

    const decodedToken = this.getTokenData();
    const name = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    return name;
  }


  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

}



