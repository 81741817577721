import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';

const screenfull = require('screenfull');
declare var $: any;

import {MenuService} from '../../core/menu/menu.service';
import {AuthService} from '../../modules/security/services/auth.service';
import {ConfirmationDialogService} from '../../shared/confirmation-dialog/confirmation-dialog.service';
import {SetPasswordComponent} from '../../routes/pages/set-password/set-password.component';
import {ProfileComponent} from '../../expo/user/profile/profile/profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout

  givenName: string;

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', {static: true}) fsbutton;  // the fullscreen button

  constructor(public menu: MenuService
    , private router: Router, public auth: AuthService, private confirmationDialogService: ConfirmationDialogService
    , private modalService: NgbModal
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

    this.givenName = this.auth.getUserName();
  }

  ngOnInit() {
    this.isNavSearchVisible = false;

    // if (this.authData) {
    //   if (this.auth.data.name === this.auth.data.userId) {
    //     this.router.navigate(['profile']);
    //   }
    // } else {
    //   // console.log('login ngOnInit')
    //   // this.router.navigate(['/']);
    // }
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    // this.toggleSidebar.emit();
  }

  toggleCollapsedSidebar() {
    this.toggleSidebar.emit();
  }

  isCollapsedText() {
    return this.isCollapsedText;
  }

  screenFull() {
    return screenfull.isFullscreen;
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  signOut($ev) {
    $ev.preventDefault();
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to log out?', 'Yes, log out!')
      .then((confirmed) => {
        if (confirmed) {
          this.auth.logout();
        }
      }, reason => {
      });
  }

  setPassword($event: MouseEvent) {
    $event.preventDefault();

    this.modalService.open(SetPasswordComponent, {size: 'sm'});
  }

  hasRole(validRoles: Array<string>) {
    return this.auth.hasRoleAny(validRoles);
  }

  editProfile($event: MouseEvent) {
    $event.preventDefault();
    this.modalService.open(ProfileComponent, {size: 'xl'});
  }
}
