import {Component, OnInit, Injector} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserProfile} from '../../../ezfair-api';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {

  valForm: FormGroup;
  router: Router;
  currentYear: number;
  user: UserProfile;

  constructor(
    fb: FormBuilder
    , public injector: Injector
  ) {
    this.currentYear = (new Date()).getFullYear();
    this.valForm = fb.group({
      'password': [null, Validators.required]
    });

  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      // console.log('Valid!');
      // console.log(value);
      this.router.navigate(['home']);
    }
  }

  ngOnInit() {
    this.router = this.injector.get(Router);
  }

}
