/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExhibitorGroupReferenceDto { 
    id?: number;
    name: string;
    description?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    sequence?: number;
    active?: boolean;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorGroupReferenceDto.
 */
export namespace ExhibitorGroupReferenceDto {
    /**
     * All properties of ExhibitorGroupReferenceDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        sequence = 'sequence',
        active = 'active',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorGroupReferenceDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorGroupReferenceDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        sequence: [
        ],
        active: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorGroupReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorGroupReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorGroupReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorGroupReferenceDto = {
            id: null,
            name: null,
            description: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            sequence: null,
            active: null,
            rowVersion: null,
          }
        ) {
            super(model, ExhibitorGroupReferenceDto.ModelValidators);
        }
    }

}


