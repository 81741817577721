import {EventEmitter, HostListener, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JitsiConfigureComponent} from './jitsi-configure/jitsi-configure.component';
import {JitsiConfigurationDialogComponent} from './jitsi-configuration-dialog/jitsi-configuration-dialog.component';
import {MExhibitorService, MWebService, WebService} from '../../ezfair-api';
import {environment} from '../../../environments/environment';
import {ScriptService} from '../../core/script.service';
import {AppCompatibilityService} from '../app-compatibility.service';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class JitsiService {
  public currentExhibitor = '';
  public currentExpo = '';
  public currentRoom = '';
  public refreshLocalTracks: EventEmitter<void> = new EventEmitter();
  public newMessageEvent: EventEmitter<ChatMessage> = new EventEmitter();
  public userJoinedEvent: EventEmitter<{ id: string, name: string }> = new EventEmitter();
  public userLeftEvent: EventEmitter<string> = new EventEmitter();
  public userNameChangedEvent: EventEmitter<{ id: string, oldName: string, newName: string }> = new EventEmitter();
  public conferenceChangedEvent: EventEmitter<string> = new EventEmitter();
  public trackMuteChangedEvent: EventEmitter<{ id: string, type: string, muted: boolean }> = new EventEmitter();

  public conferenceEnabled: EventEmitter<void> = new EventEmitter();
  public conferenceDisabled: EventEmitter<void> = new EventEmitter();

  public updateSelectedDevicesEvent: EventEmitter<void> = new EventEmitter();

  public jitsiEnabledChangedEvent: EventEmitter<boolean> = new EventEmitter();
  public jitsiEnabled: boolean = false;
  public mediaCheckedChangedEvent: EventEmitter<boolean> = new EventEmitter();
  public mediaChecked: boolean = false;

  public localTracks: Array<JitsiMediaTrack> = new Array<JitsiMediaTrack>();
  public remoteTracks: Map<string, Array<JitsiMediaTrack>> = new Map<string, Array<JitsiMediaTrack>>();

  public participants: Map<string, string> = new Map();
  public displayName: string = '';

  public devices: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();

  public soundOff: boolean = true;
  public conferenceOff: boolean = false;

  public microphoneOff: boolean = true;
  public cameraOff: boolean = false;
  public selectedCamera: string = '';
  public selectedMicrophone: string = '';
  public selectedSpeaker: string = '';

  public chatVisible: boolean = false;

  private options = {
    hosts: {
      domain: 'meet.jitsi',
      muc: 'muc.meet.jitsi'
    },
    bosh: environment.jitsiServiceUrl,
    disableBeforeUnloadHandlers: true
  };

  private confOptions = {
    p2p: {
      enabled: false
    }
  };

  private connection: any;
  private isJoined = false;
  private conference: any;

  private JitsiMeetJS: any;

  public configurationChecked: boolean = false;
  public connected = false;
  public userId: string = '';
  public conferenceSubject: string = '';
  public maximized: boolean = false;
  public isScreenshare: boolean = false;
  private jitsiToken: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngbModal: NgbModal,
    private scriptService: ScriptService,
    private exhibitorService: MExhibitorService,
    private appCompatibilityService: AppCompatibilityService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.scriptService.load('jquery3', 'jitsi-api').then(value => {
        this.participants.set('local', 'local');
        this.JitsiMeetJS = (window as any).JitsiMeetJS;

        // this.webService.mWebGetJitsiToken('')
        //   .subscribe(token => {
        //     this.jitsiToken = token;
        //     this.displayName = this.appCompatibilityService.getDisplayName();
        //     this.connectServer();
        //   });
      });
    }
  }

  connectServer(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.connection) {
        resolve();
        return;
      }
      const initOptions = {
        disableAudioLevels: true,
        disableThirdPartyRequests: true,
      };
      this.JitsiMeetJS.init(initOptions);
      this.JitsiMeetJS.setLogLevel(this.JitsiMeetJS.logLevels.WARN);

      console.log('connecting to server ' + this.jitsiToken);
      this.connection = new this.JitsiMeetJS.JitsiConnection(null, this.jitsiToken, this.options);

      this.connection.addEventListener(
        this.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        () => {
          this.onConnectionSuccess();
        });
      this.connection.addEventListener(
        this.JitsiMeetJS.events.connection.CONNECTION_FAILED,
        (error: any) => {
          this.onConnectionFailed(error);
          this.connection = null;
          this.exhibitorService.mExhibitorGetJitsiToken(this.currentExhibitor, this.currentExpo)
            .subscribe((token) => {
              this.jitsiToken = token.token;
              this.currentRoom = token.room;
              this.displayName = this.appCompatibilityService.getDisplayName();
              this.connectServer();
            });
        });
      this.connection.addEventListener(
        this.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        () => {
          this.disconnect();
        });

      this.JitsiMeetJS.mediaDevices.addEventListener(
        this.JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
        (devices: any) => {
          this.onDeviceListChanged(devices);
        });

      this.openConnection().then(() => {
        resolve();
      });
    });
  }

  openConnection(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.connection.connect();

      if (this.jitsiEnabled) {
        this.connectLocalTracks().then(() => {
          resolve();
        });
      }
      this.jitsiEnabledChangedEvent.subscribe(active => {
        if (active) {
          this.connectLocalTracks().then(() => {
            resolve();
          });
        }
      });
    });
  }

  connectLocalTracks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let devices: Array<string> = [];
      if (!this.microphoneOff) {
        devices.push('audio');
      }
      if (!this.cameraOff) {
        devices.push('video');
      }

      this.localTracks.length = 0;
      this.JitsiMeetJS.createLocalTracks({
        devices: [devices],
        cameraDeviceId: this.selectedCamera,
        micDeviceId: this.selectedMicrophone,
      })
        .then((tracks: JitsiMediaTrack[]) => {
          this.onLocalTracks(tracks);
          // this.localTracks.forEach(value => {
          //   if (value.getType() === 'video' && this.cameraOff) {
          //     value.mute();
          //   } else if (value.getType() === 'audio' && this.microphoneOff) {
          //     value.mute();
          //   }
          // })
          this.selectedSpeaker = this.JitsiMeetJS.mediaDevices.getAudioOutputDevice();
          this.updateSelectedDevicesEvent.emit();
          console.log('spk: ' + this.selectedSpeaker);
        })
        .catch((error: any) => {
          throw error;
        });

      this.JitsiMeetJS.mediaDevices.enumerateDevices((devices: MediaDeviceInfo[]) => {
        this.devices.length = 0;
        devices.forEach(d => this.devices.push(d));
      });
    });
  }

  // joinExpo(expoId: string) {
  //   console.log('joinExpo: ' + expoId);
  //   this.expoConnection = new this.JitsiMeetJS.JitsiConnection(null, null, this.options);
  //   this.expoConnection.addEventListener(
  //     this.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
  //     () => {
  //       this.expoConference = this.expoConnection.initJitsiConference(expoId, this.confOptions);
  //       this.expoConference.setDisplayName(this.displayName);
  //       this.expoConference.on(this.JitsiMeetJS.events.conference.USER_JOINED,
  //         (id: string, participant: JitsiParticipant) => {
  //           console.log('user join ' + id + ' ' + participant._displayName);
  //         });
  //       this.expoConference.on(this.JitsiMeetJS.events.conference.USER_LEFT,
  //         (id: string) => {
  //           console.log('user left ' + id);
  //         });
  //       this.expoConference.on(
  //         this.JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
  //         (userID: string, message: string, ts: number) => {
  //           console.log(`expoMessage: ${userID} - ${message} - ${ts}`);
  //           const msg: ChatMessage = {
  //             userID,
  //             displayName: this.participants.get(userID) || 'unknown',
  //             message,
  //             ts
  //           };
  //           this.newMessageEvent.emit(msg);
  //         });
  //       this.expoConference.addCommandListener('myCommand', (params: JitsiCommand) => {
  //           console.log(`expoCommand myCommand: ${params}`);
  //         }
  //       )
  //       this.expoConference.join();
  //     });
  //   this.expoConnection.connect()
  // }

  joinConference(exhibitorId: string, expoId: string, subject: string) {
    this.connectServer().then(() => {
      this.hookupConference(exhibitorId, expoId, subject);
    });
  }

  hookupConference(exhibitorId: string, expoId: string, subject: string) {
    console.log('joinConference', exhibitorId);
    if (this.currentExhibitor && this.currentExhibitor !== '' && this.conference) {
      this.conference.leave();
    }
    if (!exhibitorId || exhibitorId.trim() === '') {
      return;
    }
    this.currentExhibitor = exhibitorId;
    this.currentExpo = expoId;
    this.exhibitorService.mExhibitorGetJitsiToken(exhibitorId, expoId)
      .subscribe((token) => {
        this.currentRoom = token.room;
        this.jitsiToken = token.token;

        this.conference = this.connection.initJitsiConference(this.currentRoom, this.confOptions);
        this.conference.setDisplayName(this.displayName);
        this.participants.set(this.conference.myUserId(), this.displayName);
        this.userId = this.conference.myUserId();

        this.conference.on(this.JitsiMeetJS.events.conference.TRACK_ADDED,
          (track: JitsiMediaTrack) => {
            this.onRemoteTrack(track);
          });
        this.conference.on(this.JitsiMeetJS.events.conference.TRACK_REMOVED,
          (track: JitsiMediaTrack) => {
            console.log(`track removed!!!${track}`);
          });
        this.conference.on(this.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
          (track: JitsiMediaTrack) => {
            console.log(`track mute changed!!!${track}`);
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
          () => {
            this.onConferenceJoined();
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
          (id: string, role: string) => {
            console.log(`user role changed!!!${id} - ${role}`);
            if (role === 'moderator') {
              this.conference.setSubject(subject);
            }
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.SUBJECT_CHANGED,
          (subject: string) => {
            console.log(`subject changed!!!${subject}`);
            this.conferenceSubject = subject;
          });
        this.conference.on(this.JitsiMeetJS.events.conference.USER_JOINED,
          (id: string, participant: JitsiParticipant) => {
            console.log('user join ' + id + ' ' + participant._displayName);
            const displayName = this.generateDisplayName(participant);
            this.participants.set(id, displayName);
            console.log(`showing as ${this.participants.get(id)}`);
            this.remoteTracks.set(id, new Array<JitsiMediaTrack>());
            this.userJoinedEvent.emit({id: id, name: displayName});
          });
        this.conference.on(this.JitsiMeetJS.events.conference.USER_LEFT,
          (id: string) => {
            this.onUserLeft(id);
          });
        // this.conference.on(this.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
        //   (track: any) => {
        //     console.log(`${track.getType()} - ${track.isMuted()}`);
        //     this.trackMuteChangedEvent.emit({
        //       id: track.getParticipantId(),
        //       type: track.getType(),
        //       muted: track.isMuted(),
        //     })
        //   });
        this.conference.on(
          this.JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
          (userID: string, displayName: string) => {
            console.log(`${userID} - ${this.participants.get(userID)} - ${displayName}`);
            this.userNameChangedEvent.emit({
              id: userID,
              oldName: this.participants.get(userID) || userID,
              newName: displayName
            });
            this.participants.set(userID, displayName);
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
          (userID: string, message: string, ts: number) => {
            console.log(`${userID} - ${message} - ${ts}`);
            const msg: ChatMessage = {
              userID,
              displayName: this.participants.get(userID) || 'unknown',
              message,
              ts
            };
            this.newMessageEvent.emit(msg);
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
          (userID: string, audioLevel: number) => {
            console.log(`${userID} - ${audioLevel}`);
          });
        this.conference.on(
          this.JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
          () => {
            console.log(`${this.conference.getPhoneNumber()} - ${this.conference.getPhonePin()}`);
          });
        this.conference.join();
        this.conferenceChangedEvent.emit(exhibitorId);
      });
  }

  private generateDisplayName(participant: JitsiParticipant): string {
    return participant._displayName || participant._id;
  }

  leaveRoom() {
    console.log('leaveRoom');
    this.currentExhibitor = '';
    this.conference.leave();
    this.conferenceChangedEvent.emit('');
  }

  enableSpeaker() {
    console.log('enableSpeaker');
    this.soundOff = false;
  }

  enableConference() {
    console.log('enableVideo');
    this.conferenceOff = false;
    this.connectServer();
  }

  enableMicrophone() {
    console.log('enableMicrophone');
    this.microphoneOff = false;
    this.localTracks.forEach(track => {
      if (track.getType() === 'audio') {
        track.unmute();
      }
    });
  }

  enableCamera() {
    console.log('enableCamera');
    this.cameraOff = false;

    if (this.localTracks[1]) {
      this.localTracks[1].dispose();
      this.localTracks.pop();
    }
    this.JitsiMeetJS.createLocalTracks({
      devices: ['video'],
      cameraDeviceId: this.selectedCamera,
      micDeviceId: this.selectedMicrophone,
    })
      .then((tracks: any[]) => {
        this.localTracks.push(tracks[0]);
        this.attachLocalEvents(tracks[0]);
        if (this.conference) {
          this.conference.addTrack(this.localTracks[1]);
        }
      })
      .catch((error: any) => console.log(error));
  }

  disableSpeaker() {
    console.log('disableSpeaker');
    this.soundOff = true;
  }

  disableConference() {
    console.log('disableVideo');
    this.conferenceOff = true;
    this.unload();
  }

  disableMicrophone() {
    console.log('disableMicrophone');
    this.microphoneOff = true;
    this.localTracks.forEach(track => {
      if (track.getType() === 'audio') {
        track.mute();
      }
    });
  }

  disableCamera() {
    console.log('disableCamera');
    this.cameraOff = true;
    this.localTracks.forEach(track => {
      if (track.getType() === 'video') {
        track.mute();
      }
    });
  }

  setAudioSilenced(state: boolean, $event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    this.soundOff = state;
  }

  /**
   * Handles local tracks.
   * @param tracks Array with JitsiTrack objects
   */
  private onLocalTracks(tracks: any[]) {
    // this.localTracks = tracks;
    this.localTracks.length = 0;
    tracks.forEach(value => {
      this.localTracks.push(value);
    });
    for (let i = 0; i < this.localTracks.length; i++) {
      this.attachLocalEvents(this.localTracks[i]);
      if (this.isJoined) {
        this.conference.addTrack(this.localTracks[i]);
      }
    }
    this.refreshLocalTracks.emit();
  }

  /**
   * Handles remote tracks
   * @param track JitsiTrack object
   */
  private onRemoteTrack(track: JitsiMediaTrack) {
    if (track.isLocal()) {
      return;
    }
    const participant = track.getParticipantId();

    if (!this.remoteTracks.has(participant)) {
      this.remoteTracks.set(participant, new Array<JitsiMediaTrack>());
    }

    // @ts-ignore
    const idx = this.remoteTracks.get(participant).push(track);

    track.addEventListener(
      this.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
      (audioLevel: number) => console.log(`Audio Level remote: ${audioLevel}`));
    track.addEventListener(
      this.JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
      (track: JitsiMediaTrack) => {
        console.log('remote track muted');
        this.trackMuteChangedEvent.emit({id: track.getParticipantId(), type: track.getType(), muted: track.isMuted()});
      });
    track.addEventListener(
      this.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
      () => console.log('remote track stoped'));
    track.addEventListener(this.JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
      (deviceId: any) =>
        console.log(
          `track audio output device was changed to ${deviceId}`));
    const id = participant + track.getType() + idx;

    // if (track.getType() === 'video') {
    //   $('#jitsi-remote').append(
    //     `<video autoplay='1' id='${participant}video${idx}' class="jitsi-video-remote" />`);
    // } else {
    //   $('#jitsi-remote').append(
    //     `<audio autoplay='1' id='${participant}audio${idx}' class="jitsi-audio-remote" />`);
    // }
    // track.attach($(`#${id}`)[0]);
  }

  /**
   * That private is executed when the conference is joined
   */
  private onConferenceJoined() {
    console.log('conference joined!');
    this.isJoined = true;
    this.participants.set(this.conference.myUserId(), this.conference.myUserId());
    for (let i = 0; i < this.localTracks.length; i++) {
      this.conference.addTrack(this.localTracks[i]);
    }
  }

  /**
   *
   * @param id
   */
  private onUserLeft(id: string) {
    console.log('user left ' + id);
    if (this.participants.has(id)) {
      this.participants.delete(id);
    }
    if (!this.remoteTracks.has(id)) {
      return;
    }
    this.remoteTracks.delete(id);
    this.userLeftEvent.emit(id);
  }

  /**
   * That private is called when connection is established successfully
   */
  private onConnectionSuccess() {
    this.connected = true;
    this.conferenceEnabled.emit();
  }

  /**
   * This private is called when the connection fail.
   */
  private onConnectionFailed(error: any) {
    console.error('Connection Failed!');
  }

  /**
   * This private is called when the connection fail.
   */
  private onDeviceListChanged(devices: Array<MediaDeviceInfo>) {
    console.info('current devices', devices);
    this.devices = devices;
  }

  /**
   * This private is called when we disconnect.
   */
  private disconnect() {
    console.log('disconnect!');
    this.connection.removeEventListener(
      this.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => {
        this.onConnectionSuccess();
      });
    this.connection.removeEventListener(
      this.JitsiMeetJS.events.connection.CONNECTION_FAILED,
      () => {
        this.onConnectionFailed(null);
      });
    this.connection.removeEventListener(
      this.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      () => {
        this.disconnect();
      });
  }

  /**
   *
   */
  unload($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    for (let i = 0; i < this.localTracks.length; i++) {
      this.localTracks[i].dispose();
    }
    this.localTracks.length = 0;
    if (this.conference) {
      this.conference.leave();
    }
    this.conference = null;
    if (this.connection) {
      this.connection.disconnect();
    }
    this.connection = null;
    this.conferenceDisabled.emit();
  }

  public browserClose() {
    if (this.connection) {
      console.log('browser closing, disconnect');
      if (this.conference) {
        this.conference.leave();
      }
      if (this.connection) {
        this.connection.disconnect();
      }
      console.log('disconnected');
    }
  }

  /**
   *
   * @param selected
   */
  private changeAudioOutput(selected: { value: any; }) { // eslint-disable-line no-unused-vars
    this.JitsiMeetJS.mediaDevices.setAudioOutputDevice(selected.value);
  }

  public setDisplayName(name: string) {
    this.conference.setDisplayName(name);
    this.participants.set(this.conference.myUserId(), name);
  }

  public sendTextMessage(message: string) {
    this.conference.sendTextMessage(message);
  }

  private attachLocalEvents(track: any) {
    track.addEventListener(
      this.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
      (audioLevel: any) => console.log(`Audio Level local: ${audioLevel}`));
    track.addEventListener(
      this.JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
      () => console.log('local track muted'));
    track.addEventListener(
      this.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
      () => console.log('local track stoped'));
    track.addEventListener(
      this.JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
      (deviceId: any) =>
        console.log(
          `track audio output device was changed to ${deviceId}`));

    if (track.getType() === 'video') {
      this.selectedCamera = track.getDeviceId();
    } else if (track.getType() === 'audio') {
      this.selectedMicrophone = track.getDeviceId();
    }
  }

  selectMicrophone(selectedMicrophone: string) {
    this.selectedMicrophone = selectedMicrophone;

    if (this.localTracks[0]) {
      this.localTracks[0].dispose();
    }
    this.JitsiMeetJS.createLocalTracks({
      devices: ['audio'],
      cameraDeviceId: this.selectedCamera,
      micDeviceId: this.selectedMicrophone,
    })
      .then((tracks: any[]) => {
        this.localTracks[0] = tracks[0];
        this.attachLocalEvents(tracks[0]);
        if (this.conference) {
          this.conference.addTrack(this.localTracks[1]);
        }
      })
      .catch((error: any) => console.log(error));
  }

  selectCamera(selectedCamera: string) {
    this.selectedCamera = selectedCamera;

    if (this.localTracks[1]) {
      this.localTracks[1].dispose();
      this.localTracks.pop();
    }
    this.JitsiMeetJS.createLocalTracks({
      devices: ['video'],
      cameraDeviceId: this.selectedCamera,
      micDeviceId: this.selectedMicrophone,
    })
      .then((tracks: any[]) => {
        this.localTracks.push(tracks[0]);
        this.attachLocalEvents(tracks[0]);
        if (this.conference) {
          this.conference.addTrack(this.localTracks[1]);
        }
      })
      .catch((error: any) => console.log(error));
  }

  selectSpeaker(selectedSpeaker: string) {
    this.selectedSpeaker = selectedSpeaker;
    this.JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectedSpeaker);
  }

  startScreensharing() {
    this.localTracks.forEach((track: any, index: number) => {
      if (track.getType() === 'video') {
        track.dispose();
        this.localTracks.splice(index, 1);
      }
    });
    if (this.isScreenshare) {
      this.isScreenshare = false;
      this.enableCamera();
      return;
    }
    if (this.localTracks[1]) {
      this.localTracks[1].dispose();
      this.localTracks.pop();
    }
    this.JitsiMeetJS.createLocalTracks({
      devices: ['desktop'],
      cameraDeviceId: this.selectedCamera,
      micDeviceId: this.selectedMicrophone,
    })
      .then((tracks: any[]) => {
        this.cameraOff = true;
        this.isScreenshare = true;
        this.localTracks.push(tracks[0]);
        this.attachLocalEvents(tracks[0]);
        if (this.conference) {
          this.conference.addTrack(this.localTracks[1]);
        }
      })
      .catch((error: any) => console.log(error));
  }

  // public sendExpoMessage(message: string) {
  //   this.expoConference.sendTextMessage(message);
  // }

  // public sendCommand(message: string) {
  //   const command: JitsiCommand = {
  //     value: message,
  //     attributes: {
  //     },
  //     children: []
  //   }
  //   this.expoConference.sendCommand('myCommand', command);
  // }

  public configureSources($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    const modal = this.ngbModal.open(JitsiConfigurationDialogComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static'
    });
    modal.closed.subscribe((result: { microphone: string, camera: string, speaker: string, cameraOff: boolean, microphoneOff: boolean }) => {
      // this.jitsiEnabled = (result.microphone !== null && result.microphone !== '') || (result.camera !== null && result.camera !== '');
      if (result) {
        this.updateDevices(result.camera, result.microphone, result.speaker, result.cameraOff, result.microphoneOff);
      }
      this.configurationChecked = true;
    });
    modal.dismissed.subscribe(() => {
      if (!this.jitsiEnabled) {
        this.mediaChecked = false;
      }
      this.configurationChecked = true;
    });
  }

  public updateDevices(selectedCamera: string, selectedMicrophone: string, selectedSpeaker: string, cameraOff: boolean, microphoneOff: boolean) {
    this.selectedCamera = selectedCamera;
    this.selectedMicrophone = selectedMicrophone;
    this.selectedSpeaker = selectedSpeaker;

    this.cameraOff = cameraOff;
    this.microphoneOff = microphoneOff;

    if (!this.jitsiEnabled) {
      this.jitsiEnabled = this.mediaChecked;
      this.jitsiEnabledChangedEvent.emit(this.jitsiEnabled);
      if (!this.jitsiEnabled) {
        return;
      }
    }

    this.JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectedSpeaker);

    for (let i = this.localTracks.length - 1; i >= 0; i--) {
      this.localTracks[i].dispose();
      this.localTracks.pop();
    }

    let devices: Array<string> = [];
    if (!this.microphoneOff) {
      devices.push('audio');
    }
    if (!this.cameraOff) {
      devices.push('video');
    }

    this.localTracks.length = 0;
    this.JitsiMeetJS.createLocalTracks({
      devices: devices,
      cameraDeviceId: this.selectedCamera,
      micDeviceId: this.selectedMicrophone,
    })
      .then((tracks: any[]) => {
        tracks.forEach(value => {
          this.localTracks.push(value);
        });
        for (let i = 0; i < this.localTracks.length; i++) {
          this.attachLocalEvents(this.localTracks[i]);
          if (this.isJoined) {
            this.conference.addTrack(this.localTracks[i]);
          }
        }
      })
      .catch((error: any) => console.log(error));
  }

  public changeMediaActive(active: boolean) {
    this.mediaChecked = active;
    this.mediaCheckedChangedEvent.emit(this.mediaChecked);
  }

  ensureConfigured() {
    if (!this.configurationChecked) {
      this.configureSources();
    }
  }
}

export interface ChatMessage {
  userID: string;
  displayName: string;
  message: string;
  ts: number;
}

export interface JitsiParticipant {
  _displayName: string;
  _id: string;
}

export interface JitsiCommand {
  value: string,
  attributes: {}, // map with keys the name of the attribute and values - the values of the attributes.
  children: [] // array with JS object with the same structure.
}

export interface JitsiMediaTrack {
  getType(): 'audio' | 'video' | 'desktop';

  mute(): void;

  unmute(): void;

  dispose(): void;

  getDeviceId(): string;

  isLocal(): Boolean;

  getParticipantId(): string;

  addEventListener(event: any, params: any): void;

  isMuted(): boolean;
}
