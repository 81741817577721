/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MStageEditDto } from './mStageEditDto';
import { MExpoEditDto } from './mExpoEditDto';
import { MExhibitorListItem } from './mExhibitorListItem';
import { MStageListItem } from './mStageListItem';


export interface MStageEditResult { 
    expo?: MExpoEditDto;
    exhibitors?: Array<MExhibitorListItem>;
    stages?: Array<MStageListItem>;
    expoCount?: number;
    stage?: MStageEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of MStageEditResult.
 */
export namespace MStageEditResult {
    /**
     * All properties of MStageEditResult.
     */
    export enum Properties {
        expo = 'expo',
        exhibitors = 'exhibitors',
        stages = 'stages',
        expoCount = 'expoCount',
        stage = 'stage'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MStageEditResult.
    */
    export const ModelValidators: {[K in keyof MStageEditResult]: [string, ValidatorFn][]} = {
        expo: [
        ],
        exhibitors: [
        ],
        stages: [
        ],
        expoCount: [
        ],
        stage: [
        ],
    };

    /**
    * The FormControlFactory for MStageEditResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MStageEditResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MStageEditResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MStageEditResult = {
            expo: null,
            exhibitors: null,
            stages: null,
            expoCount: null,
            stage: null,
          }
        ) {
            super(model, MStageEditResult.ModelValidators);
        }
    }

}


