/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface YoutubeVideo { 
    id?: string;
    title?: string;
    description?: string;
    previewImage?: string;
    playlist?: string;
}

/**
 * Namespace for property- and property-value-enumerations of YoutubeVideo.
 */
export namespace YoutubeVideo {
    /**
     * All properties of YoutubeVideo.
     */
    export enum Properties {
        id = 'id',
        title = 'title',
        description = 'description',
        previewImage = 'previewImage',
        playlist = 'playlist'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of YoutubeVideo.
    */
    export const ModelValidators: {[K in keyof YoutubeVideo]: [string, ValidatorFn][]} = {
        id: [
        ],
        title: [
        ],
        description: [
        ],
        previewImage: [
        ],
        playlist: [
        ],
    };

    /**
    * The FormControlFactory for YoutubeVideo.
    */
    export class FormControlFactory extends BaseFormControlFactory<YoutubeVideo> {

        /**
         * Constructor.
         *
         * @param model An existing model for YoutubeVideo.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: YoutubeVideo = {
            id: null,
            title: null,
            description: null,
            previewImage: null,
            playlist: null,
          }
        ) {
            super(model, YoutubeVideo.ModelValidators);
        }
    }

}


