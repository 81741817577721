/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CommandType } from './commandType';


export interface SignalDto { 
    commandType?: CommandType;
    shortKey?: string;
    sender?: string;
    meta?: string;
}

/**
 * Namespace for property- and property-value-enumerations of SignalDto.
 */
export namespace SignalDto {
    /**
     * All properties of SignalDto.
     */
    export enum Properties {
        commandType = 'commandType',
        shortKey = 'shortKey',
        sender = 'sender',
        meta = 'meta'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of SignalDto.
    */
    export const ModelValidators: {[K in keyof SignalDto]: [string, ValidatorFn][]} = {
        commandType: [
        ],
        shortKey: [
        ],
        sender: [
        ],
        meta: [
        ],
    };

    /**
    * The FormControlFactory for SignalDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<SignalDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for SignalDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: SignalDto = {
            commandType: null,
            shortKey: null,
            sender: null,
            meta: null,
          }
        ) {
            super(model, SignalDto.ModelValidators);
        }
    }

}


