/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MExpoContactEditDto { 
    id?: string;
    responsibility?: string;
    showOnWebsite?: boolean;
    sequence?: number;
    displayName?: string;
    email?: string;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoContactEditDto.
 */
export namespace MExpoContactEditDto {
    /**
     * All properties of MExpoContactEditDto.
     */
    export enum Properties {
        id = 'id',
        responsibility = 'responsibility',
        showOnWebsite = 'showOnWebsite',
        sequence = 'sequence',
        displayName = 'displayName',
        email = 'email',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoContactEditDto.
    */
    export const ModelValidators: {[K in keyof MExpoContactEditDto]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        responsibility: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        showOnWebsite: [
        ],
        sequence: [
        ],
        displayName: [
        ],
        email: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for MExpoContactEditDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoContactEditDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoContactEditDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoContactEditDto = {
            id: null,
            responsibility: null,
            showOnWebsite: null,
            sequence: null,
            displayName: null,
            email: null,
            rowVersion: null,
          }
        ) {
            super(model, MExpoContactEditDto.ModelValidators);
        }
    }

}


