/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { PresentationScreenMode } from './presentationScreenMode';
import { ExhibitorContactMode } from './exhibitorContactMode';
import { ContactListItemDto } from './contactListItemDto';
import { MLink } from './mLink';
import { LecternVisibility } from './lecternVisibility';
import { BoothConfigurationDto } from './boothConfigurationDto';
import { MExhibitorContactListDto } from './mExhibitorContactListDto';
import { MDisplay } from './mDisplay';
import { MExhibitorGroupReferenceDto } from './mExhibitorGroupReferenceDto';
import { ExhibitorInfoVisibility } from './exhibitorInfoVisibility';
import { MStageReferenceDto } from './mStageReferenceDto';
import { MInfoSlide } from './mInfoSlide';
import { SchedulingService } from './schedulingService';


export interface MExhibitorDtoSequence { 
    shortKey?: string;
    name?: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    descriptionLong?: string;
    socialProfiles?: { [key: string]: string; };
    label?: string;
    shortLabel?: string;
    showContact?: boolean;
    website?: string;
    logoSquare?: string;
    logoWide?: string;
    avatar?: string;
    schedulingService?: SchedulingService;
    schedulingKey?: string;
    schedulingActive?: boolean;
    contacts?: Array<MExhibitorContactListDto>;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatWelcomeMessage?: string;
    hideAvatar?: boolean;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
    singleChatWelcomeMessage?: string;
    contactMail?: string;
    contactMode?: ExhibitorContactMode;
    tags?: Array<string>;
    boothType?: string;
    boothIcon?: string;
    style?: string;
    rotation?: number;
    panels?: string;
    displays?: Array<MDisplay>;
    links?: Array<MLink>;
    infoSlides?: Array<MInfoSlide>;
    categoryId?: number;
    boothConfiguration?: BoothConfigurationDto;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    chatEnabled?: boolean;
    exhibitorGroupId?: string;
    exhibitorInfoVisibility?: ExhibitorInfoVisibility;
    hideFurniture?: boolean;
    hideWalls?: boolean;
    hideLogo?: boolean;
    lecternVisibility?: LecternVisibility;
    presentationScreenMode?: PresentationScreenMode;
    sequence?: number;
    exhibitorGroup?: MExhibitorGroupReferenceDto;
    stage?: MStageReferenceDto;
    boothImage?: string;
    boothImageTrans?: string;
    contact?: ContactListItemDto;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorDtoSequence.
 */
export namespace MExhibitorDtoSequence {
    /**
     * All properties of MExhibitorDtoSequence.
     */
    export enum Properties {
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        descriptionLong = 'descriptionLong',
        socialProfiles = 'socialProfiles',
        label = 'label',
        shortLabel = 'shortLabel',
        showContact = 'showContact',
        website = 'website',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        avatar = 'avatar',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        contacts = 'contacts',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatWelcomeMessage = 'chatWelcomeMessage',
        hideAvatar = 'hideAvatar',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled',
        singleChatWelcomeMessage = 'singleChatWelcomeMessage',
        contactMail = 'contactMail',
        contactMode = 'contactMode',
        tags = 'tags',
        boothType = 'boothType',
        boothIcon = 'boothIcon',
        style = 'style',
        rotation = 'rotation',
        panels = 'panels',
        displays = 'displays',
        links = 'links',
        infoSlides = 'infoSlides',
        categoryId = 'categoryId',
        boothConfiguration = 'boothConfiguration',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        chatEnabled = 'chatEnabled',
        exhibitorGroupId = 'exhibitorGroupId',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        hideFurniture = 'hideFurniture',
        hideWalls = 'hideWalls',
        hideLogo = 'hideLogo',
        lecternVisibility = 'lecternVisibility',
        presentationScreenMode = 'presentationScreenMode',
        sequence = 'sequence',
        exhibitorGroup = 'exhibitorGroup',
        stage = 'stage',
        boothImage = 'boothImage',
        boothImageTrans = 'boothImageTrans',
        contact = 'contact'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorDtoSequence.
    */
    export const ModelValidators: {[K in keyof MExhibitorDtoSequence]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        name: [
        ],
        company: [
        ],
        phoneNumber: [
        ],
        address: [
        ],
        email: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        socialProfiles: [
        ],
        label: [
        ],
        shortLabel: [
        ],
        showContact: [
        ],
        website: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        avatar: [
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        contacts: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
        ],
        externalMatomoServer: [
        ],
        externalMatomoId: [
        ],
        chatWelcomeMessage: [
        ],
        hideAvatar: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
        singleChatWelcomeMessage: [
        ],
        contactMail: [
        ],
        contactMode: [
        ],
        tags: [
        ],
        boothType: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        boothIcon: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        style: [
        ],
        rotation: [
        ],
        panels: [
        ],
        displays: [
        ],
        links: [
        ],
        infoSlides: [
        ],
        categoryId: [
        ],
        boothConfiguration: [
        ],
        backgroundImage: [
        ],
        posterA: [
        ],
        posterB: [
        ],
        chatEnabled: [
        ],
        exhibitorGroupId: [
        ],
        exhibitorInfoVisibility: [
        ],
        hideFurniture: [
        ],
        hideWalls: [
        ],
        hideLogo: [
        ],
        lecternVisibility: [
        ],
        presentationScreenMode: [
        ],
        sequence: [
        ],
        exhibitorGroup: [
        ],
        stage: [
        ],
        boothImage: [
        ],
        boothImageTrans: [
        ],
        contact: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorDtoSequence.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorDtoSequence> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorDtoSequence.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorDtoSequence = {
            shortKey: null,
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            descriptionLong: null,
            socialProfiles: null,
            label: null,
            shortLabel: null,
            showContact: null,
            website: null,
            logoSquare: null,
            logoWide: null,
            avatar: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            contacts: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatWelcomeMessage: null,
            hideAvatar: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
            singleChatWelcomeMessage: null,
            contactMail: null,
            contactMode: null,
            tags: null,
            boothType: null,
            boothIcon: null,
            style: null,
            rotation: null,
            panels: null,
            displays: null,
            links: null,
            infoSlides: null,
            categoryId: null,
            boothConfiguration: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            chatEnabled: null,
            exhibitorGroupId: null,
            exhibitorInfoVisibility: null,
            hideFurniture: null,
            hideWalls: null,
            hideLogo: null,
            lecternVisibility: null,
            presentationScreenMode: null,
            sequence: null,
            exhibitorGroup: null,
            stage: null,
            boothImage: null,
            boothImageTrans: null,
            contact: null,
          }
        ) {
            super(model, MExhibitorDtoSequence.ModelValidators);
        }
    }

}


