/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ContactListItemDto { 
    name?: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    label?: string;
    website?: string;
    socialProfiles?: { [key: string]: string; };
    image?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ContactListItemDto.
 */
export namespace ContactListItemDto {
    /**
     * All properties of ContactListItemDto.
     */
    export enum Properties {
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        label = 'label',
        website = 'website',
        socialProfiles = 'socialProfiles',
        image = 'image'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ContactListItemDto.
    */
    export const ModelValidators: {[K in keyof ContactListItemDto]: [string, ValidatorFn][]} = {
        name: [
        ],
        company: [
        ],
        phoneNumber: [
        ],
        address: [
        ],
        email: [
        ],
        description: [
        ],
        label: [
        ],
        website: [
        ],
        socialProfiles: [
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
    };

    /**
    * The FormControlFactory for ContactListItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ContactListItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ContactListItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ContactListItemDto = {
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            label: null,
            website: null,
            socialProfiles: null,
            image: null,
          }
        ) {
            super(model, ContactListItemDto.ModelValidators);
        }
    }

}


