export const environment = {
  production: true,
  apiurl: 'api/',
  authurl: 'api/',
  chatHub: 'api/chat',
  adminChatHub: 'api/adminchat',
  videoHub: 'https://conference.meet.dotenterprise.com',
  videoHubDomain: 'conference.meet.dotenterprise.com',
  videoAuthDomain: 'https://ezfair.io',
  API_BASE_PATH: '.',
  versionCheckURL : '/version.json',
  cdnURL: 'https://cdn.ezfair.io/',
  imageUploadSize: 5242880,
  fileUploadSize: 33554432,
  watchdogInterval: 120000,
  sentryEnabled: true,
  sentryDsn: 'https://792f6dc368c2405bbd9a1af8b1e06529@debug.nethotline.io/2',
  agoraId: '7619ac48cb274b109606888a71d9a8a0',
  enableOneOnOne: true,
  enableSessions: false,
  timeStamp: '2022-09-24 11:02:40 UTC',
  webexIntegrationPartnerId: 'df022c4e-d85e-4594-a161-03247f5465fd',
  defaultLocale: 'de',
  signalsServer: 'https://chat.ezfair.io/signals',
  defaultClientDomain: 'demo.ezfair.io',
  jitsiServiceUrl: '//conference.meet.dotenterprise.com/http-bind',
  baseUrlHelper: 'https://ezfair.io/docs/'
};
