import {MenuItem} from '../objects/menu-item';
import {environment} from '../../environments/environment';

const Home: MenuItem = {
  text: 'Home',
  link: '/home',
  icon: 'fa-home'
};

const Web: MenuItem = {
  text: 'Website',
  elink: 'https://ezfair.io',
  icon: 'fa-city',
  target: '_blank'
};

const WebChat: MenuItem = {
  text: 'Exhibitor chat & video',
  link: '/webchat',
  role: ['IsChatUser'],
  icon: 'fa-comments'
};

// const Director: MenuItem = {
//   text: 'Director',
//   link: '/director',
//   role: ['Organizer', 'IsManager', 'Exhibitor'],
//   icon: 'fa-file-video'
// };

// const Calendar: MenuItem = {
//   text: 'Calendar',
//   link: '/calendar',
//   role: ['Organizer', 'IsManager', 'Exhibitor', 'IsChatUser'],
//   icon: 'fa-calendar'
// };

// const PresentationControl: MenuItem = {
//   text: 'Presentation control',
//   link: '/presentation/stage',
//   role: ['Organizer', 'IsManager'],
//   icon: 'fa-chalkboard'
// };

const User: MenuItem = {
  text: 'User',
  link: '/user',
  icon: 'fa-user-cog',
  submenu: [
    {
      text: 'Profile',
      link: '/user/profile'
    },
    {
      text: 'Password',
      link: 'setPassword'
    }
  ]
};

const ExpoManage: MenuItem = {
  text: 'Manage Platform',
  link: '/admin',
  icon: 'fa-cog',
  role: ['Manager']
  // ,
  // submenu: [
  //   {
  //     text: 'Set user password',
  //     link: 'setUserPassword'
  //   },
  //   {
  //     text: 'Get user token',
  //     link: 'getUserToken'
  //   },
  //   {
  //     text: 'Manage Expo',
  //     link: '/admin/expo'
  //   },
  //   // {
  //   //   text: 'Manage Scenes',
  //   //   link: '/admin/scene'
  //   // },
  //   {
  //     text: 'Manage Content',
  //     link: '/admin/content'
  //   }
  // ]
};

const EventManage: MenuItem = {
  text: 'Organizer',
  link: '/organizer',
  icon: 'fa-hotel',
  role: ['Organizer']
};

const PartnerManage: MenuItem = {
  text: 'Exhibitor',
  link: '/exhibitor',
  icon: 'fa-store',
  role: ['Exhibitor', 'Organizer']
};

const Info: MenuItem = {
  text: 'Changelog',
  link: '/info/changelog',
  icon: 'fa-clipboard-list'
};

const headingMain: MenuItem = {
  text: 'Virtual Expo Management',
  heading: true
};

export const menu: MenuItem[] = [
  // headingMain,
  // Home,
  // Web,
  // {text: 'Private', heading: true},
  // User,
  // {
  //   text: 'Interaction',
  //   heading: true,
  //   role: ['Manager', 'Organizer', 'Exhibitor', 'IsChatUser'],
  // },
  // WebChat,
  // Interaction,
  // PresentationControl,
  // Director,
  // Calendar,
  // {
  //   text: 'Manage',
  //   heading: true,
  //   role: ['Manager', 'Organizer', 'Exhibitor'],
  // },
  ExpoManage,
  EventManage,
  PartnerManage,
  // {text: 'Info', heading: true}
  // , Info
];
