import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../modules/security/services/auth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  error = false;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    // const email = this.route.snapshot.paramMap.get('email');
    // const token = this.route.snapshot.paramMap.get('token');
    // // debugger;
    // this.authService.validatePwdLess(email, token, true, null)
    //   .then((value) => {
    //     if (this.authService.data.name !== this.authService.data.userId) {
    //       this.router.navigate(['profile']);
    //     } else {
    //       this.router.navigate(['/']);
    //     }
    //   }, error => {
    //     this.error = true;
    //     // console.log(error);
    //   });
  }

}
