import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component implements OnInit {
  currentYear: number;

  constructor() {
    this.currentYear = (new Date()).getFullYear();
  }

  ngOnInit() {
  }

}
