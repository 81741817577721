/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MExhibitorListItem } from './mExhibitorListItem';
import { ExpoListDto } from './expoListDto';


export interface MExhibitorListResult { 
    expo?: ExpoListDto;
    exhibitors?: Array<MExhibitorListItem>;
    expos?: Array<ExpoListDto>;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorListResult.
 */
export namespace MExhibitorListResult {
    /**
     * All properties of MExhibitorListResult.
     */
    export enum Properties {
        expo = 'expo',
        exhibitors = 'exhibitors',
        expos = 'expos'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorListResult.
    */
    export const ModelValidators: {[K in keyof MExhibitorListResult]: [string, ValidatorFn][]} = {
        expo: [
        ],
        exhibitors: [
        ],
        expos: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorListResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorListResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorListResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorListResult = {
            expo: null,
            exhibitors: null,
            expos: null,
          }
        ) {
            super(model, MExhibitorListResult.ModelValidators);
        }
    }

}


