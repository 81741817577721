/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { Material } from './material';
import { Furniture } from './furniture';


export interface RenderExhibitor { 
    exhibitorID?: string;
    exhibitorLabel?: string;
    boothName?: string;
    boothMaterials?: { [key: string]: Material; };
    boothImages?: { [key: string]: string; };
    furniture?: { [key: string]: Furniture; };
}

/**
 * Namespace for property- and property-value-enumerations of RenderExhibitor.
 */
export namespace RenderExhibitor {
    /**
     * All properties of RenderExhibitor.
     */
    export enum Properties {
        exhibitorID = 'exhibitorID',
        exhibitorLabel = 'exhibitorLabel',
        boothName = 'boothName',
        boothMaterials = 'boothMaterials',
        boothImages = 'boothImages',
        furniture = 'furniture'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of RenderExhibitor.
    */
    export const ModelValidators: {[K in keyof RenderExhibitor]: [string, ValidatorFn][]} = {
        exhibitorID: [
        ],
        exhibitorLabel: [
        ],
        boothName: [
        ],
        boothMaterials: [
        ],
        boothImages: [
        ],
        furniture: [
        ],
    };

    /**
    * The FormControlFactory for RenderExhibitor.
    */
    export class FormControlFactory extends BaseFormControlFactory<RenderExhibitor> {

        /**
         * Constructor.
         *
         * @param model An existing model for RenderExhibitor.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: RenderExhibitor = {
            exhibitorID: null,
            exhibitorLabel: null,
            boothName: null,
            boothMaterials: null,
            boothImages: null,
            furniture: null,
          }
        ) {
            super(model, RenderExhibitor.ModelValidators);
        }
    }

}


