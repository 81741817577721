/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { Coordinates } from './coordinates';


export interface TouchArea { 
    type?: string;
    tl?: Coordinates;
    tr?: Coordinates;
    bl?: Coordinates;
    br?: Coordinates;
}

/**
 * Namespace for property- and property-value-enumerations of TouchArea.
 */
export namespace TouchArea {
    /**
     * All properties of TouchArea.
     */
    export enum Properties {
        type = 'type',
        tl = 'tl',
        tr = 'tr',
        bl = 'bl',
        br = 'br'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TouchArea.
    */
    export const ModelValidators: {[K in keyof TouchArea]: [string, ValidatorFn][]} = {
        type: [
        ],
        tl: [
        ],
        tr: [
        ],
        bl: [
        ],
        br: [
        ],
    };

    /**
    * The FormControlFactory for TouchArea.
    */
    export class FormControlFactory extends BaseFormControlFactory<TouchArea> {

        /**
         * Constructor.
         *
         * @param model An existing model for TouchArea.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TouchArea = {
            type: null,
            tl: null,
            tr: null,
            bl: null,
            br: null,
          }
        ) {
            super(model, TouchArea.ModelValidators);
        }
    }

}


