<div class="activateMediaInfo d-flex flex-column flex-md-row p-5" *ngIf="!jitsiService.mediaChecked">
  <div i18n>
    Um die Nutzung von Sprach- und Video-Chats zu aktivieren, müssen Sie in ihrem Browser der Benutzung
    zustimmen.<br/>
    Auch wenn Sie zustimmen können Sie ihre Kamera und ihr Mikrofon stumm schalten so das Sie weder zu sehen noch
    zu hören sind. Dies erleichtert Ihnen aber die Nutzung da sie Kamera und Video jederzeit wieder aktivieren
    können.<br/>
    Blockieren Sie die Sicherheitsabfrage in Ihrem Browser, wenn Sie sich nicht sicher sind, dass Sie die Kamera und
    das Mikrofon nutzen wollen. Wenn Sie Ihre Meinung ändern möchten, können Sie diese Einstellungen später wieder in
    Ihrem Browser ändern.<br/>
    Wenn Sie zum jetzigen Zeitpunkt diese Einstellung nicht ändern möchten können Sie diese Einstellungen später
    jederzeit in ihrem Browser ändern.
  </div>
  <button class="btn btn-lg btn-success"
          (click)="jitsiService.changeMediaActive(true)" i18n
  >Video und/oder Audio aktivieren
  </button>
</div>
<div class="deviceSelector" *ngIf="jitsiService.mediaChecked && !devicesLoaded">
  <h3 i18n>Geräte werden gesucht</h3>
</div>
<div class="deviceSelector" *ngIf="jitsiService.mediaChecked && devicesLoaded">
  <div class="previewStream">
    <app-jitsi-track [participantId]="'local'" [trackId]="'preview'" [tracks]="localTracks"
                     *ngIf="!cameraOff"
                     [hideIcons]="true"></app-jitsi-track>
    <div class="camera-disabled" *ngIf="cameraOff"></div>
  </div>
  <form class="devices">
    <div class="d-flex flex-row justify-content-between">
      <h2 i18n>Geräte {{selectedMicrophone}}</h2>
    </div>
    <div class="form-group">
      <label for="device-camera" i18n>Camera:</label>
      <div class="d-flex flex-row">
        <div class="input-group" *ngIf="!cameraOff">
          <select [(ngModel)]="selectedCamera" [ngModelOptions]="{standalone: true}" class="form-control"
                  (change)="selectCamera($event)" id="device-camera">
            <ng-container *ngFor="let device of devices; let i = index;">
              <option [value]="device.deviceId"
                      *ngIf="device.kind === 'videoinput'">{{ device.label }}</option>
            </ng-container>
          </select>
          <div class="input-group-append" (click)="changeCamera($event, true)">
            <span class="input-group-text" i18n>Kamera aus</span>
          </div>
        </div>
        <button class="btn btn-danger btn-block" (click)="changeCamera($event, false)" *ngIf="cameraOff" i18n>Kamera einschalten</button>
      </div>
    </div>
    <div class="form-group">
      <label for="device-microphone" i18n>Microphone:</label>
      <div class="d-flex flex-row">
        <div class="input-group" *ngIf="!microphoneOff">
          <select [(ngModel)]="selectedMicrophone" [ngModelOptions]="{standalone: true}" class="form-control"
                  (change)="selectMicrophone($event)" id="device-microphone">
            <ng-container *ngFor="let device of devices; let i = index;">
              <option [value]="device.deviceId"
                      *ngIf="device.kind === 'audioinput'">{{ device.label }}</option>
            </ng-container>
          </select>
          <div class="input-group-append" (click)="changeMic($event, true)">
            <span class="input-group-text" i18n>Mikrofon aus</span>
          </div>
        </div>
        <button class="btn btn-danger btn-block" (click)="changeMic($event, false)" *ngIf="microphoneOff" i18n>
          Mikrofon einschalten
        </button>
      </div>
    </div>
    <div class="form-group">
      <label for="device-speaker" i18n>Speaker:</label>
      <select [(ngModel)]="selectedSpeaker" [ngModelOptions]="{standalone: true}" class="form-control"
              (change)="selectSpeaker($event)" id="device-speaker">
        <ng-container *ngFor="let device of devices">
          <option [value]="device.deviceId" [selected]="device.deviceId === selectedCamera"
                  *ngIf="device.kind === 'audiooutput'">{{ device.label }}</option>
        </ng-container>
      </select>
    </div>
  </form>
</div>
