import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {JitsiMediaTrack, JitsiService} from '../jitsi.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-jitsi-configure',
  templateUrl: './jitsi-configure.component.html',
  styleUrls: ['./jitsi-configure.component.scss']
})
export class JitsiConfigureComponent implements OnInit {
  selectedMicrophone: string = '';
  selectedCamera: string = '';
  selectedSpeaker: string = '';

  private JitsiMeetJS: any;
  public localTracks: Array<JitsiMediaTrack> = new Array<JitsiMediaTrack>();
  devices: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  devicesLoaded: boolean = false;
  cameraOff: boolean = false;
  microphoneOff: boolean = false;
  public formReady: boolean = false;


  constructor(
    public jitsiService: JitsiService,
    public ngbActiveModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    if (this.jitsiService.mediaChecked) {
      this.initializeJitsi()
    } else {
      this.jitsiService.mediaCheckedChangedEvent.subscribe(
        (mediaActive: boolean) => {
          if (mediaActive) {
            this.initializeJitsi();
          }
        }
      );
    }
  }

  initializeJitsi() {
    this.JitsiMeetJS = (window as any).JitsiMeetJS;
    const initOptions = {
      disableAudioLevels: true,
      disableThirdPartyRequests: true,
    };
    this.JitsiMeetJS.init(initOptions);
    this.JitsiMeetJS.setLogLevel(this.JitsiMeetJS.logLevels.WARN);

    this.reCreateLocalTracks();
  }

  updateSelectedDevices() {
    this.JitsiMeetJS.mediaDevices.enumerateDevices((devices: MediaDeviceInfo[]) => {
      this.devices.length = 0;
      devices.forEach(d => this.devices.push(d));
      this.devicesLoaded = true;
    });
    this.selectedSpeaker = this.JitsiMeetJS.mediaDevices.getAudioOutputDevice();
  }

  reCreateLocalTracks() {
    if (this.localTracks && this.localTracks.length > 0) {
      this.localTracks.forEach(track => {
        track.dispose();
      });
    }
    this.localTracks.length = 0;
    this.JitsiMeetJS.createLocalTracks(
      {
        devices: ['audio', 'video'],
        cameraDeviceId: this.selectedCamera,
        micDeviceId: this.selectedMicrophone,
      })
      .then((tracks: JitsiMediaTrack[]) => {
        this.onLocalTracks(tracks);
      })
      .catch((error: any) => {
        throw error;
      });
  }

  selectMicrophone($event: Event) {
    this.reCreateLocalTracks();
  }

  selectCamera($event: Event) {
    this.reCreateLocalTracks();
  }

  selectSpeaker($event: Event) {
    this.reCreateLocalTracks();
  }

  private onLocalTracks(tracks: JitsiMediaTrack[]) {
    this.localTracks.length = 0;
    tracks.forEach(track => {
      this.localTracks.push(track);
      if (track.getType() === 'audio') {
        this.selectedMicrophone = track.getDeviceId();
      } else if (track.getType() === 'video') {
        this.selectedCamera = track.getDeviceId();
      }
      this.updateSelectedDevices();

      this.formReady = true;
    });
  }

  public useDevices() {
    this.jitsiService.updateDevices(this.selectedCamera, this.selectedMicrophone, this.selectedSpeaker, this.cameraOff, this.microphoneOff);
  }

  changeCamera($event: MouseEvent, state: boolean) {
    $event.preventDefault();
    this.cameraOff = state;
  }

  changeMic($event: MouseEvent, state: boolean) {
    $event.preventDefault();
    this.microphoneOff = state;
  }
}
