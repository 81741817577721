/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type FeaturedMode = 0 | 1;

export const FeaturedMode = {
    NUMBER_0: 0 as FeaturedMode,
    NUMBER_1: 1 as FeaturedMode
};

