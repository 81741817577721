/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MExhibitorContactEditDto } from './mExhibitorContactEditDto';


export interface MExhibitorContactUpdateResult { 
    rowVersion?: number;
    contact?: MExhibitorContactEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorContactUpdateResult.
 */
export namespace MExhibitorContactUpdateResult {
    /**
     * All properties of MExhibitorContactUpdateResult.
     */
    export enum Properties {
        rowVersion = 'rowVersion',
        contact = 'contact'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorContactUpdateResult.
    */
    export const ModelValidators: {[K in keyof MExhibitorContactUpdateResult]: [string, ValidatorFn][]} = {
        rowVersion: [
        ],
        contact: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorContactUpdateResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorContactUpdateResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorContactUpdateResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorContactUpdateResult = {
            rowVersion: null,
            contact: null,
          }
        ) {
            super(model, MExhibitorContactUpdateResult.ModelValidators);
        }
    }

}


