import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ColorsService} from './colors/colors.service';
import {UploadFormComponent} from './directives/upload/upload.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UiSwitchModule} from 'ngx-ui-switch';
import {MarkdownModule} from 'ngx-markdown';
import {StorageComponent} from './directives/storage/storage.component';
import {
  ArrayFilterPipe,
  DeletedPipe,
  NameFilterPipe,
  NotDeletedPipe,
  SequenceSortPipe
} from '../core/sequence-sort.pipe';
import {ColorPickerModule} from 'ngx-color-picker';
import {StageSizesComponent} from '../expo/common/stage-sizes/stage-sizes.component';
import {StockOrCustomImageComponent} from '../expo/common/stock-or-custom-image/stock-or-custom-image.component';
import {
  ControlValidationResultsComponent
} from '../expo/common/control-validation-results/control-validation-results.component';
import {HelpComponent} from '../expo/common/help/help.component';
import {MarkdownSampleComponent} from '../expo/common/markdown-sample/markdown-sample.component';
import {MarkdownSampleMessageComponent} from '../expo/common/markdown-sample-message/markdown-sample-message.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {PreventSpacesDirective} from './directives/prevent-spaces.directive';
import {ContentDisplayComponent} from '../expo/common/content/content.component';
import {CalendarStageEditComponent} from '../expo/common/calendar-edit/calendar-edit.component';
import {CalendarSocialComponent} from '../expo/common/calendar-edit-social/calendar-edit-social.component';
import {NgAddToCalendarModule} from '../modules/ngAddToCalendar/ng-add-to-calendar.module';
import {FormStringInputComponent} from './forms/form-string-input/form-string-input.component';
import {FormMarkdownInputComponent} from './forms/form-markdown-input/form-markdown-input.component';
import {FormTextInputComponent} from './forms/form-text-input/form-text-input.component';
import {FormToggleComponent} from './forms/form-toggle/form-toggle.component';
import {FormNumberInputComponent} from './forms/form-number-input/form-number-input.component';
import {FormColorInputComponent} from './forms/form-color-input/form-color-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {Ng2DropdownModule} from 'ng2-material-dropdown';
import {FormChipsInputComponent} from './forms/form-chips-input/form-chips-input.component';
import {IntegrationsModule} from '../modules/integrations/integrations.module';
import {
  ExhibitorPresentationEditComponent
} from '../expo/common/exhibitor-presentation-edit/exhibitor-presentation-edit.component';
import {TagInputModule} from '../modules/ngx-chips';
import {FormMaterialInputComponent} from './forms/form-material-input/form-material-input.component';
import {EasymdeModule} from '../modules/ngx-easymde/src/module';
import {ExpoNavHeaderComponent} from '../expo/common/expo-nav-header/expo-nav-header.component';
import {AreaNavHeaderComponent} from '../expo/common/area-nav-header/area-nav-header.component';
import {FooterComponent} from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    RouterModule,
    UiSwitchModule,
    ColorPickerModule,
    NgAddToCalendarModule,
    NgxPaginationModule,
    NgbModule,
    NgSelectModule,
    TagInputModule,
    Ng2DropdownModule,
    IntegrationsModule,
    EasymdeModule
  ],
  providers: [
    ColorsService
  ],
  declarations: [
    UploadFormComponent,
    StorageComponent,
    SequenceSortPipe,
    NotDeletedPipe,
    DeletedPipe,
    NameFilterPipe,
    ArrayFilterPipe,
    StageSizesComponent,
    StockOrCustomImageComponent,
    ControlValidationResultsComponent,
    HelpComponent,
    MarkdownSampleComponent,
    MarkdownSampleMessageComponent,
    PreventSpacesDirective,
    ContentDisplayComponent,
    CalendarStageEditComponent,
    CalendarSocialComponent,
    ExhibitorPresentationEditComponent,
    FormStringInputComponent,
    FormMarkdownInputComponent,
    FormTextInputComponent,
    FormToggleComponent,
    FormNumberInputComponent,
    FormColorInputComponent,
    FormChipsInputComponent,
    FormMaterialInputComponent,
    ExpoNavHeaderComponent,
    AreaNavHeaderComponent,
    FooterComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    MarkdownModule,
    UploadFormComponent,
    StorageComponent,
    NgbModule,
    UiSwitchModule,
    SequenceSortPipe,
    DeletedPipe,
    NameFilterPipe,
    StageSizesComponent,
    StockOrCustomImageComponent,
    ControlValidationResultsComponent,
    HelpComponent,
    MarkdownSampleComponent,
    MarkdownSampleMessageComponent,
    PreventSpacesDirective,
    ContentDisplayComponent,
    ColorPickerModule,
    CalendarStageEditComponent,
    CalendarSocialComponent,
    FormStringInputComponent,
    FormMarkdownInputComponent,
    FormTextInputComponent,
    FormToggleComponent,
    FormNumberInputComponent,
    FormColorInputComponent,
    TagInputModule,
    Ng2DropdownModule,
    FormChipsInputComponent,
    IntegrationsModule,
    FormMaterialInputComponent,
    NotDeletedPipe,
    ArrayFilterPipe,
    ExpoNavHeaderComponent,
    AreaNavHeaderComponent,
    FooterComponent
  ]
})


export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
