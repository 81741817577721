/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ActiveUpdateDto } from '../model/activeUpdateDto';
import { ExpoListDto } from '../model/expoListDto';
import { MExhibitorCreateDto } from '../model/mExhibitorCreateDto';
import { MExpoContactEditDto } from '../model/mExpoContactEditDto';
import { MExpoContactListUpdateResult } from '../model/mExpoContactListUpdateResult';
import { MExpoContactUpdateResult } from '../model/mExpoContactUpdateResult';
import { MExpoManagerDto } from '../model/mExpoManagerDto';
import { MExpoResult } from '../model/mExpoResult';
import { MExpoUpdateDto } from '../model/mExpoUpdateDto';
import { SortUpdateDto } from '../model/sortUpdateDto';
import { StockMediaDto } from '../model/stockMediaDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mExpoContactDelete.
 */
export namespace MExpoContactDelete {
    /**
     * Parameter map for mExpoContactDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      contactId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExpoContactDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      contactId = 'contactId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoContactDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoContactDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      contactId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoContactSort.
 */
export namespace MExpoContactSort {
    /**
     * Parameter map for mExpoContactSort.
     */
    export interface PartialParamMap {
      expoId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExpoContactSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoContactSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoContactSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoContactUpdate.
 */
export namespace MExpoContactUpdate {
    /**
     * Parameter map for mExpoContactUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      MExpoContactEditDto: MExpoContactEditDto;
    }

    /**
     * Enumeration of all parameters for mExpoContactUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      MExpoContactEditDto = 'MExpoContactEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoContactUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoContactUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorActive.
 */
export namespace MExpoExhibitorActive {
    /**
     * Parameter map for mExpoExhibitorActive.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorActive.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorActive
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorActive.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorDelete.
 */
export namespace MExpoExhibitorDelete {
    /**
     * Parameter map for mExpoExhibitorDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorGet.
 */
export namespace MExpoExhibitorGet {
    /**
     * Parameter map for mExpoExhibitorGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorSort.
 */
export namespace MExpoExhibitorSort {
    /**
     * Parameter map for mExpoExhibitorSort.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorUndelete.
 */
export namespace MExpoExhibitorUndelete {
    /**
     * Parameter map for mExpoExhibitorUndelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorUndelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorUndelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorUndelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExhibitorUpdate.
 */
export namespace MExpoExhibitorUpdate {
    /**
     * Parameter map for mExpoExhibitorUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      MExhibitorCreateDto: MExhibitorCreateDto;
    }

    /**
     * Enumeration of all parameters for mExpoExhibitorUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      MExhibitorCreateDto = 'MExhibitorCreateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExhibitorUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExhibitorUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExpoGet.
 */
export namespace MExpoExpoGet {
    /**
     * Parameter map for mExpoExpoGet.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mExpoExpoGet.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExpoGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExpoGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoExpoUpdate.
 */
export namespace MExpoExpoUpdate {
    /**
     * Parameter map for mExpoExpoUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      MExpoUpdateDto: MExpoUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExpoExpoUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      MExpoUpdateDto = 'MExpoUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoExpoUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoExpoUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoGetBackgrounds.
 */
export namespace MExpoGetBackgrounds {
    /**
     * Parameter map for mExpoGetBackgrounds.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mExpoGetBackgrounds.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoGetBackgrounds
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoGetBackgrounds.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExpoListExpo.
 */
export namespace MExpoListExpo {
    /**
     * Parameter map for mExpoListExpo.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mExpoListExpo.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoListExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoListExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExpoManageGet.
 */
export namespace MExpoManageGet {
    /**
     * Parameter map for mExpoManageGet.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mExpoManageGet.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoManageGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoManageGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoManageList.
 */
export namespace MExpoManageList {
    /**
     * Parameter map for mExpoManageList.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mExpoManageList.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoManageList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoManageList.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExpoManageUpdate.
 */
export namespace MExpoManageUpdate {
    /**
     * Parameter map for mExpoManageUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      MExpoManagerDto: MExpoManagerDto;
    }

    /**
     * Enumeration of all parameters for mExpoManageUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      MExpoManagerDto = 'MExpoManagerDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoManageUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoManageUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExpoPushUpdate.
 */
export namespace MExpoPushUpdate {
    /**
     * Parameter map for mExpoPushUpdate.
     */
    export interface PartialParamMap {
      expoId?: string;
    }

    /**
     * Enumeration of all parameters for mExpoPushUpdate.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoPushUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoPushUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
      ],
    };
}

/**
 * Namespace for mExpoRebake.
 */
export namespace MExpoRebake {
    /**
     * Parameter map for mExpoRebake.
     */
    export interface PartialParamMap {
      expoId?: string;
    }

    /**
     * Enumeration of all parameters for mExpoRebake.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExpoRebake
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExpoRebake.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MExpoService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoContactDeleteByMap(
    map: MExpoContactDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoContactListUpdateResult>;
  public mExpoContactDeleteByMap(
    map: MExpoContactDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactListUpdateResult>>;
  public mExpoContactDeleteByMap(
    map: MExpoContactDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactListUpdateResult>>;
  public mExpoContactDeleteByMap(
    map: MExpoContactDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoContactDelete(
      map.expoId,
      map.contactId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param contactId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoContactDelete(expoId: string, contactId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoContactListUpdateResult>;
    public mExpoContactDelete(expoId: string, contactId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactListUpdateResult>>;
    public mExpoContactDelete(expoId: string, contactId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactListUpdateResult>>;
    public mExpoContactDelete(expoId: string, contactId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoContactDelete.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling mExpoContactDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExpoContactDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MExpoContactListUpdateResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/contact/${encodeURIComponent(String(contactId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ContactDelete']) {
                this.cancelMap['MExpo_ContactDelete'].next(null);
            }
            this.cancelMap['MExpo_ContactDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ContactDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ContactDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ContactDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ContactDelete']) {
            this.cancelMap['MExpo_ContactDelete'].complete();
            delete this.cancelMap['MExpo_ContactDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ContactDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ContactDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoContactSortByMap(
    map: MExpoContactSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoContactListUpdateResult>;
  public mExpoContactSortByMap(
    map: MExpoContactSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactListUpdateResult>>;
  public mExpoContactSortByMap(
    map: MExpoContactSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactListUpdateResult>>;
  public mExpoContactSortByMap(
    map: MExpoContactSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoContactSort(
      map.expoId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoContactSort(expoId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoContactListUpdateResult>;
    public mExpoContactSort(expoId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactListUpdateResult>>;
    public mExpoContactSort(expoId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactListUpdateResult>>;
    public mExpoContactSort(expoId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoContactSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExpoContactSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoContactListUpdateResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/sort/contact`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ContactSort']) {
                this.cancelMap['MExpo_ContactSort'].next(null);
            }
            this.cancelMap['MExpo_ContactSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ContactSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ContactSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ContactSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ContactSort']) {
            this.cancelMap['MExpo_ContactSort'].complete();
            delete this.cancelMap['MExpo_ContactSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ContactSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ContactSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoContactUpdateByMap(
    map: MExpoContactUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoContactUpdateResult>;
  public mExpoContactUpdateByMap(
    map: MExpoContactUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactUpdateResult>>;
  public mExpoContactUpdateByMap(
    map: MExpoContactUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactUpdateResult>>;
  public mExpoContactUpdateByMap(
    map: MExpoContactUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoContactUpdate(
      map.expoId,
      map.MExpoContactEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param MExpoContactEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoContactUpdate(expoId: string, MExpoContactEditDto: MExpoContactEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoContactUpdateResult>;
    public mExpoContactUpdate(expoId: string, MExpoContactEditDto: MExpoContactEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoContactUpdateResult>>;
    public mExpoContactUpdate(expoId: string, MExpoContactEditDto: MExpoContactEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoContactUpdateResult>>;
    public mExpoContactUpdate(expoId: string, MExpoContactEditDto: MExpoContactEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoContactUpdate.');
        }
        if (MExpoContactEditDto === null || MExpoContactEditDto === undefined) {
            throw new Error('Required parameter MExpoContactEditDto was null or undefined when calling mExpoContactUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.put<MExpoContactUpdateResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/contact`,
            MExpoContactEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ContactUpdate']) {
                this.cancelMap['MExpo_ContactUpdate'].next(null);
            }
            this.cancelMap['MExpo_ContactUpdate'] = 'put'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ContactUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ContactUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ContactUpdate', 'put'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ContactUpdate']) {
            this.cancelMap['MExpo_ContactUpdate'].complete();
            delete this.cancelMap['MExpo_ContactUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ContactUpdate',
              'put'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ContactUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorActiveByMap(
    map: MExpoExhibitorActive.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExhibitorActiveByMap(
    map: MExpoExhibitorActive.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExhibitorActiveByMap(
    map: MExpoExhibitorActive.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExhibitorActiveByMap(
    map: MExpoExhibitorActive.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorActive(
      map.expoId,
      map.exhibitorId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExhibitorActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExhibitorActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExhibitorActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorActive.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExpoExhibitorActive.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mExpoExhibitorActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor/${encodeURIComponent(String(exhibitorId))}/active`,
            ActiveUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorActive']) {
                this.cancelMap['MExpo_ExhibitorActive'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorActive'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorActive']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorActive']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorActive', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorActive']) {
            this.cancelMap['MExpo_ExhibitorActive'].complete();
            delete this.cancelMap['MExpo_ExhibitorActive'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorActive',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorActive')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorDeleteByMap(
    map: MExpoExhibitorDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExhibitorDeleteByMap(
    map: MExpoExhibitorDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExhibitorDeleteByMap(
    map: MExpoExhibitorDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExhibitorDeleteByMap(
    map: MExpoExhibitorDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorDelete(
      map.expoId,
      map.exhibitorId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorDelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExhibitorDelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExhibitorDelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExhibitorDelete(expoId: string, exhibitorId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExpoExhibitorDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExpoExhibitorDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MExpoResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorDelete']) {
                this.cancelMap['MExpo_ExhibitorDelete'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorDelete']) {
            this.cancelMap['MExpo_ExhibitorDelete'].complete();
            delete this.cancelMap['MExpo_ExhibitorDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorGetByMap(
    map: MExpoExhibitorGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorCreateDto>;
  public mExpoExhibitorGetByMap(
    map: MExpoExhibitorGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorCreateDto>>;
  public mExpoExhibitorGetByMap(
    map: MExpoExhibitorGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorCreateDto>>;
  public mExpoExhibitorGetByMap(
    map: MExpoExhibitorGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorGet(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorGet(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorCreateDto>;
    public mExpoExhibitorGet(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorCreateDto>>;
    public mExpoExhibitorGet(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorCreateDto>>;
    public mExpoExhibitorGet(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExpoExhibitorGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExhibitorCreateDto>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorGet']) {
                this.cancelMap['MExpo_ExhibitorGet'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorGet']) {
            this.cancelMap['MExpo_ExhibitorGet'].complete();
            delete this.cancelMap['MExpo_ExhibitorGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorSortByMap(
    map: MExpoExhibitorSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExhibitorSortByMap(
    map: MExpoExhibitorSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExhibitorSortByMap(
    map: MExpoExhibitorSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExhibitorSortByMap(
    map: MExpoExhibitorSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorSort(
      map.expoId,
      map.exhibitorId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExhibitorSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExhibitorSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExhibitorSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorSort.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExpoExhibitorSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExpoExhibitorSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor/${encodeURIComponent(String(exhibitorId))}/sort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorSort']) {
                this.cancelMap['MExpo_ExhibitorSort'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorSort']) {
            this.cancelMap['MExpo_ExhibitorSort'].complete();
            delete this.cancelMap['MExpo_ExhibitorSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorUndeleteByMap(
    map: MExpoExhibitorUndelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExhibitorUndeleteByMap(
    map: MExpoExhibitorUndelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExhibitorUndeleteByMap(
    map: MExpoExhibitorUndelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExhibitorUndeleteByMap(
    map: MExpoExhibitorUndelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorUndelete(
      map.expoId,
      map.exhibitorId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorUndelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExhibitorUndelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExhibitorUndelete(expoId: string, exhibitorId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExhibitorUndelete(expoId: string, exhibitorId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorUndelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExpoExhibitorUndelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExpoExhibitorUndelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(rowVersion))}/recover`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorUndelete']) {
                this.cancelMap['MExpo_ExhibitorUndelete'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorUndelete'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorUndelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorUndelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorUndelete', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorUndelete']) {
            this.cancelMap['MExpo_ExhibitorUndelete'].complete();
            delete this.cancelMap['MExpo_ExhibitorUndelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorUndelete',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorUndelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExhibitorUpdateByMap(
    map: MExpoExhibitorUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExhibitorUpdateByMap(
    map: MExpoExhibitorUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExhibitorUpdateByMap(
    map: MExpoExhibitorUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExhibitorUpdateByMap(
    map: MExpoExhibitorUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExhibitorUpdate(
      map.expoId,
      map.MExhibitorCreateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param MExhibitorCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExhibitorUpdate(expoId: string, MExhibitorCreateDto: MExhibitorCreateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExhibitorUpdate(expoId: string, MExhibitorCreateDto: MExhibitorCreateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExhibitorUpdate(expoId: string, MExhibitorCreateDto: MExhibitorCreateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExhibitorUpdate(expoId: string, MExhibitorCreateDto: MExhibitorCreateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExhibitorUpdate.');
        }
        if (MExhibitorCreateDto === null || MExhibitorCreateDto === undefined) {
            throw new Error('Required parameter MExhibitorCreateDto was null or undefined when calling mExpoExhibitorUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoResult>(`${this.configuration.basePath}/api/MExpo/${encodeURIComponent(String(expoId))}/exhibitor`,
            MExhibitorCreateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExhibitorUpdate']) {
                this.cancelMap['MExpo_ExhibitorUpdate'].next(null);
            }
            this.cancelMap['MExpo_ExhibitorUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExhibitorUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExhibitorUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExhibitorUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExhibitorUpdate']) {
            this.cancelMap['MExpo_ExhibitorUpdate'].complete();
            delete this.cancelMap['MExpo_ExhibitorUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExhibitorUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExhibitorUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExpoGetByMap(
    map: MExpoExpoGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResult>;
  public mExpoExpoGetByMap(
    map: MExpoExpoGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
  public mExpoExpoGetByMap(
    map: MExpoExpoGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
  public mExpoExpoGetByMap(
    map: MExpoExpoGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExpoGet(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExpoGet(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResult>;
    public mExpoExpoGet(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResult>>;
    public mExpoExpoGet(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResult>>;
    public mExpoExpoGet(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExpoGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoResult>(`${this.configuration.basePath}/api/MExpo/expo/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExpoGet']) {
                this.cancelMap['MExpo_ExpoGet'].next(null);
            }
            this.cancelMap['MExpo_ExpoGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExpoGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExpoGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExpoGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExpoGet']) {
            this.cancelMap['MExpo_ExpoGet'].complete();
            delete this.cancelMap['MExpo_ExpoGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExpoGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExpoGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoExpoUpdateByMap(
    map: MExpoExpoUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoUpdateDto>;
  public mExpoExpoUpdateByMap(
    map: MExpoExpoUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoUpdateDto>>;
  public mExpoExpoUpdateByMap(
    map: MExpoExpoUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoUpdateDto>>;
  public mExpoExpoUpdateByMap(
    map: MExpoExpoUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoExpoUpdate(
      map.expoId,
      map.MExpoUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param MExpoUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoExpoUpdate(expoId: string, MExpoUpdateDto: MExpoUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoUpdateDto>;
    public mExpoExpoUpdate(expoId: string, MExpoUpdateDto: MExpoUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoUpdateDto>>;
    public mExpoExpoUpdate(expoId: string, MExpoUpdateDto: MExpoUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoUpdateDto>>;
    public mExpoExpoUpdate(expoId: string, MExpoUpdateDto: MExpoUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoExpoUpdate.');
        }
        if (MExpoUpdateDto === null || MExpoUpdateDto === undefined) {
            throw new Error('Required parameter MExpoUpdateDto was null or undefined when calling mExpoExpoUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoUpdateDto>(`${this.configuration.basePath}/api/MExpo/expo/${encodeURIComponent(String(expoId))}`,
            MExpoUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ExpoUpdate']) {
                this.cancelMap['MExpo_ExpoUpdate'].next(null);
            }
            this.cancelMap['MExpo_ExpoUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ExpoUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ExpoUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ExpoUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ExpoUpdate']) {
            this.cancelMap['MExpo_ExpoUpdate'].complete();
            delete this.cancelMap['MExpo_ExpoUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ExpoUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ExpoUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoGetBackgroundsByMap(
    map: MExpoGetBackgrounds.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public mExpoGetBackgroundsByMap(
    map: MExpoGetBackgrounds.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public mExpoGetBackgroundsByMap(
    map: MExpoGetBackgrounds.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public mExpoGetBackgroundsByMap(
    map: MExpoGetBackgrounds.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoGetBackgrounds(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoGetBackgrounds(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public mExpoGetBackgrounds(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public mExpoGetBackgrounds(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public mExpoGetBackgrounds(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/MExpo/backgrounds`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_GetBackgrounds']) {
                this.cancelMap['MExpo_GetBackgrounds'].next(null);
            }
            this.cancelMap['MExpo_GetBackgrounds'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_GetBackgrounds']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_GetBackgrounds']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_GetBackgrounds', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_GetBackgrounds']) {
            this.cancelMap['MExpo_GetBackgrounds'].complete();
            delete this.cancelMap['MExpo_GetBackgrounds'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_GetBackgrounds',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_GetBackgrounds')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoListExpoByMap(
    map: MExpoListExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
  public mExpoListExpoByMap(
    map: MExpoListExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
  public mExpoListExpoByMap(
    map: MExpoListExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
  public mExpoListExpoByMap(
    map: MExpoListExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoListExpo(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoListExpo(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
    public mExpoListExpo(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
    public mExpoListExpo(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
    public mExpoListExpo(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoListDto>>(`${this.configuration.basePath}/api/MExpo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ListExpo']) {
                this.cancelMap['MExpo_ListExpo'].next(null);
            }
            this.cancelMap['MExpo_ListExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ListExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ListExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ListExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ListExpo']) {
            this.cancelMap['MExpo_ListExpo'].complete();
            delete this.cancelMap['MExpo_ListExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ListExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ListExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoManageGetByMap(
    map: MExpoManageGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoManagerDto>;
  public mExpoManageGetByMap(
    map: MExpoManageGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoManagerDto>>;
  public mExpoManageGetByMap(
    map: MExpoManageGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoManagerDto>>;
  public mExpoManageGetByMap(
    map: MExpoManageGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoManageGet(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoManageGet(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoManagerDto>;
    public mExpoManageGet(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoManagerDto>>;
    public mExpoManageGet(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoManagerDto>>;
    public mExpoManageGet(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoManageGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoManagerDto>(`${this.configuration.basePath}/api/MExpo/manage/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ManageGet']) {
                this.cancelMap['MExpo_ManageGet'].next(null);
            }
            this.cancelMap['MExpo_ManageGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ManageGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ManageGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ManageGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ManageGet']) {
            this.cancelMap['MExpo_ManageGet'].complete();
            delete this.cancelMap['MExpo_ManageGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ManageGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ManageGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoManageListByMap(
    map: MExpoManageList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
  public mExpoManageListByMap(
    map: MExpoManageList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
  public mExpoManageListByMap(
    map: MExpoManageList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
  public mExpoManageListByMap(
    map: MExpoManageList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoManageList(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoManageList(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
    public mExpoManageList(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
    public mExpoManageList(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
    public mExpoManageList(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoListDto>>(`${this.configuration.basePath}/api/MExpo/manage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ManageList']) {
                this.cancelMap['MExpo_ManageList'].next(null);
            }
            this.cancelMap['MExpo_ManageList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ManageList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ManageList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ManageList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ManageList']) {
            this.cancelMap['MExpo_ManageList'].complete();
            delete this.cancelMap['MExpo_ManageList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ManageList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ManageList')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoManageUpdateByMap(
    map: MExpoManageUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoManagerDto>;
  public mExpoManageUpdateByMap(
    map: MExpoManageUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoManagerDto>>;
  public mExpoManageUpdateByMap(
    map: MExpoManageUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoManagerDto>>;
  public mExpoManageUpdateByMap(
    map: MExpoManageUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoManageUpdate(
      map.expoId,
      map.MExpoManagerDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param MExpoManagerDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoManageUpdate(expoId: string, MExpoManagerDto: MExpoManagerDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoManagerDto>;
    public mExpoManageUpdate(expoId: string, MExpoManagerDto: MExpoManagerDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoManagerDto>>;
    public mExpoManageUpdate(expoId: string, MExpoManagerDto: MExpoManagerDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoManagerDto>>;
    public mExpoManageUpdate(expoId: string, MExpoManagerDto: MExpoManagerDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExpoManageUpdate.');
        }
        if (MExpoManagerDto === null || MExpoManagerDto === undefined) {
            throw new Error('Required parameter MExpoManagerDto was null or undefined when calling mExpoManageUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoManagerDto>(`${this.configuration.basePath}/api/MExpo/manage/${encodeURIComponent(String(expoId))}`,
            MExpoManagerDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_ManageUpdate']) {
                this.cancelMap['MExpo_ManageUpdate'].next(null);
            }
            this.cancelMap['MExpo_ManageUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_ManageUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_ManageUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_ManageUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_ManageUpdate']) {
            this.cancelMap['MExpo_ManageUpdate'].complete();
            delete this.cancelMap['MExpo_ManageUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_ManageUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_ManageUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoPushUpdateByMap(
    map: MExpoPushUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExpoPushUpdateByMap(
    map: MExpoPushUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExpoPushUpdateByMap(
    map: MExpoPushUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExpoPushUpdateByMap(
    map: MExpoPushUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoPushUpdate(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoPushUpdate(expoId?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExpoPushUpdate(expoId?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExpoPushUpdate(expoId?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExpoPushUpdate(expoId?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (expoId !== undefined && expoId !== null) {
            queryParameters = queryParameters.set('expoId', <any>expoId);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MExpo/pushUpdate`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_PushUpdate']) {
                this.cancelMap['MExpo_PushUpdate'].next(null);
            }
            this.cancelMap['MExpo_PushUpdate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_PushUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_PushUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_PushUpdate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_PushUpdate']) {
            this.cancelMap['MExpo_PushUpdate'].complete();
            delete this.cancelMap['MExpo_PushUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_PushUpdate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_PushUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExpoRebakeByMap(
    map: MExpoRebake.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExpoRebakeByMap(
    map: MExpoRebake.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExpoRebakeByMap(
    map: MExpoRebake.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExpoRebakeByMap(
    map: MExpoRebake.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExpoRebake(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExpoRebake(expoId?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExpoRebake(expoId?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExpoRebake(expoId?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExpoRebake(expoId?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (expoId !== undefined && expoId !== null) {
            queryParameters = queryParameters.set('expoId', <any>expoId);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MExpo/rebake`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExpo_Rebake']) {
                this.cancelMap['MExpo_Rebake'].next(null);
            }
            this.cancelMap['MExpo_Rebake'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExpo_Rebake']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExpo_Rebake']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExpo_Rebake', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExpo_Rebake']) {
            this.cancelMap['MExpo_Rebake'].complete();
            delete this.cancelMap['MExpo_Rebake'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExpo_Rebake',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExpo_Rebake')));
        }
        return handle;
    }

  }

export namespace MExpoService {
  export enum Operations {
    MExpo_ContactDelete = 'MExpo_ContactDelete',
    MExpo_ContactSort = 'MExpo_ContactSort',
    MExpo_ContactUpdate = 'MExpo_ContactUpdate',
    MExpo_ExhibitorActive = 'MExpo_ExhibitorActive',
    MExpo_ExhibitorDelete = 'MExpo_ExhibitorDelete',
    MExpo_ExhibitorGet = 'MExpo_ExhibitorGet',
    MExpo_ExhibitorSort = 'MExpo_ExhibitorSort',
    MExpo_ExhibitorUndelete = 'MExpo_ExhibitorUndelete',
    MExpo_ExhibitorUpdate = 'MExpo_ExhibitorUpdate',
    MExpo_ExpoGet = 'MExpo_ExpoGet',
    MExpo_ExpoUpdate = 'MExpo_ExpoUpdate',
    MExpo_GetBackgrounds = 'MExpo_GetBackgrounds',
    MExpo_ListExpo = 'MExpo_ListExpo',
    MExpo_ManageGet = 'MExpo_ManageGet',
    MExpo_ManageList = 'MExpo_ManageList',
    MExpo_ManageUpdate = 'MExpo_ManageUpdate',
    MExpo_PushUpdate = 'MExpo_PushUpdate',
    MExpo_Rebake = 'MExpo_Rebake'
  }
}
