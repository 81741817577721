/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';
import { MCategoryEditDto } from './mCategoryEditDto';
import { SidebarMode } from './sidebarMode';
import { VideoChatMode } from './videoChatMode';
import { AccessMode } from './accessMode';
import { MExhibitorGroup } from './mExhibitorGroup';
import { ExpoDesignEditDto } from './expoDesignEditDto';
import { MExpoContactEditDto } from './mExpoContactEditDto';
import { OverviewMode } from './overviewMode';


export interface MExpoManagerDto { 
    id?: string;
    contacts?: Array<MExpoContactEditDto>;
    categories?: Array<MCategoryEditDto>;
    logoSquareRaw?: string;
    logoWideRaw?: string;
    backgroundRaw?: string;
    shortKey?: string;
    name: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    dateStart: Date;
    dateEnd: Date;
    logoWide?: string;
    logoSquare?: string;
    background?: string;
    active?: boolean;
    isPrivate?: boolean;
    chatActive?: boolean;
    schedulingActive?: boolean;
    worldActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    promoted?: boolean;
    domain?: string;
    showHall?: boolean;
    accessMode?: AccessMode;
    defaultLanguage?: string;
    sizes?: string;
    displayMax?: number;
    linkMax?: number;
    infoSlideMax?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    calendarEnabled?: boolean;
    stageEnabled?: boolean;
    stageCount?: number;
    boothCount?: number;
    isPreview?: boolean;
    overviewMode?: OverviewMode;
    timeZone?: string;
    introType?: InventoryItemType;
    introSource?: string;
    videoChatMode?: VideoChatMode;
    vodLibraryType?: InventoryItemType;
    vodLibraryId?: string;
    randomChatNames?: boolean;
    sidebarMode?: SidebarMode;
    exhibitorGroups?: Array<MExhibitorGroup>;
    groupingEnabled?: boolean;
    groupingHideUngroupedLanding?: boolean;
    groupingHideUngroupedSidebar?: boolean;
    exhibitorInfoVisibility?: boolean;
    liveStreamAvailable?: boolean;
    liveStreamEnabled?: boolean;
    showStagesOnLanding?: boolean;
    expoDesign?: ExpoDesignEditDto;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    tags?: Array<string>;
    catalogEnabled?: boolean;
    boothPresentationEnabled?: boolean;
    surveyEnabled?: boolean;
    zoomRequiresLogin?: boolean;
    descriptionEntry?: string;
    seoKeywords?: string;
    homepage?: string;
    privacy?: string;
    uploadTs?: Date;
    rowVersion?: number;
    ownerName?: string;
    ownerChangeEmail?: string;
    consultantName?: string;
    consultantChangeEmail?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoManagerDto.
 */
export namespace MExpoManagerDto {
    /**
     * All properties of MExpoManagerDto.
     */
    export enum Properties {
        id = 'id',
        contacts = 'contacts',
        categories = 'categories',
        logoSquareRaw = 'logoSquareRaw',
        logoWideRaw = 'logoWideRaw',
        backgroundRaw = 'backgroundRaw',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logoWide = 'logoWide',
        logoSquare = 'logoSquare',
        background = 'background',
        active = 'active',
        isPrivate = 'isPrivate',
        chatActive = 'chatActive',
        schedulingActive = 'schedulingActive',
        worldActive = 'worldActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        promoted = 'promoted',
        domain = 'domain',
        showHall = 'showHall',
        accessMode = 'accessMode',
        defaultLanguage = 'defaultLanguage',
        sizes = 'sizes',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        infoSlideMax = 'infoSlideMax',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        calendarEnabled = 'calendarEnabled',
        stageEnabled = 'stageEnabled',
        stageCount = 'stageCount',
        boothCount = 'boothCount',
        isPreview = 'isPreview',
        overviewMode = 'overviewMode',
        timeZone = 'timeZone',
        introType = 'introType',
        introSource = 'introSource',
        videoChatMode = 'videoChatMode',
        vodLibraryType = 'vodLibraryType',
        vodLibraryId = 'vodLibraryId',
        randomChatNames = 'randomChatNames',
        sidebarMode = 'sidebarMode',
        exhibitorGroups = 'exhibitorGroups',
        groupingEnabled = 'groupingEnabled',
        groupingHideUngroupedLanding = 'groupingHideUngroupedLanding',
        groupingHideUngroupedSidebar = 'groupingHideUngroupedSidebar',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamAvailable = 'liveStreamAvailable',
        liveStreamEnabled = 'liveStreamEnabled',
        showStagesOnLanding = 'showStagesOnLanding',
        expoDesign = 'expoDesign',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        tags = 'tags',
        catalogEnabled = 'catalogEnabled',
        boothPresentationEnabled = 'boothPresentationEnabled',
        surveyEnabled = 'surveyEnabled',
        zoomRequiresLogin = 'zoomRequiresLogin',
        descriptionEntry = 'descriptionEntry',
        seoKeywords = 'seoKeywords',
        homepage = 'homepage',
        privacy = 'privacy',
        uploadTs = 'uploadTs',
        rowVersion = 'rowVersion',
        ownerName = 'ownerName',
        ownerChangeEmail = 'ownerChangeEmail',
        consultantName = 'consultantName',
        consultantChangeEmail = 'consultantChangeEmail'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoManagerDto.
    */
    export const ModelValidators: {[K in keyof MExpoManagerDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        contacts: [
        ],
        categories: [
        ],
        logoSquareRaw: [
        ],
        logoWideRaw: [
        ],
        backgroundRaw: [
        ],
        shortKey: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        dateStart: [
                ['required', Validators.required],
        ],
        dateEnd: [
                ['required', Validators.required],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        background: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        active: [
        ],
        isPrivate: [
        ],
        chatActive: [
        ],
        schedulingActive: [
        ],
        worldActive: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        promoted: [
        ],
        domain: [
        ],
        showHall: [
        ],
        accessMode: [
        ],
        defaultLanguage: [
        ],
        sizes: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        infoSlideMax: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        calendarEnabled: [
        ],
        stageEnabled: [
        ],
        stageCount: [
        ],
        boothCount: [
        ],
        isPreview: [
        ],
        overviewMode: [
        ],
        timeZone: [
        ],
        introType: [
        ],
        introSource: [
        ],
        videoChatMode: [
        ],
        vodLibraryType: [
        ],
        vodLibraryId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        randomChatNames: [
        ],
        sidebarMode: [
        ],
        exhibitorGroups: [
        ],
        groupingEnabled: [
        ],
        groupingHideUngroupedLanding: [
        ],
        groupingHideUngroupedSidebar: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamAvailable: [
        ],
        liveStreamEnabled: [
        ],
        showStagesOnLanding: [
        ],
        expoDesign: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        tags: [
        ],
        catalogEnabled: [
        ],
        boothPresentationEnabled: [
        ],
        surveyEnabled: [
        ],
        zoomRequiresLogin: [
        ],
        descriptionEntry: [
        ],
        seoKeywords: [
        ],
        homepage: [
        ],
        privacy: [
        ],
        uploadTs: [
        ],
        rowVersion: [
        ],
        ownerName: [
        ],
        ownerChangeEmail: [
        ],
        consultantName: [
        ],
        consultantChangeEmail: [
        ],
    };

    /**
    * The FormControlFactory for MExpoManagerDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoManagerDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoManagerDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoManagerDto = {
            id: null,
            contacts: null,
            categories: null,
            logoSquareRaw: null,
            logoWideRaw: null,
            backgroundRaw: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            dateStart: null,
            dateEnd: null,
            logoWide: null,
            logoSquare: null,
            background: null,
            active: null,
            isPrivate: null,
            chatActive: null,
            schedulingActive: null,
            worldActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            promoted: null,
            domain: null,
            showHall: null,
            accessMode: null,
            defaultLanguage: null,
            sizes: null,
            displayMax: null,
            linkMax: null,
            infoSlideMax: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            calendarEnabled: null,
            stageEnabled: null,
            stageCount: null,
            boothCount: null,
            isPreview: null,
            overviewMode: null,
            timeZone: null,
            introType: null,
            introSource: null,
            videoChatMode: null,
            vodLibraryType: null,
            vodLibraryId: null,
            randomChatNames: null,
            sidebarMode: null,
            exhibitorGroups: null,
            groupingEnabled: null,
            groupingHideUngroupedLanding: null,
            groupingHideUngroupedSidebar: null,
            exhibitorInfoVisibility: null,
            liveStreamAvailable: null,
            liveStreamEnabled: null,
            showStagesOnLanding: null,
            expoDesign: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tagsExhibitorEditable: null,
            tags: null,
            catalogEnabled: null,
            boothPresentationEnabled: null,
            surveyEnabled: null,
            zoomRequiresLogin: null,
            descriptionEntry: null,
            seoKeywords: null,
            homepage: null,
            privacy: null,
            uploadTs: null,
            rowVersion: null,
            ownerName: null,
            ownerChangeEmail: null,
            consultantName: null,
            consultantChangeEmail: null,
          }
        ) {
            super(model, MExpoManagerDto.ModelValidators);
        }
    }

}


