/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExhibitorState } from './exhibitorState';
import { StageState } from './stageState';


export interface MExpoState { 
    shortKey?: string;
    stageStates?: Array<StageState>;
    exhibitorStates?: Array<ExhibitorState>;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoState.
 */
export namespace MExpoState {
    /**
     * All properties of MExpoState.
     */
    export enum Properties {
        shortKey = 'shortKey',
        stageStates = 'stageStates',
        exhibitorStates = 'exhibitorStates'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoState.
    */
    export const ModelValidators: {[K in keyof MExpoState]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        stageStates: [
        ],
        exhibitorStates: [
        ],
    };

    /**
    * The FormControlFactory for MExpoState.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoState> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoState.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoState = {
            shortKey: null,
            stageStates: null,
            exhibitorStates: null,
          }
        ) {
            super(model, MExpoState.ModelValidators);
        }
    }

}


