/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ChatChannelMessageDto } from '../model/chatChannelMessageDto';
import { ChatLogPackageDto } from '../model/chatLogPackageDto';
import { ChatQueryDto } from '../model/chatQueryDto';
import { GroupChatMessage } from '../model/groupChatMessage';
import { JoinExpoDto } from '../model/joinExpoDto';
import { MongoChatItemDto } from '../model/mongoChatItemDto';
import { RequestResult } from '../model/requestResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for chatJoinGroup.
 */
export namespace ChatJoinGroup {
    /**
     * Parameter map for chatJoinGroup.
     */
    export interface PartialParamMap {
      expoId: string;
      groupId: string;
      JoinExpoDto: JoinExpoDto;
    }

    /**
     * Enumeration of all parameters for chatJoinGroup.
     */
    export enum Parameters {
      expoId = 'expoId',
      groupId = 'groupId',
      JoinExpoDto = 'JoinExpoDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatJoinGroup
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatJoinGroup.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      groupId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatLeaveGroup.
 */
export namespace ChatLeaveGroup {
    /**
     * Parameter map for chatLeaveGroup.
     */
    export interface PartialParamMap {
      expoId: string;
      groupId: string;
      JoinExpoDto: JoinExpoDto;
    }

    /**
     * Enumeration of all parameters for chatLeaveGroup.
     */
    export enum Parameters {
      expoId = 'expoId',
      groupId = 'groupId',
      JoinExpoDto = 'JoinExpoDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatLeaveGroup
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatLeaveGroup.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      groupId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatBlock.
 */
export namespace ChatPrivateChatBlock {
    /**
     * Parameter map for chatPrivateChatBlock.
     */
    export interface PartialParamMap {
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatBlock.
     */
    export enum Parameters {
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatBlock
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatBlock.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatClose.
 */
export namespace ChatPrivateChatClose {
    /**
     * Parameter map for chatPrivateChatClose.
     */
    export interface PartialParamMap {
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatClose.
     */
    export enum Parameters {
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatClose
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatClose.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatGet.
 */
export namespace ChatPrivateChatGet {
    /**
     * Parameter map for chatPrivateChatGet.
     */
    export interface PartialParamMap {
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatGet.
     */
    export enum Parameters {
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatGetList.
 */
export namespace ChatPrivateChatGetList {
    /**
     * Parameter map for chatPrivateChatGetList.
     */
    export interface PartialParamMap {
      ChatQueryDto: ChatQueryDto;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatGetList.
     */
    export enum Parameters {
      ChatQueryDto = 'ChatQueryDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatGetList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatGetList.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for chatPrivateChatSendMessage.
 */
export namespace ChatPrivateChatSendMessage {
    /**
     * Parameter map for chatPrivateChatSendMessage.
     */
    export interface PartialParamMap {
      ChatChannelMessageDto: ChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatSendMessage.
     */
    export enum Parameters {
      ChatChannelMessageDto = 'ChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatSendMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatSendMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for chatPrivateChatTimestamp.
 */
export namespace ChatPrivateChatTimestamp {
    /**
     * Parameter map for chatPrivateChatTimestamp.
     */
    export interface PartialParamMap {
      partnerId: string;
      last: Date;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatTimestamp.
     */
    export enum Parameters {
      partnerId = 'partnerId',
      last = 'last'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatTimestamp
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatTimestamp.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
      last: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatUnblock.
 */
export namespace ChatPrivateChatUnblock {
    /**
     * Parameter map for chatPrivateChatUnblock.
     */
    export interface PartialParamMap {
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatUnblock.
     */
    export enum Parameters {
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatUnblock
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatUnblock.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for chatPrivateChatVideoAccept.
 */
export namespace ChatPrivateChatVideoAccept {
    /**
     * Parameter map for chatPrivateChatVideoAccept.
     */
    export interface PartialParamMap {
      ChatChannelMessageDto: ChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatVideoAccept.
     */
    export enum Parameters {
      ChatChannelMessageDto = 'ChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatVideoAccept
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatVideoAccept.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for chatPrivateChatVideoDeny.
 */
export namespace ChatPrivateChatVideoDeny {
    /**
     * Parameter map for chatPrivateChatVideoDeny.
     */
    export interface PartialParamMap {
      ChatChannelMessageDto: ChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatVideoDeny.
     */
    export enum Parameters {
      ChatChannelMessageDto = 'ChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatVideoDeny
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatVideoDeny.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for chatPrivateChatVideoHangup.
 */
export namespace ChatPrivateChatVideoHangup {
    /**
     * Parameter map for chatPrivateChatVideoHangup.
     */
    export interface PartialParamMap {
      ChatChannelMessageDto: ChatChannelMessageDto;
    }

    /**
     * Enumeration of all parameters for chatPrivateChatVideoHangup.
     */
    export enum Parameters {
      ChatChannelMessageDto = 'ChatChannelMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatPrivateChatVideoHangup
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatPrivateChatVideoHangup.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for chatSendMessage.
 */
export namespace ChatSendMessage {
    /**
     * Parameter map for chatSendMessage.
     */
    export interface PartialParamMap {
      expoId: string;
      GroupChatMessage: GroupChatMessage;
    }

    /**
     * Enumeration of all parameters for chatSendMessage.
     */
    export enum Parameters {
      expoId = 'expoId',
      GroupChatMessage = 'GroupChatMessage'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of chatSendMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ChatSendMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class ChatService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatJoinGroupByMap(
    map: ChatJoinGroup.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ChatLogPackageDto>;
  public chatJoinGroupByMap(
    map: ChatJoinGroup.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ChatLogPackageDto>>;
  public chatJoinGroupByMap(
    map: ChatJoinGroup.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ChatLogPackageDto>>;
  public chatJoinGroupByMap(
    map: ChatJoinGroup.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatJoinGroup(
      map.expoId,
      map.groupId,
      map.JoinExpoDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param groupId 
     * @param JoinExpoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatJoinGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ChatLogPackageDto>;
    public chatJoinGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ChatLogPackageDto>>;
    public chatJoinGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ChatLogPackageDto>>;
    public chatJoinGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling chatJoinGroup.');
        }
        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling chatJoinGroup.');
        }
        if (JoinExpoDto === null || JoinExpoDto === undefined) {
            throw new Error('Required parameter JoinExpoDto was null or undefined when calling chatJoinGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ChatLogPackageDto>(`${this.configuration.basePath}/api/Chat/chat/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(groupId))}`,
            JoinExpoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_JoinGroup']) {
                this.cancelMap['Chat_JoinGroup'].next(null);
            }
            this.cancelMap['Chat_JoinGroup'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_JoinGroup']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_JoinGroup']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_JoinGroup', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_JoinGroup']) {
            this.cancelMap['Chat_JoinGroup'].complete();
            delete this.cancelMap['Chat_JoinGroup'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_JoinGroup',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_JoinGroup')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatLeaveGroupByMap(
    map: ChatLeaveGroup.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public chatLeaveGroupByMap(
    map: ChatLeaveGroup.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public chatLeaveGroupByMap(
    map: ChatLeaveGroup.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public chatLeaveGroupByMap(
    map: ChatLeaveGroup.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatLeaveGroup(
      map.expoId,
      map.groupId,
      map.JoinExpoDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param groupId 
     * @param JoinExpoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatLeaveGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public chatLeaveGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public chatLeaveGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public chatLeaveGroup(expoId: string, groupId: string, JoinExpoDto: JoinExpoDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling chatLeaveGroup.');
        }
        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling chatLeaveGroup.');
        }
        if (JoinExpoDto === null || JoinExpoDto === undefined) {
            throw new Error('Required parameter JoinExpoDto was null or undefined when calling chatLeaveGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Chat/leave/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(groupId))}`,
            JoinExpoDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_LeaveGroup']) {
                this.cancelMap['Chat_LeaveGroup'].next(null);
            }
            this.cancelMap['Chat_LeaveGroup'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_LeaveGroup']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_LeaveGroup']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_LeaveGroup', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_LeaveGroup']) {
            this.cancelMap['Chat_LeaveGroup'].complete();
            delete this.cancelMap['Chat_LeaveGroup'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_LeaveGroup',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_LeaveGroup')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatBlockByMap(
    map: ChatPrivateChatBlock.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public chatPrivateChatBlockByMap(
    map: ChatPrivateChatBlock.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public chatPrivateChatBlockByMap(
    map: ChatPrivateChatBlock.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public chatPrivateChatBlockByMap(
    map: ChatPrivateChatBlock.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatBlock(
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatBlock(partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public chatPrivateChatBlock(partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public chatPrivateChatBlock(partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public chatPrivateChatBlock(partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling chatPrivateChatBlock.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Chat/private/block/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatBlock']) {
                this.cancelMap['Chat_PrivateChatBlock'].next(null);
            }
            this.cancelMap['Chat_PrivateChatBlock'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatBlock']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatBlock']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatBlock', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatBlock']) {
            this.cancelMap['Chat_PrivateChatBlock'].complete();
            delete this.cancelMap['Chat_PrivateChatBlock'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatBlock',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatBlock')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatCloseByMap(
    map: ChatPrivateChatClose.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public chatPrivateChatCloseByMap(
    map: ChatPrivateChatClose.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public chatPrivateChatCloseByMap(
    map: ChatPrivateChatClose.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public chatPrivateChatCloseByMap(
    map: ChatPrivateChatClose.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatClose(
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatClose(partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public chatPrivateChatClose(partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public chatPrivateChatClose(partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public chatPrivateChatClose(partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling chatPrivateChatClose.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Chat/private/close/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatClose']) {
                this.cancelMap['Chat_PrivateChatClose'].next(null);
            }
            this.cancelMap['Chat_PrivateChatClose'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatClose']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatClose']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatClose', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatClose']) {
            this.cancelMap['Chat_PrivateChatClose'].complete();
            delete this.cancelMap['Chat_PrivateChatClose'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatClose',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatClose')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatGetByMap(
    map: ChatPrivateChatGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
  public chatPrivateChatGetByMap(
    map: ChatPrivateChatGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
  public chatPrivateChatGetByMap(
    map: ChatPrivateChatGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
  public chatPrivateChatGetByMap(
    map: ChatPrivateChatGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatGet(
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatGet(partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
    public chatPrivateChatGet(partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
    public chatPrivateChatGet(partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
    public chatPrivateChatGet(partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling chatPrivateChatGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<MongoChatItemDto>(`${this.configuration.basePath}/api/Chat/private/get/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatGet']) {
                this.cancelMap['Chat_PrivateChatGet'].next(null);
            }
            this.cancelMap['Chat_PrivateChatGet'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatGet', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatGet']) {
            this.cancelMap['Chat_PrivateChatGet'].complete();
            delete this.cancelMap['Chat_PrivateChatGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatGet',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatGetListByMap(
    map: ChatPrivateChatGetList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MongoChatItemDto>>;
  public chatPrivateChatGetListByMap(
    map: ChatPrivateChatGetList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MongoChatItemDto>>>;
  public chatPrivateChatGetListByMap(
    map: ChatPrivateChatGetList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MongoChatItemDto>>>;
  public chatPrivateChatGetListByMap(
    map: ChatPrivateChatGetList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatGetList(
      map.ChatQueryDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ChatQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatGetList(ChatQueryDto: ChatQueryDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MongoChatItemDto>>;
    public chatPrivateChatGetList(ChatQueryDto: ChatQueryDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MongoChatItemDto>>>;
    public chatPrivateChatGetList(ChatQueryDto: ChatQueryDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MongoChatItemDto>>>;
    public chatPrivateChatGetList(ChatQueryDto: ChatQueryDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ChatQueryDto === null || ChatQueryDto === undefined) {
            throw new Error('Required parameter ChatQueryDto was null or undefined when calling chatPrivateChatGetList.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<MongoChatItemDto>>(`${this.configuration.basePath}/api/Chat/private/get`,
            ChatQueryDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatGetList']) {
                this.cancelMap['Chat_PrivateChatGetList'].next(null);
            }
            this.cancelMap['Chat_PrivateChatGetList'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatGetList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatGetList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatGetList', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatGetList']) {
            this.cancelMap['Chat_PrivateChatGetList'].complete();
            delete this.cancelMap['Chat_PrivateChatGetList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatGetList',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatGetList')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatSendMessageByMap(
    map: ChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
  public chatPrivateChatSendMessageByMap(
    map: ChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
  public chatPrivateChatSendMessageByMap(
    map: ChatPrivateChatSendMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
  public chatPrivateChatSendMessageByMap(
    map: ChatPrivateChatSendMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatSendMessage(
      map.ChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatSendMessage(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MongoChatItemDto>;
    public chatPrivateChatSendMessage(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MongoChatItemDto>>;
    public chatPrivateChatSendMessage(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MongoChatItemDto>>;
    public chatPrivateChatSendMessage(ChatChannelMessageDto: ChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ChatChannelMessageDto === null || ChatChannelMessageDto === undefined) {
            throw new Error('Required parameter ChatChannelMessageDto was null or undefined when calling chatPrivateChatSendMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MongoChatItemDto>(`${this.configuration.basePath}/api/Chat/private/send`,
            ChatChannelMessageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatSendMessage']) {
                this.cancelMap['Chat_PrivateChatSendMessage'].next(null);
            }
            this.cancelMap['Chat_PrivateChatSendMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatSendMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatSendMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatSendMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatSendMessage']) {
            this.cancelMap['Chat_PrivateChatSendMessage'].complete();
            delete this.cancelMap['Chat_PrivateChatSendMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatSendMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatSendMessage')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatTimestampByMap(
    map: ChatPrivateChatTimestamp.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public chatPrivateChatTimestampByMap(
    map: ChatPrivateChatTimestamp.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public chatPrivateChatTimestampByMap(
    map: ChatPrivateChatTimestamp.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public chatPrivateChatTimestampByMap(
    map: ChatPrivateChatTimestamp.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatTimestamp(
      map.partnerId,
      map.last,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param last 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatTimestamp(partnerId: string, last: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public chatPrivateChatTimestamp(partnerId: string, last: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public chatPrivateChatTimestamp(partnerId: string, last: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public chatPrivateChatTimestamp(partnerId: string, last: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling chatPrivateChatTimestamp.');
        }
        if (last === null || last === undefined) {
            throw new Error('Required parameter last was null or undefined when calling chatPrivateChatTimestamp.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Chat/private/ts/${encodeURIComponent(String(partnerId))}/${encodeURIComponent(String(last.toISOString()))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatTimestamp']) {
                this.cancelMap['Chat_PrivateChatTimestamp'].next(null);
            }
            this.cancelMap['Chat_PrivateChatTimestamp'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatTimestamp']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatTimestamp']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatTimestamp', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatTimestamp']) {
            this.cancelMap['Chat_PrivateChatTimestamp'].complete();
            delete this.cancelMap['Chat_PrivateChatTimestamp'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatTimestamp',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatTimestamp')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatUnblockByMap(
    map: ChatPrivateChatUnblock.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public chatPrivateChatUnblockByMap(
    map: ChatPrivateChatUnblock.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public chatPrivateChatUnblockByMap(
    map: ChatPrivateChatUnblock.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public chatPrivateChatUnblockByMap(
    map: ChatPrivateChatUnblock.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatUnblock(
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatUnblock(partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public chatPrivateChatUnblock(partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public chatPrivateChatUnblock(partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public chatPrivateChatUnblock(partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling chatPrivateChatUnblock.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Chat/private/unblock/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatUnblock']) {
                this.cancelMap['Chat_PrivateChatUnblock'].next(null);
            }
            this.cancelMap['Chat_PrivateChatUnblock'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatUnblock']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatUnblock']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatUnblock', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatUnblock']) {
            this.cancelMap['Chat_PrivateChatUnblock'].complete();
            delete this.cancelMap['Chat_PrivateChatUnblock'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatUnblock',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatUnblock')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatVideoAcceptByMap(
    map: ChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public chatPrivateChatVideoAcceptByMap(
    map: ChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public chatPrivateChatVideoAcceptByMap(
    map: ChatPrivateChatVideoAccept.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public chatPrivateChatVideoAcceptByMap(
    map: ChatPrivateChatVideoAccept.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatVideoAccept(
      map.ChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatVideoAccept(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public chatPrivateChatVideoAccept(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public chatPrivateChatVideoAccept(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public chatPrivateChatVideoAccept(ChatChannelMessageDto: ChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ChatChannelMessageDto === null || ChatChannelMessageDto === undefined) {
            throw new Error('Required parameter ChatChannelMessageDto was null or undefined when calling chatPrivateChatVideoAccept.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Chat/private/video/accept`,
            ChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatVideoAccept']) {
                this.cancelMap['Chat_PrivateChatVideoAccept'].next(null);
            }
            this.cancelMap['Chat_PrivateChatVideoAccept'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatVideoAccept']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatVideoAccept']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatVideoAccept', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatVideoAccept']) {
            this.cancelMap['Chat_PrivateChatVideoAccept'].complete();
            delete this.cancelMap['Chat_PrivateChatVideoAccept'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatVideoAccept',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatVideoAccept')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatVideoDenyByMap(
    map: ChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public chatPrivateChatVideoDenyByMap(
    map: ChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public chatPrivateChatVideoDenyByMap(
    map: ChatPrivateChatVideoDeny.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public chatPrivateChatVideoDenyByMap(
    map: ChatPrivateChatVideoDeny.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatVideoDeny(
      map.ChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatVideoDeny(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public chatPrivateChatVideoDeny(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public chatPrivateChatVideoDeny(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public chatPrivateChatVideoDeny(ChatChannelMessageDto: ChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ChatChannelMessageDto === null || ChatChannelMessageDto === undefined) {
            throw new Error('Required parameter ChatChannelMessageDto was null or undefined when calling chatPrivateChatVideoDeny.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Chat/private/video/close`,
            ChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatVideoDeny']) {
                this.cancelMap['Chat_PrivateChatVideoDeny'].next(null);
            }
            this.cancelMap['Chat_PrivateChatVideoDeny'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatVideoDeny']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatVideoDeny']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatVideoDeny', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatVideoDeny']) {
            this.cancelMap['Chat_PrivateChatVideoDeny'].complete();
            delete this.cancelMap['Chat_PrivateChatVideoDeny'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatVideoDeny',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatVideoDeny')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatPrivateChatVideoHangupByMap(
    map: ChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public chatPrivateChatVideoHangupByMap(
    map: ChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public chatPrivateChatVideoHangupByMap(
    map: ChatPrivateChatVideoHangup.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public chatPrivateChatVideoHangupByMap(
    map: ChatPrivateChatVideoHangup.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatPrivateChatVideoHangup(
      map.ChatChannelMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ChatChannelMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatPrivateChatVideoHangup(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public chatPrivateChatVideoHangup(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public chatPrivateChatVideoHangup(ChatChannelMessageDto: ChatChannelMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public chatPrivateChatVideoHangup(ChatChannelMessageDto: ChatChannelMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ChatChannelMessageDto === null || ChatChannelMessageDto === undefined) {
            throw new Error('Required parameter ChatChannelMessageDto was null or undefined when calling chatPrivateChatVideoHangup.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Chat/private/video/hangup`,
            ChatChannelMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_PrivateChatVideoHangup']) {
                this.cancelMap['Chat_PrivateChatVideoHangup'].next(null);
            }
            this.cancelMap['Chat_PrivateChatVideoHangup'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_PrivateChatVideoHangup']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_PrivateChatVideoHangup']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_PrivateChatVideoHangup', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_PrivateChatVideoHangup']) {
            this.cancelMap['Chat_PrivateChatVideoHangup'].complete();
            delete this.cancelMap['Chat_PrivateChatVideoHangup'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_PrivateChatVideoHangup',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_PrivateChatVideoHangup')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public chatSendMessageByMap(
    map: ChatSendMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public chatSendMessageByMap(
    map: ChatSendMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public chatSendMessageByMap(
    map: ChatSendMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public chatSendMessageByMap(
    map: ChatSendMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.chatSendMessage(
      map.expoId,
      map.GroupChatMessage,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param GroupChatMessage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public chatSendMessage(expoId: string, GroupChatMessage: GroupChatMessage, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public chatSendMessage(expoId: string, GroupChatMessage: GroupChatMessage, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public chatSendMessage(expoId: string, GroupChatMessage: GroupChatMessage, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public chatSendMessage(expoId: string, GroupChatMessage: GroupChatMessage, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling chatSendMessage.');
        }
        if (GroupChatMessage === null || GroupChatMessage === undefined) {
            throw new Error('Required parameter GroupChatMessage was null or undefined when calling chatSendMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Chat/chat/${encodeURIComponent(String(expoId))}`,
            GroupChatMessage,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Chat_SendMessage']) {
                this.cancelMap['Chat_SendMessage'].next(null);
            }
            this.cancelMap['Chat_SendMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Chat_SendMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Chat_SendMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Chat_SendMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Chat_SendMessage']) {
            this.cancelMap['Chat_SendMessage'].complete();
            delete this.cancelMap['Chat_SendMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Chat_SendMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Chat_SendMessage')));
        }
        return handle;
    }

  }

export namespace ChatService {
  export enum Operations {
    Chat_JoinGroup = 'Chat_JoinGroup',
    Chat_LeaveGroup = 'Chat_LeaveGroup',
    Chat_PrivateChatBlock = 'Chat_PrivateChatBlock',
    Chat_PrivateChatClose = 'Chat_PrivateChatClose',
    Chat_PrivateChatGet = 'Chat_PrivateChatGet',
    Chat_PrivateChatGetList = 'Chat_PrivateChatGetList',
    Chat_PrivateChatSendMessage = 'Chat_PrivateChatSendMessage',
    Chat_PrivateChatTimestamp = 'Chat_PrivateChatTimestamp',
    Chat_PrivateChatUnblock = 'Chat_PrivateChatUnblock',
    Chat_PrivateChatVideoAccept = 'Chat_PrivateChatVideoAccept',
    Chat_PrivateChatVideoDeny = 'Chat_PrivateChatVideoDeny',
    Chat_PrivateChatVideoHangup = 'Chat_PrivateChatVideoHangup',
    Chat_SendMessage = 'Chat_SendMessage'
  }
}
