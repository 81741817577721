/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MStageEditDto } from './mStageEditDto';
import { MExpoState } from './mExpoState';
import { MExpoEditDto } from './mExpoEditDto';
import { MExhibitorEditDto } from './mExhibitorEditDto';


export interface MExpoEventsDto { 
    expo?: MExpoEditDto;
    stages?: Array<MStageEditDto>;
    exhibitors?: Array<MExhibitorEditDto>;
    state?: MExpoState;
    canEditStages?: boolean;
    canEditAvailabilities?: boolean;
    canEditSessions?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoEventsDto.
 */
export namespace MExpoEventsDto {
    /**
     * All properties of MExpoEventsDto.
     */
    export enum Properties {
        expo = 'expo',
        stages = 'stages',
        exhibitors = 'exhibitors',
        state = 'state',
        canEditStages = 'canEditStages',
        canEditAvailabilities = 'canEditAvailabilities',
        canEditSessions = 'canEditSessions'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoEventsDto.
    */
    export const ModelValidators: {[K in keyof MExpoEventsDto]: [string, ValidatorFn][]} = {
        expo: [
        ],
        stages: [
        ],
        exhibitors: [
        ],
        state: [
        ],
        canEditStages: [
        ],
        canEditAvailabilities: [
        ],
        canEditSessions: [
        ],
    };

    /**
    * The FormControlFactory for MExpoEventsDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoEventsDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoEventsDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoEventsDto = {
            expo: null,
            stages: null,
            exhibitors: null,
            state: null,
            canEditStages: null,
            canEditAvailabilities: null,
            canEditSessions: null,
          }
        ) {
            super(model, MExpoEventsDto.ModelValidators);
        }
    }

}


