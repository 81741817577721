/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { InventoryItemType } from './inventoryItemType';
import { StageMode } from './stageMode';


export interface StreamUpdateDto { 
    forceActivate?: boolean;
    streamType?: StreamType;
    stream?: string;
    streamAdditional?: string;
    streamAdditional2?: string;
    vodType?: InventoryItemType;
    vodSource?: string;
    vodSourceAdditional?: string;
    vodComment?: string;
    background?: string;
    stageMode?: StageMode;
}

/**
 * Namespace for property- and property-value-enumerations of StreamUpdateDto.
 */
export namespace StreamUpdateDto {
    /**
     * All properties of StreamUpdateDto.
     */
    export enum Properties {
        forceActivate = 'forceActivate',
        streamType = 'streamType',
        stream = 'stream',
        streamAdditional = 'streamAdditional',
        streamAdditional2 = 'streamAdditional2',
        vodType = 'vodType',
        vodSource = 'vodSource',
        vodSourceAdditional = 'vodSourceAdditional',
        vodComment = 'vodComment',
        background = 'background',
        stageMode = 'stageMode'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StreamUpdateDto.
    */
    export const ModelValidators: {[K in keyof StreamUpdateDto]: [string, ValidatorFn][]} = {
        forceActivate: [
        ],
        streamType: [
        ],
        stream: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional2: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        vodType: [
        ],
        vodSource: [
        ],
        vodSourceAdditional: [
        ],
        vodComment: [
        ],
        background: [
        ],
        stageMode: [
        ],
    };

    /**
    * The FormControlFactory for StreamUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StreamUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StreamUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StreamUpdateDto = {
            forceActivate: null,
            streamType: null,
            stream: null,
            streamAdditional: null,
            streamAdditional2: null,
            vodType: null,
            vodSource: null,
            vodSourceAdditional: null,
            vodComment: null,
            background: null,
            stageMode: null,
          }
        ) {
            super(model, StreamUpdateDto.ModelValidators);
        }
    }

}


