/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { CalendarEntrySessionDto } from '../model/calendarEntrySessionDto';
import { MCalendarEntryStageDto } from '../model/mCalendarEntryStageDto';
import { MCalendarEntryStageEditDto } from '../model/mCalendarEntryStageEditDto';
import { MExpoEventsDto } from '../model/mExpoEventsDto';
import { NotificationMessageDto } from '../model/notificationMessageDto';
import { StageState } from '../model/stageState';
import { StreamUpdateDto } from '../model/streamUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mCalendarCalendarActivate.
 */
export namespace MCalendarCalendarActivate {
    /**
     * Parameter map for mCalendarCalendarActivate.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarActivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarActivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarActivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarDeactivate.
 */
export namespace MCalendarCalendarDeactivate {
    /**
     * Parameter map for mCalendarCalendarDeactivate.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarDeactivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarDeactivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarDeactivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarDelete.
 */
export namespace MCalendarCalendarDelete {
    /**
     * Parameter map for mCalendarCalendarDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarGet.
 */
export namespace MCalendarCalendarGet {
    /**
     * Parameter map for mCalendarCalendarGet.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarGetAll.
 */
export namespace MCalendarCalendarGetAll {
    /**
     * Parameter map for mCalendarCalendarGetAll.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarGetAll.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarGetAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarGetAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarPost.
 */
export namespace MCalendarCalendarPost {
    /**
     * Parameter map for mCalendarCalendarPost.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      MCalendarEntryStageEditDto: MCalendarEntryStageEditDto;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarPost.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      MCalendarEntryStageEditDto = 'MCalendarEntryStageEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarPost.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarCalendarUpdateStream.
 */
export namespace MCalendarCalendarUpdateStream {
    /**
     * Parameter map for mCalendarCalendarUpdateStream.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
      StreamUpdateDto: StreamUpdateDto;
    }

    /**
     * Enumeration of all parameters for mCalendarCalendarUpdateStream.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId',
      StreamUpdateDto = 'StreamUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarCalendarUpdateStream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarCalendarUpdateStream.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarDeleteSession.
 */
export namespace MCalendarDeleteSession {
    /**
     * Parameter map for mCalendarDeleteSession.
     */
    export interface PartialParamMap {
      id: number;
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarDeleteSession.
     */
    export enum Parameters {
      id = 'id',
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarDeleteSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarDeleteSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarGetCalendarExpo.
 */
export namespace MCalendarGetCalendarExpo {
    /**
     * Parameter map for mCalendarGetCalendarExpo.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarGetCalendarExpo.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarGetCalendarExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarGetCalendarExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarGetSessions.
 */
export namespace MCalendarGetSessions {
    /**
     * Parameter map for mCalendarGetSessions.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarGetSessions.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarGetSessions
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarGetSessions.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarOpenSession.
 */
export namespace MCalendarOpenSession {
    /**
     * Parameter map for mCalendarOpenSession.
     */
    export interface PartialParamMap {
      id: number;
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarOpenSession.
     */
    export enum Parameters {
      id = 'id',
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarOpenSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarOpenSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarPostSession.
 */
export namespace MCalendarPostSession {
    /**
     * Parameter map for mCalendarPostSession.
     */
    export interface PartialParamMap {
      expoId: string;
      CalendarEntrySessionDto: CalendarEntrySessionDto;
    }

    /**
     * Enumeration of all parameters for mCalendarPostSession.
     */
    export enum Parameters {
      expoId = 'expoId',
      CalendarEntrySessionDto = 'CalendarEntrySessionDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarPostSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarPostSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarStageNotify.
 */
export namespace MCalendarStageNotify {
    /**
     * Parameter map for mCalendarStageNotify.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mCalendarStageNotify.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarStageNotify
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarStageNotify.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mCalendarStageNotifyMessage.
 */
export namespace MCalendarStageNotifyMessage {
    /**
     * Parameter map for mCalendarStageNotifyMessage.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      NotificationMessageDto: NotificationMessageDto;
    }

    /**
     * Enumeration of all parameters for mCalendarStageNotifyMessage.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      NotificationMessageDto = 'NotificationMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mCalendarStageNotifyMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MCalendarStageNotifyMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MCalendarService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarActivateByMap(
    map: MCalendarCalendarActivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<StageState>;
  public mCalendarCalendarActivateByMap(
    map: MCalendarCalendarActivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<StageState>>;
  public mCalendarCalendarActivateByMap(
    map: MCalendarCalendarActivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<StageState>>;
  public mCalendarCalendarActivateByMap(
    map: MCalendarCalendarActivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarActivate(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarActivate(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<StageState>;
    public mCalendarCalendarActivate(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<StageState>>;
    public mCalendarCalendarActivate(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<StageState>>;
    public mCalendarCalendarActivate(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarActivate.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarActivate.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mCalendarCalendarActivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<StageState>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar/${encodeURIComponent(String(entryId))}/activate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarActivate']) {
                this.cancelMap['MCalendar_CalendarActivate'].next(null);
            }
            this.cancelMap['MCalendar_CalendarActivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarActivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarActivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarActivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarActivate']) {
            this.cancelMap['MCalendar_CalendarActivate'].complete();
            delete this.cancelMap['MCalendar_CalendarActivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarActivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarActivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarDeactivateByMap(
    map: MCalendarCalendarDeactivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<StageState>;
  public mCalendarCalendarDeactivateByMap(
    map: MCalendarCalendarDeactivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<StageState>>;
  public mCalendarCalendarDeactivateByMap(
    map: MCalendarCalendarDeactivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<StageState>>;
  public mCalendarCalendarDeactivateByMap(
    map: MCalendarCalendarDeactivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarDeactivate(
      map.expoId,
      map.stageId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarDeactivate(expoId: string, stageId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<StageState>;
    public mCalendarCalendarDeactivate(expoId: string, stageId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<StageState>>;
    public mCalendarCalendarDeactivate(expoId: string, stageId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<StageState>>;
    public mCalendarCalendarDeactivate(expoId: string, stageId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarDeactivate.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarDeactivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<StageState>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar/deactivate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarDeactivate']) {
                this.cancelMap['MCalendar_CalendarDeactivate'].next(null);
            }
            this.cancelMap['MCalendar_CalendarDeactivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarDeactivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarDeactivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarDeactivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarDeactivate']) {
            this.cancelMap['MCalendar_CalendarDeactivate'].complete();
            delete this.cancelMap['MCalendar_CalendarDeactivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarDeactivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarDeactivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarDeleteByMap(
    map: MCalendarCalendarDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public mCalendarCalendarDeleteByMap(
    map: MCalendarCalendarDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public mCalendarCalendarDeleteByMap(
    map: MCalendarCalendarDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public mCalendarCalendarDeleteByMap(
    map: MCalendarCalendarDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarDelete(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarDelete(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public mCalendarCalendarDelete(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public mCalendarCalendarDelete(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public mCalendarCalendarDelete(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarDelete.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarDelete.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mCalendarCalendarDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarDelete']) {
                this.cancelMap['MCalendar_CalendarDelete'].next(null);
            }
            this.cancelMap['MCalendar_CalendarDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarDelete']) {
            this.cancelMap['MCalendar_CalendarDelete'].complete();
            delete this.cancelMap['MCalendar_CalendarDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarGetByMap(
    map: MCalendarCalendarGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageEditDto>;
  public mCalendarCalendarGetByMap(
    map: MCalendarCalendarGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageEditDto>>;
  public mCalendarCalendarGetByMap(
    map: MCalendarCalendarGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageEditDto>>;
  public mCalendarCalendarGetByMap(
    map: MCalendarCalendarGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarGet(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarGet(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageEditDto>;
    public mCalendarCalendarGet(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageEditDto>>;
    public mCalendarCalendarGet(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageEditDto>>;
    public mCalendarCalendarGet(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarGet.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarGet.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mCalendarCalendarGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MCalendarEntryStageEditDto>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarGet']) {
                this.cancelMap['MCalendar_CalendarGet'].next(null);
            }
            this.cancelMap['MCalendar_CalendarGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarGet']) {
            this.cancelMap['MCalendar_CalendarGet'].complete();
            delete this.cancelMap['MCalendar_CalendarGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarGetAllByMap(
    map: MCalendarCalendarGetAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryStageEditDto>>;
  public mCalendarCalendarGetAllByMap(
    map: MCalendarCalendarGetAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryStageEditDto>>>;
  public mCalendarCalendarGetAllByMap(
    map: MCalendarCalendarGetAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryStageEditDto>>>;
  public mCalendarCalendarGetAllByMap(
    map: MCalendarCalendarGetAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarGetAll(
      map.expoId,
      map.stageId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarGetAll(expoId: string, stageId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryStageEditDto>>;
    public mCalendarCalendarGetAll(expoId: string, stageId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryStageEditDto>>>;
    public mCalendarCalendarGetAll(expoId: string, stageId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryStageEditDto>>>;
    public mCalendarCalendarGetAll(expoId: string, stageId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarGetAll.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarGetAll.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MCalendarEntryStageEditDto>>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarGetAll']) {
                this.cancelMap['MCalendar_CalendarGetAll'].next(null);
            }
            this.cancelMap['MCalendar_CalendarGetAll'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarGetAll']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarGetAll']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarGetAll', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarGetAll']) {
            this.cancelMap['MCalendar_CalendarGetAll'].complete();
            delete this.cancelMap['MCalendar_CalendarGetAll'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarGetAll',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarGetAll')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarPostByMap(
    map: MCalendarCalendarPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageEditDto>;
  public mCalendarCalendarPostByMap(
    map: MCalendarCalendarPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageEditDto>>;
  public mCalendarCalendarPostByMap(
    map: MCalendarCalendarPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageEditDto>>;
  public mCalendarCalendarPostByMap(
    map: MCalendarCalendarPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarPost(
      map.expoId,
      map.stageId,
      map.MCalendarEntryStageEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param MCalendarEntryStageEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarPost(expoId: string, stageId: string, MCalendarEntryStageEditDto: MCalendarEntryStageEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageEditDto>;
    public mCalendarCalendarPost(expoId: string, stageId: string, MCalendarEntryStageEditDto: MCalendarEntryStageEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageEditDto>>;
    public mCalendarCalendarPost(expoId: string, stageId: string, MCalendarEntryStageEditDto: MCalendarEntryStageEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageEditDto>>;
    public mCalendarCalendarPost(expoId: string, stageId: string, MCalendarEntryStageEditDto: MCalendarEntryStageEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarPost.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarPost.');
        }
        if (MCalendarEntryStageEditDto === null || MCalendarEntryStageEditDto === undefined) {
            throw new Error('Required parameter MCalendarEntryStageEditDto was null or undefined when calling mCalendarCalendarPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MCalendarEntryStageEditDto>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar`,
            MCalendarEntryStageEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarPost']) {
                this.cancelMap['MCalendar_CalendarPost'].next(null);
            }
            this.cancelMap['MCalendar_CalendarPost'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarPost']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarPost']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarPost', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarPost']) {
            this.cancelMap['MCalendar_CalendarPost'].complete();
            delete this.cancelMap['MCalendar_CalendarPost'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarPost',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarPost')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarCalendarUpdateStreamByMap(
    map: MCalendarCalendarUpdateStream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageDto>;
  public mCalendarCalendarUpdateStreamByMap(
    map: MCalendarCalendarUpdateStream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageDto>>;
  public mCalendarCalendarUpdateStreamByMap(
    map: MCalendarCalendarUpdateStream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageDto>>;
  public mCalendarCalendarUpdateStreamByMap(
    map: MCalendarCalendarUpdateStream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarCalendarUpdateStream(
      map.expoId,
      map.stageId,
      map.entryId,
      map.StreamUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param StreamUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarCalendarUpdateStream(expoId: string, stageId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryStageDto>;
    public mCalendarCalendarUpdateStream(expoId: string, stageId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryStageDto>>;
    public mCalendarCalendarUpdateStream(expoId: string, stageId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryStageDto>>;
    public mCalendarCalendarUpdateStream(expoId: string, stageId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarCalendarUpdateStream.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarCalendarUpdateStream.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mCalendarCalendarUpdateStream.');
        }
        if (StreamUpdateDto === null || StreamUpdateDto === undefined) {
            throw new Error('Required parameter StreamUpdateDto was null or undefined when calling mCalendarCalendarUpdateStream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MCalendarEntryStageDto>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/calendar/${encodeURIComponent(String(entryId))}/update`,
            StreamUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_CalendarUpdateStream']) {
                this.cancelMap['MCalendar_CalendarUpdateStream'].next(null);
            }
            this.cancelMap['MCalendar_CalendarUpdateStream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_CalendarUpdateStream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_CalendarUpdateStream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_CalendarUpdateStream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_CalendarUpdateStream']) {
            this.cancelMap['MCalendar_CalendarUpdateStream'].complete();
            delete this.cancelMap['MCalendar_CalendarUpdateStream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_CalendarUpdateStream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_CalendarUpdateStream')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarDeleteSessionByMap(
    map: MCalendarDeleteSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mCalendarDeleteSessionByMap(
    map: MCalendarDeleteSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mCalendarDeleteSessionByMap(
    map: MCalendarDeleteSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mCalendarDeleteSessionByMap(
    map: MCalendarDeleteSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarDeleteSession(
      map.id,
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarDeleteSession(id: number, expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mCalendarDeleteSession(id: number, expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mCalendarDeleteSession(id: number, expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mCalendarDeleteSession(id: number, expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mCalendarDeleteSession.');
        }
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarDeleteSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete(`${this.configuration.basePath}/api/MCalendar/sessions/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_DeleteSession']) {
                this.cancelMap['MCalendar_DeleteSession'].next(null);
            }
            this.cancelMap['MCalendar_DeleteSession'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_DeleteSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_DeleteSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_DeleteSession', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_DeleteSession']) {
            this.cancelMap['MCalendar_DeleteSession'].complete();
            delete this.cancelMap['MCalendar_DeleteSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_DeleteSession',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_DeleteSession')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarGetCalendarExpoByMap(
    map: MCalendarGetCalendarExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoEventsDto>;
  public mCalendarGetCalendarExpoByMap(
    map: MCalendarGetCalendarExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoEventsDto>>;
  public mCalendarGetCalendarExpoByMap(
    map: MCalendarGetCalendarExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoEventsDto>>;
  public mCalendarGetCalendarExpoByMap(
    map: MCalendarGetCalendarExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarGetCalendarExpo(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarGetCalendarExpo(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoEventsDto>;
    public mCalendarGetCalendarExpo(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoEventsDto>>;
    public mCalendarGetCalendarExpo(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoEventsDto>>;
    public mCalendarGetCalendarExpo(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarGetCalendarExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoEventsDto>(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/stages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_GetCalendarExpo']) {
                this.cancelMap['MCalendar_GetCalendarExpo'].next(null);
            }
            this.cancelMap['MCalendar_GetCalendarExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_GetCalendarExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_GetCalendarExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_GetCalendarExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_GetCalendarExpo']) {
            this.cancelMap['MCalendar_GetCalendarExpo'].complete();
            delete this.cancelMap['MCalendar_GetCalendarExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_GetCalendarExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_GetCalendarExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarGetSessionsByMap(
    map: MCalendarGetSessions.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarEntrySessionDto>>;
  public mCalendarGetSessionsByMap(
    map: MCalendarGetSessions.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntrySessionDto>>>;
  public mCalendarGetSessionsByMap(
    map: MCalendarGetSessions.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntrySessionDto>>>;
  public mCalendarGetSessionsByMap(
    map: MCalendarGetSessions.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarGetSessions(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarGetSessions(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarEntrySessionDto>>;
    public mCalendarGetSessions(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntrySessionDto>>>;
    public mCalendarGetSessions(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntrySessionDto>>>;
    public mCalendarGetSessions(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarGetSessions.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarEntrySessionDto>>(`${this.configuration.basePath}/api/MCalendar/sessions/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_GetSessions']) {
                this.cancelMap['MCalendar_GetSessions'].next(null);
            }
            this.cancelMap['MCalendar_GetSessions'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_GetSessions']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_GetSessions']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_GetSessions', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_GetSessions']) {
            this.cancelMap['MCalendar_GetSessions'].complete();
            delete this.cancelMap['MCalendar_GetSessions'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_GetSessions',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_GetSessions')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarOpenSessionByMap(
    map: MCalendarOpenSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mCalendarOpenSessionByMap(
    map: MCalendarOpenSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mCalendarOpenSessionByMap(
    map: MCalendarOpenSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mCalendarOpenSessionByMap(
    map: MCalendarOpenSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarOpenSession(
      map.id,
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarOpenSession(id: number, expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mCalendarOpenSession(id: number, expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mCalendarOpenSession(id: number, expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mCalendarOpenSession(id: number, expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mCalendarOpenSession.');
        }
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarOpenSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MCalendar/sessionopen/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_OpenSession']) {
                this.cancelMap['MCalendar_OpenSession'].next(null);
            }
            this.cancelMap['MCalendar_OpenSession'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_OpenSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_OpenSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_OpenSession', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_OpenSession']) {
            this.cancelMap['MCalendar_OpenSession'].complete();
            delete this.cancelMap['MCalendar_OpenSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_OpenSession',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_OpenSession')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarPostSessionByMap(
    map: MCalendarPostSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntrySessionDto>;
  public mCalendarPostSessionByMap(
    map: MCalendarPostSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntrySessionDto>>;
  public mCalendarPostSessionByMap(
    map: MCalendarPostSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntrySessionDto>>;
  public mCalendarPostSessionByMap(
    map: MCalendarPostSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarPostSession(
      map.expoId,
      map.CalendarEntrySessionDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param CalendarEntrySessionDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarPostSession(expoId: string, CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntrySessionDto>;
    public mCalendarPostSession(expoId: string, CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntrySessionDto>>;
    public mCalendarPostSession(expoId: string, CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntrySessionDto>>;
    public mCalendarPostSession(expoId: string, CalendarEntrySessionDto: CalendarEntrySessionDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarPostSession.');
        }
        if (CalendarEntrySessionDto === null || CalendarEntrySessionDto === undefined) {
            throw new Error('Required parameter CalendarEntrySessionDto was null or undefined when calling mCalendarPostSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntrySessionDto>(`${this.configuration.basePath}/api/MCalendar/sessions/${encodeURIComponent(String(expoId))}`,
            CalendarEntrySessionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_PostSession']) {
                this.cancelMap['MCalendar_PostSession'].next(null);
            }
            this.cancelMap['MCalendar_PostSession'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_PostSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_PostSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_PostSession', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_PostSession']) {
            this.cancelMap['MCalendar_PostSession'].complete();
            delete this.cancelMap['MCalendar_PostSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_PostSession',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_PostSession')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarStageNotifyByMap(
    map: MCalendarStageNotify.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mCalendarStageNotifyByMap(
    map: MCalendarStageNotify.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mCalendarStageNotifyByMap(
    map: MCalendarStageNotify.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mCalendarStageNotifyByMap(
    map: MCalendarStageNotify.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarStageNotify(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarStageNotify(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mCalendarStageNotify(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mCalendarStageNotify(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mCalendarStageNotify(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarStageNotify.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarStageNotify.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mCalendarStageNotify.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/notify/${encodeURIComponent(String(entryId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_StageNotify']) {
                this.cancelMap['MCalendar_StageNotify'].next(null);
            }
            this.cancelMap['MCalendar_StageNotify'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_StageNotify']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_StageNotify']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_StageNotify', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_StageNotify']) {
            this.cancelMap['MCalendar_StageNotify'].complete();
            delete this.cancelMap['MCalendar_StageNotify'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_StageNotify',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_StageNotify')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mCalendarStageNotifyMessageByMap(
    map: MCalendarStageNotifyMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mCalendarStageNotifyMessageByMap(
    map: MCalendarStageNotifyMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mCalendarStageNotifyMessageByMap(
    map: MCalendarStageNotifyMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mCalendarStageNotifyMessageByMap(
    map: MCalendarStageNotifyMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mCalendarStageNotifyMessage(
      map.expoId,
      map.stageId,
      map.NotificationMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param NotificationMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mCalendarStageNotifyMessage(expoId: string, stageId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mCalendarStageNotifyMessage(expoId: string, stageId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mCalendarStageNotifyMessage(expoId: string, stageId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mCalendarStageNotifyMessage(expoId: string, stageId: string, NotificationMessageDto: NotificationMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mCalendarStageNotifyMessage.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mCalendarStageNotifyMessage.');
        }
        if (NotificationMessageDto === null || NotificationMessageDto === undefined) {
            throw new Error('Required parameter NotificationMessageDto was null or undefined when calling mCalendarStageNotifyMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MCalendar/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(stageId))}/notify`,
            NotificationMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MCalendar_StageNotifyMessage']) {
                this.cancelMap['MCalendar_StageNotifyMessage'].next(null);
            }
            this.cancelMap['MCalendar_StageNotifyMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MCalendar_StageNotifyMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MCalendar_StageNotifyMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MCalendar_StageNotifyMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MCalendar_StageNotifyMessage']) {
            this.cancelMap['MCalendar_StageNotifyMessage'].complete();
            delete this.cancelMap['MCalendar_StageNotifyMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MCalendar_StageNotifyMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MCalendar_StageNotifyMessage')));
        }
        return handle;
    }

  }

export namespace MCalendarService {
  export enum Operations {
    MCalendar_CalendarActivate = 'MCalendar_CalendarActivate',
    MCalendar_CalendarDeactivate = 'MCalendar_CalendarDeactivate',
    MCalendar_CalendarDelete = 'MCalendar_CalendarDelete',
    MCalendar_CalendarGet = 'MCalendar_CalendarGet',
    MCalendar_CalendarGetAll = 'MCalendar_CalendarGetAll',
    MCalendar_CalendarPost = 'MCalendar_CalendarPost',
    MCalendar_CalendarUpdateStream = 'MCalendar_CalendarUpdateStream',
    MCalendar_DeleteSession = 'MCalendar_DeleteSession',
    MCalendar_GetCalendarExpo = 'MCalendar_GetCalendarExpo',
    MCalendar_GetSessions = 'MCalendar_GetSessions',
    MCalendar_OpenSession = 'MCalendar_OpenSession',
    MCalendar_PostSession = 'MCalendar_PostSession',
    MCalendar_StageNotify = 'MCalendar_StageNotify',
    MCalendar_StageNotifyMessage = 'MCalendar_StageNotifyMessage'
  }
}
