/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MLinkEditDto } from './mLinkEditDto';


export interface MLinkListUpdateResult { 
    rowVersion?: number;
    links?: Array<MLinkEditDto>;
}

/**
 * Namespace for property- and property-value-enumerations of MLinkListUpdateResult.
 */
export namespace MLinkListUpdateResult {
    /**
     * All properties of MLinkListUpdateResult.
     */
    export enum Properties {
        rowVersion = 'rowVersion',
        links = 'links'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MLinkListUpdateResult.
    */
    export const ModelValidators: {[K in keyof MLinkListUpdateResult]: [string, ValidatorFn][]} = {
        rowVersion: [
        ],
        links: [
        ],
    };

    /**
    * The FormControlFactory for MLinkListUpdateResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MLinkListUpdateResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MLinkListUpdateResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MLinkListUpdateResult = {
            rowVersion: null,
            links: null,
          }
        ) {
            super(model, MLinkListUpdateResult.ModelValidators);
        }
    }

}


