/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { TouchArea } from './touchArea';


export interface RenderResponse { 
    code?: number;
    positions?: { [key: string]: { [key: string]: TouchArea; }; };
    img?: string;
}

/**
 * Namespace for property- and property-value-enumerations of RenderResponse.
 */
export namespace RenderResponse {
    /**
     * All properties of RenderResponse.
     */
    export enum Properties {
        code = 'code',
        positions = 'positions',
        img = 'img'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of RenderResponse.
    */
    export const ModelValidators: {[K in keyof RenderResponse]: [string, ValidatorFn][]} = {
        code: [
        ],
        positions: [
        ],
        img: [
        ],
    };

    /**
    * The FormControlFactory for RenderResponse.
    */
    export class FormControlFactory extends BaseFormControlFactory<RenderResponse> {

        /**
         * Constructor.
         *
         * @param model An existing model for RenderResponse.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: RenderResponse = {
            code: null,
            positions: null,
            img: null,
          }
        ) {
            super(model, RenderResponse.ModelValidators);
        }
    }

}


