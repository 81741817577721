<ng-container *ngIf="!submitted">
  <h2 class="text-center pv">User profile</h2>
  <p *ngIf="!registered">
    Please fill out the following form to complete your registration:
  </p>
  <p *ngIf="registered">
    Please fill out to change your displayed name:
  </p>
  <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
        (submit)="submitForm($event, valForm.value)">
    <app-form-string-input formControlName="name" [formGroup]="valForm"
                           label="Display name" i18n-label
                           placeholder="Your chosen display name" i18n-placeholder
                           >
    </app-form-string-input>
    <div class="clearfix" *ngIf="errorMessage && errorMessage.length > 0">
      <ul>
        <li class="text-danger" *ngFor="let error of errorMessage">{{ error.description }}</li>
      </ul>
    </div>
    <button class="btn btn-block btn-primary mt-lg" type="submit">Update</button>
  </form>
</ng-container>
<ng-container *ngIf="submitted">
  <h2 class="text-center pv">Thank you.</h2>
  <p>
    I will now take you to the management system.
  </p>
</ng-container>
