/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ActionDefinition } from '../model/actionDefinition';
import { JitsiRoomSettings } from '../model/jitsiRoomSettings';
import { JoinExpoDto } from '../model/joinExpoDto';
import { MExpoResultDto } from '../model/mExpoResultDto';
import { MExpoState } from '../model/mExpoState';
import { ProblemDetails } from '../model/problemDetails';
import { VHost } from '../model/vHost';
import { YoutubeChannel } from '../model/youtubeChannel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mWebAction.
 */
export namespace MWebAction {
    /**
     * Parameter map for mWebAction.
     */
    export interface PartialParamMap {
      ActionDefinition: ActionDefinition;
    }

    /**
     * Enumeration of all parameters for mWebAction.
     */
    export enum Parameters {
      ActionDefinition = 'ActionDefinition'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebAction
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebAction.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mWebCheck.
 */
export namespace MWebCheck {
    /**
     * Parameter map for mWebCheck.
     */
    export interface PartialParamMap {
      url?: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for mWebCheck.
     */
    export enum Parameters {
      url = 'url',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebCheck
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebCheck.PartialParamMap]?: [string, ValidatorFn][]} = {
      url: [
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for mWebDownload.
 */
export namespace MWebDownload {
    /**
     * Parameter map for mWebDownload.
     */
    export interface PartialParamMap {
      expoShortKey: string;
      exhibitorShortKey: string;
      displayId: string;
    }

    /**
     * Enumeration of all parameters for mWebDownload.
     */
    export enum Parameters {
      expoShortKey = 'expoShortKey',
      exhibitorShortKey = 'exhibitorShortKey',
      displayId = 'displayId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebDownload
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebDownload.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoShortKey: [
              ['required', Validators.required],
      ],
      exhibitorShortKey: [
              ['required', Validators.required],
      ],
      displayId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebExpoJoin.
 */
export namespace MWebExpoJoin {
    /**
     * Parameter map for mWebExpoJoin.
     */
    export interface PartialParamMap {
      shortKey: string;
      JoinExpoDto: JoinExpoDto;
    }

    /**
     * Enumeration of all parameters for mWebExpoJoin.
     */
    export enum Parameters {
      shortKey = 'shortKey',
      JoinExpoDto = 'JoinExpoDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebExpoJoin
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebExpoJoin.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKey: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebGet.
 */
export namespace MWebGet {
    /**
     * Parameter map for mWebGet.
     */
    export interface PartialParamMap {
      shortKey: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for mWebGet.
     */
    export enum Parameters {
      shortKey = 'shortKey',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKey: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for mWebGetExpoSitemap.
 */
export namespace MWebGetExpoSitemap {
    /**
     * Parameter map for mWebGetExpoSitemap.
     */
    export interface PartialParamMap {
      expoShortKey: string;
    }

    /**
     * Enumeration of all parameters for mWebGetExpoSitemap.
     */
    export enum Parameters {
      expoShortKey = 'expoShortKey'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebGetExpoSitemap
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebGetExpoSitemap.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoShortKey: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebGetJitsiToken.
 */
export namespace MWebGetJitsiToken {
    /**
     * Parameter map for mWebGetJitsiToken.
     */
    export interface PartialParamMap {
      exhibitorShortKey: string;
      expoShortKey?: string;
    }

    /**
     * Enumeration of all parameters for mWebGetJitsiToken.
     */
    export enum Parameters {
      exhibitorShortKey = 'exhibitorShortKey',
      expoShortKey = 'expoShortKey'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebGetJitsiToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebGetJitsiToken.PartialParamMap]?: [string, ValidatorFn][]} = {
      exhibitorShortKey: [
              ['required', Validators.required],
      ],
      expoShortKey: [
      ],
    };
}

/**
 * Namespace for mWebGetYoutubePlaylist.
 */
export namespace MWebGetYoutubePlaylist {
    /**
     * Parameter map for mWebGetYoutubePlaylist.
     */
    export interface PartialParamMap {
      id: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for mWebGetYoutubePlaylist.
     */
    export enum Parameters {
      id = 'id',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebGetYoutubePlaylist
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebGetYoutubePlaylist.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for mWebWebexBoothClient.
 */
export namespace MWebWebexBoothClient {
    /**
     * Parameter map for mWebWebexBoothClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for mWebWebexBoothClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebWebexBoothClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebWebexBoothClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebWebexBoothSipClient.
 */
export namespace MWebWebexBoothSipClient {
    /**
     * Parameter map for mWebWebexBoothSipClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for mWebWebexBoothSipClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebWebexBoothSipClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebWebexBoothSipClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebWebexClient.
 */
export namespace MWebWebexClient {
    /**
     * Parameter map for mWebWebexClient.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mWebWebexClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebWebexClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebWebexClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebZoomBoothClient.
 */
export namespace MWebZoomBoothClient {
    /**
     * Parameter map for mWebZoomBoothClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mWebZoomBoothClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebZoomBoothClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebZoomBoothClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mWebZoomClient.
 */
export namespace MWebZoomClient {
    /**
     * Parameter map for mWebZoomClient.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mWebZoomClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mWebZoomClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MWebZoomClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MWebService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebActionByMap(
    map: MWebAction.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mWebActionByMap(
    map: MWebAction.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mWebActionByMap(
    map: MWebAction.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mWebActionByMap(
    map: MWebAction.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebAction(
      map.ActionDefinition,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ActionDefinition 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebAction(ActionDefinition: ActionDefinition, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mWebAction(ActionDefinition: ActionDefinition, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mWebAction(ActionDefinition: ActionDefinition, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mWebAction(ActionDefinition: ActionDefinition, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ActionDefinition === null || ActionDefinition === undefined) {
            throw new Error('Required parameter ActionDefinition was null or undefined when calling mWebAction.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MWeb/action`,
            ActionDefinition,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_Action']) {
                this.cancelMap['MWeb_Action'].next(null);
            }
            this.cancelMap['MWeb_Action'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_Action']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_Action']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_Action', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_Action']) {
            this.cancelMap['MWeb_Action'].complete();
            delete this.cancelMap['MWeb_Action'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_Action',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_Action')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebCheckByMap(
    map: MWebCheck.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<VHost>;
  public mWebCheckByMap(
    map: MWebCheck.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<VHost>>;
  public mWebCheckByMap(
    map: MWebCheck.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<VHost>>;
  public mWebCheckByMap(
    map: MWebCheck.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebCheck(
      map.url,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param url 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebCheck(url?: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<VHost>;
    public mWebCheck(url?: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<VHost>>;
    public mWebCheck(url?: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<VHost>>;
    public mWebCheck(url?: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<VHost>(`${this.configuration.basePath}/api/MWeb/check`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_Check']) {
                this.cancelMap['MWeb_Check'].next(null);
            }
            this.cancelMap['MWeb_Check'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_Check']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_Check']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_Check', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_Check']) {
            this.cancelMap['MWeb_Check'].complete();
            delete this.cancelMap['MWeb_Check'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_Check',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_Check')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebDownloadByMap(
    map: MWebDownload.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mWebDownloadByMap(
    map: MWebDownload.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mWebDownloadByMap(
    map: MWebDownload.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mWebDownloadByMap(
    map: MWebDownload.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebDownload(
      map.expoShortKey,
      map.exhibitorShortKey,
      map.displayId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoShortKey 
     * @param exhibitorShortKey 
     * @param displayId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebDownload(expoShortKey: string, exhibitorShortKey: string, displayId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mWebDownload(expoShortKey: string, exhibitorShortKey: string, displayId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mWebDownload(expoShortKey: string, exhibitorShortKey: string, displayId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mWebDownload(expoShortKey: string, exhibitorShortKey: string, displayId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoShortKey === null || expoShortKey === undefined) {
            throw new Error('Required parameter expoShortKey was null or undefined when calling mWebDownload.');
        }
        if (exhibitorShortKey === null || exhibitorShortKey === undefined) {
            throw new Error('Required parameter exhibitorShortKey was null or undefined when calling mWebDownload.');
        }
        if (displayId === null || displayId === undefined) {
            throw new Error('Required parameter displayId was null or undefined when calling mWebDownload.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MWeb/pdf/${encodeURIComponent(String(expoShortKey))}/${encodeURIComponent(String(exhibitorShortKey))}/${encodeURIComponent(String(displayId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_Download']) {
                this.cancelMap['MWeb_Download'].next(null);
            }
            this.cancelMap['MWeb_Download'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_Download']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_Download']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_Download', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_Download']) {
            this.cancelMap['MWeb_Download'].complete();
            delete this.cancelMap['MWeb_Download'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_Download',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_Download')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebExpoJoinByMap(
    map: MWebExpoJoin.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoState>;
  public mWebExpoJoinByMap(
    map: MWebExpoJoin.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoState>>;
  public mWebExpoJoinByMap(
    map: MWebExpoJoin.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoState>>;
  public mWebExpoJoinByMap(
    map: MWebExpoJoin.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebExpoJoin(
      map.shortKey,
      map.JoinExpoDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param shortKey 
     * @param JoinExpoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebExpoJoin(shortKey: string, JoinExpoDto: JoinExpoDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoState>;
    public mWebExpoJoin(shortKey: string, JoinExpoDto: JoinExpoDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoState>>;
    public mWebExpoJoin(shortKey: string, JoinExpoDto: JoinExpoDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoState>>;
    public mWebExpoJoin(shortKey: string, JoinExpoDto: JoinExpoDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKey === null || shortKey === undefined) {
            throw new Error('Required parameter shortKey was null or undefined when calling mWebExpoJoin.');
        }
        if (JoinExpoDto === null || JoinExpoDto === undefined) {
            throw new Error('Required parameter JoinExpoDto was null or undefined when calling mWebExpoJoin.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExpoState>(`${this.configuration.basePath}/api/MWeb/expo/${encodeURIComponent(String(shortKey))}/state`,
            JoinExpoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_ExpoJoin']) {
                this.cancelMap['MWeb_ExpoJoin'].next(null);
            }
            this.cancelMap['MWeb_ExpoJoin'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_ExpoJoin']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_ExpoJoin']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_ExpoJoin', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_ExpoJoin']) {
            this.cancelMap['MWeb_ExpoJoin'].complete();
            delete this.cancelMap['MWeb_ExpoJoin'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_ExpoJoin',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_ExpoJoin')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebGetByMap(
    map: MWebGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExpoResultDto>;
  public mWebGetByMap(
    map: MWebGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResultDto>>;
  public mWebGetByMap(
    map: MWebGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResultDto>>;
  public mWebGetByMap(
    map: MWebGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebGet(
      map.shortKey,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param shortKey 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebGet(shortKey: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExpoResultDto>;
    public mWebGet(shortKey: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExpoResultDto>>;
    public mWebGet(shortKey: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExpoResultDto>>;
    public mWebGet(shortKey: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKey === null || shortKey === undefined) {
            throw new Error('Required parameter shortKey was null or undefined when calling mWebGet.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExpoResultDto>(`${this.configuration.basePath}/api/MWeb/expo/${encodeURIComponent(String(shortKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_Get']) {
                this.cancelMap['MWeb_Get'].next(null);
            }
            this.cancelMap['MWeb_Get'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_Get']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_Get']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_Get', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_Get']) {
            this.cancelMap['MWeb_Get'].complete();
            delete this.cancelMap['MWeb_Get'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_Get',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_Get')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebGetExpoSitemapByMap(
    map: MWebGetExpoSitemap.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebGetExpoSitemapByMap(
    map: MWebGetExpoSitemap.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebGetExpoSitemapByMap(
    map: MWebGetExpoSitemap.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebGetExpoSitemapByMap(
    map: MWebGetExpoSitemap.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebGetExpoSitemap(
      map.expoShortKey,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoShortKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebGetExpoSitemap(expoShortKey: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebGetExpoSitemap(expoShortKey: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebGetExpoSitemap(expoShortKey: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebGetExpoSitemap(expoShortKey: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoShortKey === null || expoShortKey === undefined) {
            throw new Error('Required parameter expoShortKey was null or undefined when calling mWebGetExpoSitemap.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/expoSitemap/${encodeURIComponent(String(expoShortKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_GetExpoSitemap']) {
                this.cancelMap['MWeb_GetExpoSitemap'].next(null);
            }
            this.cancelMap['MWeb_GetExpoSitemap'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_GetExpoSitemap']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_GetExpoSitemap']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_GetExpoSitemap', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_GetExpoSitemap']) {
            this.cancelMap['MWeb_GetExpoSitemap'].complete();
            delete this.cancelMap['MWeb_GetExpoSitemap'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_GetExpoSitemap',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_GetExpoSitemap')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebGetJitsiTokenByMap(
    map: MWebGetJitsiToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<JitsiRoomSettings>;
  public mWebGetJitsiTokenByMap(
    map: MWebGetJitsiToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<JitsiRoomSettings>>;
  public mWebGetJitsiTokenByMap(
    map: MWebGetJitsiToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<JitsiRoomSettings>>;
  public mWebGetJitsiTokenByMap(
    map: MWebGetJitsiToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebGetJitsiToken(
      map.exhibitorShortKey,
      map.expoShortKey,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param exhibitorShortKey 
     * @param expoShortKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebGetJitsiToken(exhibitorShortKey: string, expoShortKey?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<JitsiRoomSettings>;
    public mWebGetJitsiToken(exhibitorShortKey: string, expoShortKey?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<JitsiRoomSettings>>;
    public mWebGetJitsiToken(exhibitorShortKey: string, expoShortKey?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<JitsiRoomSettings>>;
    public mWebGetJitsiToken(exhibitorShortKey: string, expoShortKey?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (exhibitorShortKey === null || exhibitorShortKey === undefined) {
            throw new Error('Required parameter exhibitorShortKey was null or undefined when calling mWebGetJitsiToken.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (expoShortKey !== undefined && expoShortKey !== null) {
            queryParameters = queryParameters.set('expoShortKey', <any>expoShortKey);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<JitsiRoomSettings>(`${this.configuration.basePath}/api/MWeb/jitsi/${encodeURIComponent(String(exhibitorShortKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_GetJitsiToken']) {
                this.cancelMap['MWeb_GetJitsiToken'].next(null);
            }
            this.cancelMap['MWeb_GetJitsiToken'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_GetJitsiToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_GetJitsiToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_GetJitsiToken', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_GetJitsiToken']) {
            this.cancelMap['MWeb_GetJitsiToken'].complete();
            delete this.cancelMap['MWeb_GetJitsiToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_GetJitsiToken',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_GetJitsiToken')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebGetYoutubePlaylistByMap(
    map: MWebGetYoutubePlaylist.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
  public mWebGetYoutubePlaylistByMap(
    map: MWebGetYoutubePlaylist.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
  public mWebGetYoutubePlaylistByMap(
    map: MWebGetYoutubePlaylist.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
  public mWebGetYoutubePlaylistByMap(
    map: MWebGetYoutubePlaylist.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebGetYoutubePlaylist(
      map.id,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
    public mWebGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
    public mWebGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
    public mWebGetYoutubePlaylist(id: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mWebGetYoutubePlaylist.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<YoutubeChannel>(`${this.configuration.basePath}/api/MWeb/playlist/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_GetYoutubePlaylist']) {
                this.cancelMap['MWeb_GetYoutubePlaylist'].next(null);
            }
            this.cancelMap['MWeb_GetYoutubePlaylist'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_GetYoutubePlaylist']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_GetYoutubePlaylist']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_GetYoutubePlaylist', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_GetYoutubePlaylist']) {
            this.cancelMap['MWeb_GetYoutubePlaylist'].complete();
            delete this.cancelMap['MWeb_GetYoutubePlaylist'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_GetYoutubePlaylist',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_GetYoutubePlaylist')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebWebexBoothClientByMap(
    map: MWebWebexBoothClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebWebexBoothClientByMap(
    map: MWebWebexBoothClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebWebexBoothClientByMap(
    map: MWebWebexBoothClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebWebexBoothClientByMap(
    map: MWebWebexBoothClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebWebexBoothClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mWebWebexBoothClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mWebWebexBoothClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mWebWebexBoothClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/webex-booth/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_WebexBoothClient']) {
                this.cancelMap['MWeb_WebexBoothClient'].next(null);
            }
            this.cancelMap['MWeb_WebexBoothClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_WebexBoothClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_WebexBoothClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_WebexBoothClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_WebexBoothClient']) {
            this.cancelMap['MWeb_WebexBoothClient'].complete();
            delete this.cancelMap['MWeb_WebexBoothClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_WebexBoothClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_WebexBoothClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebWebexBoothSipClientByMap(
    map: MWebWebexBoothSipClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebWebexBoothSipClientByMap(
    map: MWebWebexBoothSipClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebWebexBoothSipClientByMap(
    map: MWebWebexBoothSipClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebWebexBoothSipClientByMap(
    map: MWebWebexBoothSipClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebWebexBoothSipClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mWebWebexBoothSipClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mWebWebexBoothSipClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mWebWebexBoothSipClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/webex-sip/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_WebexBoothSipClient']) {
                this.cancelMap['MWeb_WebexBoothSipClient'].next(null);
            }
            this.cancelMap['MWeb_WebexBoothSipClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_WebexBoothSipClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_WebexBoothSipClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_WebexBoothSipClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_WebexBoothSipClient']) {
            this.cancelMap['MWeb_WebexBoothSipClient'].complete();
            delete this.cancelMap['MWeb_WebexBoothSipClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_WebexBoothSipClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_WebexBoothSipClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebWebexClientByMap(
    map: MWebWebexClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebWebexClientByMap(
    map: MWebWebexClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebWebexClientByMap(
    map: MWebWebexClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebWebexClientByMap(
    map: MWebWebexClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebWebexClient(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebWebexClient(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebWebexClient(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebWebexClient(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebWebexClient(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mWebWebexClient.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mWebWebexClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mWebWebexClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/webex/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_WebexClient']) {
                this.cancelMap['MWeb_WebexClient'].next(null);
            }
            this.cancelMap['MWeb_WebexClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_WebexClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_WebexClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_WebexClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_WebexClient']) {
            this.cancelMap['MWeb_WebexClient'].complete();
            delete this.cancelMap['MWeb_WebexClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_WebexClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_WebexClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebZoomBoothClientByMap(
    map: MWebZoomBoothClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebZoomBoothClientByMap(
    map: MWebZoomBoothClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebZoomBoothClientByMap(
    map: MWebZoomBoothClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebZoomBoothClientByMap(
    map: MWebZoomBoothClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebZoomBoothClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebZoomBoothClient(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebZoomBoothClient(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebZoomBoothClient(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebZoomBoothClient(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mWebZoomBoothClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mWebZoomBoothClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mWebZoomBoothClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/zoom-booth/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_ZoomBoothClient']) {
                this.cancelMap['MWeb_ZoomBoothClient'].next(null);
            }
            this.cancelMap['MWeb_ZoomBoothClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_ZoomBoothClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_ZoomBoothClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_ZoomBoothClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_ZoomBoothClient']) {
            this.cancelMap['MWeb_ZoomBoothClient'].complete();
            delete this.cancelMap['MWeb_ZoomBoothClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_ZoomBoothClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_ZoomBoothClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mWebZoomClientByMap(
    map: MWebZoomClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mWebZoomClientByMap(
    map: MWebZoomClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mWebZoomClientByMap(
    map: MWebZoomClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mWebZoomClientByMap(
    map: MWebZoomClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mWebZoomClient(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mWebZoomClient(expoId: string, stageId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mWebZoomClient(expoId: string, stageId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mWebZoomClient(expoId: string, stageId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mWebZoomClient(expoId: string, stageId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mWebZoomClient.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling mWebZoomClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mWebZoomClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MWeb/zoom/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MWeb_ZoomClient']) {
                this.cancelMap['MWeb_ZoomClient'].next(null);
            }
            this.cancelMap['MWeb_ZoomClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MWeb_ZoomClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MWeb_ZoomClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MWeb_ZoomClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MWeb_ZoomClient']) {
            this.cancelMap['MWeb_ZoomClient'].complete();
            delete this.cancelMap['MWeb_ZoomClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MWeb_ZoomClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MWeb_ZoomClient')));
        }
        return handle;
    }

  }

export namespace MWebService {
  export enum Operations {
    MWeb_Action = 'MWeb_Action',
    MWeb_Check = 'MWeb_Check',
    MWeb_Download = 'MWeb_Download',
    MWeb_ExpoJoin = 'MWeb_ExpoJoin',
    MWeb_Get = 'MWeb_Get',
    MWeb_GetExpoSitemap = 'MWeb_GetExpoSitemap',
    MWeb_GetJitsiToken = 'MWeb_GetJitsiToken',
    MWeb_GetYoutubePlaylist = 'MWeb_GetYoutubePlaylist',
    MWeb_WebexBoothClient = 'MWeb_WebexBoothClient',
    MWeb_WebexBoothSipClient = 'MWeb_WebexBoothSipClient',
    MWeb_WebexClient = 'MWeb_WebexClient',
    MWeb_ZoomBoothClient = 'MWeb_ZoomBoothClient',
    MWeb_ZoomClient = 'MWeb_ZoomClient'
  }
}
