import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule} from '@angular/router';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages/pages.module';

import {menu} from './menu';
import {routes} from './routes';
import {SecurityModule} from '../modules/security/security.module';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
  // ...any other options you'd like to use
};

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, routerOptions),
    PagesModule
  ],
  declarations: [],
  exports: []
})

export class RoutesModule {
  constructor(public menuService: MenuService) {
    menuService.addMenu(menu);
  }
}
