/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CategoryDto { 
    id?: number;
    name?: string;
    description?: string;
    descriptionLong?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    sequence?: number;
    sizes?: string;
    displayMax?: number;
    linkMax?: number;
    infoSlideMax?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    calendarEnabled?: boolean;
    liveStreamEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    boothPresentationEnabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of CategoryDto.
 */
export namespace CategoryDto {
    /**
     * All properties of CategoryDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        sequence = 'sequence',
        sizes = 'sizes',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        infoSlideMax = 'infoSlideMax',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        calendarEnabled = 'calendarEnabled',
        liveStreamEnabled = 'liveStreamEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        boothPresentationEnabled = 'boothPresentationEnabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CategoryDto.
    */
    export const ModelValidators: {[K in keyof CategoryDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        sequence: [
        ],
        sizes: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        infoSlideMax: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        calendarEnabled: [
        ],
        liveStreamEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        boothPresentationEnabled: [
        ],
    };

    /**
    * The FormControlFactory for CategoryDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CategoryDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CategoryDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CategoryDto = {
            id: null,
            name: null,
            description: null,
            descriptionLong: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            sequence: null,
            sizes: null,
            displayMax: null,
            linkMax: null,
            infoSlideMax: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            calendarEnabled: null,
            liveStreamEnabled: null,
            tagsExhibitorEditable: null,
            boothPresentationEnabled: null,
          }
        ) {
            super(model, CategoryDto.ModelValidators);
        }
    }

}


