/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { LoginDto } from '../model/loginDto';
import { MUserProfileDto } from '../model/mUserProfileDto';
import { MUserProfileEditDto } from '../model/mUserProfileEditDto';
import { PasswordSetDto } from '../model/passwordSetDto';
import { ProblemDetails } from '../model/problemDetails';
import { PwdLessCallbackDto } from '../model/pwdLessCallbackDto';
import { PwdLessLoginDto } from '../model/pwdLessLoginDto';
import { RequestResult } from '../model/requestResult';
import { TokenRefreshDto } from '../model/tokenRefreshDto';
import { TokenResultDto } from '../model/tokenResultDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for accountEmailCallback.
 */
export namespace AccountEmailCallback {
    /**
     * Parameter map for accountEmailCallback.
     */
    export interface PartialParamMap {
      PwdLessCallbackDto: PwdLessCallbackDto;
    }

    /**
     * Enumeration of all parameters for accountEmailCallback.
     */
    export enum Parameters {
      PwdLessCallbackDto = 'PwdLessCallbackDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountEmailCallback
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountEmailCallback.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountEmailLogin.
 */
export namespace AccountEmailLogin {
    /**
     * Parameter map for accountEmailLogin.
     */
    export interface PartialParamMap {
      PwdLessLoginDto: PwdLessLoginDto;
    }

    /**
     * Enumeration of all parameters for accountEmailLogin.
     */
    export enum Parameters {
      PwdLessLoginDto = 'PwdLessLoginDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountEmailLogin
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountEmailLogin.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountLogin.
 */
export namespace AccountLogin {
    /**
     * Parameter map for accountLogin.
     */
    export interface PartialParamMap {
      LoginDto: LoginDto;
    }

    /**
     * Enumeration of all parameters for accountLogin.
     */
    export enum Parameters {
      LoginDto = 'LoginDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountLogin
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountLogin.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountProfileGetOwn.
 */
export namespace AccountProfileGetOwn {
    /**
     * Parameter map for accountProfileGetOwn.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for accountProfileGetOwn.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountProfileGetOwn
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountProfileGetOwn.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountProfileGetUser.
 */
export namespace AccountProfileGetUser {
    /**
     * Parameter map for accountProfileGetUser.
     */
    export interface PartialParamMap {
      userId: string;
    }

    /**
     * Enumeration of all parameters for accountProfileGetUser.
     */
    export enum Parameters {
      userId = 'userId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountProfileGetUser
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountProfileGetUser.PartialParamMap]?: [string, ValidatorFn][]} = {
      userId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for accountProfileUpdate.
 */
export namespace AccountProfileUpdate {
    /**
     * Parameter map for accountProfileUpdate.
     */
    export interface PartialParamMap {
      MUserProfileEditDto: MUserProfileEditDto;
      termsAccepted?: boolean;
    }

    /**
     * Enumeration of all parameters for accountProfileUpdate.
     */
    export enum Parameters {
      MUserProfileEditDto = 'MUserProfileEditDto',
      termsAccepted = 'termsAccepted'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountProfileUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountProfileUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      termsAccepted: [
      ],
    };
}

/**
 * Namespace for accountRefresh.
 */
export namespace AccountRefresh {
    /**
     * Parameter map for accountRefresh.
     */
    export interface PartialParamMap {
      TokenRefreshDto: TokenRefreshDto;
    }

    /**
     * Enumeration of all parameters for accountRefresh.
     */
    export enum Parameters {
      TokenRefreshDto = 'TokenRefreshDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountRefresh
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountRefresh.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountSetPassword.
 */
export namespace AccountSetPassword {
    /**
     * Parameter map for accountSetPassword.
     */
    export interface PartialParamMap {
      PasswordSetDto: PasswordSetDto;
    }

    /**
     * Enumeration of all parameters for accountSetPassword.
     */
    export enum Parameters {
      PasswordSetDto = 'PasswordSetDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountSetPassword
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountSetPassword.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class AccountService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountEmailCallbackByMap(
    map: AccountEmailCallback.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
  public accountEmailCallbackByMap(
    map: AccountEmailCallback.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
  public accountEmailCallbackByMap(
    map: AccountEmailCallback.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
  public accountEmailCallbackByMap(
    map: AccountEmailCallback.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountEmailCallback(
      map.PwdLessCallbackDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessCallbackDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountEmailCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
    public accountEmailCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
    public accountEmailCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
    public accountEmailCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessCallbackDto === null || PwdLessCallbackDto === undefined) {
            throw new Error('Required parameter PwdLessCallbackDto was null or undefined when calling accountEmailCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<TokenResultDto>(`${this.configuration.basePath}/api/Account/EmailCallback`,
            PwdLessCallbackDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_EmailCallback']) {
                this.cancelMap['Account_EmailCallback'].next(null);
            }
            this.cancelMap['Account_EmailCallback'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_EmailCallback']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_EmailCallback']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_EmailCallback', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_EmailCallback']) {
            this.cancelMap['Account_EmailCallback'].complete();
            delete this.cancelMap['Account_EmailCallback'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_EmailCallback',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_EmailCallback')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountEmailLoginByMap(
    map: AccountEmailLogin.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public accountEmailLoginByMap(
    map: AccountEmailLogin.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public accountEmailLoginByMap(
    map: AccountEmailLogin.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public accountEmailLoginByMap(
    map: AccountEmailLogin.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountEmailLogin(
      map.PwdLessLoginDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessLoginDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountEmailLogin(PwdLessLoginDto: PwdLessLoginDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public accountEmailLogin(PwdLessLoginDto: PwdLessLoginDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public accountEmailLogin(PwdLessLoginDto: PwdLessLoginDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public accountEmailLogin(PwdLessLoginDto: PwdLessLoginDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessLoginDto === null || PwdLessLoginDto === undefined) {
            throw new Error('Required parameter PwdLessLoginDto was null or undefined when calling accountEmailLogin.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Account/Email`,
            PwdLessLoginDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_EmailLogin']) {
                this.cancelMap['Account_EmailLogin'].next(null);
            }
            this.cancelMap['Account_EmailLogin'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_EmailLogin']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_EmailLogin']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_EmailLogin', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_EmailLogin']) {
            this.cancelMap['Account_EmailLogin'].complete();
            delete this.cancelMap['Account_EmailLogin'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_EmailLogin',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_EmailLogin')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountLogin(
      map.LoginDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param LoginDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountLogin(LoginDto: LoginDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
    public accountLogin(LoginDto: LoginDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
    public accountLogin(LoginDto: LoginDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
    public accountLogin(LoginDto: LoginDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (LoginDto === null || LoginDto === undefined) {
            throw new Error('Required parameter LoginDto was null or undefined when calling accountLogin.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<TokenResultDto>(`${this.configuration.basePath}/api/Account/Login`,
            LoginDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_Login']) {
                this.cancelMap['Account_Login'].next(null);
            }
            this.cancelMap['Account_Login'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_Login']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_Login']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_Login', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_Login']) {
            this.cancelMap['Account_Login'].complete();
            delete this.cancelMap['Account_Login'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_Login',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_Login')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountProfileGetOwnByMap(
    map: AccountProfileGetOwn.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MUserProfileEditDto>;
  public accountProfileGetOwnByMap(
    map: AccountProfileGetOwn.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MUserProfileEditDto>>;
  public accountProfileGetOwnByMap(
    map: AccountProfileGetOwn.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MUserProfileEditDto>>;
  public accountProfileGetOwnByMap(
    map: AccountProfileGetOwn.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountProfileGetOwn(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountProfileGetOwn(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MUserProfileEditDto>;
    public accountProfileGetOwn(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MUserProfileEditDto>>;
    public accountProfileGetOwn(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MUserProfileEditDto>>;
    public accountProfileGetOwn(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MUserProfileEditDto>(`${this.configuration.basePath}/api/Account/Profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_ProfileGetOwn']) {
                this.cancelMap['Account_ProfileGetOwn'].next(null);
            }
            this.cancelMap['Account_ProfileGetOwn'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_ProfileGetOwn']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_ProfileGetOwn']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_ProfileGetOwn', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_ProfileGetOwn']) {
            this.cancelMap['Account_ProfileGetOwn'].complete();
            delete this.cancelMap['Account_ProfileGetOwn'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_ProfileGetOwn',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_ProfileGetOwn')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountProfileGetUserByMap(
    map: AccountProfileGetUser.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MUserProfileDto>;
  public accountProfileGetUserByMap(
    map: AccountProfileGetUser.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MUserProfileDto>>;
  public accountProfileGetUserByMap(
    map: AccountProfileGetUser.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MUserProfileDto>>;
  public accountProfileGetUserByMap(
    map: AccountProfileGetUser.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountProfileGetUser(
      map.userId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountProfileGetUser(userId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MUserProfileDto>;
    public accountProfileGetUser(userId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MUserProfileDto>>;
    public accountProfileGetUser(userId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MUserProfileDto>>;
    public accountProfileGetUser(userId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling accountProfileGetUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MUserProfileDto>(`${this.configuration.basePath}/api/Account/Profile/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_ProfileGetUser']) {
                this.cancelMap['Account_ProfileGetUser'].next(null);
            }
            this.cancelMap['Account_ProfileGetUser'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_ProfileGetUser']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_ProfileGetUser']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_ProfileGetUser', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_ProfileGetUser']) {
            this.cancelMap['Account_ProfileGetUser'].complete();
            delete this.cancelMap['Account_ProfileGetUser'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_ProfileGetUser',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_ProfileGetUser')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountProfileUpdateByMap(
    map: AccountProfileUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
  public accountProfileUpdateByMap(
    map: AccountProfileUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
  public accountProfileUpdateByMap(
    map: AccountProfileUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
  public accountProfileUpdateByMap(
    map: AccountProfileUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountProfileUpdate(
      map.MUserProfileEditDto,
      map.termsAccepted,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param MUserProfileEditDto 
     * @param termsAccepted 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountProfileUpdate(MUserProfileEditDto: MUserProfileEditDto, termsAccepted?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
    public accountProfileUpdate(MUserProfileEditDto: MUserProfileEditDto, termsAccepted?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
    public accountProfileUpdate(MUserProfileEditDto: MUserProfileEditDto, termsAccepted?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
    public accountProfileUpdate(MUserProfileEditDto: MUserProfileEditDto, termsAccepted?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (MUserProfileEditDto === null || MUserProfileEditDto === undefined) {
            throw new Error('Required parameter MUserProfileEditDto was null or undefined when calling accountProfileUpdate.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (termsAccepted !== undefined && termsAccepted !== null) {
            queryParameters = queryParameters.set('termsAccepted', <any>termsAccepted);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<TokenResultDto>(`${this.configuration.basePath}/api/Account/Profile`,
            MUserProfileEditDto,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_ProfileUpdate']) {
                this.cancelMap['Account_ProfileUpdate'].next(null);
            }
            this.cancelMap['Account_ProfileUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_ProfileUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_ProfileUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_ProfileUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_ProfileUpdate']) {
            this.cancelMap['Account_ProfileUpdate'].complete();
            delete this.cancelMap['Account_ProfileUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_ProfileUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_ProfileUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountRefresh(
      map.TokenRefreshDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param TokenRefreshDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountRefresh(TokenRefreshDto: TokenRefreshDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<TokenResultDto>;
    public accountRefresh(TokenRefreshDto: TokenRefreshDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<TokenResultDto>>;
    public accountRefresh(TokenRefreshDto: TokenRefreshDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<TokenResultDto>>;
    public accountRefresh(TokenRefreshDto: TokenRefreshDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TokenRefreshDto === null || TokenRefreshDto === undefined) {
            throw new Error('Required parameter TokenRefreshDto was null or undefined when calling accountRefresh.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<TokenResultDto>(`${this.configuration.basePath}/api/Account/Refresh`,
            TokenRefreshDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_Refresh']) {
                this.cancelMap['Account_Refresh'].next(null);
            }
            this.cancelMap['Account_Refresh'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_Refresh']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_Refresh']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_Refresh', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_Refresh']) {
            this.cancelMap['Account_Refresh'].complete();
            delete this.cancelMap['Account_Refresh'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_Refresh',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_Refresh')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RequestResult>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountSetPassword(
      map.PasswordSetDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PasswordSetDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RequestResult>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RequestResult>>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RequestResult>>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PasswordSetDto === null || PasswordSetDto === undefined) {
            throw new Error('Required parameter PasswordSetDto was null or undefined when calling accountSetPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<RequestResult>(`${this.configuration.basePath}/api/Account/SetPassword`,
            PasswordSetDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_SetPassword']) {
                this.cancelMap['Account_SetPassword'].next(null);
            }
            this.cancelMap['Account_SetPassword'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_SetPassword']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_SetPassword']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_SetPassword', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_SetPassword']) {
            this.cancelMap['Account_SetPassword'].complete();
            delete this.cancelMap['Account_SetPassword'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_SetPassword',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_SetPassword')));
        }
        return handle;
    }

  }

export namespace AccountService {
  export enum Operations {
    Account_EmailCallback = 'Account_EmailCallback',
    Account_EmailLogin = 'Account_EmailLogin',
    Account_Login = 'Account_Login',
    Account_ProfileGetOwn = 'Account_ProfileGetOwn',
    Account_ProfileGetUser = 'Account_ProfileGetUser',
    Account_ProfileUpdate = 'Account_ProfileUpdate',
    Account_Refresh = 'Account_Refresh',
    Account_SetPassword = 'Account_SetPassword'
  }
}
