import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../modules/security/services/auth.service';
import {TypedFormGroup} from '../../../ezfair-api/formgroup';
import {AccountService,  MUserProfileEditDto} from '../../../ezfair-api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  submitted: boolean;
  registered = false;

  profile: MUserProfileEditDto;

  valForm: TypedFormGroup<MUserProfileEditDto>;
  errorMessage: { description: string }[] = [];

  constructor(
    fb: FormBuilder
    , private router: Router
    , private auth: AuthService
  ) {
  }

  ngOnInit() {
    const formControlFactory = new MUserProfileEditDto.FormControlFactory(this.profile);
    this.valForm = new TypedFormGroup<MUserProfileEditDto>({
      name: formControlFactory.createFormControl<string>('name'),
      address: formControlFactory.createFormControl<string>('address'),
      company: formControlFactory.createFormControl<string>('company'),
      description: formControlFactory.createFormControl<string>('description'),
      role: formControlFactory.createFormControl<string>('role'),
      email: formControlFactory.createFormControl<string>('email'),
      phoneNumber: formControlFactory.createFormControl<string>('phoneNumber')
    });

    this.auth.getProfile()
      .then((value) => {
        this.profile = value;
        this.valForm.reset(this.profile);
      })

    // if (this.auth.data.name !== this.auth.data.userId) {
    //   this.valForm.reset({givenName: this.auth.data.name, termsAccepted: true});
    //   this.registered = true;
    // }
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (!this.valForm.value['termsAccepted']) {
      this.errorMessage.push({description: 'You have to accept our terms of service before using the management system'});
    } else {
      if (this.valForm.valid) {
        this.auth.updateProfile(this.valForm.value)
          .then(value1 => {
            this.submitted = true;
            this.router.navigate(['/']);
          }, error => {
            this.errorMessage = error;
            console.log(error);
          });
      }
    }
  }
}
