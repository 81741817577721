/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MDisplayEditDto } from './mDisplayEditDto';


export interface MDisplayUpdateResult { 
    rowVersion?: number;
    display?: MDisplayEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of MDisplayUpdateResult.
 */
export namespace MDisplayUpdateResult {
    /**
     * All properties of MDisplayUpdateResult.
     */
    export enum Properties {
        rowVersion = 'rowVersion',
        display = 'display'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MDisplayUpdateResult.
    */
    export const ModelValidators: {[K in keyof MDisplayUpdateResult]: [string, ValidatorFn][]} = {
        rowVersion: [
        ],
        display: [
        ],
    };

    /**
    * The FormControlFactory for MDisplayUpdateResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MDisplayUpdateResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MDisplayUpdateResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MDisplayUpdateResult = {
            rowVersion: null,
            display: null,
          }
        ) {
            super(model, MDisplayUpdateResult.ModelValidators);
        }
    }

}


