/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { AvailableSlotDto } from './availableSlotDto';


export interface AvailabilityDto { 
    rowId?: number;
    start?: Date;
    end?: Date;
    interval?: number;
    comment?: string;
    name?: string;
    slots?: Array<AvailableSlotDto>;
}

/**
 * Namespace for property- and property-value-enumerations of AvailabilityDto.
 */
export namespace AvailabilityDto {
    /**
     * All properties of AvailabilityDto.
     */
    export enum Properties {
        rowId = 'rowId',
        start = 'start',
        end = 'end',
        interval = 'interval',
        comment = 'comment',
        name = 'name',
        slots = 'slots'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AvailabilityDto.
    */
    export const ModelValidators: {[K in keyof AvailabilityDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        start: [
        ],
        end: [
        ],
        interval: [
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(5000)],
        ],
        name: [
        ],
        slots: [
        ],
    };

    /**
    * The FormControlFactory for AvailabilityDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<AvailabilityDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for AvailabilityDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AvailabilityDto = {
            rowId: null,
            start: null,
            end: null,
            interval: null,
            comment: null,
            name: null,
            slots: null,
          }
        ) {
            super(model, AvailabilityDto.ModelValidators);
        }
    }

}


