/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MExpoContactEditDto } from './mExpoContactEditDto';


export interface MExpoContactUpdateResult { 
    rowVersion?: number;
    contact?: MExpoContactEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoContactUpdateResult.
 */
export namespace MExpoContactUpdateResult {
    /**
     * All properties of MExpoContactUpdateResult.
     */
    export enum Properties {
        rowVersion = 'rowVersion',
        contact = 'contact'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoContactUpdateResult.
    */
    export const ModelValidators: {[K in keyof MExpoContactUpdateResult]: [string, ValidatorFn][]} = {
        rowVersion: [
        ],
        contact: [
        ],
    };

    /**
    * The FormControlFactory for MExpoContactUpdateResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoContactUpdateResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoContactUpdateResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoContactUpdateResult = {
            rowVersion: null,
            contact: null,
          }
        ) {
            super(model, MExpoContactUpdateResult.ModelValidators);
        }
    }

}


