import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges
} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ConfirmationDialogService} from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadFormComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() imageUrl: string;
  @Input() imageId: string;
  @Input() width: number;
  @Input() height: number;
  @Input() dynamic: boolean;
  @Input() allowClear: boolean;

  @Input() ar: string;

  @Input() tooltip: string;

  @Output() imageUploaded: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('paintCanvas', {static: true}) paintCanvas: ElementRef;
  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  instanceId = '';
  hasImage = false;

  glob: UploadFormComponent;

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  aspectStyle: SafeStyle;

  constructor(private confirmationDialogService: ConfirmationDialogService) {
    this.instanceId = '_' + Math.random().toString(36).substr(2, 9);
  }

  ngOnInit() {
    this.aspectStyle = '';
    if (this.ar && this.ar !== '') {
      this.aspectStyle = `--aspect-ratio:${this.ar};`;
    }
    if (!this.ctx) {
      if (this.paintCanvas && this.paintCanvas.nativeElement) {
        this.canvas = this.paintCanvas.nativeElement as HTMLCanvasElement;
        this.ctx = this.canvas.getContext('2d', {alpha: true});
      }
    }
  }

  ngOnChanges() {
    if (this.paintCanvas && this.paintCanvas.nativeElement) {
      if (!this.ctx) {
        this.canvas = this.paintCanvas.nativeElement as HTMLCanvasElement;
        this.ctx = this.canvas.getContext('2d', {alpha: true});
      }
      this.aspectStyle = '';
      if (this.ar && this.ar !== '') {
        this.aspectStyle = `--aspect-ratio:${this.ar};`;
      }
      this.ctx.globalAlpha = 1.0;
      if (this.imageId && this.imageId !== '' && this.imageId !== 'DELETED') {
        if (this.imageId.startsWith('data')) {
          this.imageUrl = this.imageId;
        } else {
          this.imageUrl = environment.cdnURL + this.imageId + '?ts=' + Date.now().toString();
        }
      } else {
        this.imageUrl = '';
      }
      if (this.imageUrl && this.imageUrl !== '') {
        const img = new Image;
        const self = this;

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        img.onload = function () {
          const scale = img.width / img.height;
          const glob: UploadFormComponent = self;
          self.canvas.height = glob.height;
          self.canvas.width = glob.width;

          if (glob.dynamic) {
            if (scale > 0) {
              const scale2 = img.width / glob.width;
              self.canvas.width = img.width / scale2;
              self.canvas.height = img.height / scale2;
            } else {
              const scale2 = img.height / glob.height;
              self.canvas.height = img.height / scale2;
              self.canvas.width = img.width / scale2;
            }
          }

          let offX = 0;
          let offY = 0;

          if (!glob.dynamic) {
            if (img.height > img.width) {
              offX = ((img.height - img.width) / 2) / img.height;
            } else {
              offY = ((img.width - img.height) / 2) / img.width;
            }
          }

          // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          self.ctx.globalAlpha = 0.0;
          self.ctx.fillRect(0, 0, self.canvas.width, self.canvas.height);
          self.ctx.globalAlpha = 1.0;
          self.ctx.drawImage(img, offX * self.canvas.width, offY * self.canvas.width, self.canvas.width * (1 - (2 * offX)), self.canvas.height * (1 - (2 * offY)));
          // self.ctx.drawImage(img, 0, 0, self.canvas.width, self.canvas.height); // Or at whatever offset you like
        };
        img.src = this.imageUrl;
      } else {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
    }
  }

  clear($event: MouseEvent) {
    $event.preventDefault();
    this.confirmationDialogService.confirm('Clear image', 'Really clear image?')
      .then(value => {
        if (value) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.imageUploaded.emit('DELETED');
        }
      }, reason => {
      });
  }

  getWidth() {
    let locFactor = 1;
    if (this.width > 256) {
      locFactor = this.width / 256;
    }
    if (this.height > this.width) {
      locFactor = this.height / 256;
    }
    return this.width / locFactor;
  }

  getHeight() {
    let locFactor = 1;
    if (this.width > 256) {
      locFactor = this.width / 256;
    }
    if (this.height > this.width) {
      locFactor = this.height / 256;
    }
    return this.height / locFactor;
  }

  startRead(evt) {
    if (this.fileInput && this.fileInput.nativeElement) {
      const file = this.fileInput.nativeElement.files[0];
      const glob: UploadFormComponent = this;
      if (file) {
        // if (file.size > environment.imageUploadSize) {
        //   alert(`Sorry, this file is too big. Max file size is ${this.formatBytes(environment.imageUploadSize)}`);
        // } else {
        if (file.type.match('image.*')) {
          this.getAsImage(file);
        } else {
          alert('Not an image');
        }
        // }
      }
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getAsImage(readFile) {
    const reader = new FileReader();
    reader.onload = (event) => {

      const glob = this;
      const img = new Image();
      img.onload = (event2) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scale = img.width / img.height;

        canvas.height = glob.height;
        canvas.width = glob.width;

        if (glob.dynamic) {
          if (scale > 0) {
            const scale2 = img.width / glob.width;
            canvas.width = img.width / scale2;
            canvas.height = img.height / scale2;
          } else {
            const scale2 = img.height / glob.height;
            canvas.height = img.height / scale2;
            canvas.width = img.width / scale2;
          }
        }

        let offX = 0;
        let offY = 0;

        if (!glob.dynamic) {
          if (img.height > img.width) {
            offX = ((img.height - img.width) / 2) / img.height;
          } else {
            offY = ((img.width - img.height) / 2) / img.width;
          }
        }

        // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 0.0;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 1.0;
        ctx.drawImage(img, offX * canvas.width, offY * canvas.width, canvas.width * (1 - (2 * offX)), canvas.height * (1 - (2 * offY)));
        const imageString = canvas.toDataURL('image/png', 0.95);
        this.imageUrl = imageString;

        glob.imageUploaded.emit(imageString);

        glob.canvas.width = canvas.width;
        glob.canvas.height = canvas.height;
        glob.ctx.clearRect(0, 0, canvas.width, canvas.height);
        glob.ctx.globalAlpha = 0.0;
        glob.ctx.fillStyle = 'blue';
        glob.ctx.fillRect(0, 0, canvas.width, canvas.height);
        glob.ctx.globalAlpha = 1.0;
        // glob.ctx.drawImage(img, 0, 0, glob.canvas.width, glob.canvas.height); // Or at whatever offset you like
        // glob.ctx.drawImage(img, offX, offY, canvas.width - offX - offX, canvas.height - offY - offY);
        glob.ctx.drawImage(img, offX * canvas.width, offY * canvas.width, canvas.width * (1 - (2 * offX)), canvas.height * (1 - (2 * offY)));
      };
      if (reader.result) {
        img.src = reader.result as string;
      }
    };
    reader.readAsDataURL(readFile);
  }
}
