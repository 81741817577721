import {Component, OnInit} from '@angular/core';
import {UserChatService} from '../../services/user-chat.service';

@Component({
  selector: 'app-user-chat-notification',
  templateUrl: './user-chat-notification.component.html',
  styleUrls: ['./user-chat-notification.component.scss']
})
export class UserChatNotificationComponent implements OnInit {

  constructor(
    private userChatService: UserChatService
  ) {
  }

  ngOnInit(): void {
  }

  unreadMessages(): number {
    let count = 0;
    this.userChatService.sessions.forEach((self) => {
      self.forEach((partnerId) => {
        if (partnerId.unreadMessages > 0) {
          count++;
        }
      });
    });
    // Object.keys(this.userChatService.sessions).forEach((selfId) => {
    //   Object.keys(this.userChatService.sessions[selfId]).forEach((partnerId) => {
    //     if (this.userChatService.sessions[selfId][partnerId].unreadMessages > 0) {
    //       count++;
    //     }
    //   });
    // });
    return count;
  }

  openChat($event: MouseEvent) {
    $event.preventDefault();
    this.userChatService.openChatWindow();
  }
}
