/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface SortUpdateDto { 
    expoId?: string;
    exhibitorId?: string;
    itemId?: string;
    direction?: number;
    newSequence?: number;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of SortUpdateDto.
 */
export namespace SortUpdateDto {
    /**
     * All properties of SortUpdateDto.
     */
    export enum Properties {
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        itemId = 'itemId',
        direction = 'direction',
        newSequence = 'newSequence',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of SortUpdateDto.
    */
    export const ModelValidators: {[K in keyof SortUpdateDto]: [string, ValidatorFn][]} = {
        expoId: [
        ],
        exhibitorId: [
        ],
        itemId: [
        ],
        direction: [
        ],
        newSequence: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for SortUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<SortUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for SortUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: SortUpdateDto = {
            expoId: null,
            exhibitorId: null,
            itemId: null,
            direction: null,
            newSequence: null,
            rowVersion: null,
          }
        ) {
            super(model, SortUpdateDto.ModelValidators);
        }
    }

}


