import {Component, OnInit, ViewChild} from '@angular/core';
import {UserChatService} from '../../services/user-chat.service';
import {MongoChatItemExtended} from '../../objects/mongo-chat-item.extended';


@Component({
  selector: 'app-user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.scss']
})
export class UserChatComponent implements OnInit {
  selectedSession: MongoChatItemExtended;
  windowMaximized: boolean;

  constructor(
    public userChatService: UserChatService
  ) { }

  ngOnInit(): void {
  }

  recountUnread() {
    this.userChatService.recountUnread();
  }

  chooseChat($event: MouseEvent, session: MongoChatItemExtended) {
    $event.preventDefault();
    this.selectedSession = session;
  }

  hideSession($event: MongoChatItemExtended) {
    this.selectedSession = null;
  }

  closeSession($event: MongoChatItemExtended) {
    this.selectedSession = null;
  }

  hasSessions(): boolean {
    return this.userChatService && this.userChatService.sessions && this.userChatService.sessions.size > 0;
  }

  close($event: MouseEvent) {
    $event.preventDefault();
    this.userChatService.showChatWindow = false;
  }

  maximize($event: MouseEvent) {
    $event.preventDefault();
    this.windowMaximized = true;
  }

  minimize($event: MouseEvent) {
    $event.preventDefault();
    this.windowMaximized = false;
  }
}
