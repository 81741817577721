import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MongoChatItemExtended} from '../../objects/mongo-chat-item.extended';
import {UserChatService} from '../../services/user-chat.service';
import {MongoChatMessage} from '../../../../ezfair-api';
import {VideoCallComponent} from '../video-call/video-call.component';
import {AuthService} from '../../../security/services/auth.service';
import {ConfirmationDialogService} from '../../../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-user-chat-session',
  templateUrl: './user-chat-session.component.html',
  styleUrls: ['./user-chat-session.component.scss']
})
export class UserChatSessionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() session: MongoChatItemExtended;
  @Output() unreadChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() hideSession: EventEmitter<MongoChatItemExtended> = new EventEmitter<MongoChatItemExtended>();
  @Output() closeSession: EventEmitter<MongoChatItemExtended> = new EventEmitter<MongoChatItemExtended>();

  message: string;

  @ViewChild('chatContentFrame', {static: false}) chatLog: ElementRef;

  readTimerUpdate = new Subject();
  callModal: NgbModalRef;
  private newMessageSubscription: Subscription;

  constructor(
    public userChatService: UserChatService
    , private authService: AuthService
    , private dialogService: ConfirmationDialogService
    , private ngbModalService: NgbModal
  ) {
    this.readTimerUpdate
      .pipe(debounceTime(5000))
      .subscribe(() => {
        if (this.session.unreadMessages > 0) {
          const lastMsg = this.session.chatMessages[this.session.chatMessages.length - 1];
          if (lastMsg.timestamp > this.session.timestamp) {
            this.userChatService.privateChatUpdateTimestamp(this.session.selfId, this.session.partnerId, lastMsg.timestamp);
          }
          this.session.unreadMessages = 0;
          this.unreadChanged.emit(this.session);
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.session.firstChange) {
      this.chatLog.nativeElement.innerHTML = '';
      this.refreshChatList();
    }

    if (this.newMessageSubscription) {
      this.newMessageSubscription.unsubscribe();
    }

    this.newMessageSubscription = this.session.newMessage.subscribe(value => {
      this.addLine(value);
    });

    if (this.session.totalMessages > this.session.chatMessages.length) {
      this.userChatService.GetBacklog(this.session.selfId, this.session.partnerId)
        .subscribe(item => {
          this.session.chatMessages.length = 0;
          this.session.unreadMessages = 0;
          this.chatLog.nativeElement.innerHTML = '';
          item.chatMessages.forEach(msg => {
            this.session.addMessage(msg);
          });
        });
    }
  }

  ngAfterViewInit() {
    this.refreshChatList();
  }

  refreshChatList() {
    if (this.session.chatMessages.length > 0) {
      this.session.chatMessages.forEach(value => {
        this.addLine(value);
      });
    }
  }

  getSenderName(message: MongoChatMessage): string {
    return message.sender !== this.session.selfId ? this.session.partnerName : $localize`you`;
  }

  addLine(message: MongoChatMessage) {
    const senderName = this.getSenderName(message);

    const msg = document.createElement('div');
    if (message.timestamp) {
      const timeString = ('0' + message.timestamp.getHours()).slice(-2) + ':'
        + ('0' + message.timestamp.getMinutes()).slice(-2) + ':'
        + ('0' + message.timestamp.getSeconds()).slice(-2);
      msg.innerHTML += `<span class="sender-time">${timeString}</span> `;
    }
    if (senderName && senderName !== '') {
      msg.innerHTML += `<span class="sender-name">${senderName}</span>: `;
    }

    if (message.sender !== this.session.selfId) {
      msg.classList.add('chatOthers');
    }

    msg.innerHTML += `<span class="message">${message.message}</span>`;
    this.chatLog.nativeElement.appendChild(msg);
    this.chatLog.nativeElement.scrollTop = this.chatLog.nativeElement.scrollHeight;

    this.readTimerUpdate.next(null);
  }

  sendButtonClick($event: MouseEvent) {
    $event.preventDefault();
    this.sendMessage(this.message);
  }

  sendMessage(message: string) {
    const msg = (message ?? '').trim();
    this.message = '';
    if (msg && msg !== '') {
      this.userChatService.sendMessage(this.session.selfId, this.session.partnerId, msg);
    }
  }

  close($event: MouseEvent) {
    $event.preventDefault();
    this.dialogService.confirm($localize`:userChat.closeTitle|:Chat schließen`, $localize`:userChat.closeMessage|:Möchten Sie den Chat wirklich schließen?`, $localize`:userChat.closeOk|:Schließen`, $localize`:userChat.closeCancel|:Abbrechen`)
      .then(value => {
        if (value) {
          this.userChatService.closeSession(this.session);
          this.closeSession.emit(this.session);
        }
      });
  }

  callSendMessage() {
    const msg = this.message;
    this.message = '';
    setTimeout(() => {
      this.sendMessage(msg);
    }, 0);
  }

  hide($event: MouseEvent) {
    $event.preventDefault();
    this.hideSession.emit(this.session);
  }

  toggle($event: MouseEvent) {
    $event.preventDefault();
    this.session.open = !this.session.open;
    if (this.session.open) {
      this.session.unreadMessages = 0;
    }
  }

  offerVideoCall($event: MouseEvent) {
    $event.preventDefault();
    this.dialogService.confirm('Offer video call', 'Do you really want to offer this visitor a video call?')
      .then(value => {
        if (value) {
          this.userChatService.offerVideoCall(this.session.selfId, this.session.partnerId);
        }
      });
  }
}
