<div class="video-outer">
  <img class="video-container muted"
       *ngIf="tracks.length === 0 || tracks[tracks.length - 1].getType() !== 'video'"
       src="/assets/cameraoff.jpeg"
  />
  <ng-container *ngFor="let track of tracks; let i = index">
    <video [id]="'video_' + trackId + '_' + i" [attr.autoplay]="1" playsinline
           class="video-container"
           *ngIf="track.getType() === 'video'"
           [ngStyle]="{'display': !track.isMuted() ? 'block' : 'none'}"
    ></video>
    <img class="video-container muted"
         *ngIf="track.getType() === 'video' && i === tracks.length - 1"
         [ngStyle]="{'display': track.isMuted() ? 'block' : 'none'}"
         src="/assets/cameraoff.jpeg"
    />
    <audio [id]="'audio_' + trackId + '_' + i" [attr.autoplay]="1" playsinline
           *ngIf="track.getType() !== 'video' && trackId !== 'local' && trackId !== 'preview'" class="audio-container"></audio>
  </ng-container>
  <div class="video-state" *ngIf="!hideIcons">
    <ng-container *ngIf="participantId === 'local'">
      <i class="fa fa-microphone" *ngIf="!jitsiService.microphoneOff"></i>
      <i class="fa fa-microphone-slash" *ngIf="jitsiService.microphoneOff"></i>
      <i class="fa fa-video" *ngIf="!jitsiService.cameraOff"></i>
      <i class="fa fa-video-slash" *ngIf="jitsiService.cameraOff"></i>
    </ng-container>
    <ng-container *ngIf="participantId !== 'local'">
      <i class="fa fa-microphone" *ngIf="hasAudio"></i>
      <i class="fa fa-microphone-slash" *ngIf="!hasAudio"></i>
      <i class="fa fa-video" *ngIf="hasVideo"></i>
      <i class="fa fa-video-slash" *ngIf="!hasVideo"></i>
    </ng-container>
  </div>
  <div class="video-label" *ngIf="!hideIcons">
    {{ participantId === 'local' ? 'You:' + jitsiService.displayName : jitsiService.participants.get(participantId)}}
  </div>
</div>
