/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ApplicationUser } from './applicationUser';


export interface UserProfile { 
    owner?: ApplicationUser;
    ownerId: string;
    name: string;
    email?: string;
    company?: string;
    role?: string;
    phoneNumber?: string;
    address?: string;
    description?: string;
    socialProfiles?: string;
    image?: string;
}

/**
 * Namespace for property- and property-value-enumerations of UserProfile.
 */
export namespace UserProfile {
    /**
     * All properties of UserProfile.
     */
    export enum Properties {
        owner = 'owner',
        ownerId = 'ownerId',
        name = 'name',
        email = 'email',
        company = 'company',
        role = 'role',
        phoneNumber = 'phoneNumber',
        address = 'address',
        description = 'description',
        socialProfiles = 'socialProfiles',
        image = 'image'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of UserProfile.
    */
    export const ModelValidators: {[K in keyof UserProfile]: [string, ValidatorFn][]} = {
        owner: [
        ],
        ownerId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        role: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(250)],
        ],
        description: [
        ],
        socialProfiles: [
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
    };

    /**
    * The FormControlFactory for UserProfile.
    */
    export class FormControlFactory extends BaseFormControlFactory<UserProfile> {

        /**
         * Constructor.
         *
         * @param model An existing model for UserProfile.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: UserProfile = {
            owner: null,
            ownerId: null,
            name: null,
            email: null,
            company: null,
            role: null,
            phoneNumber: null,
            address: null,
            description: null,
            socialProfiles: null,
            image: null,
          }
        ) {
            super(model, UserProfile.ModelValidators);
        }
    }

}


