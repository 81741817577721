/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ActiveUpdateDto } from '../model/activeUpdateDto';
import { ExhibitorState } from '../model/exhibitorState';
import { ExpoListDto } from '../model/expoListDto';
import { FurnitureOptions } from '../model/furnitureOptions';
import { JitsiRoomSettings } from '../model/jitsiRoomSettings';
import { LivestreamSettingsDto } from '../model/livestreamSettingsDto';
import { MAvailabilityEditDto } from '../model/mAvailabilityEditDto';
import { MCalendarEntryBoothDto } from '../model/mCalendarEntryBoothDto';
import { MDisplayEditDto } from '../model/mDisplayEditDto';
import { MDisplayListUpdateResult } from '../model/mDisplayListUpdateResult';
import { MDisplayUpdateResult } from '../model/mDisplayUpdateResult';
import { MExhibitorContactEditDto } from '../model/mExhibitorContactEditDto';
import { MExhibitorContactListUpdateResult } from '../model/mExhibitorContactListUpdateResult';
import { MExhibitorContactUpdateResult } from '../model/mExhibitorContactUpdateResult';
import { MExhibitorEditDto } from '../model/mExhibitorEditDto';
import { MExhibitorListResult } from '../model/mExhibitorListResult';
import { MExhibitorResult } from '../model/mExhibitorResult';
import { MInfoSlideEditDto } from '../model/mInfoSlideEditDto';
import { MInfoSlideListUpdateResult } from '../model/mInfoSlideListUpdateResult';
import { MInfoSlideUpdateResult } from '../model/mInfoSlideUpdateResult';
import { MLinkEditDto } from '../model/mLinkEditDto';
import { MLinkListUpdateResult } from '../model/mLinkListUpdateResult';
import { MLinkUpdateResult } from '../model/mLinkUpdateResult';
import { NotificationMessageDto } from '../model/notificationMessageDto';
import { RenderRequest } from '../model/renderRequest';
import { RenderResponse } from '../model/renderResponse';
import { SortUpdateDto } from '../model/sortUpdateDto';
import { StreamUpdateDto } from '../model/streamUpdateDto';
import { VisitorListDto } from '../model/visitorListDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mExhibitorAvailabilityDelete.
 */
export namespace MExhibitorAvailabilityDelete {
    /**
     * Parameter map for mExhibitorAvailabilityDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorAvailabilityDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorAvailabilityDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorAvailabilityDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorAvailabilityGet.
 */
export namespace MExhibitorAvailabilityGet {
    /**
     * Parameter map for mExhibitorAvailabilityGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorAvailabilityGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorAvailabilityGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorAvailabilityGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorAvailabilityGetAll.
 */
export namespace MExhibitorAvailabilityGetAll {
    /**
     * Parameter map for mExhibitorAvailabilityGetAll.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorAvailabilityGetAll.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorAvailabilityGetAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorAvailabilityGetAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorAvailabilityGetExhibitor.
 */
export namespace MExhibitorAvailabilityGetExhibitor {
    /**
     * Parameter map for mExhibitorAvailabilityGetExhibitor.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorAvailabilityGetExhibitor.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorAvailabilityGetExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorAvailabilityGetExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorAvailabilityPost.
 */
export namespace MExhibitorAvailabilityPost {
    /**
     * Parameter map for mExhibitorAvailabilityPost.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MAvailabilityEditDto: MAvailabilityEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorAvailabilityPost.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MAvailabilityEditDto = 'MAvailabilityEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorAvailabilityPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorAvailabilityPost.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarActivate.
 */
export namespace MExhibitorCalendarActivate {
    /**
     * Parameter map for mExhibitorCalendarActivate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarActivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarActivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarActivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarDeactivate.
 */
export namespace MExhibitorCalendarDeactivate {
    /**
     * Parameter map for mExhibitorCalendarDeactivate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarDeactivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarDeactivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarDeactivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarDelete.
 */
export namespace MExhibitorCalendarDelete {
    /**
     * Parameter map for mExhibitorCalendarDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarGet.
 */
export namespace MExhibitorCalendarGet {
    /**
     * Parameter map for mExhibitorCalendarGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarGetAll.
 */
export namespace MExhibitorCalendarGetAll {
    /**
     * Parameter map for mExhibitorCalendarGetAll.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarGetAll.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarGetAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarGetAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarGetAllExpo.
 */
export namespace MExhibitorCalendarGetAllExpo {
    /**
     * Parameter map for mExhibitorCalendarGetAllExpo.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarGetAllExpo.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarGetAllExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarGetAllExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarNotify.
 */
export namespace MExhibitorCalendarNotify {
    /**
     * Parameter map for mExhibitorCalendarNotify.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarNotify.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarNotify
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarNotify.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarNotifyMessage.
 */
export namespace MExhibitorCalendarNotifyMessage {
    /**
     * Parameter map for mExhibitorCalendarNotifyMessage.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      NotificationMessageDto: NotificationMessageDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarNotifyMessage.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      NotificationMessageDto = 'NotificationMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarNotifyMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarNotifyMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarPost.
 */
export namespace MExhibitorCalendarPost {
    /**
     * Parameter map for mExhibitorCalendarPost.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MCalendarEntryBoothDto: MCalendarEntryBoothDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarPost.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MCalendarEntryBoothDto = 'MCalendarEntryBoothDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarPost.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorCalendarUpdateStream.
 */
export namespace MExhibitorCalendarUpdateStream {
    /**
     * Parameter map for mExhibitorCalendarUpdateStream.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: string;
      StreamUpdateDto: StreamUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorCalendarUpdateStream.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId',
      StreamUpdateDto = 'StreamUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorCalendarUpdateStream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorCalendarUpdateStream.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorChatActivate.
 */
export namespace MExhibitorChatActivate {
    /**
     * Parameter map for mExhibitorChatActivate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorChatActivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorChatActivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorChatActivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorChatDeactivate.
 */
export namespace MExhibitorChatDeactivate {
    /**
     * Parameter map for mExhibitorChatDeactivate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorChatDeactivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorChatDeactivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorChatDeactivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorContactDelete.
 */
export namespace MExhibitorContactDelete {
    /**
     * Parameter map for mExhibitorContactDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      contactId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExhibitorContactDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      contactId = 'contactId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorContactDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorContactDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      contactId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorContactSort.
 */
export namespace MExhibitorContactSort {
    /**
     * Parameter map for mExhibitorContactSort.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorContactSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorContactSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorContactSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorContactUpdate.
 */
export namespace MExhibitorContactUpdate {
    /**
     * Parameter map for mExhibitorContactUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MExhibitorContactEditDto: MExhibitorContactEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorContactUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MExhibitorContactEditDto = 'MExhibitorContactEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorContactUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorContactUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorDisplayActive.
 */
export namespace MExhibitorDisplayActive {
    /**
     * Parameter map for mExhibitorDisplayActive.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorDisplayActive.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorDisplayActive
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorDisplayActive.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorDisplayDelete.
 */
export namespace MExhibitorDisplayDelete {
    /**
     * Parameter map for mExhibitorDisplayDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      displayId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExhibitorDisplayDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      displayId = 'displayId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorDisplayDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorDisplayDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      displayId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorDisplaySort.
 */
export namespace MExhibitorDisplaySort {
    /**
     * Parameter map for mExhibitorDisplaySort.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorDisplaySort.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorDisplaySort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorDisplaySort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorDisplayUpdate.
 */
export namespace MExhibitorDisplayUpdate {
    /**
     * Parameter map for mExhibitorDisplayUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MDisplayEditDto: MDisplayEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorDisplayUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MDisplayEditDto = 'MDisplayEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorDisplayUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorDisplayUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorExhibitorList.
 */
export namespace MExhibitorExhibitorList {
    /**
     * Parameter map for mExhibitorExhibitorList.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorExhibitorList.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorExhibitorList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorExhibitorList.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorExpoList.
 */
export namespace MExhibitorExpoList {
    /**
     * Parameter map for mExhibitorExpoList.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mExhibitorExpoList.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorExpoList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorExpoList.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExhibitorGetExhibitor.
 */
export namespace MExhibitorGetExhibitor {
    /**
     * Parameter map for mExhibitorGetExhibitor.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorGetExhibitor.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorGetExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorGetExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorGetJitsiToken.
 */
export namespace MExhibitorGetJitsiToken {
    /**
     * Parameter map for mExhibitorGetJitsiToken.
     */
    export interface PartialParamMap {
      exhibitorId?: string;
      expoId?: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorGetJitsiToken.
     */
    export enum Parameters {
      exhibitorId = 'exhibitorId',
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorGetJitsiToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorGetJitsiToken.PartialParamMap]?: [string, ValidatorFn][]} = {
      exhibitorId: [
      ],
      expoId: [
      ],
    };
}

/**
 * Namespace for mExhibitorGetVisitors.
 */
export namespace MExhibitorGetVisitors {
    /**
     * Parameter map for mExhibitorGetVisitors.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorGetVisitors.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorGetVisitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorGetVisitors.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorInfoSlideActive.
 */
export namespace MExhibitorInfoSlideActive {
    /**
     * Parameter map for mExhibitorInfoSlideActive.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorInfoSlideActive.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorInfoSlideActive
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorInfoSlideActive.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorInfoSlideDelete.
 */
export namespace MExhibitorInfoSlideDelete {
    /**
     * Parameter map for mExhibitorInfoSlideDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      infoSlideId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExhibitorInfoSlideDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      infoSlideId = 'infoSlideId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorInfoSlideDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorInfoSlideDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      infoSlideId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorInfoSlideSort.
 */
export namespace MExhibitorInfoSlideSort {
    /**
     * Parameter map for mExhibitorInfoSlideSort.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorInfoSlideSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorInfoSlideSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorInfoSlideSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorInfoSlideUpdate.
 */
export namespace MExhibitorInfoSlideUpdate {
    /**
     * Parameter map for mExhibitorInfoSlideUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MInfoSlideEditDto: MInfoSlideEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorInfoSlideUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MInfoSlideEditDto = 'MInfoSlideEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorInfoSlideUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorInfoSlideUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorLinkActive.
 */
export namespace MExhibitorLinkActive {
    /**
     * Parameter map for mExhibitorLinkActive.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      ActiveUpdateDto: ActiveUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorLinkActive.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      ActiveUpdateDto = 'ActiveUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorLinkActive
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorLinkActive.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorLinkDelete.
 */
export namespace MExhibitorLinkDelete {
    /**
     * Parameter map for mExhibitorLinkDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      linkId: string;
      rowVersion: number;
    }

    /**
     * Enumeration of all parameters for mExhibitorLinkDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      linkId = 'linkId',
      rowVersion = 'rowVersion'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorLinkDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorLinkDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      linkId: [
              ['required', Validators.required],
      ],
      rowVersion: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorLinkSort.
 */
export namespace MExhibitorLinkSort {
    /**
     * Parameter map for mExhibitorLinkSort.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorLinkSort.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorLinkSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorLinkSort.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorLinkUpdate.
 */
export namespace MExhibitorLinkUpdate {
    /**
     * Parameter map for mExhibitorLinkUpdate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MLinkEditDto: MLinkEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorLinkUpdate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MLinkEditDto = 'MLinkEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorLinkUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorLinkUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorRenderOptions.
 */
export namespace MExhibitorRenderOptions {
    /**
     * Parameter map for mExhibitorRenderOptions.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mExhibitorRenderOptions.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorRenderOptions
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorRenderOptions.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExhibitorRenderPreview.
 */
export namespace MExhibitorRenderPreview {
    /**
     * Parameter map for mExhibitorRenderPreview.
     */
    export interface PartialParamMap {
      RenderRequest: RenderRequest;
    }

    /**
     * Enumeration of all parameters for mExhibitorRenderPreview.
     */
    export enum Parameters {
      RenderRequest = 'RenderRequest'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorRenderPreview
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorRenderPreview.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mExhibitorStartLivestream.
 */
export namespace MExhibitorStartLivestream {
    /**
     * Parameter map for mExhibitorStartLivestream.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      LivestreamSettingsDto: LivestreamSettingsDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorStartLivestream.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      LivestreamSettingsDto = 'LivestreamSettingsDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorStartLivestream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorStartLivestream.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorStateGet.
 */
export namespace MExhibitorStateGet {
    /**
     * Parameter map for mExhibitorStateGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for mExhibitorStateGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorStateGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorStateGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorStopLivestream.
 */
export namespace MExhibitorStopLivestream {
    /**
     * Parameter map for mExhibitorStopLivestream.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      LivestreamSettingsDto: LivestreamSettingsDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorStopLivestream.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      LivestreamSettingsDto = 'LivestreamSettingsDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorStopLivestream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorStopLivestream.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mExhibitorUpdateExhibitor.
 */
export namespace MExhibitorUpdateExhibitor {
    /**
     * Parameter map for mExhibitorUpdateExhibitor.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      MExhibitorEditDto: MExhibitorEditDto;
    }

    /**
     * Enumeration of all parameters for mExhibitorUpdateExhibitor.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      MExhibitorEditDto = 'MExhibitorEditDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mExhibitorUpdateExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MExhibitorUpdateExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MExhibitorService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorAvailabilityDeleteByMap(
    map: MExhibitorAvailabilityDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public mExhibitorAvailabilityDeleteByMap(
    map: MExhibitorAvailabilityDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public mExhibitorAvailabilityDeleteByMap(
    map: MExhibitorAvailabilityDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public mExhibitorAvailabilityDeleteByMap(
    map: MExhibitorAvailabilityDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorAvailabilityDelete(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorAvailabilityDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public mExhibitorAvailabilityDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public mExhibitorAvailabilityDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public mExhibitorAvailabilityDelete(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorAvailabilityDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorAvailabilityDelete.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorAvailabilityDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/availability/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_AvailabilityDelete']) {
                this.cancelMap['MExhibitor_AvailabilityDelete'].next(null);
            }
            this.cancelMap['MExhibitor_AvailabilityDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_AvailabilityDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_AvailabilityDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_AvailabilityDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_AvailabilityDelete']) {
            this.cancelMap['MExhibitor_AvailabilityDelete'].complete();
            delete this.cancelMap['MExhibitor_AvailabilityDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_AvailabilityDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_AvailabilityDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorAvailabilityGetByMap(
    map: MExhibitorAvailabilityGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MAvailabilityEditDto>;
  public mExhibitorAvailabilityGetByMap(
    map: MExhibitorAvailabilityGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityGetByMap(
    map: MExhibitorAvailabilityGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityGetByMap(
    map: MExhibitorAvailabilityGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorAvailabilityGet(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorAvailabilityGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MAvailabilityEditDto>;
    public mExhibitorAvailabilityGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityGet(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorAvailabilityGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorAvailabilityGet.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorAvailabilityGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MAvailabilityEditDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/availability/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_AvailabilityGet']) {
                this.cancelMap['MExhibitor_AvailabilityGet'].next(null);
            }
            this.cancelMap['MExhibitor_AvailabilityGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_AvailabilityGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_AvailabilityGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_AvailabilityGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_AvailabilityGet']) {
            this.cancelMap['MExhibitor_AvailabilityGet'].complete();
            delete this.cancelMap['MExhibitor_AvailabilityGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_AvailabilityGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_AvailabilityGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorAvailabilityGetAllByMap(
    map: MExhibitorAvailabilityGetAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityGetAllByMap(
    map: MExhibitorAvailabilityGetAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MAvailabilityEditDto>>>;
  public mExhibitorAvailabilityGetAllByMap(
    map: MExhibitorAvailabilityGetAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MAvailabilityEditDto>>>;
  public mExhibitorAvailabilityGetAllByMap(
    map: MExhibitorAvailabilityGetAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorAvailabilityGetAll(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorAvailabilityGetAll(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityGetAll(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MAvailabilityEditDto>>>;
    public mExhibitorAvailabilityGetAll(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MAvailabilityEditDto>>>;
    public mExhibitorAvailabilityGetAll(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorAvailabilityGetAll.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MAvailabilityEditDto>>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/availability`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_AvailabilityGetAll']) {
                this.cancelMap['MExhibitor_AvailabilityGetAll'].next(null);
            }
            this.cancelMap['MExhibitor_AvailabilityGetAll'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_AvailabilityGetAll']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_AvailabilityGetAll']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_AvailabilityGetAll', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_AvailabilityGetAll']) {
            this.cancelMap['MExhibitor_AvailabilityGetAll'].complete();
            delete this.cancelMap['MExhibitor_AvailabilityGetAll'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_AvailabilityGetAll',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_AvailabilityGetAll')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorAvailabilityGetExhibitorByMap(
    map: MExhibitorAvailabilityGetExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityGetExhibitorByMap(
    map: MExhibitorAvailabilityGetExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MAvailabilityEditDto>>>;
  public mExhibitorAvailabilityGetExhibitorByMap(
    map: MExhibitorAvailabilityGetExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MAvailabilityEditDto>>>;
  public mExhibitorAvailabilityGetExhibitorByMap(
    map: MExhibitorAvailabilityGetExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorAvailabilityGetExhibitor(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorAvailabilityGetExhibitor(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityGetExhibitor(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MAvailabilityEditDto>>>;
    public mExhibitorAvailabilityGetExhibitor(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MAvailabilityEditDto>>>;
    public mExhibitorAvailabilityGetExhibitor(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorAvailabilityGetExhibitor.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorAvailabilityGetExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MAvailabilityEditDto>>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/availability`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_AvailabilityGetExhibitor']) {
                this.cancelMap['MExhibitor_AvailabilityGetExhibitor'].next(null);
            }
            this.cancelMap['MExhibitor_AvailabilityGetExhibitor'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_AvailabilityGetExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_AvailabilityGetExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_AvailabilityGetExhibitor', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_AvailabilityGetExhibitor']) {
            this.cancelMap['MExhibitor_AvailabilityGetExhibitor'].complete();
            delete this.cancelMap['MExhibitor_AvailabilityGetExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_AvailabilityGetExhibitor',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_AvailabilityGetExhibitor')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorAvailabilityPostByMap(
    map: MExhibitorAvailabilityPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MAvailabilityEditDto>;
  public mExhibitorAvailabilityPostByMap(
    map: MExhibitorAvailabilityPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityPostByMap(
    map: MExhibitorAvailabilityPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MAvailabilityEditDto>>;
  public mExhibitorAvailabilityPostByMap(
    map: MExhibitorAvailabilityPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorAvailabilityPost(
      map.expoId,
      map.exhibitorId,
      map.MAvailabilityEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MAvailabilityEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorAvailabilityPost(expoId: string, exhibitorId: string, MAvailabilityEditDto: MAvailabilityEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MAvailabilityEditDto>;
    public mExhibitorAvailabilityPost(expoId: string, exhibitorId: string, MAvailabilityEditDto: MAvailabilityEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityPost(expoId: string, exhibitorId: string, MAvailabilityEditDto: MAvailabilityEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MAvailabilityEditDto>>;
    public mExhibitorAvailabilityPost(expoId: string, exhibitorId: string, MAvailabilityEditDto: MAvailabilityEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorAvailabilityPost.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorAvailabilityPost.');
        }
        if (MAvailabilityEditDto === null || MAvailabilityEditDto === undefined) {
            throw new Error('Required parameter MAvailabilityEditDto was null or undefined when calling mExhibitorAvailabilityPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MAvailabilityEditDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/availability`,
            MAvailabilityEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_AvailabilityPost']) {
                this.cancelMap['MExhibitor_AvailabilityPost'].next(null);
            }
            this.cancelMap['MExhibitor_AvailabilityPost'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_AvailabilityPost']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_AvailabilityPost']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_AvailabilityPost', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_AvailabilityPost']) {
            this.cancelMap['MExhibitor_AvailabilityPost'].complete();
            delete this.cancelMap['MExhibitor_AvailabilityPost'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_AvailabilityPost',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_AvailabilityPost')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarActivateByMap(
    map: MExhibitorCalendarActivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorCalendarActivateByMap(
    map: MExhibitorCalendarActivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorCalendarActivateByMap(
    map: MExhibitorCalendarActivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorCalendarActivateByMap(
    map: MExhibitorCalendarActivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarActivate(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarActivate(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorCalendarActivate(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorCalendarActivate(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorCalendarActivate(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarActivate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarActivate.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorCalendarActivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/${encodeURIComponent(String(entryId))}/activate`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarActivate']) {
                this.cancelMap['MExhibitor_CalendarActivate'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarActivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarActivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarActivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarActivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarActivate']) {
            this.cancelMap['MExhibitor_CalendarActivate'].complete();
            delete this.cancelMap['MExhibitor_CalendarActivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarActivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarActivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarDeactivateByMap(
    map: MExhibitorCalendarDeactivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorCalendarDeactivateByMap(
    map: MExhibitorCalendarDeactivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorCalendarDeactivateByMap(
    map: MExhibitorCalendarDeactivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorCalendarDeactivateByMap(
    map: MExhibitorCalendarDeactivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarDeactivate(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorCalendarDeactivate(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarDeactivate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarDeactivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/deactivate`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarDeactivate']) {
                this.cancelMap['MExhibitor_CalendarDeactivate'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarDeactivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarDeactivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarDeactivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarDeactivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarDeactivate']) {
            this.cancelMap['MExhibitor_CalendarDeactivate'].complete();
            delete this.cancelMap['MExhibitor_CalendarDeactivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarDeactivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarDeactivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarDeleteByMap(
    map: MExhibitorCalendarDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public mExhibitorCalendarDeleteByMap(
    map: MExhibitorCalendarDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public mExhibitorCalendarDeleteByMap(
    map: MExhibitorCalendarDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public mExhibitorCalendarDeleteByMap(
    map: MExhibitorCalendarDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarDelete(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public mExhibitorCalendarDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public mExhibitorCalendarDelete(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public mExhibitorCalendarDelete(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarDelete.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorCalendarDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarDelete']) {
                this.cancelMap['MExhibitor_CalendarDelete'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarDelete']) {
            this.cancelMap['MExhibitor_CalendarDelete'].complete();
            delete this.cancelMap['MExhibitor_CalendarDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarGetByMap(
    map: MExhibitorCalendarGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
  public mExhibitorCalendarGetByMap(
    map: MExhibitorCalendarGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarGetByMap(
    map: MExhibitorCalendarGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarGetByMap(
    map: MExhibitorCalendarGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarGet(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
    public mExhibitorCalendarGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarGet(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarGet(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarGet.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorCalendarGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MCalendarEntryBoothDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarGet']) {
                this.cancelMap['MExhibitor_CalendarGet'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarGet']) {
            this.cancelMap['MExhibitor_CalendarGet'].complete();
            delete this.cancelMap['MExhibitor_CalendarGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarGetAllByMap(
    map: MExhibitorCalendarGetAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarGetAllByMap(
    map: MExhibitorCalendarGetAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryBoothDto>>>;
  public mExhibitorCalendarGetAllByMap(
    map: MExhibitorCalendarGetAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryBoothDto>>>;
  public mExhibitorCalendarGetAllByMap(
    map: MExhibitorCalendarGetAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarGetAll(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryBoothDto>>>;
    public mExhibitorCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryBoothDto>>>;
    public mExhibitorCalendarGetAll(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarGetAll.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarGetAll.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MCalendarEntryBoothDto>>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarGetAll']) {
                this.cancelMap['MExhibitor_CalendarGetAll'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarGetAll'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarGetAll']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarGetAll']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarGetAll', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarGetAll']) {
            this.cancelMap['MExhibitor_CalendarGetAll'].complete();
            delete this.cancelMap['MExhibitor_CalendarGetAll'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarGetAll',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarGetAll')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarGetAllExpoByMap(
    map: MExhibitorCalendarGetAllExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarGetAllExpoByMap(
    map: MExhibitorCalendarGetAllExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryBoothDto>>>;
  public mExhibitorCalendarGetAllExpoByMap(
    map: MExhibitorCalendarGetAllExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryBoothDto>>>;
  public mExhibitorCalendarGetAllExpoByMap(
    map: MExhibitorCalendarGetAllExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarGetAllExpo(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarGetAllExpo(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarGetAllExpo(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<MCalendarEntryBoothDto>>>;
    public mExhibitorCalendarGetAllExpo(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<MCalendarEntryBoothDto>>>;
    public mExhibitorCalendarGetAllExpo(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarGetAllExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<MCalendarEntryBoothDto>>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/calendar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarGetAllExpo']) {
                this.cancelMap['MExhibitor_CalendarGetAllExpo'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarGetAllExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarGetAllExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarGetAllExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarGetAllExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarGetAllExpo']) {
            this.cancelMap['MExhibitor_CalendarGetAllExpo'].complete();
            delete this.cancelMap['MExhibitor_CalendarGetAllExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarGetAllExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarGetAllExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarNotifyByMap(
    map: MExhibitorCalendarNotify.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorCalendarNotifyByMap(
    map: MExhibitorCalendarNotify.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorCalendarNotifyByMap(
    map: MExhibitorCalendarNotify.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorCalendarNotifyByMap(
    map: MExhibitorCalendarNotify.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarNotify(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarNotify(expoId: string, exhibitorId: string, entryId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorCalendarNotify(expoId: string, exhibitorId: string, entryId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorCalendarNotify(expoId: string, exhibitorId: string, entryId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorCalendarNotify(expoId: string, exhibitorId: string, entryId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarNotify.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarNotify.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorCalendarNotify.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/${encodeURIComponent(String(entryId))}/notify`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarNotify']) {
                this.cancelMap['MExhibitor_CalendarNotify'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarNotify'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarNotify']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarNotify']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarNotify', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarNotify']) {
            this.cancelMap['MExhibitor_CalendarNotify'].complete();
            delete this.cancelMap['MExhibitor_CalendarNotify'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarNotify',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarNotify')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarNotifyMessageByMap(
    map: MExhibitorCalendarNotifyMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorCalendarNotifyMessageByMap(
    map: MExhibitorCalendarNotifyMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorCalendarNotifyMessageByMap(
    map: MExhibitorCalendarNotifyMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorCalendarNotifyMessageByMap(
    map: MExhibitorCalendarNotifyMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarNotifyMessage(
      map.expoId,
      map.exhibitorId,
      map.NotificationMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param NotificationMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarNotifyMessage(expoId: string, exhibitorId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorCalendarNotifyMessage(expoId: string, exhibitorId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorCalendarNotifyMessage(expoId: string, exhibitorId: string, NotificationMessageDto: NotificationMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorCalendarNotifyMessage(expoId: string, exhibitorId: string, NotificationMessageDto: NotificationMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarNotifyMessage.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarNotifyMessage.');
        }
        if (NotificationMessageDto === null || NotificationMessageDto === undefined) {
            throw new Error('Required parameter NotificationMessageDto was null or undefined when calling mExhibitorCalendarNotifyMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/notify`,
            NotificationMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarNotifyMessage']) {
                this.cancelMap['MExhibitor_CalendarNotifyMessage'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarNotifyMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarNotifyMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarNotifyMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarNotifyMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarNotifyMessage']) {
            this.cancelMap['MExhibitor_CalendarNotifyMessage'].complete();
            delete this.cancelMap['MExhibitor_CalendarNotifyMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarNotifyMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarNotifyMessage')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarPostByMap(
    map: MExhibitorCalendarPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
  public mExhibitorCalendarPostByMap(
    map: MExhibitorCalendarPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarPostByMap(
    map: MExhibitorCalendarPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarPostByMap(
    map: MExhibitorCalendarPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarPost(
      map.expoId,
      map.exhibitorId,
      map.MCalendarEntryBoothDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MCalendarEntryBoothDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarPost(expoId: string, exhibitorId: string, MCalendarEntryBoothDto: MCalendarEntryBoothDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
    public mExhibitorCalendarPost(expoId: string, exhibitorId: string, MCalendarEntryBoothDto: MCalendarEntryBoothDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarPost(expoId: string, exhibitorId: string, MCalendarEntryBoothDto: MCalendarEntryBoothDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarPost(expoId: string, exhibitorId: string, MCalendarEntryBoothDto: MCalendarEntryBoothDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarPost.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarPost.');
        }
        if (MCalendarEntryBoothDto === null || MCalendarEntryBoothDto === undefined) {
            throw new Error('Required parameter MCalendarEntryBoothDto was null or undefined when calling mExhibitorCalendarPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MCalendarEntryBoothDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar`,
            MCalendarEntryBoothDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarPost']) {
                this.cancelMap['MExhibitor_CalendarPost'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarPost'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarPost']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarPost']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarPost', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarPost']) {
            this.cancelMap['MExhibitor_CalendarPost'].complete();
            delete this.cancelMap['MExhibitor_CalendarPost'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarPost',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarPost')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorCalendarUpdateStreamByMap(
    map: MExhibitorCalendarUpdateStream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
  public mExhibitorCalendarUpdateStreamByMap(
    map: MExhibitorCalendarUpdateStream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarUpdateStreamByMap(
    map: MExhibitorCalendarUpdateStream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
  public mExhibitorCalendarUpdateStreamByMap(
    map: MExhibitorCalendarUpdateStream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorCalendarUpdateStream(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      map.StreamUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param StreamUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorCalendarUpdateStream(expoId: string, exhibitorId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MCalendarEntryBoothDto>;
    public mExhibitorCalendarUpdateStream(expoId: string, exhibitorId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarUpdateStream(expoId: string, exhibitorId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MCalendarEntryBoothDto>>;
    public mExhibitorCalendarUpdateStream(expoId: string, exhibitorId: string, entryId: string, StreamUpdateDto: StreamUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorCalendarUpdateStream.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorCalendarUpdateStream.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling mExhibitorCalendarUpdateStream.');
        }
        if (StreamUpdateDto === null || StreamUpdateDto === undefined) {
            throw new Error('Required parameter StreamUpdateDto was null or undefined when calling mExhibitorCalendarUpdateStream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MCalendarEntryBoothDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/calendar/${encodeURIComponent(String(entryId))}/update`,
            StreamUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_CalendarUpdateStream']) {
                this.cancelMap['MExhibitor_CalendarUpdateStream'].next(null);
            }
            this.cancelMap['MExhibitor_CalendarUpdateStream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_CalendarUpdateStream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_CalendarUpdateStream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_CalendarUpdateStream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_CalendarUpdateStream']) {
            this.cancelMap['MExhibitor_CalendarUpdateStream'].complete();
            delete this.cancelMap['MExhibitor_CalendarUpdateStream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_CalendarUpdateStream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_CalendarUpdateStream')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorChatActivateByMap(
    map: MExhibitorChatActivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorChatActivateByMap(
    map: MExhibitorChatActivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorChatActivateByMap(
    map: MExhibitorChatActivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorChatActivateByMap(
    map: MExhibitorChatActivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorChatActivate(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorChatActivate(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorChatActivate(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorChatActivate(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorChatActivate(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorChatActivate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorChatActivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/chat/activate`,
            null,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ChatActivate']) {
                this.cancelMap['MExhibitor_ChatActivate'].next(null);
            }
            this.cancelMap['MExhibitor_ChatActivate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ChatActivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ChatActivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ChatActivate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ChatActivate']) {
            this.cancelMap['MExhibitor_ChatActivate'].complete();
            delete this.cancelMap['MExhibitor_ChatActivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ChatActivate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ChatActivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorChatDeactivateByMap(
    map: MExhibitorChatDeactivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorChatDeactivateByMap(
    map: MExhibitorChatDeactivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorChatDeactivateByMap(
    map: MExhibitorChatDeactivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorChatDeactivateByMap(
    map: MExhibitorChatDeactivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorChatDeactivate(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorChatDeactivate(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorChatDeactivate(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorChatDeactivate(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorChatDeactivate(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorChatDeactivate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorChatDeactivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/chat/deactivate`,
            null,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ChatDeactivate']) {
                this.cancelMap['MExhibitor_ChatDeactivate'].next(null);
            }
            this.cancelMap['MExhibitor_ChatDeactivate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ChatDeactivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ChatDeactivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ChatDeactivate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ChatDeactivate']) {
            this.cancelMap['MExhibitor_ChatDeactivate'].complete();
            delete this.cancelMap['MExhibitor_ChatDeactivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ChatDeactivate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ChatDeactivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorContactDeleteByMap(
    map: MExhibitorContactDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorContactListUpdateResult>;
  public mExhibitorContactDeleteByMap(
    map: MExhibitorContactDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactListUpdateResult>>;
  public mExhibitorContactDeleteByMap(
    map: MExhibitorContactDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactListUpdateResult>>;
  public mExhibitorContactDeleteByMap(
    map: MExhibitorContactDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorContactDelete(
      map.expoId,
      map.exhibitorId,
      map.contactId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param contactId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorContactDelete(expoId: string, exhibitorId: string, contactId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorContactListUpdateResult>;
    public mExhibitorContactDelete(expoId: string, exhibitorId: string, contactId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactListUpdateResult>>;
    public mExhibitorContactDelete(expoId: string, exhibitorId: string, contactId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactListUpdateResult>>;
    public mExhibitorContactDelete(expoId: string, exhibitorId: string, contactId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorContactDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorContactDelete.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling mExhibitorContactDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExhibitorContactDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MExhibitorContactListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/contact/${encodeURIComponent(String(contactId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ContactDelete']) {
                this.cancelMap['MExhibitor_ContactDelete'].next(null);
            }
            this.cancelMap['MExhibitor_ContactDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ContactDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ContactDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ContactDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ContactDelete']) {
            this.cancelMap['MExhibitor_ContactDelete'].complete();
            delete this.cancelMap['MExhibitor_ContactDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ContactDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ContactDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorContactSortByMap(
    map: MExhibitorContactSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorContactListUpdateResult>;
  public mExhibitorContactSortByMap(
    map: MExhibitorContactSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactListUpdateResult>>;
  public mExhibitorContactSortByMap(
    map: MExhibitorContactSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactListUpdateResult>>;
  public mExhibitorContactSortByMap(
    map: MExhibitorContactSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorContactSort(
      map.expoId,
      map.exhibitorId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorContactSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorContactListUpdateResult>;
    public mExhibitorContactSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactListUpdateResult>>;
    public mExhibitorContactSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactListUpdateResult>>;
    public mExhibitorContactSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorContactSort.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorContactSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExhibitorContactSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExhibitorContactListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/sort/contact`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ContactSort']) {
                this.cancelMap['MExhibitor_ContactSort'].next(null);
            }
            this.cancelMap['MExhibitor_ContactSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ContactSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ContactSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ContactSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ContactSort']) {
            this.cancelMap['MExhibitor_ContactSort'].complete();
            delete this.cancelMap['MExhibitor_ContactSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ContactSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ContactSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorContactUpdateByMap(
    map: MExhibitorContactUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorContactUpdateResult>;
  public mExhibitorContactUpdateByMap(
    map: MExhibitorContactUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactUpdateResult>>;
  public mExhibitorContactUpdateByMap(
    map: MExhibitorContactUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactUpdateResult>>;
  public mExhibitorContactUpdateByMap(
    map: MExhibitorContactUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorContactUpdate(
      map.expoId,
      map.exhibitorId,
      map.MExhibitorContactEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MExhibitorContactEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorContactUpdate(expoId: string, exhibitorId: string, MExhibitorContactEditDto: MExhibitorContactEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorContactUpdateResult>;
    public mExhibitorContactUpdate(expoId: string, exhibitorId: string, MExhibitorContactEditDto: MExhibitorContactEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorContactUpdateResult>>;
    public mExhibitorContactUpdate(expoId: string, exhibitorId: string, MExhibitorContactEditDto: MExhibitorContactEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorContactUpdateResult>>;
    public mExhibitorContactUpdate(expoId: string, exhibitorId: string, MExhibitorContactEditDto: MExhibitorContactEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorContactUpdate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorContactUpdate.');
        }
        if (MExhibitorContactEditDto === null || MExhibitorContactEditDto === undefined) {
            throw new Error('Required parameter MExhibitorContactEditDto was null or undefined when calling mExhibitorContactUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.put<MExhibitorContactUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/contact`,
            MExhibitorContactEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ContactUpdate']) {
                this.cancelMap['MExhibitor_ContactUpdate'].next(null);
            }
            this.cancelMap['MExhibitor_ContactUpdate'] = 'put'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ContactUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ContactUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ContactUpdate', 'put'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ContactUpdate']) {
            this.cancelMap['MExhibitor_ContactUpdate'].complete();
            delete this.cancelMap['MExhibitor_ContactUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ContactUpdate',
              'put'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ContactUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorDisplayActiveByMap(
    map: MExhibitorDisplayActive.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
  public mExhibitorDisplayActiveByMap(
    map: MExhibitorDisplayActive.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
  public mExhibitorDisplayActiveByMap(
    map: MExhibitorDisplayActive.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
  public mExhibitorDisplayActiveByMap(
    map: MExhibitorDisplayActive.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorDisplayActive(
      map.expoId,
      map.exhibitorId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorDisplayActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
    public mExhibitorDisplayActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
    public mExhibitorDisplayActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
    public mExhibitorDisplayActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorDisplayActive.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorDisplayActive.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mExhibitorDisplayActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MDisplayListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/active/display`,
            ActiveUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_DisplayActive']) {
                this.cancelMap['MExhibitor_DisplayActive'].next(null);
            }
            this.cancelMap['MExhibitor_DisplayActive'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_DisplayActive']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_DisplayActive']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_DisplayActive', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_DisplayActive']) {
            this.cancelMap['MExhibitor_DisplayActive'].complete();
            delete this.cancelMap['MExhibitor_DisplayActive'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_DisplayActive',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_DisplayActive')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorDisplayDeleteByMap(
    map: MExhibitorDisplayDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
  public mExhibitorDisplayDeleteByMap(
    map: MExhibitorDisplayDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
  public mExhibitorDisplayDeleteByMap(
    map: MExhibitorDisplayDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
  public mExhibitorDisplayDeleteByMap(
    map: MExhibitorDisplayDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorDisplayDelete(
      map.expoId,
      map.exhibitorId,
      map.displayId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param displayId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorDisplayDelete(expoId: string, exhibitorId: string, displayId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
    public mExhibitorDisplayDelete(expoId: string, exhibitorId: string, displayId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
    public mExhibitorDisplayDelete(expoId: string, exhibitorId: string, displayId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
    public mExhibitorDisplayDelete(expoId: string, exhibitorId: string, displayId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorDisplayDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorDisplayDelete.');
        }
        if (displayId === null || displayId === undefined) {
            throw new Error('Required parameter displayId was null or undefined when calling mExhibitorDisplayDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExhibitorDisplayDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MDisplayListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/display/${encodeURIComponent(String(displayId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_DisplayDelete']) {
                this.cancelMap['MExhibitor_DisplayDelete'].next(null);
            }
            this.cancelMap['MExhibitor_DisplayDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_DisplayDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_DisplayDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_DisplayDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_DisplayDelete']) {
            this.cancelMap['MExhibitor_DisplayDelete'].complete();
            delete this.cancelMap['MExhibitor_DisplayDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_DisplayDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_DisplayDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorDisplaySortByMap(
    map: MExhibitorDisplaySort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
  public mExhibitorDisplaySortByMap(
    map: MExhibitorDisplaySort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
  public mExhibitorDisplaySortByMap(
    map: MExhibitorDisplaySort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
  public mExhibitorDisplaySortByMap(
    map: MExhibitorDisplaySort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorDisplaySort(
      map.expoId,
      map.exhibitorId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorDisplaySort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MDisplayListUpdateResult>;
    public mExhibitorDisplaySort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayListUpdateResult>>;
    public mExhibitorDisplaySort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayListUpdateResult>>;
    public mExhibitorDisplaySort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorDisplaySort.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorDisplaySort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExhibitorDisplaySort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MDisplayListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/sort/display`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_DisplaySort']) {
                this.cancelMap['MExhibitor_DisplaySort'].next(null);
            }
            this.cancelMap['MExhibitor_DisplaySort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_DisplaySort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_DisplaySort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_DisplaySort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_DisplaySort']) {
            this.cancelMap['MExhibitor_DisplaySort'].complete();
            delete this.cancelMap['MExhibitor_DisplaySort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_DisplaySort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_DisplaySort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorDisplayUpdateByMap(
    map: MExhibitorDisplayUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MDisplayUpdateResult>;
  public mExhibitorDisplayUpdateByMap(
    map: MExhibitorDisplayUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayUpdateResult>>;
  public mExhibitorDisplayUpdateByMap(
    map: MExhibitorDisplayUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayUpdateResult>>;
  public mExhibitorDisplayUpdateByMap(
    map: MExhibitorDisplayUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorDisplayUpdate(
      map.expoId,
      map.exhibitorId,
      map.MDisplayEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MDisplayEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorDisplayUpdate(expoId: string, exhibitorId: string, MDisplayEditDto: MDisplayEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MDisplayUpdateResult>;
    public mExhibitorDisplayUpdate(expoId: string, exhibitorId: string, MDisplayEditDto: MDisplayEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MDisplayUpdateResult>>;
    public mExhibitorDisplayUpdate(expoId: string, exhibitorId: string, MDisplayEditDto: MDisplayEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MDisplayUpdateResult>>;
    public mExhibitorDisplayUpdate(expoId: string, exhibitorId: string, MDisplayEditDto: MDisplayEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorDisplayUpdate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorDisplayUpdate.');
        }
        if (MDisplayEditDto === null || MDisplayEditDto === undefined) {
            throw new Error('Required parameter MDisplayEditDto was null or undefined when calling mExhibitorDisplayUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.put<MDisplayUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/display`,
            MDisplayEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_DisplayUpdate']) {
                this.cancelMap['MExhibitor_DisplayUpdate'].next(null);
            }
            this.cancelMap['MExhibitor_DisplayUpdate'] = 'put'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_DisplayUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_DisplayUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_DisplayUpdate', 'put'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_DisplayUpdate']) {
            this.cancelMap['MExhibitor_DisplayUpdate'].complete();
            delete this.cancelMap['MExhibitor_DisplayUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_DisplayUpdate',
              'put'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_DisplayUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorExhibitorListByMap(
    map: MExhibitorExhibitorList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorListResult>;
  public mExhibitorExhibitorListByMap(
    map: MExhibitorExhibitorList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorListResult>>;
  public mExhibitorExhibitorListByMap(
    map: MExhibitorExhibitorList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorListResult>>;
  public mExhibitorExhibitorListByMap(
    map: MExhibitorExhibitorList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorExhibitorList(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorExhibitorList(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorListResult>;
    public mExhibitorExhibitorList(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorListResult>>;
    public mExhibitorExhibitorList(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorListResult>>;
    public mExhibitorExhibitorList(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorExhibitorList.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExhibitorListResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ExhibitorList']) {
                this.cancelMap['MExhibitor_ExhibitorList'].next(null);
            }
            this.cancelMap['MExhibitor_ExhibitorList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ExhibitorList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ExhibitorList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ExhibitorList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ExhibitorList']) {
            this.cancelMap['MExhibitor_ExhibitorList'].complete();
            delete this.cancelMap['MExhibitor_ExhibitorList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ExhibitorList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ExhibitorList')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorExpoListByMap(
    map: MExhibitorExpoList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
  public mExhibitorExpoListByMap(
    map: MExhibitorExpoList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
  public mExhibitorExpoListByMap(
    map: MExhibitorExpoList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
  public mExhibitorExpoListByMap(
    map: MExhibitorExpoList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorExpoList(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorExpoList(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoListDto>>;
    public mExhibitorExpoList(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListDto>>>;
    public mExhibitorExpoList(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListDto>>>;
    public mExhibitorExpoList(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoListDto>>(`${this.configuration.basePath}/api/MExhibitor`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_ExpoList']) {
                this.cancelMap['MExhibitor_ExpoList'].next(null);
            }
            this.cancelMap['MExhibitor_ExpoList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_ExpoList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_ExpoList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_ExpoList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_ExpoList']) {
            this.cancelMap['MExhibitor_ExpoList'].complete();
            delete this.cancelMap['MExhibitor_ExpoList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_ExpoList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_ExpoList')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorGetExhibitorByMap(
    map: MExhibitorGetExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorResult>;
  public mExhibitorGetExhibitorByMap(
    map: MExhibitorGetExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorResult>>;
  public mExhibitorGetExhibitorByMap(
    map: MExhibitorGetExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorResult>>;
  public mExhibitorGetExhibitorByMap(
    map: MExhibitorGetExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorGetExhibitor(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorGetExhibitor(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorResult>;
    public mExhibitorGetExhibitor(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorResult>>;
    public mExhibitorGetExhibitor(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorResult>>;
    public mExhibitorGetExhibitor(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorGetExhibitor.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorGetExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<MExhibitorResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_GetExhibitor']) {
                this.cancelMap['MExhibitor_GetExhibitor'].next(null);
            }
            this.cancelMap['MExhibitor_GetExhibitor'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_GetExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_GetExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_GetExhibitor', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_GetExhibitor']) {
            this.cancelMap['MExhibitor_GetExhibitor'].complete();
            delete this.cancelMap['MExhibitor_GetExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_GetExhibitor',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_GetExhibitor')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorGetJitsiTokenByMap(
    map: MExhibitorGetJitsiToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<JitsiRoomSettings>;
  public mExhibitorGetJitsiTokenByMap(
    map: MExhibitorGetJitsiToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<JitsiRoomSettings>>;
  public mExhibitorGetJitsiTokenByMap(
    map: MExhibitorGetJitsiToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<JitsiRoomSettings>>;
  public mExhibitorGetJitsiTokenByMap(
    map: MExhibitorGetJitsiToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorGetJitsiToken(
      map.exhibitorId,
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param exhibitorId 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorGetJitsiToken(exhibitorId?: string, expoId?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<JitsiRoomSettings>;
    public mExhibitorGetJitsiToken(exhibitorId?: string, expoId?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<JitsiRoomSettings>>;
    public mExhibitorGetJitsiToken(exhibitorId?: string, expoId?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<JitsiRoomSettings>>;
    public mExhibitorGetJitsiToken(exhibitorId?: string, expoId?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (exhibitorId !== undefined && exhibitorId !== null) {
            queryParameters = queryParameters.set('exhibitorId', <any>exhibitorId);
        }
        if (expoId !== undefined && expoId !== null) {
            queryParameters = queryParameters.set('expoId', <any>expoId);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<JitsiRoomSettings>(`${this.configuration.basePath}/api/MExhibitor/jitsi`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_GetJitsiToken']) {
                this.cancelMap['MExhibitor_GetJitsiToken'].next(null);
            }
            this.cancelMap['MExhibitor_GetJitsiToken'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_GetJitsiToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_GetJitsiToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_GetJitsiToken', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_GetJitsiToken']) {
            this.cancelMap['MExhibitor_GetJitsiToken'].complete();
            delete this.cancelMap['MExhibitor_GetJitsiToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_GetJitsiToken',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_GetJitsiToken')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorGetVisitorsByMap(
    map: MExhibitorGetVisitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
  public mExhibitorGetVisitorsByMap(
    map: MExhibitorGetVisitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
  public mExhibitorGetVisitorsByMap(
    map: MExhibitorGetVisitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
  public mExhibitorGetVisitorsByMap(
    map: MExhibitorGetVisitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorGetVisitors(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorGetVisitors(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
    public mExhibitorGetVisitors(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
    public mExhibitorGetVisitors(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
    public mExhibitorGetVisitors(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorGetVisitors.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorGetVisitors.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<VisitorListDto>>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/visitors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_GetVisitors']) {
                this.cancelMap['MExhibitor_GetVisitors'].next(null);
            }
            this.cancelMap['MExhibitor_GetVisitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_GetVisitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_GetVisitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_GetVisitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_GetVisitors']) {
            this.cancelMap['MExhibitor_GetVisitors'].complete();
            delete this.cancelMap['MExhibitor_GetVisitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_GetVisitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_GetVisitors')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorInfoSlideActiveByMap(
    map: MExhibitorInfoSlideActive.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
  public mExhibitorInfoSlideActiveByMap(
    map: MExhibitorInfoSlideActive.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideActiveByMap(
    map: MExhibitorInfoSlideActive.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideActiveByMap(
    map: MExhibitorInfoSlideActive.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorInfoSlideActive(
      map.expoId,
      map.exhibitorId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorInfoSlideActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
    public mExhibitorInfoSlideActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorInfoSlideActive.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorInfoSlideActive.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mExhibitorInfoSlideActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MInfoSlideListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/active/infoslide`,
            ActiveUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_InfoSlideActive']) {
                this.cancelMap['MExhibitor_InfoSlideActive'].next(null);
            }
            this.cancelMap['MExhibitor_InfoSlideActive'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_InfoSlideActive']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_InfoSlideActive']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_InfoSlideActive', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_InfoSlideActive']) {
            this.cancelMap['MExhibitor_InfoSlideActive'].complete();
            delete this.cancelMap['MExhibitor_InfoSlideActive'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_InfoSlideActive',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_InfoSlideActive')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorInfoSlideDeleteByMap(
    map: MExhibitorInfoSlideDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
  public mExhibitorInfoSlideDeleteByMap(
    map: MExhibitorInfoSlideDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideDeleteByMap(
    map: MExhibitorInfoSlideDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideDeleteByMap(
    map: MExhibitorInfoSlideDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorInfoSlideDelete(
      map.expoId,
      map.exhibitorId,
      map.infoSlideId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param infoSlideId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorInfoSlideDelete(expoId: string, exhibitorId: string, infoSlideId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
    public mExhibitorInfoSlideDelete(expoId: string, exhibitorId: string, infoSlideId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideDelete(expoId: string, exhibitorId: string, infoSlideId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideDelete(expoId: string, exhibitorId: string, infoSlideId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorInfoSlideDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorInfoSlideDelete.');
        }
        if (infoSlideId === null || infoSlideId === undefined) {
            throw new Error('Required parameter infoSlideId was null or undefined when calling mExhibitorInfoSlideDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExhibitorInfoSlideDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MInfoSlideListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/infoSlide/${encodeURIComponent(String(infoSlideId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_InfoSlideDelete']) {
                this.cancelMap['MExhibitor_InfoSlideDelete'].next(null);
            }
            this.cancelMap['MExhibitor_InfoSlideDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_InfoSlideDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_InfoSlideDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_InfoSlideDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_InfoSlideDelete']) {
            this.cancelMap['MExhibitor_InfoSlideDelete'].complete();
            delete this.cancelMap['MExhibitor_InfoSlideDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_InfoSlideDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_InfoSlideDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorInfoSlideSortByMap(
    map: MExhibitorInfoSlideSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
  public mExhibitorInfoSlideSortByMap(
    map: MExhibitorInfoSlideSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideSortByMap(
    map: MExhibitorInfoSlideSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
  public mExhibitorInfoSlideSortByMap(
    map: MExhibitorInfoSlideSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorInfoSlideSort(
      map.expoId,
      map.exhibitorId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorInfoSlideSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MInfoSlideListUpdateResult>;
    public mExhibitorInfoSlideSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideListUpdateResult>>;
    public mExhibitorInfoSlideSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorInfoSlideSort.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorInfoSlideSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExhibitorInfoSlideSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MInfoSlideListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/sort/infoslide`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_InfoSlideSort']) {
                this.cancelMap['MExhibitor_InfoSlideSort'].next(null);
            }
            this.cancelMap['MExhibitor_InfoSlideSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_InfoSlideSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_InfoSlideSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_InfoSlideSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_InfoSlideSort']) {
            this.cancelMap['MExhibitor_InfoSlideSort'].complete();
            delete this.cancelMap['MExhibitor_InfoSlideSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_InfoSlideSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_InfoSlideSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorInfoSlideUpdateByMap(
    map: MExhibitorInfoSlideUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MInfoSlideUpdateResult>;
  public mExhibitorInfoSlideUpdateByMap(
    map: MExhibitorInfoSlideUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideUpdateResult>>;
  public mExhibitorInfoSlideUpdateByMap(
    map: MExhibitorInfoSlideUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideUpdateResult>>;
  public mExhibitorInfoSlideUpdateByMap(
    map: MExhibitorInfoSlideUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorInfoSlideUpdate(
      map.expoId,
      map.exhibitorId,
      map.MInfoSlideEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MInfoSlideEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorInfoSlideUpdate(expoId: string, exhibitorId: string, MInfoSlideEditDto: MInfoSlideEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MInfoSlideUpdateResult>;
    public mExhibitorInfoSlideUpdate(expoId: string, exhibitorId: string, MInfoSlideEditDto: MInfoSlideEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MInfoSlideUpdateResult>>;
    public mExhibitorInfoSlideUpdate(expoId: string, exhibitorId: string, MInfoSlideEditDto: MInfoSlideEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MInfoSlideUpdateResult>>;
    public mExhibitorInfoSlideUpdate(expoId: string, exhibitorId: string, MInfoSlideEditDto: MInfoSlideEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorInfoSlideUpdate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorInfoSlideUpdate.');
        }
        if (MInfoSlideEditDto === null || MInfoSlideEditDto === undefined) {
            throw new Error('Required parameter MInfoSlideEditDto was null or undefined when calling mExhibitorInfoSlideUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.put<MInfoSlideUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/infoSlide`,
            MInfoSlideEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_InfoSlideUpdate']) {
                this.cancelMap['MExhibitor_InfoSlideUpdate'].next(null);
            }
            this.cancelMap['MExhibitor_InfoSlideUpdate'] = 'put'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_InfoSlideUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_InfoSlideUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_InfoSlideUpdate', 'put'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_InfoSlideUpdate']) {
            this.cancelMap['MExhibitor_InfoSlideUpdate'].complete();
            delete this.cancelMap['MExhibitor_InfoSlideUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_InfoSlideUpdate',
              'put'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_InfoSlideUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorLinkActiveByMap(
    map: MExhibitorLinkActive.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
  public mExhibitorLinkActiveByMap(
    map: MExhibitorLinkActive.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
  public mExhibitorLinkActiveByMap(
    map: MExhibitorLinkActive.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
  public mExhibitorLinkActiveByMap(
    map: MExhibitorLinkActive.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorLinkActive(
      map.expoId,
      map.exhibitorId,
      map.ActiveUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param ActiveUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorLinkActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
    public mExhibitorLinkActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
    public mExhibitorLinkActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
    public mExhibitorLinkActive(expoId: string, exhibitorId: string, ActiveUpdateDto: ActiveUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorLinkActive.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorLinkActive.');
        }
        if (ActiveUpdateDto === null || ActiveUpdateDto === undefined) {
            throw new Error('Required parameter ActiveUpdateDto was null or undefined when calling mExhibitorLinkActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MLinkListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/active/link`,
            ActiveUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_LinkActive']) {
                this.cancelMap['MExhibitor_LinkActive'].next(null);
            }
            this.cancelMap['MExhibitor_LinkActive'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_LinkActive']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_LinkActive']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_LinkActive', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_LinkActive']) {
            this.cancelMap['MExhibitor_LinkActive'].complete();
            delete this.cancelMap['MExhibitor_LinkActive'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_LinkActive',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_LinkActive')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorLinkDeleteByMap(
    map: MExhibitorLinkDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
  public mExhibitorLinkDeleteByMap(
    map: MExhibitorLinkDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
  public mExhibitorLinkDeleteByMap(
    map: MExhibitorLinkDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
  public mExhibitorLinkDeleteByMap(
    map: MExhibitorLinkDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorLinkDelete(
      map.expoId,
      map.exhibitorId,
      map.linkId,
      map.rowVersion,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param linkId 
     * @param rowVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorLinkDelete(expoId: string, exhibitorId: string, linkId: string, rowVersion: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
    public mExhibitorLinkDelete(expoId: string, exhibitorId: string, linkId: string, rowVersion: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
    public mExhibitorLinkDelete(expoId: string, exhibitorId: string, linkId: string, rowVersion: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
    public mExhibitorLinkDelete(expoId: string, exhibitorId: string, linkId: string, rowVersion: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorLinkDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorLinkDelete.');
        }
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling mExhibitorLinkDelete.');
        }
        if (rowVersion === null || rowVersion === undefined) {
            throw new Error('Required parameter rowVersion was null or undefined when calling mExhibitorLinkDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<MLinkListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/link/${encodeURIComponent(String(linkId))}/${encodeURIComponent(String(rowVersion))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_LinkDelete']) {
                this.cancelMap['MExhibitor_LinkDelete'].next(null);
            }
            this.cancelMap['MExhibitor_LinkDelete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_LinkDelete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_LinkDelete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_LinkDelete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_LinkDelete']) {
            this.cancelMap['MExhibitor_LinkDelete'].complete();
            delete this.cancelMap['MExhibitor_LinkDelete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_LinkDelete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_LinkDelete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorLinkSortByMap(
    map: MExhibitorLinkSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
  public mExhibitorLinkSortByMap(
    map: MExhibitorLinkSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
  public mExhibitorLinkSortByMap(
    map: MExhibitorLinkSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
  public mExhibitorLinkSortByMap(
    map: MExhibitorLinkSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorLinkSort(
      map.expoId,
      map.exhibitorId,
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param SortUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorLinkSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MLinkListUpdateResult>;
    public mExhibitorLinkSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkListUpdateResult>>;
    public mExhibitorLinkSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkListUpdateResult>>;
    public mExhibitorLinkSort(expoId: string, exhibitorId: string, SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorLinkSort.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorLinkSort.');
        }
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling mExhibitorLinkSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MLinkListUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/sort/link`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_LinkSort']) {
                this.cancelMap['MExhibitor_LinkSort'].next(null);
            }
            this.cancelMap['MExhibitor_LinkSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_LinkSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_LinkSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_LinkSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_LinkSort']) {
            this.cancelMap['MExhibitor_LinkSort'].complete();
            delete this.cancelMap['MExhibitor_LinkSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_LinkSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_LinkSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorLinkUpdateByMap(
    map: MExhibitorLinkUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MLinkUpdateResult>;
  public mExhibitorLinkUpdateByMap(
    map: MExhibitorLinkUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkUpdateResult>>;
  public mExhibitorLinkUpdateByMap(
    map: MExhibitorLinkUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkUpdateResult>>;
  public mExhibitorLinkUpdateByMap(
    map: MExhibitorLinkUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorLinkUpdate(
      map.expoId,
      map.exhibitorId,
      map.MLinkEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MLinkEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorLinkUpdate(expoId: string, exhibitorId: string, MLinkEditDto: MLinkEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MLinkUpdateResult>;
    public mExhibitorLinkUpdate(expoId: string, exhibitorId: string, MLinkEditDto: MLinkEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MLinkUpdateResult>>;
    public mExhibitorLinkUpdate(expoId: string, exhibitorId: string, MLinkEditDto: MLinkEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MLinkUpdateResult>>;
    public mExhibitorLinkUpdate(expoId: string, exhibitorId: string, MLinkEditDto: MLinkEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorLinkUpdate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorLinkUpdate.');
        }
        if (MLinkEditDto === null || MLinkEditDto === undefined) {
            throw new Error('Required parameter MLinkEditDto was null or undefined when calling mExhibitorLinkUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.put<MLinkUpdateResult>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/link`,
            MLinkEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_LinkUpdate']) {
                this.cancelMap['MExhibitor_LinkUpdate'].next(null);
            }
            this.cancelMap['MExhibitor_LinkUpdate'] = 'put'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_LinkUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_LinkUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_LinkUpdate', 'put'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_LinkUpdate']) {
            this.cancelMap['MExhibitor_LinkUpdate'].complete();
            delete this.cancelMap['MExhibitor_LinkUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_LinkUpdate',
              'put'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_LinkUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorRenderOptionsByMap(
    map: MExhibitorRenderOptions.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<FurnitureOptions>;
  public mExhibitorRenderOptionsByMap(
    map: MExhibitorRenderOptions.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<FurnitureOptions>>;
  public mExhibitorRenderOptionsByMap(
    map: MExhibitorRenderOptions.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<FurnitureOptions>>;
  public mExhibitorRenderOptionsByMap(
    map: MExhibitorRenderOptions.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorRenderOptions(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorRenderOptions(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<FurnitureOptions>;
    public mExhibitorRenderOptions(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<FurnitureOptions>>;
    public mExhibitorRenderOptions(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<FurnitureOptions>>;
    public mExhibitorRenderOptions(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<FurnitureOptions>(`${this.configuration.basePath}/api/MExhibitor/furnitureOptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_RenderOptions']) {
                this.cancelMap['MExhibitor_RenderOptions'].next(null);
            }
            this.cancelMap['MExhibitor_RenderOptions'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_RenderOptions']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_RenderOptions']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_RenderOptions', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_RenderOptions']) {
            this.cancelMap['MExhibitor_RenderOptions'].complete();
            delete this.cancelMap['MExhibitor_RenderOptions'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_RenderOptions',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_RenderOptions')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorRenderPreviewByMap(
    map: MExhibitorRenderPreview.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<RenderResponse>;
  public mExhibitorRenderPreviewByMap(
    map: MExhibitorRenderPreview.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<RenderResponse>>;
  public mExhibitorRenderPreviewByMap(
    map: MExhibitorRenderPreview.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<RenderResponse>>;
  public mExhibitorRenderPreviewByMap(
    map: MExhibitorRenderPreview.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorRenderPreview(
      map.RenderRequest,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param RenderRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorRenderPreview(RenderRequest: RenderRequest, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<RenderResponse>;
    public mExhibitorRenderPreview(RenderRequest: RenderRequest, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<RenderResponse>>;
    public mExhibitorRenderPreview(RenderRequest: RenderRequest, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<RenderResponse>>;
    public mExhibitorRenderPreview(RenderRequest: RenderRequest, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (RenderRequest === null || RenderRequest === undefined) {
            throw new Error('Required parameter RenderRequest was null or undefined when calling mExhibitorRenderPreview.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<RenderResponse>(`${this.configuration.basePath}/api/MExhibitor/preview`,
            RenderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_RenderPreview']) {
                this.cancelMap['MExhibitor_RenderPreview'].next(null);
            }
            this.cancelMap['MExhibitor_RenderPreview'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_RenderPreview']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_RenderPreview']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_RenderPreview', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_RenderPreview']) {
            this.cancelMap['MExhibitor_RenderPreview'].complete();
            delete this.cancelMap['MExhibitor_RenderPreview'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_RenderPreview',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_RenderPreview')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorStartLivestreamByMap(
    map: MExhibitorStartLivestream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorStartLivestreamByMap(
    map: MExhibitorStartLivestream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorStartLivestreamByMap(
    map: MExhibitorStartLivestream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorStartLivestreamByMap(
    map: MExhibitorStartLivestream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorStartLivestream(
      map.expoId,
      map.exhibitorId,
      map.LivestreamSettingsDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param LivestreamSettingsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorStartLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorStartLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorStartLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorStartLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorStartLivestream.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorStartLivestream.');
        }
        if (LivestreamSettingsDto === null || LivestreamSettingsDto === undefined) {
            throw new Error('Required parameter LivestreamSettingsDto was null or undefined when calling mExhibitorStartLivestream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/stream/start`,
            LivestreamSettingsDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_StartLivestream']) {
                this.cancelMap['MExhibitor_StartLivestream'].next(null);
            }
            this.cancelMap['MExhibitor_StartLivestream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_StartLivestream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_StartLivestream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_StartLivestream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_StartLivestream']) {
            this.cancelMap['MExhibitor_StartLivestream'].complete();
            delete this.cancelMap['MExhibitor_StartLivestream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_StartLivestream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_StartLivestream')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorStateGetByMap(
    map: MExhibitorStateGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExhibitorState>;
  public mExhibitorStateGetByMap(
    map: MExhibitorStateGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorState>>;
  public mExhibitorStateGetByMap(
    map: MExhibitorStateGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorState>>;
  public mExhibitorStateGetByMap(
    map: MExhibitorStateGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorStateGet(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorStateGet(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExhibitorState>;
    public mExhibitorStateGet(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorState>>;
    public mExhibitorStateGet(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorState>>;
    public mExhibitorStateGet(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorStateGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorStateGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExhibitorState>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_StateGet']) {
                this.cancelMap['MExhibitor_StateGet'].next(null);
            }
            this.cancelMap['MExhibitor_StateGet'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_StateGet']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_StateGet']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_StateGet', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_StateGet']) {
            this.cancelMap['MExhibitor_StateGet'].complete();
            delete this.cancelMap['MExhibitor_StateGet'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_StateGet',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_StateGet')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorStopLivestreamByMap(
    map: MExhibitorStopLivestream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public mExhibitorStopLivestreamByMap(
    map: MExhibitorStopLivestream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public mExhibitorStopLivestreamByMap(
    map: MExhibitorStopLivestream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public mExhibitorStopLivestreamByMap(
    map: MExhibitorStopLivestream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorStopLivestream(
      map.expoId,
      map.exhibitorId,
      map.LivestreamSettingsDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param LivestreamSettingsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorStopLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public mExhibitorStopLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public mExhibitorStopLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public mExhibitorStopLivestream(expoId: string, exhibitorId: string, LivestreamSettingsDto: LivestreamSettingsDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorStopLivestream.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorStopLivestream.');
        }
        if (LivestreamSettingsDto === null || LivestreamSettingsDto === undefined) {
            throw new Error('Required parameter LivestreamSettingsDto was null or undefined when calling mExhibitorStopLivestream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/stream/stop`,
            LivestreamSettingsDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_StopLivestream']) {
                this.cancelMap['MExhibitor_StopLivestream'].next(null);
            }
            this.cancelMap['MExhibitor_StopLivestream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_StopLivestream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_StopLivestream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_StopLivestream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_StopLivestream']) {
            this.cancelMap['MExhibitor_StopLivestream'].complete();
            delete this.cancelMap['MExhibitor_StopLivestream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_StopLivestream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_StopLivestream')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mExhibitorUpdateExhibitorByMap(
    map: MExhibitorUpdateExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<MExhibitorEditDto>;
  public mExhibitorUpdateExhibitorByMap(
    map: MExhibitorUpdateExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorEditDto>>;
  public mExhibitorUpdateExhibitorByMap(
    map: MExhibitorUpdateExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorEditDto>>;
  public mExhibitorUpdateExhibitorByMap(
    map: MExhibitorUpdateExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mExhibitorUpdateExhibitor(
      map.expoId,
      map.exhibitorId,
      map.MExhibitorEditDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param MExhibitorEditDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mExhibitorUpdateExhibitor(expoId: string, exhibitorId: string, MExhibitorEditDto: MExhibitorEditDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<MExhibitorEditDto>;
    public mExhibitorUpdateExhibitor(expoId: string, exhibitorId: string, MExhibitorEditDto: MExhibitorEditDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<MExhibitorEditDto>>;
    public mExhibitorUpdateExhibitor(expoId: string, exhibitorId: string, MExhibitorEditDto: MExhibitorEditDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<MExhibitorEditDto>>;
    public mExhibitorUpdateExhibitor(expoId: string, exhibitorId: string, MExhibitorEditDto: MExhibitorEditDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mExhibitorUpdateExhibitor.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling mExhibitorUpdateExhibitor.');
        }
        if (MExhibitorEditDto === null || MExhibitorEditDto === undefined) {
            throw new Error('Required parameter MExhibitorEditDto was null or undefined when calling mExhibitorUpdateExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<MExhibitorEditDto>(`${this.configuration.basePath}/api/MExhibitor/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            MExhibitorEditDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MExhibitor_UpdateExhibitor']) {
                this.cancelMap['MExhibitor_UpdateExhibitor'].next(null);
            }
            this.cancelMap['MExhibitor_UpdateExhibitor'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MExhibitor_UpdateExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MExhibitor_UpdateExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MExhibitor_UpdateExhibitor', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MExhibitor_UpdateExhibitor']) {
            this.cancelMap['MExhibitor_UpdateExhibitor'].complete();
            delete this.cancelMap['MExhibitor_UpdateExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MExhibitor_UpdateExhibitor',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MExhibitor_UpdateExhibitor')));
        }
        return handle;
    }

  }

export namespace MExhibitorService {
  export enum Operations {
    MExhibitor_AvailabilityDelete = 'MExhibitor_AvailabilityDelete',
    MExhibitor_AvailabilityGet = 'MExhibitor_AvailabilityGet',
    MExhibitor_AvailabilityGetAll = 'MExhibitor_AvailabilityGetAll',
    MExhibitor_AvailabilityGetExhibitor = 'MExhibitor_AvailabilityGetExhibitor',
    MExhibitor_AvailabilityPost = 'MExhibitor_AvailabilityPost',
    MExhibitor_CalendarActivate = 'MExhibitor_CalendarActivate',
    MExhibitor_CalendarDeactivate = 'MExhibitor_CalendarDeactivate',
    MExhibitor_CalendarDelete = 'MExhibitor_CalendarDelete',
    MExhibitor_CalendarGet = 'MExhibitor_CalendarGet',
    MExhibitor_CalendarGetAll = 'MExhibitor_CalendarGetAll',
    MExhibitor_CalendarGetAllExpo = 'MExhibitor_CalendarGetAllExpo',
    MExhibitor_CalendarNotify = 'MExhibitor_CalendarNotify',
    MExhibitor_CalendarNotifyMessage = 'MExhibitor_CalendarNotifyMessage',
    MExhibitor_CalendarPost = 'MExhibitor_CalendarPost',
    MExhibitor_CalendarUpdateStream = 'MExhibitor_CalendarUpdateStream',
    MExhibitor_ChatActivate = 'MExhibitor_ChatActivate',
    MExhibitor_ChatDeactivate = 'MExhibitor_ChatDeactivate',
    MExhibitor_ContactDelete = 'MExhibitor_ContactDelete',
    MExhibitor_ContactSort = 'MExhibitor_ContactSort',
    MExhibitor_ContactUpdate = 'MExhibitor_ContactUpdate',
    MExhibitor_DisplayActive = 'MExhibitor_DisplayActive',
    MExhibitor_DisplayDelete = 'MExhibitor_DisplayDelete',
    MExhibitor_DisplaySort = 'MExhibitor_DisplaySort',
    MExhibitor_DisplayUpdate = 'MExhibitor_DisplayUpdate',
    MExhibitor_ExhibitorList = 'MExhibitor_ExhibitorList',
    MExhibitor_ExpoList = 'MExhibitor_ExpoList',
    MExhibitor_GetExhibitor = 'MExhibitor_GetExhibitor',
    MExhibitor_GetJitsiToken = 'MExhibitor_GetJitsiToken',
    MExhibitor_GetVisitors = 'MExhibitor_GetVisitors',
    MExhibitor_InfoSlideActive = 'MExhibitor_InfoSlideActive',
    MExhibitor_InfoSlideDelete = 'MExhibitor_InfoSlideDelete',
    MExhibitor_InfoSlideSort = 'MExhibitor_InfoSlideSort',
    MExhibitor_InfoSlideUpdate = 'MExhibitor_InfoSlideUpdate',
    MExhibitor_LinkActive = 'MExhibitor_LinkActive',
    MExhibitor_LinkDelete = 'MExhibitor_LinkDelete',
    MExhibitor_LinkSort = 'MExhibitor_LinkSort',
    MExhibitor_LinkUpdate = 'MExhibitor_LinkUpdate',
    MExhibitor_RenderOptions = 'MExhibitor_RenderOptions',
    MExhibitor_RenderPreview = 'MExhibitor_RenderPreview',
    MExhibitor_StartLivestream = 'MExhibitor_StartLivestream',
    MExhibitor_StateGet = 'MExhibitor_StateGet',
    MExhibitor_StopLivestream = 'MExhibitor_StopLivestream',
    MExhibitor_UpdateExhibitor = 'MExhibitor_UpdateExhibitor'
  }
}
