<div class="wrapper">
  <div class="abs-center wd-xl">
    <!-- START panel-->
    <div class="p">
      <img class="img-thumbnail img-circle center-block" [src]="user.image" alt="Avatar" width="60" height="60"/>
    </div>
    <div class="card widget b0">
      <div class="card-body">
        <p class="text-center">Please login to unlock your screen.</p>
        <form [formGroup]="valForm" role="form" name="lockForm" (submit)="submitForm($event, valForm.value)">
          <div class="form-group has-feedback">
            <input class="form-control" id="exampleInputPassword1" type="password" placeholder="Password"
                   name="password" formControlName="password"/>
            <span class="far fa fa-lock form-control-feedback text-muted"></span>
            <span class="text-danger"
                  *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">This field is required</span>
          </div>
          <div class="clearfix">
            <div class="float-left mt-sm">
              <a class="text-muted" [routerLink]="'/recover'">
                <small>Forgot your password?</small>
              </a>
            </div>
            <div class="float-right">
              <!-- Change this button type to submit to send the form data-->
              <button class="btn btn-sm btn-primary" type="submit">Unlock</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
