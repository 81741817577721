/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface StageState { 
    shortKey?: string;
    current?: string;
}

/**
 * Namespace for property- and property-value-enumerations of StageState.
 */
export namespace StageState {
    /**
     * All properties of StageState.
     */
    export enum Properties {
        shortKey = 'shortKey',
        current = 'current'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageState.
    */
    export const ModelValidators: {[K in keyof StageState]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        current: [
        ],
    };

    /**
    * The FormControlFactory for StageState.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageState> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageState.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageState = {
            shortKey: null,
            current: null,
          }
        ) {
            super(model, StageState.ModelValidators);
        }
    }

}


