<div class="modal-header bg-light">
  <h4 class="modal-title text-primary" i81n>ezFair Settings</h4>
  <!--  <button type="button" class="close" aria-label="Close" (click)="ngbActiveModal.dismiss('Cross click')">-->
  <!--    <span aria-hidden="true">&times;</span>-->
  <!--  </button>-->
</div>
<div class="modal-body">
  <div class="d-flex flex-row justify-content-between">
    <div class="text-muted text-secondary description" i18n>
      Live-Events bei ezFair nutzen Text-, Sprach- und Video-Chats. Diese Einstellungen werden nur für diese Live-Events
      verwendet.<br/>
      Bitte wählen sie ihre Geräte aus und einen Namen mit dem sie für die Dauer des Live-Events angesprochen werden
      möchten.<br/>
      Die Nutzung von Sprach- und Video-Chats ist optional. Sie können auch im späteren Verlauf ihres Besuches aktiviert
      oder deaktiviert werden.
    </div>
    <div class="text-success text-center">
      <a (click)="showHelp($event)" class="btn btn-outline-success"
         title="Warum ? Hier klicken für ein kurzes Einführungsvideo" i18n-title>
        <i class="fa fa-question-circle fa-3x" aria-hidden="true"></i><br>
        <span i18n>Help</span>
      </a>
    </div>
  </div>
  <app-jitsi-configure #jitsiConfigureComponent></app-jitsi-configure>
</div>
<div class="modal-footer bg-light d-flex" [ngClass]="{
'justify-content-between': (jitsiService.jitsiEnabled || jitsiService.mediaChecked),
'justify-content-end': !(jitsiService.jitsiEnabled || jitsiService.mediaChecked)}
">
  <button type="button" class="btn btn-warning"
          *ngIf="jitsiService.jitsiEnabled || jitsiService.mediaChecked"
          (click)="ngbActiveModal.dismiss()" i18n
  >Cancel</button>
  <button type="button" class="btn btn-warning"
          *ngIf="!jitsiService.mediaChecked"
          (click)="useDevices($event)" #closeButton i18n
  >Continue without video and audio
  </button>
  <button type="button" class="btn btn-lg btn-success"
          [disabled]="!jitsiConfigureComponent.formReady"
          *ngIf="jitsiService.mediaChecked"
          (click)="useDevices($event)" #closeButton i18n
  >Use
  </button>
</div>
