/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MExhibitorGroupReferenceDto } from './mExhibitorGroupReferenceDto';


export interface MExhibitorReferenceDto { 
    shortKey?: string;
    name?: string;
    label?: string;
    company?: string;
    exhibitorGroup?: MExhibitorGroupReferenceDto;
    sequence?: number;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorReferenceDto.
 */
export namespace MExhibitorReferenceDto {
    /**
     * All properties of MExhibitorReferenceDto.
     */
    export enum Properties {
        shortKey = 'shortKey',
        name = 'name',
        label = 'label',
        company = 'company',
        exhibitorGroup = 'exhibitorGroup',
        sequence = 'sequence'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorReferenceDto.
    */
    export const ModelValidators: {[K in keyof MExhibitorReferenceDto]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        name: [
        ],
        label: [
        ],
        company: [
        ],
        exhibitorGroup: [
        ],
        sequence: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorReferenceDto = {
            shortKey: null,
            name: null,
            label: null,
            company: null,
            exhibitorGroup: null,
            sequence: null,
          }
        ) {
            super(model, MExhibitorReferenceDto.ModelValidators);
        }
    }

}


