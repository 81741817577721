import {EventEmitter, Injectable} from '@angular/core';
import {FurnitureOptions, MExhibitorService} from '../ezfair-api';

@Injectable({
  providedIn: 'root'
})
export class RenderOptionsService {
  public optionsLoaded: EventEmitter<FurnitureOptions> = new EventEmitter<FurnitureOptions>();
  public designOptions: FurnitureOptions;

  public materials: Array<string> = new Array<string>();

  constructor(
    private exhibitorService: MExhibitorService
  ) {
    this.exhibitorService.mExhibitorRenderOptions().subscribe(options => {
      this.designOptions = options;
      this.materials.length = 0;
      options.materials.forEach(material => {
        this.materials.push(material);
      });
      this.optionsLoaded.emit(options);
    });
  }
}
