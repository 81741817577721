/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MExhibitorGroupEditDto { 
    id?: string;
    name: string;
    description?: string;
    logoSquare?: string;
    logoSquareRaw?: string;
    logoWide?: string;
    logoWideRaw?: string;
    sequence?: number;
    active?: boolean;
    deleted?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorGroupEditDto.
 */
export namespace MExhibitorGroupEditDto {
    /**
     * All properties of MExhibitorGroupEditDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        logoSquare = 'logoSquare',
        logoSquareRaw = 'logoSquareRaw',
        logoWide = 'logoWide',
        logoWideRaw = 'logoWideRaw',
        sequence = 'sequence',
        active = 'active',
        deleted = 'deleted'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorGroupEditDto.
    */
    export const ModelValidators: {[K in keyof MExhibitorGroupEditDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoSquareRaw: [
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWideRaw: [
        ],
        sequence: [
        ],
        active: [
        ],
        deleted: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorGroupEditDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorGroupEditDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorGroupEditDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorGroupEditDto = {
            id: null,
            name: null,
            description: null,
            logoSquare: null,
            logoSquareRaw: null,
            logoWide: null,
            logoWideRaw: null,
            sequence: null,
            active: null,
            deleted: null,
          }
        ) {
            super(model, MExhibitorGroupEditDto.ModelValidators);
        }
    }

}


