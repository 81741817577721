/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { RenderExhibitor } from './renderExhibitor';
import { Material } from './material';


export interface RenderRequest { 
    width?: number;
    height?: number;
    quality?: number;
    format?: string;
    hallWidth?: number;
    hallHeight?: number;
    hallName?: string;
    hallMaterials?: { [key: string]: Material; };
    hallImages?: { [key: string]: string; };
    exhibitors?: Array<RenderExhibitor>;
    transparent?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of RenderRequest.
 */
export namespace RenderRequest {
    /**
     * All properties of RenderRequest.
     */
    export enum Properties {
        width = 'width',
        height = 'height',
        quality = 'quality',
        format = 'format',
        hallWidth = 'hallWidth',
        hallHeight = 'hallHeight',
        hallName = 'hallName',
        hallMaterials = 'hallMaterials',
        hallImages = 'hallImages',
        exhibitors = 'exhibitors',
        transparent = 'transparent'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of RenderRequest.
    */
    export const ModelValidators: {[K in keyof RenderRequest]: [string, ValidatorFn][]} = {
        width: [
        ],
        height: [
        ],
        quality: [
        ],
        format: [
        ],
        hallWidth: [
        ],
        hallHeight: [
        ],
        hallName: [
        ],
        hallMaterials: [
        ],
        hallImages: [
        ],
        exhibitors: [
        ],
        transparent: [
        ],
    };

    /**
    * The FormControlFactory for RenderRequest.
    */
    export class FormControlFactory extends BaseFormControlFactory<RenderRequest> {

        /**
         * Constructor.
         *
         * @param model An existing model for RenderRequest.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: RenderRequest = {
            width: null,
            height: null,
            quality: null,
            format: null,
            hallWidth: null,
            hallHeight: null,
            hallName: null,
            hallMaterials: null,
            hallImages: null,
            exhibitors: null,
            transparent: null,
          }
        ) {
            super(model, RenderRequest.ModelValidators);
        }
    }

}


