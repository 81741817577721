import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../modules/security/services/auth.service';

@Component({
  selector: 'app-pwd-less',
  templateUrl: './pwd-less.component.html',
  styleUrls: ['./pwd-less.component.scss']
})
export class PwdLessComponent implements OnInit {
  submitted: boolean;

  constructor(private fb: FormBuilder, private router: Router, private auth: AuthService) {
  }

  valForm: FormGroup;
  errorMessage: string;
  errorMessageLogin: string;
  valForm2: FormGroup;
  valForm3: FormGroup;
  errorMessage2: any;

  ngOnInit() {
    this.valForm = this.fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])]
    });

    this.valForm2 = this.fb.group({
      'token': [null, Validators.compose([Validators.required])]
    });

    this.valForm3 = this.fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required]
    });

    // if (this.auth.isLoggedIn()) {
    //   this.auth.logout();
    // }
  }

  submitForm($ev) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.auth.startPwdLess(this.valForm.controls['email'].value)
        .then(res => {
            this.errorMessage = '';
            this.submitted = true;
          },
          error => {
            this.errorMessage = 'An error occurred, please try again.';
          }
        );
    }
  }

  submitToken($event: Event) {
    $event.preventDefault();
    for (const c in this.valForm2.controls) {
      if (c) {
        this.valForm2.controls[c].markAsTouched();
      }
    }

    if (this.valForm2.valid) {
      this.auth
        .validatePwdLess(this.valForm.controls['email'].value, this.valForm2.controls['token'].value)
        .then((res) => {
            this.errorMessage = '';
            if (this.auth.getUserName() !== this.auth.getUserId()) {
              this.router.navigate(['profile']);
            } else {
              this.router.navigate(['/']);
            }
          },
          error => {
          if (error.status === 403) {
            this.router.navigate(['noadmin']);
          }
            this.errorMessage = 'Invalid, please try again.';
          }
        );
    }
  }

  submitLoginForm($event: Event, value: any) {
    $event.preventDefault();
    for (const c in this.valForm3.controls) {
      if (c) {
        this.valForm3.controls[c].markAsTouched();
      }
    }
    if (this.valForm3.valid) {
      this.auth.login(this.valForm3.controls['email'].value, this.valForm3.controls['password'].value)
        .subscribe(res => {
            this.errorMessageLogin = '';
            this.router.navigate(['home']);
          },
          error => {
            this.errorMessageLogin = 'Invalid username or password';
          }
        );
    }
  }

  backToLogin($event: MouseEvent) {
    $event.preventDefault();
    this.submitted = false;
  }
}
