<div style="display: flex; flex-direction: column; min-height: 100%">
  <app-header></app-header>
  <!-- sidebar-->
  <div style="flex: 1 1 auto; display: flex; flex-direction: row; max-width: 100%;" class="bg-light">
<!--    <div style="flex: 0 0 12em; overflow: hidden;">-->
<!--      <app-sidebar></app-sidebar>-->
<!--    </div>-->
    <div style="flex: 1 1 auto; padding: 1.5em; background-color: white; min-height: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div style="margin-top: auto">
    <app-footer></app-footer>
  </div>
</div>
<app-user-chat></app-user-chat>
