import {Injectable} from '@angular/core';
import {LinkBase} from '../objects/link-base';
import {environment} from '../../environments/environment';
import {AuthService} from '../modules/security/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LinkHandlerService {

  lastTs: number;
  baseUrl: string;
  basePort: string;

  constructor(
    private authService: AuthService
  ) {
    this.mapUrl();
    if (!this.lastTs || this.lastTs < Date.now() - 5000) {
      this.lastTs = Date.now();
    }
  }

  mapUrl() {
    const url = location.href;
    let baseUrl: string;
    const testEnvironment = url.match(/http[s]?\:\/\/([\w\.\-]+)\:(\d+)(\/.*)?$/);

    let port = '';
    if (testEnvironment && testEnvironment.length > 1) {
      port = `:${parseInt(testEnvironment[2], 10) - 100}`;
    }

    let subdomain = url.match(/https?\:\/\/manage\.([\w-]+)\.(\w+)\.(\w+)/);
    if (subdomain) {
      baseUrl = `${subdomain[1]}.${subdomain[2]}.${subdomain[3]}`;
      baseUrl = `//${baseUrl}${port}/`;
    } else {
      subdomain = url.match(/http[s]?\:\/\/manage\.(\w+)\.(\w+)/);
      if (subdomain) {
        baseUrl = `${subdomain[1]}.${subdomain[2]}`;
        baseUrl = `//${baseUrl}${port}/`;
      } else {
        subdomain = url.match(/https?\:\/\/([\w-.]+)/);
        if (subdomain) {
          baseUrl = `${subdomain[1]}`;
          baseUrl = `//${baseUrl}${port}/`;
        }
      }
    }
    this.baseUrl = baseUrl;
    this.basePort = port;
  }

  prepare(name: string, shortKey: string): string {
    name = name.trim();
    name = name.replace(/ /g, '-');
    name = name.replace(/_/g, '-');
    name = name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '-');
    name = name.substr(0, 39);
    // return this.codec.encodeKey(name) + '_' + shortKey;
    return name + '_' + shortKey;
  }

  parse(path: string): string {
    path = path.replace(/.*_/, '');
    return path;
  }

  construct(base: string, main?: LinkBase, sub?: LinkBase): string {
    let area = '';
    if (base === 'stage') {
      area = `/s`;
    } else if (sub) {
      area = '/e';
    }
    let url = `${this.baseUrl}`;
    if (main.domain && main.domain.trim() !== '' && environment.production) {
      const domain = main.domain.split(',')[0];
      if (domain.match(/local\.test/) || domain.match(/ube-asus/)) {
        // url = `//${domain}${this.settingsService.app.clientPort}/${base}`;
      } else {
        url = `//${domain}/${base}`;
      }
    }
    // if (main) {
    //   url += `/${this.prepare(main.name, main.shortKey)}`;
    // }
    if (area && sub) {
      url += area;
    }
    if (sub) {
      url += `/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}&expo=${main.shortKey}`;
  }

  constructBase(main: LinkBase): string {
    let url = `${this.baseUrl}${environment.defaultLocale}`;
    if (!main.domain || main.domain.trim() === '') {
      main.domain = environment.defaultClientDomain;
      url = `//${main.domain}/${environment.defaultLocale}`;
    }
    if (main.domain && main.domain.trim() !== '' && environment.production) {
      const domain = main.domain.split(',')[0];
      url = `//${domain}/${environment.defaultLocale}`;
    }
    // url += `/exhibition/${this.prepare(main.name, main.shortKey)}`;
    return url;
  }

  constructExpo(main: LinkBase): string {
    const url = this.constructBase(main);
    return url + `?noCache=${this.lastTs}`;
  }

  constructExhibitor(main: LinkBase, sub: LinkBase): string {
    let url = this.constructBase(main);
    if (sub) {
      url += `/e/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}&expo=${main.shortKey}`;
  }

  constructStage(main: LinkBase, sub: LinkBase): string {
    let url = this.constructBase(main);
    if (sub) {
      url += `/s/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}&expo=${main.shortKey}`;
  }

  constructStageEvent(main: LinkBase, sub: LinkBase, eventId: string): string {
    let url = this.constructStage(main, sub) + `/${eventId}`;
    return url + `?noCache=${this.lastTs}&expo=${main.shortKey}`;
  }

  createId(): string {
    const parts = 4;
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return 'tmp_' + stringArr.join('-');
  }
}
