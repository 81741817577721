import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EqualValidator} from '../../../shared/directives/equal-validator.directive';
import {ToasterService} from '../../../core/toaster.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../../ezfair-api';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  valForm: FormGroup;
  errorMessage: string;

  submitted: boolean;

  constructor(fb: FormBuilder, private router: Router, private accountService: AccountService
    , private toasterService: ToasterService, private activeModal: NgbActiveModal) {

    // const password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
    const pattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{6,16}';
    const password = new FormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)]));
    const certainPassword = new FormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)]));

    this.valForm = fb.group({
      'password': password,
      'confirmPassword': certainPassword
    }, {
      validators: EqualValidator('password', 'confirmPassword')
    });
  }

  submitForm($ev, value: any) {
    this.submitted = true;
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      this.accountService.accountSetPassword({password: this.valForm.value['password']})
        .subscribe(value1 => {
            this.errorMessage = '';
            this.toasterService.success('Password set');
            this.router.navigate(['admin'])
              .then(value2 => {
                this.activeModal.close();
              });
          },
          error => {
            this.submitted = false;
            this.toasterService.error(error.error);
            this.errorMessage = error.error;
          });
    } else {
      this.submitted = false;
    }
  }

  ngOnInit() {
  }

}
