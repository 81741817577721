<p class="text-center pv">SIGN IN TO CONTINUE.</p>
<form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
      (submit)="submitForm($event, valForm.value)">
  <div class="form-group has-feedback">
    <div class="input-group">
      <input class="form-control" id="exampleInputEmail1" type="email" name="email" placeholder="Enter email"
             autocomplete="email" formControlName="email" required=""/>
      <div class="input-group-append">
        <span class="input-group-text" id="emailIcon"><i class="far fa fa-envelope"></i></span>
      </div>
    </div>
    <app-control-validation-results [formGroup]="valForm" [validatedControl]="'email'"></app-control-validation-results>
  </div>
  <div class="form-group has-feedback">
    <div class="input-group">
      <input class="form-control" id="password" type="password" name="password" placeholder="Password"
             formControlName="password" required="" autocomplete="current-password"/>
      <div class="input-group-append">
        <span class="input-group-text" id="passwordIcon"><i class="fa fa-lock"></i></span>
      </div>
    </div>
    <app-control-validation-results [formGroup]="valForm" [validatedControl]="'password'"></app-control-validation-results>
  </div>
  <div class="clearfix">
    <span class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</span>
  </div>
  <div class="clearfix">
    <div class="clearfix">
      <span class="text-muted">If you forgot your password, please use the <a
        routerLink="pwdless">passwordless login</a> and set a new one after logging in.</span>
    </div>
  </div>
  <button class="btn btn-block btn-primary mt-lg" type="submit">Login</button>
</form>
