/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { FloorPlanDto } from './floorPlanDto';


export interface HallDto { 
    id?: string;
    name?: string;
    label?: string;
    description?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    floorplans?: Array<FloorPlanDto>;
}

/**
 * Namespace for property- and property-value-enumerations of HallDto.
 */
export namespace HallDto {
    /**
     * All properties of HallDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        label = 'label',
        description = 'description',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        floorplans = 'floorplans'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of HallDto.
    */
    export const ModelValidators: {[K in keyof HallDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        label: [
        ],
        description: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        floorplans: [
        ],
    };

    /**
    * The FormControlFactory for HallDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<HallDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for HallDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: HallDto = {
            id: null,
            name: null,
            label: null,
            description: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            floorplans: null,
          }
        ) {
            super(model, HallDto.ModelValidators);
        }
    }

}


