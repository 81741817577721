import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JitsiService} from '../jitsi.service';
import {JitsiConfigureComponent} from '../jitsi-configure/jitsi-configure.component';

@Component({
  selector: 'app-jitsi-configuration-dialog',
  templateUrl: './jitsi-configuration-dialog.component.html',
  styleUrls: ['./jitsi-configuration-dialog.component.scss']
})
export class JitsiConfigurationDialogComponent implements OnInit, AfterViewInit {
  // @ts-ignore
  @ViewChild('jitsiConfigureComponent') jitsiConfigureComponent: JitsiConfigureComponent;

  // @ts-ignore
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    public jitsiService: JitsiService
  ) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.closeButton.nativeElement.focus();
  }

  useDevices($event: MouseEvent) {
    $event.preventDefault();
    const result = {
      microphone: this.jitsiConfigureComponent.selectedMicrophone,
      camera: this.jitsiConfigureComponent.selectedCamera,
      speaker: this.jitsiConfigureComponent.selectedSpeaker,
      cameraOff: this.jitsiConfigureComponent.cameraOff,
      microphoneOff: this.jitsiConfigureComponent.microphoneOff
    };
    this.ngbActiveModal.close(result);
  }

  showHelp($event: MouseEvent) {
    $event.preventDefault();
    alert('help');
  }
}
