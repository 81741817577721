/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { TrackingEventId } from './trackingEventId';


export interface TrackingDto { 
    sessionId?: string;
    expoId?: string;
    subId?: string;
    eventId?: TrackingEventId;
    comment?: string;
    comment2?: string;
}

/**
 * Namespace for property- and property-value-enumerations of TrackingDto.
 */
export namespace TrackingDto {
    /**
     * All properties of TrackingDto.
     */
    export enum Properties {
        sessionId = 'sessionId',
        expoId = 'expoId',
        subId = 'subId',
        eventId = 'eventId',
        comment = 'comment',
        comment2 = 'comment2'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TrackingDto.
    */
    export const ModelValidators: {[K in keyof TrackingDto]: [string, ValidatorFn][]} = {
        sessionId: [
        ],
        expoId: [
        ],
        subId: [
        ],
        eventId: [
        ],
        comment: [
        ],
        comment2: [
        ],
    };

    /**
    * The FormControlFactory for TrackingDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<TrackingDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for TrackingDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TrackingDto = {
            sessionId: null,
            expoId: null,
            subId: null,
            eventId: null,
            comment: null,
            comment2: null,
          }
        ) {
            super(model, TrackingDto.ModelValidators);
        }
    }

}


