/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface BoothOption { 
    name?: string;
    availablePositions?: Array<string>;
}

/**
 * Namespace for property- and property-value-enumerations of BoothOption.
 */
export namespace BoothOption {
    /**
     * All properties of BoothOption.
     */
    export enum Properties {
        name = 'name',
        availablePositions = 'availablePositions'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothOption.
    */
    export const ModelValidators: {[K in keyof BoothOption]: [string, ValidatorFn][]} = {
        name: [
        ],
        availablePositions: [
        ],
    };

    /**
    * The FormControlFactory for BoothOption.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothOption> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothOption.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothOption = {
            name: null,
            availablePositions: null,
          }
        ) {
            super(model, BoothOption.ModelValidators);
        }
    }

}


