/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CalendarBookedSlotMeta { 
    notes?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlotMeta.
 */
export namespace CalendarBookedSlotMeta {
    /**
     * All properties of CalendarBookedSlotMeta.
     */
    export enum Properties {
        notes = 'notes'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlotMeta.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlotMeta]: [string, ValidatorFn][]} = {
        notes: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlotMeta.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlotMeta> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlotMeta.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlotMeta = {
            notes: null,
          }
        ) {
            super(model, CalendarBookedSlotMeta.ModelValidators);
        }
    }

}


