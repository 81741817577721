/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExpoListDto { 
    id?: string;
    name?: string;
    description?: string;
    descriptionLong?: string;
    active?: boolean;
    dateStart?: Date;
    dateEnd?: Date;
    descriptionEntry?: string;
    seoKeywords?: string;
    homepage?: string;
    privacy?: string;
    shortKey?: string;
    logoSquare?: string;
    logoWide?: string;
    timeZone?: string;
    rowVersion?: number;
    isPrivate?: boolean;
    ownerName?: string;
    consultantName?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoListDto.
 */
export namespace ExpoListDto {
    /**
     * All properties of ExpoListDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        active = 'active',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        descriptionEntry = 'descriptionEntry',
        seoKeywords = 'seoKeywords',
        homepage = 'homepage',
        privacy = 'privacy',
        shortKey = 'shortKey',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        timeZone = 'timeZone',
        rowVersion = 'rowVersion',
        isPrivate = 'isPrivate',
        ownerName = 'ownerName',
        consultantName = 'consultantName'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoListDto.
    */
    export const ModelValidators: {[K in keyof ExpoListDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        active: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        descriptionEntry: [
        ],
        seoKeywords: [
        ],
        homepage: [
        ],
        privacy: [
        ],
        shortKey: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        timeZone: [
        ],
        rowVersion: [
        ],
        isPrivate: [
        ],
        ownerName: [
        ],
        consultantName: [
        ],
    };

    /**
    * The FormControlFactory for ExpoListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoListDto = {
            id: null,
            name: null,
            description: null,
            descriptionLong: null,
            active: null,
            dateStart: null,
            dateEnd: null,
            descriptionEntry: null,
            seoKeywords: null,
            homepage: null,
            privacy: null,
            shortKey: null,
            logoSquare: null,
            logoWide: null,
            timeZone: null,
            rowVersion: null,
            isPrivate: null,
            ownerName: null,
            consultantName: null,
          }
        ) {
            super(model, ExpoListDto.ModelValidators);
        }
    }

}


