/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MExhibitorCreateDto { 
    id?: string;
    name?: string;
    logoWide?: string;
    logoSquare?: string;
    description?: string;
    company?: string;
    tags?: Array<string>;
    chatEnabled?: boolean;
    schedulingActive?: boolean;
    managerId?: string;
    managerName?: string;
    managerChangeEmail?: string;
    logoSquareRaw?: string;
    logoWideRaw?: string;
    hidden?: boolean;
    categoryId?: string;
    exhibitorGroupId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorCreateDto.
 */
export namespace MExhibitorCreateDto {
    /**
     * All properties of MExhibitorCreateDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        logoWide = 'logoWide',
        logoSquare = 'logoSquare',
        description = 'description',
        company = 'company',
        tags = 'tags',
        chatEnabled = 'chatEnabled',
        schedulingActive = 'schedulingActive',
        managerId = 'managerId',
        managerName = 'managerName',
        managerChangeEmail = 'managerChangeEmail',
        logoSquareRaw = 'logoSquareRaw',
        logoWideRaw = 'logoWideRaw',
        hidden = 'hidden',
        categoryId = 'categoryId',
        exhibitorGroupId = 'exhibitorGroupId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorCreateDto.
    */
    export const ModelValidators: {[K in keyof MExhibitorCreateDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        logoWide: [
        ],
        logoSquare: [
        ],
        description: [
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        tags: [
        ],
        chatEnabled: [
        ],
        schedulingActive: [
        ],
        managerId: [
        ],
        managerName: [
        ],
        managerChangeEmail: [
        ],
        logoSquareRaw: [
        ],
        logoWideRaw: [
        ],
        hidden: [
        ],
        categoryId: [
        ],
        exhibitorGroupId: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorCreateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorCreateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorCreateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorCreateDto = {
            id: null,
            name: null,
            logoWide: null,
            logoSquare: null,
            description: null,
            company: null,
            tags: null,
            chatEnabled: null,
            schedulingActive: null,
            managerId: null,
            managerName: null,
            managerChangeEmail: null,
            logoSquareRaw: null,
            logoWideRaw: null,
            hidden: null,
            categoryId: null,
            exhibitorGroupId: null,
          }
        ) {
            super(model, MExhibitorCreateDto.ModelValidators);
        }
    }

}


