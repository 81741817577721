import {EventEmitter, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MongoChatItemExtended} from '../objects/mongo-chat-item.extended';
import {SignalsService} from './signals.service';
import {
  AdminChatChannelMessageDto,
  AdminChatService,
  ChatChannelMessageDto,
  ChatService,
  MongoChatItemDto, MongoChatMessage
} from '../../../ezfair-api';
import {VideoCallComponent} from '../components/video-call/video-call.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UserChatService {
  // public sessions: { [selfId: string]: { [partnerId: string]: MongoChatItemExtended } } = {};
  public sessions: Map<string, Map<string, MongoChatItemExtended>> = new Map<string, Map<string, MongoChatItemExtended>>()

  public unreadMessagesChanged = new Subject();

  sessionClosed: EventEmitter<{ selfId: string, partnerId: string }> = new EventEmitter<{ selfId: string, partnerId: string }>();
  sessionOpened: EventEmitter<{ selfId: string, partnerId: string }> = new EventEmitter<{ selfId: string, partnerId: string }>();
  unreadMessages: number;

  showChatWindow: boolean;
  videoActive: boolean;

  callModal: NgbModalRef;

  constructor(
    private adminChatService: AdminChatService
    , private signalsService: SignalsService
    , private dialogService: ConfirmationDialogService
    , private ngbModalService: NgbModal
  ) {
    this.signalsService.Connected
      .subscribe(() => {
        this.signalsService.PrivateChatMessageReceived
          .subscribe((message) => {
            if (!this.sessions.has(message.selfId)) {
              this.sessions.set(message.selfId, new Map<string, MongoChatItemExtended>());
            }
            const session = this.sessions.get(message.selfId);
            if (session.has(message.partnerId)) {
              const partner = this.sessions.get(message.selfId).get(message.partnerId);
              message.chatMessages.forEach(value => {
                partner.addMessage(value);
              });
              if (message.partnerName && message.partnerName !== '') {
                partner.partnerName = message.partnerName;
              }
              partner.totalMessages = partner.chatMessages.length;
            } else {
              this.sessions.get(message.selfId).set(message.partnerId, new MongoChatItemExtended(message));
              const partner = this.sessions.get(message.selfId).get(message.partnerId);
              partner.totalMessages = partner.chatMessages.length;
              partner.newCommand
                .subscribe(command => {
                  this.processCommand(command.session, command.command);
                });

            }
          });
      });
  }

  getSenderName(session: MongoChatItemExtended, message: MongoChatMessage): string {
    return message.sender !== session.selfId ? session.partnerName : $localize`you`;
  }

  private processCommand(session: MongoChatItemExtended, message: MongoChatMessage) {
    const senderName = this.getSenderName(session, message);
    if (message.message === '*_OFFERVIDEOCALL_*') {
      this.dialogService.confirm($localize`:videoCall.title|:Video-Anruf annehmen`,
        $localize`:videoCall.message|:Möchten Sie einen Video-Anruf von ${senderName} annehmen?`,
        $localize`:videoCall.accept|:Annehmen`,
        $localize`:videoCall.reject|:Nicht annehmen`, 'sm'
      )
        .then(value => {
          if (value) {
            this.acceptCall(session);
          } else {
            this.rejectCall(session);
          }
        });
      return;
    } else if (message.message.startsWith('*_OPENCALL_*|')) {
      if (!this.callModal) {
        const callId = message.message.replace(/\*_OPENCALL_\*\|/, '');
        this.callModal = this.ngbModalService.open(VideoCallComponent, {
          backdrop: 'static',
          keyboard: false,
          size: 'lg'
        });
        const instance = this.callModal.componentInstance as VideoCallComponent;
        instance.callId = callId;
        instance.name = senderName;
        instance.senderName = senderName;
        this.callModal.result
          .then(value => {
            this.callModal = null;
            this.sendMessage(session.selfId, session.partnerId, '*_HANGUPCALL_*');
          }, reason => {
            this.callModal = null;
            this.sendMessage(session.selfId, session.partnerId, '*_HANGUPCALL_*');
          })
          .catch(reason => {
            this.callModal = null;
            this.sendMessage(session.selfId, session.partnerId, '*_HANGUPCALL_*');
          });
      }
      return;
    } else if (message.message === '*_HANGUPCALL_*') {
      if (this.callModal) {
        (this.callModal.componentInstance as VideoCallComponent).hangupCall();
      }
      this.callModal = null;
      return;
    }
  }

  sendMessage(selfId: string, partnerId: string, message: string) {
    this.adminChatService.adminChatPrivateChatSendMessage({selfId, partnerId, message})
      .subscribe(item => {
        if (this.sessions.has(selfId)) {
          if (this.sessions.get(selfId).has(partnerId)) {
            const partner = this.sessions.get(selfId).get(partnerId);
            item.chatMessages.forEach(msg => {
              partner.addMessage(msg);
            });
            if (item.partnerName !== partner.partnerName && item.partnerName && item.partnerName !== '') {
              partner.partnerName = item.partnerName;
            }
          } else {
            this.sessions.get(selfId).set(partnerId, new MongoChatItemExtended(item));
            const partner = this.sessions.get(selfId).get(partnerId);
            partner.newCommand
              .subscribe(command => {
                this.processCommand(command.session, command.command);
              });
          }
        }
      });
  }

  closeSession(item: { selfId: string, partnerId: string }) {
    if (this.sessions.has(item.selfId) && this.sessions.get(item.selfId).has(item.partnerId)) {
      const session = this.sessions.get(item.selfId).get(item.partnerId);
      if (session) {
        this.adminChatService.adminChatPrivateChatClose(session.selfId, session.partnerId)
          .subscribe(() => {
            this.sessions.get(item.selfId).delete(item.partnerId);
            if (this.sessions.get(item.selfId).size === 0) {
              this.sessions.delete(item.selfId);
            }
            this.sessionClosed.emit(item);
          });
      }
    }
  }

  recountUnread() {
    let count = 0;
    this.sessions.forEach(session => {
      session.forEach(item => {
        if (item.unreadMessages > 0) {
          count++;
        }
      });
    });
    if (count !== this.unreadMessages) {
      this.unreadMessages = count;
      this.unreadMessagesChanged.next(null);
    }
  }

  persistRead(session: MongoChatItemExtended) {
    // let sessions: Array<UserChatList> = this.storageService.get('chatList');
    // if (!sessions) {
    //   sessions = new Array<UserChatList>();
    // }
    // for (let i = 0; i < sessions.length; i++) {
    //   if (sessions[i].id === data.id) {
    //     sessions[i].readMessages = data.messages.length;
    //   }
    // }
    // this.storageService.store('chatList', sessions);
  }

  acceptCall(session: MongoChatItemExtended) {
    if (session) {
      const msg: AdminChatChannelMessageDto = {
        partnerType: 2,
        selfId: session.selfId,
        partnerId: session.partnerId,
        message: ''
      };
      this.adminChatService.adminChatPrivateChatVideoAccept(msg);
    }
  }

  rejectCall(session: MongoChatItemExtended) {
    if (session) {
      const msg: AdminChatChannelMessageDto = {
        partnerType: 2,
        selfId: session.selfId,
        partnerId: session.partnerId,
        message: ''
      };
      this.adminChatService.adminChatPrivateChatVideoDeny(msg);
    }
  }

  hangupCall(session: MongoChatItemExtended) {
    if (session) {
      const msg: AdminChatChannelMessageDto = {
        partnerType: 2,
        selfId: session.selfId,
        partnerId: session.partnerId,
        message: ''
      };
      this.adminChatService.adminChatPrivateChatVideoHangup(msg);
    }
  }

  // openChat(exhibitorId: string) {
  //   if (!this.sessions[exhibitorId]) {
  //     const name = exhibitor.label && exhibitor.label.trim() !== '' ? exhibitor.label : exhibitor.name;
  //     const session: MongoChatItemExtended = new MongoChatItemExtended({
  //       partnerName: name,
  //       partnerId: exhibitor.id,
  //       isBlocked: false,
  //       isClosed: false
  //     });
  //     this.sessions[exhibitor.id] = session;
  //     this.sessionList.push(exhibitor.id);
  //   }
  // }
  GetBacklog(selfId: string, partnerId: string): Observable<MongoChatItemDto> {
    return this.adminChatService.adminChatPrivateChatGet(selfId, partnerId);
  }

  privateChatUpdateTimestamp(selfId, partnerId: string, timestamp: Date) {
    this.adminChatService.adminChatMarkAsRead(selfId, partnerId, timestamp);
  }

  openChatWindow() {
    this.showChatWindow = !this.showChatWindow;
  }

  offerVideoCall(selfId: string, partnerId: string) {
    this.adminChatService.adminChatPrivateChatVideoOffer({selfId: selfId, partnerId: partnerId, message: ''});
  }
}

