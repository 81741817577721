import {EventEmitter, Injectable} from '@angular/core';
import {CalendarBookedSlotDto} from '../ezfair-api';
import {SignalsService} from '../modules/signals-chat/services/signals.service';
import {AuthService} from '../modules/security/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarHandlerService {
  bookedSlots: Array<CalendarBookedSlotDto> = new Array<CalendarBookedSlotDto>();
  bookingRequestReceived: EventEmitter<CalendarBookedSlotDto> = new EventEmitter<CalendarBookedSlotDto>();

  constructor(
    private signalsService: SignalsService
    , private authService: AuthService
  ) {
    this.authService.onLogin.subscribe(value => {
      this.bookedSlots.length = 0;
    });

    this.signalsService.BookingListMessageReceived
      .subscribe(bookings => {
      this.bookedSlots.length = 0;
      bookings.forEach(value => {
        this.bookedSlots.push(value);
      });
    });

    this.signalsService.BookingMessageReceived
      .subscribe(booking => {
      this.bookedSlots.push(booking);
      this.bookingRequestReceived.emit(booking);
    });
  }
}
