/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MExpoEditDto } from './mExpoEditDto';
import { MExhibitorListItem } from './mExhibitorListItem';
import { MStageListItem } from './mStageListItem';


export interface MExpoResult { 
    expo?: MExpoEditDto;
    exhibitors?: Array<MExhibitorListItem>;
    stages?: Array<MStageListItem>;
    expoCount?: number;
}

/**
 * Namespace for property- and property-value-enumerations of MExpoResult.
 */
export namespace MExpoResult {
    /**
     * All properties of MExpoResult.
     */
    export enum Properties {
        expo = 'expo',
        exhibitors = 'exhibitors',
        stages = 'stages',
        expoCount = 'expoCount'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExpoResult.
    */
    export const ModelValidators: {[K in keyof MExpoResult]: [string, ValidatorFn][]} = {
        expo: [
        ],
        exhibitors: [
        ],
        stages: [
        ],
        expoCount: [
        ],
    };

    /**
    * The FormControlFactory for MExpoResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExpoResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExpoResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExpoResult = {
            expo: null,
            exhibitors: null,
            stages: null,
            expoCount: null,
          }
        ) {
            super(model, MExpoResult.ModelValidators);
        }
    }

}


