/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BookingState } from './bookingState';


export interface CalendarBookedSlotUpdateDto { 
    rowId?: number;
    availabilityId?: number;
    message?: string;
    meta?: { [key: string]: string; };
    bookingState?: BookingState;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlotUpdateDto.
 */
export namespace CalendarBookedSlotUpdateDto {
    /**
     * All properties of CalendarBookedSlotUpdateDto.
     */
    export enum Properties {
        rowId = 'rowId',
        availabilityId = 'availabilityId',
        message = 'message',
        meta = 'meta',
        bookingState = 'bookingState'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlotUpdateDto.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlotUpdateDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        availabilityId: [
        ],
        message: [
        ],
        meta: [
        ],
        bookingState: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlotUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlotUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlotUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlotUpdateDto = {
            rowId: null,
            availabilityId: null,
            message: null,
            meta: null,
            bookingState: null,
          }
        ) {
            super(model, CalendarBookedSlotUpdateDto.ModelValidators);
        }
    }

}


