<div class="modal-header">
  <h4 class="modal-title float-left">Edit social network</h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="expoForm" novalidate="">
    <div class="row">
      <div class="col-sm-4">
        <label for="network">Your network</label>
        <select class="form-control" id="network" name="network" placeholder="Choose network"
                formControlName="network">
          <option value="facebook" i18n="profile.facebook">Facebook</option>
          <option value="instagram" i18n="profile.instagram">Instagram</option>
          <option value="linkedin" i18n="profile.linkedin">LinkedIn</option>
          <option value="tiktok" i18n="profile.tiktok">TikTok</option>
          <option value="twitter" i18n="profile.twitter">Twitter</option>
          <option value="pinterest" i18n="profile.pinterest">PInterest</option>
          <option value="website" i18n="profile.website">Website</option>
          <option value="xing" i18n="profile.xing">Xing</option>
          <option value="youtube" i18n="profile.youtube">Youtube</option>
        </select>
      </div>
      <div class="col-sm-8">
        <div class="form-group has-feedback">
          <label for="value">Your id</label>
          <input class="form-control" id="value" type="text" name="value" placeholder="Enter value" autocomplete="off"
                 formControlName="value"/>
          <span class="form-control-feedback text-muted"></span>
          <span class="text-danger"
                *ngIf="valForm.controls['value'].hasError('required') && (valForm.controls['value'].dirty || valForm.controls['value'].touched)">This field is required</span>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <button class="btn btn-block btn-danger" (click)="cancelEditor($event)">Cancel</button>
      </div>
      <div class="col-md-6">
        &nbsp;
      </div>
      <div class="col-md-3">
        <button class="btn btn-block btn-success" (click)="submitForm($event)">Update</button>
      </div>
    </div>
  </div>
</div>
