import {Injectable, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import {
  AdminChatService, CalendarBookedSlotDto, ChatLogPackageDto,
  MongoChatItemDto,
  SignalDto,
  WebService
} from '../../../ezfair-api';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../security/services/auth.service';
import {ToasterService} from '../../../core/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class SignalsService {
  // ExhibitorTextChatActivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorTextChatDeactivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorVideoChatActivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorVideoChatDeactivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorLivestreamActivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorLivestreamDeactivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  // ExhibitorPresentationActivated: EventEmitter<{ expoId: string, exhibitorId: string, entry: CalendarUpdateBoothDto }>
  // = new EventEmitter<{ expoId: string, exhibitorId: string, entry: CalendarUpdateBoothDto }>();
  // ExhibitorPresentationDeactivated: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  //
  // ExhibitorCalendarUpdated: EventEmitter<{ expoId: string, exhibitorId: string, update: CalendarEntryBoothDto }>
  // = new EventEmitter<{ expoId: string, exhibitorId: string, update: CalendarEntryBoothDto }>();
  // ExhibitorNotice: EventEmitter<{ expoId: string, exhibitorId: string, entry: CalendarUpdateBoothDto, message: string }>
  // = new EventEmitter<{ expoId: string, exhibitorId: string, entry: CalendarUpdateBoothDto, message: string }>();
  // ExhibitorClear: EventEmitter<{ expoId: string, exhibitorId: string }> = new EventEmitter<{ expoId: string, exhibitorId: string }>();
  //
  // StagePresentationActivated: EventEmitter<{ expoId: string, stageId: string, entry: CalendarUpdateDto }>
  // = new EventEmitter<{ expoId: string, stageId: string, entry: CalendarUpdateDto }>();
  // StagePresentationUpdated: EventEmitter<{ expoId: string, stageId: string, update: CalendarUpdateDto }>
  // = new EventEmitter<{ expoId: string, stageId: string, update: CalendarUpdateDto }>();
  // StagePresentationDeactivated: EventEmitter<{ expoId: string, stageId: string }> = new EventEmitter<{ expoId: string, stageId: string }>();
  // StageNotice: EventEmitter<{ expoId: string, stageId: string, entry: CalendarUpdateDto, message: string }>
  // = new EventEmitter<{ expoId: string, stageId: string, entry: CalendarUpdateDto, message: string }>();

  GroupChatMessageReceived: EventEmitter<ChatLogPackageDto> = new EventEmitter<ChatLogPackageDto>();
  GroupChatClear: EventEmitter<{ expoId: string, groupId: string }> = new EventEmitter<{ expoId: string, groupId: string }>();
  GroupChatUserJoined: EventEmitter<{ expoId: string, groupId: string, name: string, admin: boolean }>
    = new EventEmitter<{ expoId: string, groupId: string, name: string, admin: boolean }>();
  GroupChatUserRenamed: EventEmitter<{ expoId: string, groupId: string, name: string, newName: string }>
    = new EventEmitter<{ expoId: string, groupId: string, name: string, newName: string }>();
  GroupChatUserLeft: EventEmitter<{ expoId: string, groupId: string, name: string }> = new EventEmitter<{ expoId: string, groupId: string, name: string }>();

  PrivateChatMessageReceived: EventEmitter<MongoChatItemDto> = new EventEmitter<MongoChatItemDto>();
  BookingListMessageReceived: EventEmitter<Array<CalendarBookedSlotDto>> = new EventEmitter<Array<CalendarBookedSlotDto>>();
  BookingMessageReceived: EventEmitter<CalendarBookedSlotDto> = new EventEmitter<CalendarBookedSlotDto>();

  Connected: EventEmitter<void> = new EventEmitter<void>();
  private activeGroups: Array<string> = new Array<string>();

  private hubConnection: HubConnection;
  public connectionId: string;

  constructor(
    private webService: WebService
    , private chatService: AdminChatService
    , private authService: AuthService
    , private toaster: ToasterService
  ) {
    if (this.authService.isLoggedIn()) {
      this.connect();
    }

    this.authService.onLogin
      .subscribe(() => {
        this.connect();
      });

    this.authService.onLogout
      .subscribe(() => {
        this.hubConnection.stop()
          .then(() => {
            this.hubConnection = null;
          });
      });
  }

  connect() {
    if (this.hubConnection) {
      return;
    }
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalsServer, {
        accessTokenFactory: () => this.authService.token
      })
      // .withUrl(environment.systemNotificationHub, {accessTokenFactory: () => token})
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          return Math.random() * 10000 + 10000;
        }
      })
      .configureLogging(environment.production ? LogLevel.Critical : LogLevel.Information)
      .build();

    this.hubConnection.onreconnecting(error => {
      console.log('reconnecting system notifications');
      this.toaster.warn('Chat connection lost, reconnecting');
      if (error) {
        console.log(error);
      }
    });

    this.hubConnection.onreconnected((connectionId) => {
      console.log('reconnected');
      this.connectionId = connectionId;
      this.chatService.adminChatJoinChat({connectionId: connectionId})
        .subscribe(value => {
          // console.log('rejoined');
          this.toaster.success('Chat connection re-established');
        });
    });

    this.hubConnection.onclose(error => {
      this.connectionId = null;
      console.log('closing system notifications');
      if (error) {
        console.log(error);
      }
    });

    this.hubConnection
      .start()
      .then(() => {
        this.connectionId = this.hubConnection.connectionId;
        this.hubConnection.on('signal', (args) => {
          const cal = args as SignalDto;

          const metaVal = JSON.parse(cal.meta ?? '{}');

          switch (cal.commandType) {
            // case 1:
            //   if (!environment.production) {
            //     console.log(`joined expo ${metaVal}`);
            //   }
            //   break;
            // case 10:
            //   break;
            // case 40:
            //   const presentationStartDto = metaVal as CalendarUpdateBoothDto;
            //   if (!environment.production) {
            //     console.log(`Presentation opened ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorCalendarToggle(cal.expoId, cal.sender, true, presentationStartDto);
            //   this.ExhibitorPresentationActivated.emit({
            //     expoId: cal.expoId,
            //     exhibitorId: cal.sender,
            //     entry: presentationStartDto
            //   });
            //   break;
            // case 41:
            //   const presentationNotice = metaVal as CalendarEntryBoothDto;
            //   this.ExhibitorNotice.emit({
            //     expoId: cal.expoId,
            //     exhibitorId: cal.sender,
            //     entry: presentationNotice,
            //     message: ''
            //   });
            //   break;
            // case 42:
            //   const presentationNoticeCustom = metaVal as { notice: CalendarEntryBoothDto, message: string };
            //   this.ExhibitorNotice.emit({
            //     expoId: cal.expoId,
            //     exhibitorId: cal.sender,
            //     entry: presentationNoticeCustom.notice,
            //     message: presentationNoticeCustom.message
            //   });
            //   break;
            // case 43:
            //   if (!environment.production) {
            //     console.log(`Presentation closed ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorCalendarToggle(cal.expoId, cal.sender, false, null);
            //   this.ExhibitorPresentationDeactivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 44:
            //   const liveStreamStartDto = metaVal as LivestreamSettingsDto;
            //   if (!environment.production) {
            //     console.log(`Livestream opened ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorStreamToggle(cal.expoId, cal.sender, true, liveStreamStartDto.streamId, liveStreamStartDto.streamType);
            //   this.ExhibitorLivestreamActivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 45:
            //   const liveStreamEndDto = metaVal as LivestreamSettingsDto;
            //   if (!environment.production) {
            //     console.log(`Livestream closed ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorStreamToggle(cal.expoId, cal.sender, false, liveStreamEndDto.streamId, liveStreamEndDto.streamType);
            //   this.ExhibitorLivestreamDeactivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 50:
            //   if (!environment.production) {
            //     console.log(`Text chat opened ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorChatToggle(cal.expoId, cal.sender, true);
            //   this.ExhibitorTextChatActivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 51:
            //   if (!environment.production) {
            //     console.log(`Text chat closed ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorChatToggle(cal.expoId, cal.sender, false);
            //   this.ExhibitorTextChatDeactivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 52:
            //   if (!environment.production) {
            //     console.log(`Video chat opened ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorVideoToggle(cal.expoId, cal.sender, true);
            //   this.ExhibitorVideoChatActivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 53:
            //   if (!environment.production) {
            //     console.log(`Video chat closed ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.exhibitorVideoToggle(cal.expoId, cal.sender, false);
            //   this.ExhibitorVideoChatDeactivated.emit({expoId: cal.expoId, exhibitorId: cal.sender});
            //   break;
            // case 60:
            //   const stageStartDto = metaVal as CalendarUpdateDto;
            //   if (!environment.production) {
            //     console.log(`Presentation opened ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.stageCalendarToggle(cal.expoId, cal.sender, true, stageStartDto);
            //   this.StagePresentationActivated.emit({expoId: cal.expoId, stageId: cal.sender, entry: stageStartDto});
            //   break;
            // case 61:
            //   const stageNotice = metaVal as CalendarUpdateDto;
            //   this.StageNotice.emit({expoId: cal.expoId, stageId: cal.sender, entry: stageNotice, message: ''});
            //   break;
            // case 62:
            //   const stageNoticeCustom = metaVal as { notice: CalendarUpdateDto, message: string };
            //   this.StageNotice.emit({
            //     expoId: cal.expoId,
            //     stageId: cal.sender,
            //     entry: stageNoticeCustom.notice,
            //     message: stageNoticeCustom.message
            //   });
            //   break;
            // case 63:
            //   if (!environment.production) {
            //     console.log(`Presentation closed ${JSON.stringify(metaVal)}`);
            //   }
            //   this.dataService.stageCalendarToggle(cal.expoId, cal.sender, false, null);
            //   this.StagePresentationDeactivated.emit({expoId: cal.expoId, stageId: cal.sender});
            //   break;
            // case 101:
            //   break;
            case 101:
              this.toaster.success('Rendering complete');
              break;
            case 102:
              this.toaster.warn('Rendering error, please retry');
              break;
            case 201:
              const userJoinedDto = metaVal as { name: string, admin: boolean };
              this.GroupChatUserJoined.emit({
                expoId: cal.shortKey,
                groupId: cal.sender,
                name: userJoinedDto.name,
                admin: userJoinedDto.admin
              });
              break;
            case 202:
              const chatLogPackageDto = metaVal as ChatLogPackageDto;
              this.GroupChatMessageReceived.emit(chatLogPackageDto);
              break;
            case 208:
              this.GroupChatClear.emit({expoId: cal.shortKey, groupId: cal.sender});
              break;
            case 209:
              const userLeftDto = metaVal as { name: string, admin: boolean };
              this.GroupChatUserLeft.emit({expoId: cal.shortKey, groupId: cal.sender, name: userLeftDto.name});
              break;
            case 222:
            case 223:
            case 224:
            case 225:
              const message = metaVal as MongoChatItemDto;
              this.PrivateChatMessageReceived.emit(message);
              break;
            case 300:
              const bookingList = metaVal as CalendarBookedSlotDto[];
              this.BookingListMessageReceived.emit(bookingList);
              break;
            case 301:
              const bookingEntry = metaVal as CalendarBookedSlotDto;
              this.BookingMessageReceived.emit(bookingEntry);
              break;
          }
        });

        this.chatService.adminChatJoinChat({connectionId: this.hubConnection.connectionId})
          .subscribe((messageItems) => {
            this.Connected.emit();
            messageItems.forEach(item => {
              this.PrivateChatMessageReceived.emit(item);
            });
          });
      })
      .catch(err => {
        console.log('Error while establishing connection :(');
      });
  }
}
