/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarAvailability } from './calendarAvailability';
import { Expo } from './expo';
import { UserProfile } from './userProfile';
import { Exhibitor } from './exhibitor';


export interface ApplicationUser { 
    id?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: Date;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
    sinespaceUser?: string;
    sinespaceUserId?: number;
    displayName?: string;
    isManager?: boolean;
    exhibitors?: Array<Exhibitor>;
    expos?: Array<Expo>;
    profile?: UserProfile;
    permission?: boolean;
    availabilities?: Array<CalendarAvailability>;
}

/**
 * Namespace for property- and property-value-enumerations of ApplicationUser.
 */
export namespace ApplicationUser {
    /**
     * All properties of ApplicationUser.
     */
    export enum Properties {
        id = 'id',
        userName = 'userName',
        normalizedUserName = 'normalizedUserName',
        email = 'email',
        normalizedEmail = 'normalizedEmail',
        emailConfirmed = 'emailConfirmed',
        passwordHash = 'passwordHash',
        securityStamp = 'securityStamp',
        concurrencyStamp = 'concurrencyStamp',
        phoneNumber = 'phoneNumber',
        phoneNumberConfirmed = 'phoneNumberConfirmed',
        twoFactorEnabled = 'twoFactorEnabled',
        lockoutEnd = 'lockoutEnd',
        lockoutEnabled = 'lockoutEnabled',
        accessFailedCount = 'accessFailedCount',
        sinespaceUser = 'sinespaceUser',
        sinespaceUserId = 'sinespaceUserId',
        displayName = 'displayName',
        isManager = 'isManager',
        exhibitors = 'exhibitors',
        expos = 'expos',
        profile = 'profile',
        permission = 'permission',
        availabilities = 'availabilities'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ApplicationUser.
    */
    export const ModelValidators: {[K in keyof ApplicationUser]: [string, ValidatorFn][]} = {
        id: [
        ],
        userName: [
        ],
        normalizedUserName: [
        ],
        email: [
        ],
        normalizedEmail: [
        ],
        emailConfirmed: [
        ],
        passwordHash: [
        ],
        securityStamp: [
        ],
        concurrencyStamp: [
        ],
        phoneNumber: [
        ],
        phoneNumberConfirmed: [
        ],
        twoFactorEnabled: [
        ],
        lockoutEnd: [
        ],
        lockoutEnabled: [
        ],
        accessFailedCount: [
        ],
        sinespaceUser: [
        ],
        sinespaceUserId: [
        ],
        displayName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        isManager: [
        ],
        exhibitors: [
        ],
        expos: [
        ],
        profile: [
        ],
        permission: [
        ],
        availabilities: [
        ],
    };

    /**
    * The FormControlFactory for ApplicationUser.
    */
    export class FormControlFactory extends BaseFormControlFactory<ApplicationUser> {

        /**
         * Constructor.
         *
         * @param model An existing model for ApplicationUser.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ApplicationUser = {
            id: null,
            userName: null,
            normalizedUserName: null,
            email: null,
            normalizedEmail: null,
            emailConfirmed: null,
            passwordHash: null,
            securityStamp: null,
            concurrencyStamp: null,
            phoneNumber: null,
            phoneNumberConfirmed: null,
            twoFactorEnabled: null,
            lockoutEnd: null,
            lockoutEnabled: null,
            accessFailedCount: null,
            sinespaceUser: null,
            sinespaceUserId: null,
            displayName: null,
            isManager: null,
            exhibitors: null,
            expos: null,
            profile: null,
            permission: null,
            availabilities: null,
          }
        ) {
            super(model, ApplicationUser.ModelValidators);
        }
    }

}


