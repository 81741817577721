/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ChatPartnerType } from './chatPartnerType';


export interface AdminChatChannelMessageDto { 
    timestamp?: Date;
    partnerId?: string;
    partnerType?: ChatPartnerType;
    message?: string;
    selfId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of AdminChatChannelMessageDto.
 */
export namespace AdminChatChannelMessageDto {
    /**
     * All properties of AdminChatChannelMessageDto.
     */
    export enum Properties {
        timestamp = 'timestamp',
        partnerId = 'partnerId',
        partnerType = 'partnerType',
        message = 'message',
        selfId = 'selfId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AdminChatChannelMessageDto.
    */
    export const ModelValidators: {[K in keyof AdminChatChannelMessageDto]: [string, ValidatorFn][]} = {
        timestamp: [
        ],
        partnerId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        partnerType: [
        ],
        message: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        selfId: [
        ],
    };

    /**
    * The FormControlFactory for AdminChatChannelMessageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<AdminChatChannelMessageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for AdminChatChannelMessageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AdminChatChannelMessageDto = {
            timestamp: null,
            partnerId: null,
            partnerType: null,
            message: null,
            selfId: null,
          }
        ) {
            super(model, AdminChatChannelMessageDto.ModelValidators);
        }
    }

}


