/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MAvailabilityEditDto { 
    userName?: string;
    exhibitorShortKey?: string;
    id?: string;
    userId?: string;
    start?: Date;
    end?: Date;
    interval?: number;
    comment?: string;
    active?: boolean;
    deleted?: boolean;
    exhibitorId?: string;
    bookedSlotsCount?: number;
}

/**
 * Namespace for property- and property-value-enumerations of MAvailabilityEditDto.
 */
export namespace MAvailabilityEditDto {
    /**
     * All properties of MAvailabilityEditDto.
     */
    export enum Properties {
        userName = 'userName',
        exhibitorShortKey = 'exhibitorShortKey',
        id = 'id',
        userId = 'userId',
        start = 'start',
        end = 'end',
        interval = 'interval',
        comment = 'comment',
        active = 'active',
        deleted = 'deleted',
        exhibitorId = 'exhibitorId',
        bookedSlotsCount = 'bookedSlotsCount'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MAvailabilityEditDto.
    */
    export const ModelValidators: {[K in keyof MAvailabilityEditDto]: [string, ValidatorFn][]} = {
        userName: [
        ],
        exhibitorShortKey: [
        ],
        id: [
        ],
        userId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        start: [
        ],
        end: [
        ],
        interval: [
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(5000)],
        ],
        active: [
        ],
        deleted: [
        ],
        exhibitorId: [
        ],
        bookedSlotsCount: [
        ],
    };

    /**
    * The FormControlFactory for MAvailabilityEditDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MAvailabilityEditDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MAvailabilityEditDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MAvailabilityEditDto = {
            userName: null,
            exhibitorShortKey: null,
            id: null,
            userId: null,
            start: null,
            end: null,
            interval: null,
            comment: null,
            active: null,
            deleted: null,
            exhibitorId: null,
            bookedSlotsCount: null,
          }
        ) {
            super(model, MAvailabilityEditDto.ModelValidators);
        }
    }

}


