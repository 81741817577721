/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface GroupChatMessage { 
    groupId?: string;
    message?: string;
}

/**
 * Namespace for property- and property-value-enumerations of GroupChatMessage.
 */
export namespace GroupChatMessage {
    /**
     * All properties of GroupChatMessage.
     */
    export enum Properties {
        groupId = 'groupId',
        message = 'message'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of GroupChatMessage.
    */
    export const ModelValidators: {[K in keyof GroupChatMessage]: [string, ValidatorFn][]} = {
        groupId: [
        ],
        message: [
        ],
    };

    /**
    * The FormControlFactory for GroupChatMessage.
    */
    export class FormControlFactory extends BaseFormControlFactory<GroupChatMessage> {

        /**
         * Constructor.
         *
         * @param model An existing model for GroupChatMessage.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: GroupChatMessage = {
            groupId: null,
            message: null,
          }
        ) {
            super(model, GroupChatMessage.ModelValidators);
        }
    }

}


