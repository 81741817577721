import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInitService {
  constructor(
    private authService: AuthService
    , private router: Router
  ) {
  }

  public init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authService.refreshToken()
        .then(value => {
          resolve();
        }).catch(error => {
        resolve();
      });
    });
  }
}
