/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';


export interface MStageReferenceDto { 
    shortKey?: string;
    name?: string;
    logoSquare?: string;
    logoWide?: string;
    current?: string;
    vodType?: InventoryItemType;
    vodSource?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MStageReferenceDto.
 */
export namespace MStageReferenceDto {
    /**
     * All properties of MStageReferenceDto.
     */
    export enum Properties {
        shortKey = 'shortKey',
        name = 'name',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        current = 'current',
        vodType = 'vodType',
        vodSource = 'vodSource'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MStageReferenceDto.
    */
    export const ModelValidators: {[K in keyof MStageReferenceDto]: [string, ValidatorFn][]} = {
        shortKey: [
        ],
        name: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        current: [
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
    };

    /**
    * The FormControlFactory for MStageReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MStageReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MStageReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MStageReferenceDto = {
            shortKey: null,
            name: null,
            logoSquare: null,
            logoWide: null,
            current: null,
            vodType: null,
            vodSource: null,
          }
        ) {
            super(model, MStageReferenceDto.ModelValidators);
        }
    }

}


