import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import {AuthService} from '../../../security/services/auth.service';
import {environment} from '../../../../../environments/environment';
import {AdminChatService, MExhibitorService} from '../../../../ezfair-api';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit {
  @Input() callId: string;
  @Input() expoId: string;
  @Input() name: string;
  @Input() senderName: string;

  @Output() callHangup: EventEmitter<any> = new EventEmitter<any>();

  options: any;
  api: any = {};
  user: any = {};
  isLoaded = false;
  isOpen = false;

  constructor(
    public activeModal: NgbActiveModal
    , private authService: AuthService
    , private confirmationService: ConfirmationDialogService
    , private exhibitorService: MExhibitorService
  ) {
  }

  ngOnInit() {
    const roomName = this.callId.replace(/-/gi, '');
    this.exhibitorService.mExhibitorGetJitsiToken(roomName, this.expoId)
      .subscribe((token) => {
        this.options = {
          roomName: token.room,
          jwt: token.token,
          parentNode: document.querySelector('#meet'),
          configOverwrite: {
            enableWelcomePage: false,
            authdomain: environment.videoAuthDomain
          },
          interfaceConfigOverwrite: {
            MOBILE_APP_PROMO: false,
            SHOW_JITSI_WATERMARK: false,
            APP_NAME: name,
          }
        };
        const tmp = sessionStorage.getItem('userdata');
        if (tmp) {
          this.user = JSON.parse(tmp);
        }
        this.api = new JitsiMeetExternalAPI(environment.videoHubDomain, this.options);
        // this.tracking.trackEvent(this.exhibitor.expo, this.exhibitor, 'videochat', this.exhibitor.name, 'video chat opened');
      });
  }

  handleLoad() {
    // this.api = api;
    this.api.executeCommand('subject', name);
    this.api.executeCommand('displayName', [this.user.name]);
    this.api.executeCommand('toggleChat');
  }

  hangup() {
    this.confirmationService.confirm($localize`:videoCall.hangupHeader|:Video-Anruf auflegen`,
      $localize`:videoCall.hangupMessage|:Möchten Sie den Video-Anruf wirklich beenden?`,
      $localize`:videoCall.hangupOk|:Auflegen`,
      $localize`:videoCall.hangupCancel|:Nicht auflegen`, 'sm'
    )
      .then(value => {
        this.api.executeCommand('hangup');
        setTimeout(() => {
          this.api = null;
          const node = document.getElementById('meet');
          while (node.lastChild) {
            node.removeChild(node.lastChild);
          }
          this.activeModal.dismiss();
          this.callHangup.emit();
        }, 250);
      });
  }

  hangupCall() {
    this.api.executeCommand('hangup');
    setTimeout(() => {
      this.api = null;
      const node = document.getElementById('meet');
      while (node.lastChild) {
        node.removeChild(node.lastChild);
      }
      this.activeModal.dismiss();
    }, 250);
  }
}
