/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface Link { 
    id?: string;
    sequence?: number;
    url?: string;
    description?: string;
    inActive?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of Link.
 */
export namespace Link {
    /**
     * All properties of Link.
     */
    export enum Properties {
        id = 'id',
        sequence = 'sequence',
        url = 'url',
        description = 'description',
        inActive = 'inActive'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Link.
    */
    export const ModelValidators: {[K in keyof Link]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        sequence: [
        ],
        url: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        inActive: [
        ],
    };

    /**
    * The FormControlFactory for Link.
    */
    export class FormControlFactory extends BaseFormControlFactory<Link> {

        /**
         * Constructor.
         *
         * @param model An existing model for Link.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Link = {
            id: null,
            sequence: null,
            url: null,
            description: null,
            inActive: null,
          }
        ) {
            super(model, Link.ModelValidators);
        }
    }

}


