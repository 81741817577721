/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';
import { StageConfigurationDto } from './stageConfigurationDto';
import { StageMode } from './stageMode';
import { MExhibitorReferenceDto } from './mExhibitorReferenceDto';


export interface MStageDtoSequence { 
    shortKey?: string;
    name: string;
    description?: string;
    logoSquare?: string;
    logoWide?: string;
    current?: string;
    stagePass?: string;
    background?: string;
    stageMode?: StageMode;
    exhibitor?: MExhibitorReferenceDto;
    exhibitors?: Array<MExhibitorReferenceDto>;
    vodType?: InventoryItemType;
    vodSource?: string;
    hideOnLanding?: boolean;
    stageConfiguration?: StageConfigurationDto;
    sequence?: number;
    stageImage?: string;
    stageImageTrans?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MStageDtoSequence.
 */
export namespace MStageDtoSequence {
    /**
     * All properties of MStageDtoSequence.
     */
    export enum Properties {
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        current = 'current',
        stagePass = 'stagePass',
        background = 'background',
        stageMode = 'stageMode',
        exhibitor = 'exhibitor',
        exhibitors = 'exhibitors',
        vodType = 'vodType',
        vodSource = 'vodSource',
        hideOnLanding = 'hideOnLanding',
        stageConfiguration = 'stageConfiguration',
        sequence = 'sequence',
        stageImage = 'stageImage',
        stageImageTrans = 'stageImageTrans'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MStageDtoSequence.
    */
    export const ModelValidators: {[K in keyof MStageDtoSequence]: [string, ValidatorFn][]} = {
        shortKey: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(10)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        current: [
        ],
        stagePass: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        background: [
        ],
        stageMode: [
        ],
        exhibitor: [
        ],
        exhibitors: [
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        hideOnLanding: [
        ],
        stageConfiguration: [
        ],
        sequence: [
        ],
        stageImage: [
        ],
        stageImageTrans: [
        ],
    };

    /**
    * The FormControlFactory for MStageDtoSequence.
    */
    export class FormControlFactory extends BaseFormControlFactory<MStageDtoSequence> {

        /**
         * Constructor.
         *
         * @param model An existing model for MStageDtoSequence.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MStageDtoSequence = {
            shortKey: null,
            name: null,
            description: null,
            logoSquare: null,
            logoWide: null,
            current: null,
            stagePass: null,
            background: null,
            stageMode: null,
            exhibitor: null,
            exhibitors: null,
            vodType: null,
            vodSource: null,
            hideOnLanding: null,
            stageConfiguration: null,
            sequence: null,
            stageImage: null,
            stageImageTrans: null,
          }
        ) {
            super(model, MStageDtoSequence.ModelValidators);
        }
    }

}


