/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { PresentationScreenMode } from './presentationScreenMode';
import { BoothConfigurationEditDto } from './boothConfigurationEditDto';
import { ExhibitorContactMode } from './exhibitorContactMode';
import { MInfoSlideEditDto } from './mInfoSlideEditDto';
import { MCalendarEntryBooth } from './mCalendarEntryBooth';
import { MLinkEditDto } from './mLinkEditDto';
import { LecternVisibility } from './lecternVisibility';
import { SchedulingService } from './schedulingService';
import { MDisplayEditDto } from './mDisplayEditDto';
import { ExhibitorInfoVisibility } from './exhibitorInfoVisibility';
import { MExhibitorContactEditDto } from './mExhibitorContactEditDto';


export interface MExhibitorEditDto { 
    id?: string;
    shortKey?: string;
    name: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    descriptionLong?: string;
    socialProfiles?: { [key: string]: string; };
    label?: string;
    shortLabel?: string;
    showContact?: boolean;
    website?: string;
    logoSquare?: string;
    logoWide?: string;
    avatar?: string;
    schedulingService?: SchedulingService;
    schedulingKey?: string;
    schedulingActive?: boolean;
    contacts?: Array<MExhibitorContactEditDto>;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatWelcomeMessage?: string;
    hideAvatar?: boolean;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
    singleChatWelcomeMessage?: string;
    internalContactMail?: string;
    publicContactMail?: string;
    contactMode?: ExhibitorContactMode;
    tags?: Array<string>;
    rowVersion?: number;
    uploadTs?: Date;
    boothType?: string;
    boothIcon?: string;
    style?: string;
    rotation?: number;
    panels?: string;
    displays?: Array<MDisplayEditDto>;
    links?: Array<MLinkEditDto>;
    infoSlides?: Array<MInfoSlideEditDto>;
    categoryId?: number;
    boothConfiguration?: BoothConfigurationEditDto;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    chatEnabled?: boolean;
    exhibitorGroupId?: number;
    exhibitorInfoVisibility?: ExhibitorInfoVisibility;
    hideFurniture?: boolean;
    hideWalls?: boolean;
    hideLogo?: boolean;
    lecternVisibility?: LecternVisibility;
    calendarEntries?: Array<MCalendarEntryBooth>;
    presentationScreenMode?: PresentationScreenMode;
    logoSquareRaw?: string;
    logoWideRaw?: string;
    boothIconRaw?: string;
    backgroundImageRaw?: string;
    avatarRaw?: string;
    posterARaw?: string;
    posterBRaw?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorEditDto.
 */
export namespace MExhibitorEditDto {
    /**
     * All properties of MExhibitorEditDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        descriptionLong = 'descriptionLong',
        socialProfiles = 'socialProfiles',
        label = 'label',
        shortLabel = 'shortLabel',
        showContact = 'showContact',
        website = 'website',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        avatar = 'avatar',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        contacts = 'contacts',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatWelcomeMessage = 'chatWelcomeMessage',
        hideAvatar = 'hideAvatar',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled',
        singleChatWelcomeMessage = 'singleChatWelcomeMessage',
        internalContactMail = 'internalContactMail',
        publicContactMail = 'publicContactMail',
        contactMode = 'contactMode',
        tags = 'tags',
        rowVersion = 'rowVersion',
        uploadTs = 'uploadTs',
        boothType = 'boothType',
        boothIcon = 'boothIcon',
        style = 'style',
        rotation = 'rotation',
        panels = 'panels',
        displays = 'displays',
        links = 'links',
        infoSlides = 'infoSlides',
        categoryId = 'categoryId',
        boothConfiguration = 'boothConfiguration',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        chatEnabled = 'chatEnabled',
        exhibitorGroupId = 'exhibitorGroupId',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        hideFurniture = 'hideFurniture',
        hideWalls = 'hideWalls',
        hideLogo = 'hideLogo',
        lecternVisibility = 'lecternVisibility',
        calendarEntries = 'calendarEntries',
        presentationScreenMode = 'presentationScreenMode',
        logoSquareRaw = 'logoSquareRaw',
        logoWideRaw = 'logoWideRaw',
        boothIconRaw = 'boothIconRaw',
        backgroundImageRaw = 'backgroundImageRaw',
        avatarRaw = 'avatarRaw',
        posterARaw = 'posterARaw',
        posterBRaw = 'posterBRaw'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorEditDto.
    */
    export const ModelValidators: {[K in keyof MExhibitorEditDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        descriptionLong: [
        ],
        socialProfiles: [
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortLabel: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        showContact: [
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        avatar: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        contacts: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatWelcomeMessage: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        hideAvatar: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
        singleChatWelcomeMessage: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        internalContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        publicContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        contactMode: [
        ],
        tags: [
        ],
        rowVersion: [
        ],
        uploadTs: [
        ],
        boothType: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        boothIcon: [
        ],
        style: [
        ],
        rotation: [
        ],
        panels: [
        ],
        displays: [
        ],
        links: [
        ],
        infoSlides: [
        ],
        categoryId: [
        ],
        boothConfiguration: [
        ],
        backgroundImage: [
        ],
        posterA: [
        ],
        posterB: [
        ],
        chatEnabled: [
        ],
        exhibitorGroupId: [
        ],
        exhibitorInfoVisibility: [
        ],
        hideFurniture: [
        ],
        hideWalls: [
        ],
        hideLogo: [
        ],
        lecternVisibility: [
        ],
        calendarEntries: [
        ],
        presentationScreenMode: [
        ],
        logoSquareRaw: [
        ],
        logoWideRaw: [
        ],
        boothIconRaw: [
        ],
        backgroundImageRaw: [
        ],
        avatarRaw: [
        ],
        posterARaw: [
        ],
        posterBRaw: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorEditDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorEditDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorEditDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorEditDto = {
            id: null,
            shortKey: null,
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            descriptionLong: null,
            socialProfiles: null,
            label: null,
            shortLabel: null,
            showContact: null,
            website: null,
            logoSquare: null,
            logoWide: null,
            avatar: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            contacts: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatWelcomeMessage: null,
            hideAvatar: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
            singleChatWelcomeMessage: null,
            internalContactMail: null,
            publicContactMail: null,
            contactMode: null,
            tags: null,
            rowVersion: null,
            uploadTs: null,
            boothType: null,
            boothIcon: null,
            style: null,
            rotation: null,
            panels: null,
            displays: null,
            links: null,
            infoSlides: null,
            categoryId: null,
            boothConfiguration: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            chatEnabled: null,
            exhibitorGroupId: null,
            exhibitorInfoVisibility: null,
            hideFurniture: null,
            hideWalls: null,
            hideLogo: null,
            lecternVisibility: null,
            calendarEntries: null,
            presentationScreenMode: null,
            logoSquareRaw: null,
            logoWideRaw: null,
            boothIconRaw: null,
            backgroundImageRaw: null,
            avatarRaw: null,
            posterARaw: null,
            posterBRaw: null,
          }
        ) {
            super(model, MExhibitorEditDto.ModelValidators);
        }
    }

}


