/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface AlbedoColor { 
    r?: number;
    g?: number;
    b?: number;
    a?: number;
}

/**
 * Namespace for property- and property-value-enumerations of AlbedoColor.
 */
export namespace AlbedoColor {
    /**
     * All properties of AlbedoColor.
     */
    export enum Properties {
        r = 'r',
        g = 'g',
        b = 'b',
        a = 'a'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AlbedoColor.
    */
    export const ModelValidators: {[K in keyof AlbedoColor]: [string, ValidatorFn][]} = {
        r: [
        ],
        g: [
        ],
        b: [
        ],
        a: [
        ],
    };

    /**
    * The FormControlFactory for AlbedoColor.
    */
    export class FormControlFactory extends BaseFormControlFactory<AlbedoColor> {

        /**
         * Constructor.
         *
         * @param model An existing model for AlbedoColor.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AlbedoColor = {
            r: null,
            g: null,
            b: null,
            a: null,
          }
        ) {
            super(model, AlbedoColor.ModelValidators);
        }
    }

}


