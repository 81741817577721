/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { BoothTypeDto } from '../model/boothTypeDto';
import { StockMediaDto } from '../model/stockMediaDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for mConfigOptionsGetAvatars.
 */
export namespace MConfigOptionsGetAvatars {
    /**
     * Parameter map for mConfigOptionsGetAvatars.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mConfigOptionsGetAvatars.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mConfigOptionsGetAvatars
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MConfigOptionsGetAvatars.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mConfigOptionsGetBackgrounds.
 */
export namespace MConfigOptionsGetBackgrounds {
    /**
     * Parameter map for mConfigOptionsGetBackgrounds.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mConfigOptionsGetBackgrounds.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mConfigOptionsGetBackgrounds
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MConfigOptionsGetBackgrounds.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mConfigOptionsGetBoothIcons.
 */
export namespace MConfigOptionsGetBoothIcons {
    /**
     * Parameter map for mConfigOptionsGetBoothIcons.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for mConfigOptionsGetBoothIcons.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mConfigOptionsGetBoothIcons
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MConfigOptionsGetBoothIcons.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for mConfigOptionsGetBoothTypes.
 */
export namespace MConfigOptionsGetBoothTypes {
    /**
     * Parameter map for mConfigOptionsGetBoothTypes.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for mConfigOptionsGetBoothTypes.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mConfigOptionsGetBoothTypes
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MConfigOptionsGetBoothTypes.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for mConfigOptionsGetYoutubePlaylistPreviewImage.
 */
export namespace MConfigOptionsGetYoutubePlaylistPreviewImage {
    /**
     * Parameter map for mConfigOptionsGetYoutubePlaylistPreviewImage.
     */
    export interface PartialParamMap {
      playlistId: string;
    }

    /**
     * Enumeration of all parameters for mConfigOptionsGetYoutubePlaylistPreviewImage.
     */
    export enum Parameters {
      playlistId = 'playlistId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of mConfigOptionsGetYoutubePlaylistPreviewImage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof MConfigOptionsGetYoutubePlaylistPreviewImage.PartialParamMap]?: [string, ValidatorFn][]} = {
      playlistId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class MConfigOptionsService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mConfigOptionsGetAvatarsByMap(
    map: MConfigOptionsGetAvatars.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public mConfigOptionsGetAvatarsByMap(
    map: MConfigOptionsGetAvatars.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public mConfigOptionsGetAvatarsByMap(
    map: MConfigOptionsGetAvatars.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public mConfigOptionsGetAvatarsByMap(
    map: MConfigOptionsGetAvatars.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mConfigOptionsGetAvatars(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mConfigOptionsGetAvatars(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public mConfigOptionsGetAvatars(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public mConfigOptionsGetAvatars(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public mConfigOptionsGetAvatars(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/MConfigOptions/avatars`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MConfigOptions_GetAvatars']) {
                this.cancelMap['MConfigOptions_GetAvatars'].next(null);
            }
            this.cancelMap['MConfigOptions_GetAvatars'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MConfigOptions_GetAvatars']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MConfigOptions_GetAvatars']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MConfigOptions_GetAvatars', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MConfigOptions_GetAvatars']) {
            this.cancelMap['MConfigOptions_GetAvatars'].complete();
            delete this.cancelMap['MConfigOptions_GetAvatars'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MConfigOptions_GetAvatars',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MConfigOptions_GetAvatars')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mConfigOptionsGetBackgroundsByMap(
    map: MConfigOptionsGetBackgrounds.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public mConfigOptionsGetBackgroundsByMap(
    map: MConfigOptionsGetBackgrounds.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public mConfigOptionsGetBackgroundsByMap(
    map: MConfigOptionsGetBackgrounds.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public mConfigOptionsGetBackgroundsByMap(
    map: MConfigOptionsGetBackgrounds.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mConfigOptionsGetBackgrounds(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mConfigOptionsGetBackgrounds(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public mConfigOptionsGetBackgrounds(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public mConfigOptionsGetBackgrounds(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public mConfigOptionsGetBackgrounds(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/MConfigOptions/stagebackgrounds`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MConfigOptions_GetBackgrounds']) {
                this.cancelMap['MConfigOptions_GetBackgrounds'].next(null);
            }
            this.cancelMap['MConfigOptions_GetBackgrounds'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MConfigOptions_GetBackgrounds']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MConfigOptions_GetBackgrounds']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MConfigOptions_GetBackgrounds', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MConfigOptions_GetBackgrounds']) {
            this.cancelMap['MConfigOptions_GetBackgrounds'].complete();
            delete this.cancelMap['MConfigOptions_GetBackgrounds'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MConfigOptions_GetBackgrounds',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MConfigOptions_GetBackgrounds')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mConfigOptionsGetBoothIconsByMap(
    map: MConfigOptionsGetBoothIcons.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public mConfigOptionsGetBoothIconsByMap(
    map: MConfigOptionsGetBoothIcons.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public mConfigOptionsGetBoothIconsByMap(
    map: MConfigOptionsGetBoothIcons.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public mConfigOptionsGetBoothIconsByMap(
    map: MConfigOptionsGetBoothIcons.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mConfigOptionsGetBoothIcons(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mConfigOptionsGetBoothIcons(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public mConfigOptionsGetBoothIcons(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public mConfigOptionsGetBoothIcons(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public mConfigOptionsGetBoothIcons(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/MConfigOptions/boothicons`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MConfigOptions_GetBoothIcons']) {
                this.cancelMap['MConfigOptions_GetBoothIcons'].next(null);
            }
            this.cancelMap['MConfigOptions_GetBoothIcons'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MConfigOptions_GetBoothIcons']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MConfigOptions_GetBoothIcons']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MConfigOptions_GetBoothIcons', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MConfigOptions_GetBoothIcons']) {
            this.cancelMap['MConfigOptions_GetBoothIcons'].complete();
            delete this.cancelMap['MConfigOptions_GetBoothIcons'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MConfigOptions_GetBoothIcons',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MConfigOptions_GetBoothIcons')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mConfigOptionsGetBoothTypesByMap(
    map: MConfigOptionsGetBoothTypes.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<BoothTypeDto>>;
  public mConfigOptionsGetBoothTypesByMap(
    map: MConfigOptionsGetBoothTypes.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothTypeDto>>>;
  public mConfigOptionsGetBoothTypesByMap(
    map: MConfigOptionsGetBoothTypes.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothTypeDto>>>;
  public mConfigOptionsGetBoothTypesByMap(
    map: MConfigOptionsGetBoothTypes.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mConfigOptionsGetBoothTypes(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mConfigOptionsGetBoothTypes(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<BoothTypeDto>>;
    public mConfigOptionsGetBoothTypes(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothTypeDto>>>;
    public mConfigOptionsGetBoothTypes(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothTypeDto>>>;
    public mConfigOptionsGetBoothTypes(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling mConfigOptionsGetBoothTypes.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<BoothTypeDto>>(`${this.configuration.basePath}/api/MConfigOptions/boothTypes/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MConfigOptions_GetBoothTypes']) {
                this.cancelMap['MConfigOptions_GetBoothTypes'].next(null);
            }
            this.cancelMap['MConfigOptions_GetBoothTypes'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MConfigOptions_GetBoothTypes']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MConfigOptions_GetBoothTypes']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MConfigOptions_GetBoothTypes', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MConfigOptions_GetBoothTypes']) {
            this.cancelMap['MConfigOptions_GetBoothTypes'].complete();
            delete this.cancelMap['MConfigOptions_GetBoothTypes'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MConfigOptions_GetBoothTypes',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MConfigOptions_GetBoothTypes')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public mConfigOptionsGetYoutubePlaylistPreviewImageByMap(
    map: MConfigOptionsGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public mConfigOptionsGetYoutubePlaylistPreviewImageByMap(
    map: MConfigOptionsGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public mConfigOptionsGetYoutubePlaylistPreviewImageByMap(
    map: MConfigOptionsGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public mConfigOptionsGetYoutubePlaylistPreviewImageByMap(
    map: MConfigOptionsGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.mConfigOptionsGetYoutubePlaylistPreviewImage(
      map.playlistId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param playlistId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public mConfigOptionsGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public mConfigOptionsGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public mConfigOptionsGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public mConfigOptionsGetYoutubePlaylistPreviewImage(playlistId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling mConfigOptionsGetYoutubePlaylistPreviewImage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/MConfigOptions/youtube-preview/playlist/${encodeURIComponent(String(playlistId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage']) {
                this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage'].next(null);
            }
            this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('MConfigOptions_GetYoutubePlaylistPreviewImage', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage']) {
            this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage'].complete();
            delete this.cancelMap['MConfigOptions_GetYoutubePlaylistPreviewImage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'MConfigOptions_GetYoutubePlaylistPreviewImage',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'MConfigOptions_GetYoutubePlaylistPreviewImage')));
        }
        return handle;
    }

  }

export namespace MConfigOptionsService {
  export enum Operations {
    MConfigOptions_GetAvatars = 'MConfigOptions_GetAvatars',
    MConfigOptions_GetBackgrounds = 'MConfigOptions_GetBackgrounds',
    MConfigOptions_GetBoothIcons = 'MConfigOptions_GetBoothIcons',
    MConfigOptions_GetBoothTypes = 'MConfigOptions_GetBoothTypes',
    MConfigOptions_GetYoutubePlaylistPreviewImage = 'MConfigOptions_GetYoutubePlaylistPreviewImage'
  }
}
