/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type LecternVisibility = 0 | 1;

export const LecternVisibility = {
    NUMBER_0: 0 as LecternVisibility,
    NUMBER_1: 1 as LecternVisibility
};

