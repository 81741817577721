/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface MExhibitorListItem { 
    id?: string;
    shortKey?: string;
    name?: string;
    label?: string;
    logoWide?: string;
    logoSquare?: string;
    isManager?: boolean;
    isChatUser?: boolean;
    description?: string;
    sequence?: number;
    categoryId?: string;
    deleted?: boolean;
    hidden?: boolean;
    exhibitorGroupId?: string;
    tags?: Array<string>;
    managerId?: string;
    managerName?: string;
}

/**
 * Namespace for property- and property-value-enumerations of MExhibitorListItem.
 */
export namespace MExhibitorListItem {
    /**
     * All properties of MExhibitorListItem.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        label = 'label',
        logoWide = 'logoWide',
        logoSquare = 'logoSquare',
        isManager = 'isManager',
        isChatUser = 'isChatUser',
        description = 'description',
        sequence = 'sequence',
        categoryId = 'categoryId',
        deleted = 'deleted',
        hidden = 'hidden',
        exhibitorGroupId = 'exhibitorGroupId',
        tags = 'tags',
        managerId = 'managerId',
        managerName = 'managerName'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MExhibitorListItem.
    */
    export const ModelValidators: {[K in keyof MExhibitorListItem]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(10)],
        ],
        name: [
        ],
        label: [
        ],
        logoWide: [
        ],
        logoSquare: [
        ],
        isManager: [
        ],
        isChatUser: [
        ],
        description: [
        ],
        sequence: [
        ],
        categoryId: [
        ],
        deleted: [
        ],
        hidden: [
        ],
        exhibitorGroupId: [
        ],
        tags: [
        ],
        managerId: [
        ],
        managerName: [
        ],
    };

    /**
    * The FormControlFactory for MExhibitorListItem.
    */
    export class FormControlFactory extends BaseFormControlFactory<MExhibitorListItem> {

        /**
         * Constructor.
         *
         * @param model An existing model for MExhibitorListItem.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MExhibitorListItem = {
            id: null,
            shortKey: null,
            name: null,
            label: null,
            logoWide: null,
            logoSquare: null,
            isManager: null,
            isChatUser: null,
            description: null,
            sequence: null,
            categoryId: null,
            deleted: null,
            hidden: null,
            exhibitorGroupId: null,
            tags: null,
            managerId: null,
            managerName: null,
          }
        ) {
            super(model, MExhibitorListItem.ModelValidators);
        }
    }

}


