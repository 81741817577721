/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { IntegrationPartnerDto } from '../model/integrationPartnerDto';
import { IntegrationStartDto } from '../model/integrationStartDto';
import { ProblemDetails } from '../model/problemDetails';
import { WebexRoomDto } from '../model/webexRoomDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for oauthClientFinish.
 */
export namespace OauthClientFinish {
    /**
     * Parameter map for oauthClientFinish.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for oauthClientFinish.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientFinish
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientFinish.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for oauthClientGetExhibitorIntegrations.
 */
export namespace OauthClientGetExhibitorIntegrations {
    /**
     * Parameter map for oauthClientGetExhibitorIntegrations.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for oauthClientGetExhibitorIntegrations.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientGetExhibitorIntegrations
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientGetExhibitorIntegrations.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for oauthClientGetExpoIntegrations.
 */
export namespace OauthClientGetExpoIntegrations {
    /**
     * Parameter map for oauthClientGetExpoIntegrations.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for oauthClientGetExpoIntegrations.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientGetExpoIntegrations
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientGetExpoIntegrations.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for oauthClientGetRooms.
 */
export namespace OauthClientGetRooms {
    /**
     * Parameter map for oauthClientGetRooms.
     */
    export interface PartialParamMap {
      integrationId: string;
    }

    /**
     * Enumeration of all parameters for oauthClientGetRooms.
     */
    export enum Parameters {
      integrationId = 'integrationId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientGetRooms
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientGetRooms.PartialParamMap]?: [string, ValidatorFn][]} = {
      integrationId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for oauthClientRefreshToken.
 */
export namespace OauthClientRefreshToken {
    /**
     * Parameter map for oauthClientRefreshToken.
     */
    export interface PartialParamMap {
      integrationId?: string;
    }

    /**
     * Enumeration of all parameters for oauthClientRefreshToken.
     */
    export enum Parameters {
      integrationId = 'integrationId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientRefreshToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientRefreshToken.PartialParamMap]?: [string, ValidatorFn][]} = {
      integrationId: [
      ],
    };
}

/**
 * Namespace for oauthClientRemove.
 */
export namespace OauthClientRemove {
    /**
     * Parameter map for oauthClientRemove.
     */
    export interface PartialParamMap {
      integrationId?: string;
    }

    /**
     * Enumeration of all parameters for oauthClientRemove.
     */
    export enum Parameters {
      integrationId = 'integrationId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientRemove
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientRemove.PartialParamMap]?: [string, ValidatorFn][]} = {
      integrationId: [
      ],
    };
}

/**
 * Namespace for oauthClientResult.
 */
export namespace OauthClientResult {
    /**
     * Parameter map for oauthClientResult.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for oauthClientResult.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientResult
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientResult.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for oauthClientReturn.
 */
export namespace OauthClientReturn {
    /**
     * Parameter map for oauthClientReturn.
     */
    export interface PartialParamMap {
      responseValue?: string;
    }

    /**
     * Enumeration of all parameters for oauthClientReturn.
     */
    export enum Parameters {
      responseValue = 'responseValue'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientReturn
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientReturn.PartialParamMap]?: [string, ValidatorFn][]} = {
      responseValue: [
      ],
    };
}

/**
 * Namespace for oauthClientStartIntegration.
 */
export namespace OauthClientStartIntegration {
    /**
     * Parameter map for oauthClientStartIntegration.
     */
    export interface PartialParamMap {
      IntegrationStartDto: IntegrationStartDto;
    }

    /**
     * Enumeration of all parameters for oauthClientStartIntegration.
     */
    export enum Parameters {
      IntegrationStartDto = 'IntegrationStartDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientStartIntegration
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientStartIntegration.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for oauthClientWebexClient.
 */
export namespace OauthClientWebexClient {
    /**
     * Parameter map for oauthClientWebexClient.
     */
    export interface PartialParamMap {
      integrationId: string;
      roomId: string;
    }

    /**
     * Enumeration of all parameters for oauthClientWebexClient.
     */
    export enum Parameters {
      integrationId = 'integrationId',
      roomId = 'roomId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of oauthClientWebexClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof OauthClientWebexClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      integrationId: [
              ['required', Validators.required],
      ],
      roomId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class OauthClientService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientFinishByMap(
    map: OauthClientFinish.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public oauthClientFinishByMap(
    map: OauthClientFinish.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public oauthClientFinishByMap(
    map: OauthClientFinish.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public oauthClientFinishByMap(
    map: OauthClientFinish.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientFinish(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientFinish(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public oauthClientFinish(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public oauthClientFinish(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public oauthClientFinish(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/oauth-client/finish`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_Finish']) {
                this.cancelMap['OauthClient_Finish'].next(null);
            }
            this.cancelMap['OauthClient_Finish'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_Finish']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_Finish']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_Finish', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_Finish']) {
            this.cancelMap['OauthClient_Finish'].complete();
            delete this.cancelMap['OauthClient_Finish'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_Finish',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_Finish')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientGetExhibitorIntegrationsByMap(
    map: OauthClientGetExhibitorIntegrations.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<IntegrationPartnerDto>>;
  public oauthClientGetExhibitorIntegrationsByMap(
    map: OauthClientGetExhibitorIntegrations.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<IntegrationPartnerDto>>>;
  public oauthClientGetExhibitorIntegrationsByMap(
    map: OauthClientGetExhibitorIntegrations.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<IntegrationPartnerDto>>>;
  public oauthClientGetExhibitorIntegrationsByMap(
    map: OauthClientGetExhibitorIntegrations.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientGetExhibitorIntegrations(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientGetExhibitorIntegrations(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<IntegrationPartnerDto>>;
    public oauthClientGetExhibitorIntegrations(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<IntegrationPartnerDto>>>;
    public oauthClientGetExhibitorIntegrations(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<IntegrationPartnerDto>>>;
    public oauthClientGetExhibitorIntegrations(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling oauthClientGetExhibitorIntegrations.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling oauthClientGetExhibitorIntegrations.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<IntegrationPartnerDto>>(`${this.configuration.basePath}/api/oauth-client/list/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_GetExhibitorIntegrations']) {
                this.cancelMap['OauthClient_GetExhibitorIntegrations'].next(null);
            }
            this.cancelMap['OauthClient_GetExhibitorIntegrations'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_GetExhibitorIntegrations']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_GetExhibitorIntegrations']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_GetExhibitorIntegrations', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_GetExhibitorIntegrations']) {
            this.cancelMap['OauthClient_GetExhibitorIntegrations'].complete();
            delete this.cancelMap['OauthClient_GetExhibitorIntegrations'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_GetExhibitorIntegrations',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_GetExhibitorIntegrations')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientGetExpoIntegrationsByMap(
    map: OauthClientGetExpoIntegrations.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<IntegrationPartnerDto>>;
  public oauthClientGetExpoIntegrationsByMap(
    map: OauthClientGetExpoIntegrations.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<IntegrationPartnerDto>>>;
  public oauthClientGetExpoIntegrationsByMap(
    map: OauthClientGetExpoIntegrations.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<IntegrationPartnerDto>>>;
  public oauthClientGetExpoIntegrationsByMap(
    map: OauthClientGetExpoIntegrations.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientGetExpoIntegrations(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientGetExpoIntegrations(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<IntegrationPartnerDto>>;
    public oauthClientGetExpoIntegrations(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<IntegrationPartnerDto>>>;
    public oauthClientGetExpoIntegrations(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<IntegrationPartnerDto>>>;
    public oauthClientGetExpoIntegrations(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling oauthClientGetExpoIntegrations.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<IntegrationPartnerDto>>(`${this.configuration.basePath}/api/oauth-client/list/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_GetExpoIntegrations']) {
                this.cancelMap['OauthClient_GetExpoIntegrations'].next(null);
            }
            this.cancelMap['OauthClient_GetExpoIntegrations'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_GetExpoIntegrations']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_GetExpoIntegrations']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_GetExpoIntegrations', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_GetExpoIntegrations']) {
            this.cancelMap['OauthClient_GetExpoIntegrations'].complete();
            delete this.cancelMap['OauthClient_GetExpoIntegrations'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_GetExpoIntegrations',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_GetExpoIntegrations')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientGetRoomsByMap(
    map: OauthClientGetRooms.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<WebexRoomDto>>;
  public oauthClientGetRoomsByMap(
    map: OauthClientGetRooms.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<WebexRoomDto>>>;
  public oauthClientGetRoomsByMap(
    map: OauthClientGetRooms.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<WebexRoomDto>>>;
  public oauthClientGetRoomsByMap(
    map: OauthClientGetRooms.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientGetRooms(
      map.integrationId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param integrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientGetRooms(integrationId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<WebexRoomDto>>;
    public oauthClientGetRooms(integrationId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<WebexRoomDto>>>;
    public oauthClientGetRooms(integrationId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<WebexRoomDto>>>;
    public oauthClientGetRooms(integrationId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (integrationId === null || integrationId === undefined) {
            throw new Error('Required parameter integrationId was null or undefined when calling oauthClientGetRooms.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<WebexRoomDto>>(`${this.configuration.basePath}/api/oauth-client/webex/rooms/${encodeURIComponent(String(integrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_GetRooms']) {
                this.cancelMap['OauthClient_GetRooms'].next(null);
            }
            this.cancelMap['OauthClient_GetRooms'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_GetRooms']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_GetRooms']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_GetRooms', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_GetRooms']) {
            this.cancelMap['OauthClient_GetRooms'].complete();
            delete this.cancelMap['OauthClient_GetRooms'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_GetRooms',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_GetRooms')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientRefreshTokenByMap(
    map: OauthClientRefreshToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public oauthClientRefreshTokenByMap(
    map: OauthClientRefreshToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public oauthClientRefreshTokenByMap(
    map: OauthClientRefreshToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public oauthClientRefreshTokenByMap(
    map: OauthClientRefreshToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientRefreshToken(
      map.integrationId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param integrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientRefreshToken(integrationId?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public oauthClientRefreshToken(integrationId?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public oauthClientRefreshToken(integrationId?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public oauthClientRefreshToken(integrationId?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (integrationId !== undefined && integrationId !== null) {
            queryParameters = queryParameters.set('integrationId', <any>integrationId);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/oauth-client`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_RefreshToken']) {
                this.cancelMap['OauthClient_RefreshToken'].next(null);
            }
            this.cancelMap['OauthClient_RefreshToken'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_RefreshToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_RefreshToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_RefreshToken', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_RefreshToken']) {
            this.cancelMap['OauthClient_RefreshToken'].complete();
            delete this.cancelMap['OauthClient_RefreshToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_RefreshToken',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_RefreshToken')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientRemoveByMap(
    map: OauthClientRemove.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public oauthClientRemoveByMap(
    map: OauthClientRemove.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public oauthClientRemoveByMap(
    map: OauthClientRemove.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public oauthClientRemoveByMap(
    map: OauthClientRemove.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientRemove(
      map.integrationId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param integrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientRemove(integrationId?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public oauthClientRemove(integrationId?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public oauthClientRemove(integrationId?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public oauthClientRemove(integrationId?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (integrationId !== undefined && integrationId !== null) {
            queryParameters = queryParameters.set('integrationId', <any>integrationId);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete(`${this.configuration.basePath}/api/oauth-client`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_Remove']) {
                this.cancelMap['OauthClient_Remove'].next(null);
            }
            this.cancelMap['OauthClient_Remove'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_Remove']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_Remove']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_Remove', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_Remove']) {
            this.cancelMap['OauthClient_Remove'].complete();
            delete this.cancelMap['OauthClient_Remove'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_Remove',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_Remove')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientResultByMap(
    map: OauthClientResult.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public oauthClientResultByMap(
    map: OauthClientResult.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public oauthClientResultByMap(
    map: OauthClientResult.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public oauthClientResultByMap(
    map: OauthClientResult.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientResult(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientResult(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public oauthClientResult(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public oauthClientResult(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public oauthClientResult(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/oauth-client/result`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_Result']) {
                this.cancelMap['OauthClient_Result'].next(null);
            }
            this.cancelMap['OauthClient_Result'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_Result']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_Result']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_Result', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_Result']) {
            this.cancelMap['OauthClient_Result'].complete();
            delete this.cancelMap['OauthClient_Result'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_Result',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_Result')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientReturnByMap(
    map: OauthClientReturn.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public oauthClientReturnByMap(
    map: OauthClientReturn.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public oauthClientReturnByMap(
    map: OauthClientReturn.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public oauthClientReturnByMap(
    map: OauthClientReturn.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientReturn(
      map.responseValue,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param responseValue 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientReturn(responseValue?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public oauthClientReturn(responseValue?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public oauthClientReturn(responseValue?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public oauthClientReturn(responseValue?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (responseValue !== undefined && responseValue !== null) {
            queryParameters = queryParameters.set('responseValue', <any>responseValue);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/oauth-client/return`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_Return']) {
                this.cancelMap['OauthClient_Return'].next(null);
            }
            this.cancelMap['OauthClient_Return'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_Return']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_Return']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_Return', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_Return']) {
            this.cancelMap['OauthClient_Return'].complete();
            delete this.cancelMap['OauthClient_Return'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_Return',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_Return')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientStartIntegrationByMap(
    map: OauthClientStartIntegration.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public oauthClientStartIntegrationByMap(
    map: OauthClientStartIntegration.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public oauthClientStartIntegrationByMap(
    map: OauthClientStartIntegration.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public oauthClientStartIntegrationByMap(
    map: OauthClientStartIntegration.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientStartIntegration(
      map.IntegrationStartDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param IntegrationStartDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientStartIntegration(IntegrationStartDto: IntegrationStartDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public oauthClientStartIntegration(IntegrationStartDto: IntegrationStartDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public oauthClientStartIntegration(IntegrationStartDto: IntegrationStartDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public oauthClientStartIntegration(IntegrationStartDto: IntegrationStartDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (IntegrationStartDto === null || IntegrationStartDto === undefined) {
            throw new Error('Required parameter IntegrationStartDto was null or undefined when calling oauthClientStartIntegration.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/oauth-client/start`,
            IntegrationStartDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_StartIntegration']) {
                this.cancelMap['OauthClient_StartIntegration'].next(null);
            }
            this.cancelMap['OauthClient_StartIntegration'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_StartIntegration']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_StartIntegration']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_StartIntegration', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_StartIntegration']) {
            this.cancelMap['OauthClient_StartIntegration'].complete();
            delete this.cancelMap['OauthClient_StartIntegration'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_StartIntegration',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_StartIntegration')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public oauthClientWebexClientByMap(
    map: OauthClientWebexClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public oauthClientWebexClientByMap(
    map: OauthClientWebexClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public oauthClientWebexClientByMap(
    map: OauthClientWebexClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public oauthClientWebexClientByMap(
    map: OauthClientWebexClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.oauthClientWebexClient(
      map.integrationId,
      map.roomId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param integrationId 
     * @param roomId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public oauthClientWebexClient(integrationId: string, roomId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public oauthClientWebexClient(integrationId: string, roomId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public oauthClientWebexClient(integrationId: string, roomId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public oauthClientWebexClient(integrationId: string, roomId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (integrationId === null || integrationId === undefined) {
            throw new Error('Required parameter integrationId was null or undefined when calling oauthClientWebexClient.');
        }
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling oauthClientWebexClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/oauth-client/webex/rooms/${encodeURIComponent(String(integrationId))}/${encodeURIComponent(String(roomId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['OauthClient_WebexClient']) {
                this.cancelMap['OauthClient_WebexClient'].next(null);
            }
            this.cancelMap['OauthClient_WebexClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['OauthClient_WebexClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['OauthClient_WebexClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('OauthClient_WebexClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['OauthClient_WebexClient']) {
            this.cancelMap['OauthClient_WebexClient'].complete();
            delete this.cancelMap['OauthClient_WebexClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'OauthClient_WebexClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'OauthClient_WebexClient')));
        }
        return handle;
    }

  }

export namespace OauthClientService {
  export enum Operations {
    OauthClient_Finish = 'OauthClient_Finish',
    OauthClient_GetExhibitorIntegrations = 'OauthClient_GetExhibitorIntegrations',
    OauthClient_GetExpoIntegrations = 'OauthClient_GetExpoIntegrations',
    OauthClient_GetRooms = 'OauthClient_GetRooms',
    OauthClient_RefreshToken = 'OauthClient_RefreshToken',
    OauthClient_Remove = 'OauthClient_Remove',
    OauthClient_Result = 'OauthClient_Result',
    OauthClient_Return = 'OauthClient_Return',
    OauthClient_StartIntegration = 'OauthClient_StartIntegration',
    OauthClient_WebexClient = 'OauthClient_WebexClient'
  }
}
