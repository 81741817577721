/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';


export interface Display { 
    id?: string;
    sequence?: number;
    image?: string;
    source?: string;
    sourceAdditional?: string;
    link?: string;
    description?: string;
    displayType?: InventoryItemType;
    inActive?: boolean;
    deleted?: boolean;
    rowVersion?: number;
    uploadTs?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of Display.
 */
export namespace Display {
    /**
     * All properties of Display.
     */
    export enum Properties {
        id = 'id',
        sequence = 'sequence',
        image = 'image',
        source = 'source',
        sourceAdditional = 'sourceAdditional',
        link = 'link',
        description = 'description',
        displayType = 'displayType',
        inActive = 'inActive',
        deleted = 'deleted',
        rowVersion = 'rowVersion',
        uploadTs = 'uploadTs'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Display.
    */
    export const ModelValidators: {[K in keyof Display]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        sequence: [
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        source: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        sourceAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        link: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        displayType: [
        ],
        inActive: [
        ],
        deleted: [
        ],
        rowVersion: [
        ],
        uploadTs: [
        ],
    };

    /**
    * The FormControlFactory for Display.
    */
    export class FormControlFactory extends BaseFormControlFactory<Display> {

        /**
         * Constructor.
         *
         * @param model An existing model for Display.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Display = {
            id: null,
            sequence: null,
            image: null,
            source: null,
            sourceAdditional: null,
            link: null,
            description: null,
            displayType: null,
            inActive: null,
            deleted: null,
            rowVersion: null,
            uploadTs: null,
          }
        ) {
            super(model, Display.ModelValidators);
        }
    }

}


