/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MUserProfileEditDto } from './mUserProfileEditDto';


export interface TokenResultDto { 
    token?: string;
    refreshToken?: string;
    profile?: MUserProfileEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of TokenResultDto.
 */
export namespace TokenResultDto {
    /**
     * All properties of TokenResultDto.
     */
    export enum Properties {
        token = 'token',
        refreshToken = 'refreshToken',
        profile = 'profile'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TokenResultDto.
    */
    export const ModelValidators: {[K in keyof TokenResultDto]: [string, ValidatorFn][]} = {
        token: [
        ],
        refreshToken: [
        ],
        profile: [
        ],
    };

    /**
    * The FormControlFactory for TokenResultDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<TokenResultDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for TokenResultDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TokenResultDto = {
            token: null,
            refreshToken: null,
            profile: null,
          }
        ) {
            super(model, TokenResultDto.ModelValidators);
        }
    }

}


