/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BoothDto } from './boothDto';


export interface FloorPlanDto { 
    id?: string;
    name?: string;
    plan?: string;
    booths?: Array<BoothDto>;
}

/**
 * Namespace for property- and property-value-enumerations of FloorPlanDto.
 */
export namespace FloorPlanDto {
    /**
     * All properties of FloorPlanDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        plan = 'plan',
        booths = 'booths'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of FloorPlanDto.
    */
    export const ModelValidators: {[K in keyof FloorPlanDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        plan: [
        ],
        booths: [
        ],
    };

    /**
    * The FormControlFactory for FloorPlanDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<FloorPlanDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for FloorPlanDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: FloorPlanDto = {
            id: null,
            name: null,
            plan: null,
            booths: null,
          }
        ) {
            super(model, FloorPlanDto.ModelValidators);
        }
    }

}


