/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { FeaturedMode } from './featuredMode';
import { DateMode } from './dateMode';
import { InventoryItemType } from './inventoryItemType';
import { CalendarEntryType } from './calendarEntryType';
import { StageMode } from './stageMode';
import { MExhibitorReferenceDto } from './mExhibitorReferenceDto';


export interface MCalendarEntryStageDto { 
    id?: string;
    stageShortKey?: string;
    label: string;
    entryType?: CalendarEntryType;
    description?: string;
    speakerId?: string;
    start?: Date;
    duration?: number;
    deleted?: boolean;
    logo?: string;
    sourceType?: string;
    sourceValue?: string;
    useProfile?: boolean;
    speakerName?: string;
    speakerDescription?: string;
    speakerRole?: string;
    speakerCompany?: string;
    speakerImage?: string;
    speakerSocialProfiles?: { [key: string]: string; };
    streamType?: StreamType;
    stream?: string;
    streamAdditional?: string;
    streamAdditional2?: string;
    vodType?: InventoryItemType;
    vodSource?: string;
    vodSourceAdditional?: string;
    vodComment?: string;
    hidden?: boolean;
    showSpeaker?: boolean;
    background?: string;
    stageMode?: StageMode;
    exhibitors?: Array<MExhibitorReferenceDto>;
    featured?: FeaturedMode;
    dateMode?: DateMode;
}

/**
 * Namespace for property- and property-value-enumerations of MCalendarEntryStageDto.
 */
export namespace MCalendarEntryStageDto {
    /**
     * All properties of MCalendarEntryStageDto.
     */
    export enum Properties {
        id = 'id',
        stageShortKey = 'stageShortKey',
        label = 'label',
        entryType = 'entryType',
        description = 'description',
        speakerId = 'speakerId',
        start = 'start',
        duration = 'duration',
        deleted = 'deleted',
        logo = 'logo',
        sourceType = 'sourceType',
        sourceValue = 'sourceValue',
        useProfile = 'useProfile',
        speakerName = 'speakerName',
        speakerDescription = 'speakerDescription',
        speakerRole = 'speakerRole',
        speakerCompany = 'speakerCompany',
        speakerImage = 'speakerImage',
        speakerSocialProfiles = 'speakerSocialProfiles',
        streamType = 'streamType',
        stream = 'stream',
        streamAdditional = 'streamAdditional',
        streamAdditional2 = 'streamAdditional2',
        vodType = 'vodType',
        vodSource = 'vodSource',
        vodSourceAdditional = 'vodSourceAdditional',
        vodComment = 'vodComment',
        hidden = 'hidden',
        showSpeaker = 'showSpeaker',
        background = 'background',
        stageMode = 'stageMode',
        exhibitors = 'exhibitors',
        featured = 'featured',
        dateMode = 'dateMode'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MCalendarEntryStageDto.
    */
    export const ModelValidators: {[K in keyof MCalendarEntryStageDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        stageShortKey: [
        ],
        label: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        entryType: [
        ],
        description: [
        ],
        speakerId: [
        ],
        start: [
        ],
        duration: [
        ],
        deleted: [
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        sourceType: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        sourceValue: [
        ],
        useProfile: [
        ],
        speakerName: [
        ],
        speakerDescription: [
        ],
        speakerRole: [
        ],
        speakerCompany: [
        ],
        speakerImage: [
        ],
        speakerSocialProfiles: [
        ],
        streamType: [
        ],
        stream: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional2: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        vodSourceAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        vodComment: [
        ],
        hidden: [
        ],
        showSpeaker: [
        ],
        background: [
        ],
        stageMode: [
        ],
        exhibitors: [
        ],
        featured: [
        ],
        dateMode: [
        ],
    };

    /**
    * The FormControlFactory for MCalendarEntryStageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<MCalendarEntryStageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for MCalendarEntryStageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MCalendarEntryStageDto = {
            id: null,
            stageShortKey: null,
            label: null,
            entryType: null,
            description: null,
            speakerId: null,
            start: null,
            duration: null,
            deleted: null,
            logo: null,
            sourceType: null,
            sourceValue: null,
            useProfile: null,
            speakerName: null,
            speakerDescription: null,
            speakerRole: null,
            speakerCompany: null,
            speakerImage: null,
            speakerSocialProfiles: null,
            streamType: null,
            stream: null,
            streamAdditional: null,
            streamAdditional2: null,
            vodType: null,
            vodSource: null,
            vodSourceAdditional: null,
            vodComment: null,
            hidden: null,
            showSpeaker: null,
            background: null,
            stageMode: null,
            exhibitors: null,
            featured: null,
            dateMode: null,
          }
        ) {
            super(model, MCalendarEntryStageDto.ModelValidators);
        }
    }

}


