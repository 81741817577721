/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { MInfoSlideEditDto } from './mInfoSlideEditDto';


export interface MInfoSlideUpdateResult { 
    rowVersion?: number;
    infoSlide?: MInfoSlideEditDto;
}

/**
 * Namespace for property- and property-value-enumerations of MInfoSlideUpdateResult.
 */
export namespace MInfoSlideUpdateResult {
    /**
     * All properties of MInfoSlideUpdateResult.
     */
    export enum Properties {
        rowVersion = 'rowVersion',
        infoSlide = 'infoSlide'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of MInfoSlideUpdateResult.
    */
    export const ModelValidators: {[K in keyof MInfoSlideUpdateResult]: [string, ValidatorFn][]} = {
        rowVersion: [
        ],
        infoSlide: [
        ],
    };

    /**
    * The FormControlFactory for MInfoSlideUpdateResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<MInfoSlideUpdateResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for MInfoSlideUpdateResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: MInfoSlideUpdateResult = {
            rowVersion: null,
            infoSlide: null,
          }
        ) {
            super(model, MInfoSlideUpdateResult.ModelValidators);
        }
    }

}


