interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {name: 'jitsi', src: 'https://conference.meet.dotenterprise.com/external_api.js'},
  {name: 'iubenda', src: 'https://cdn.iubenda.com/iubenda.js'},
  {name: 'jquery', src: '/assets/jquery-2.1.4.min.js'},
  {name: 'jquery3', src: 'https://code.jquery.com/jquery-3.5.1.min.js'},
  {name: 'jitsi-api', src: 'https://meet.jit.si/libs/lib-jitsi-meet.min.js'},
];
