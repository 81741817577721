/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * ezFair Admin
 * ezFair API
 *
 * OpenAPI spec version: 4.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { HallDto } from './hallDto';


export interface SceneDto { 
    id?: number;
    name?: string;
    description?: string;
    descriptionLong?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    active?: boolean;
    template?: boolean;
    used?: boolean;
    expoId?: string;
    halls?: Array<HallDto>;
}

/**
 * Namespace for property- and property-value-enumerations of SceneDto.
 */
export namespace SceneDto {
    /**
     * All properties of SceneDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        active = 'active',
        template = 'template',
        used = 'used',
        expoId = 'expoId',
        halls = 'halls'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of SceneDto.
    */
    export const ModelValidators: {[K in keyof SceneDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        active: [
        ],
        template: [
        ],
        used: [
        ],
        expoId: [
        ],
        halls: [
        ],
    };

    /**
    * The FormControlFactory for SceneDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<SceneDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for SceneDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: SceneDto = {
            id: null,
            name: null,
            description: null,
            descriptionLong: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            active: null,
            template: null,
            used: null,
            expoId: null,
            halls: null,
          }
        ) {
            super(model, SceneDto.ModelValidators);
        }
    }

}


